
class LocoArmM {

    constructor () {
        this.port = {};
        this.bufferSize = 100;

        this.optionNames = ['Temperature', 'Light', 'Solar'];

        this.options = {'Temperature': {refer: 'READ_TEMP', count: 1},
                        'Light': {refer: 'READ_LIGHT', count: 1},
                        'Solar': {refer: 'READ_SOLAR', count: 1},
        };

        this.self = {
            READ_TEMP: {
                value: 116,
                names: ['Temperature'],
                ylabels: ["Temperature (C)"],
            },
            READ_LIGHT: {
                value: 108,
                names: ['Light'],
                ylabels: ["Light (Lux)"],
            },
            READ_SOLAR: {
                value: 112,
                names: ['Solar Power'],
                ylabels: ["Power (Watts)"],
            },
            tempArray: [],
            lightArray: [],
            solarArray: [],
        };
    }

    resetArrays = () => {

        this.self.tempArray = [];
        this.self.lightArray = [];
        this.self.solarArray = [];
    }

    connect = async () => {
        try {
            window.port.disconnect();
        } catch {
        }
        this.port = await window.serial.requestPort();
        await this.port.connect();
        this.port.onReceive = data => {
            this.processData(data);
        }
        this.port.onReceiveError = (error) => {            
        }
    }

    disconnect = () => {
        return this.port.disconnect();
    }

    send_command = (data_msg) => {
        let data = new Uint8Array(1);
        data[0] = data_msg;
        this.port.send(data);
    };

    
    set_buffer_size = (buffer_size) => {
        this.bufferSize = buffer_size
    }

    get_buffer_size = () => {
        return this.bufferSize
    }

    get_data_array = (type) => {
        
        switch(type) {    
            case this.self.READ_TEMP.value:
                return [this.self.tempArray];
            case this.self.READ_LIGHT.value:
                return [this.self.lightArray];
            case this.self.READ_SOLAR.value:
                return [this.self.solarArray];
            default:
                return [0];
        }
    };

    processData = (data) => {
        let dataBytes = new Uint8Array(data.buffer);
        switch(dataBytes[0]) {
    
            case this.self.READ_TEMP.value:
                const readTempSlice = dataBytes.slice(1, 5);
    
                const tempArr = new Float32Array(readTempSlice.buffer);
    
                if (this.self.tempArray.length > this.bufferSize) {    
                    this.self.tempArray.splice(0, 1);
                }

                this.self.tempArray.push(tempArr[0]);    
                break;

            case this.self.READ_LIGHT.value:
                const readLightSlice = dataBytes.slice(1, 5);
    
                const lightArr = new Float32Array(readLightSlice.buffer);
    
                if (this.self.lightArray.length > this.bufferSize) {    
                    this.self.lightArray.splice(0, 1);
                }

                this.self.lightArray.push(lightArr[0]);    
                break;

            case this.self.READ_SOLAR.value:
                const readsolarSlice = dataBytes.slice(1, 5);
    
                const solarArr = new Float32Array(readsolarSlice.buffer);
    
                if (this.self.solarArray.length > this.bufferSize) {    
                    this.self.solarArray.splice(0, 1);
                }

                this.self.solarArray.push(solarArr[0]);    
                break;
            default:
                break;
        }
    }

};

export default LocoArmM;