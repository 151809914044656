

const MLED_TIMEOUT = 500;

const WAIT_FAIL_LIM = 6;
const WAIT_FAIL_STR = "Communication Failure";


class LocoDroneTT {

    constructor () {
        this.bufferSize = 100;

        this.optionNamesSuper = ['Accelerometer', 'Battery', 'Temperature', 'Attitude', 'Matrix', 'RGB'];

        this.optionNamesSub = ['Accelerometer', 'Battery', 'Temperature', 'Attitude'];
        this.optionNames = this.optionNamesSuper


        this.superoptions = {
            'Accelerometer': {refer: 'DATA_ACCEL', count: 3, range: ['min', 'min', 'min'], offset: 0.25,},
            'Battery': {refer: 'DATA_BATTERY', count: 1, range: ['fixed'], fixed: [0, 101], offset: 0.25,},
            'Temperature': {refer: 'DATA_TEMP', count: 1, range: ['min'], offset: 15,},
            'Attitude': {refer: 'DATA_ATTITUDE', count: 3, range: ['min', 'min', 'min'], offset: 0.25,},
            'Matrix': {count: "matrix"},
            'RGB': {count: "rgb"},
        }

        this.suboptions = {
            'Accelerometer': {refer: 'DATA_ACCEL', count: 3, range: ['min', 'min', 'min'], offset: 0.25,},
            'Battery': {refer: 'DATA_BATTERY', count: 1, range: ['fixed'], fixed: [0, 101], offset: 0.25,},
            'Temperature': {refer: 'DATA_TEMP', count: 1, range: ['min'], offset: 15,},
            'Attitude': {refer: 'DATA_ATTITUDE', count: 3, range: ['min', 'min', 'min'], offset: 0.25,},
        }

        this.options = this.superoptions

        this.TIMEOUT_STR = "timeout";
        this.FAILURE_STR = "failure";
        this.TIMEOUT_MAX = 3000;
        this.timeout_count = 0;

        this.mled_timeout_max = MLED_TIMEOUT/ 5;
        this.mled_timeout_count = 0;

        this.ldtTimeoutID = null;

        this.can_process = false;

        this.drones = [];
        // For System Endianness
        this.endianFactor = this.get_endian_factor();

        this.read_loop = {
            targetLen: 0,
            targetLenOff: 0,
            header: 0,
            hasTargetLength: false,
            hasHeader: false,
        }
        
        this.read_buffer = {
            data: [],
        }

        this.sharedData = {
            drones: {
                scanEnd: false,
                scanData: [],
                connectEnd: false,
                connectState: 0,
                receivedData: false,
                waitFlag: false,
                waitData: "",
                waitFail: false,
                waitFailCount: 0,
                messageData: {}
            }
        };
        
        this.monitor_timer_ID = null;
        this.monitor_drone_flag = 0;
        this.CONNECTED_MONITOR = 0;
        this.PLOTTING_MONITOR = 0;
        

        this.battery_last = 0;
        this.temperature_last = 0;
        this.BATTERY_THRESH = 12
        this.TEMP_THRESH = 91

        this.VITALS_MONITOR_TIME_A = 500 // milliseconds

        this.ldt_vitals_str = ""

        this.VITALS_OK = "OK";
        this.VITALS_LOW_BATT = "Low Battery! Please Charge Drone"
        this.VITALS_HIGH_TEMP = "Overheating! Please Power-Down Drone"

        this.self = {

            'DATA_ACCEL': {
                value: "DATA_ACCEL",
                names: ['ax', 'ay', 'az'],
                ylabels: ["AX (g)", "AY (g)", "AZ (g)"],
            },
            'DATA_BATTERY': {
                value: "DATA_BATTERY",
                names: ['battery'],
                ylabels: ["B (%)"],
            },
            'DATA_TEMP': {
                value: "DATA_TEMP",
                names: ['temperature'],
                ylabels: ["T (C)"],
            },
            'DATA_ATTITUDE': {
                value: "DATA_ATTITUDE",
                names: ['pitch', 'roll', 'yaw'],
                ylabels: ["Pitch (deg)", "Roll (deg)", "Yaw (deg)"],
            },
            axArray: [],
            ayArray: [],
            azArray: [],
            battArray: [],
            tempArray: [],
            pitchArray: [],
            rollArray: [],
            yawArray: [],
        };

        this.wrap_flag = 0;

    }

    
    set_buffer_size = (buffer_size) => {
        this.bufferSize = buffer_size
    }

    get_buffer_size = () => {
        return this.bufferSize
    }

    setupWait = () => {
        this.sharedData.drones['waitFlag'] = true;
        this.sharedData.drones['waitFail'] = false;
        this.sharedData.drones['waitFailCount'] = 0;
    }

    clearLDTTimeouts = () => {
        try {
            clearTimeout(this.ldtTimeoutID);
        } catch {}
    }

    clearData = () => {
        this.timeout_count = 0;

        clearTimeout(this.ldtTimeoutID);
        this.ldtTimeoutID = null;
        this.can_process = false;

        this.read_loop = {
            targetLen: 0,
            targetLenOff: 0,
            header: 0,
            hasTargetLength: false,
            hasHeader: false,
        }
        
        this.read_buffer = {
            data: [],
        }

        this.sharedData = {
            drones: {
                scanEnd: false,
                scanData: [],
                connectEnd: false,
                connectState: 0,
                receivedData: false,
                waitFlag: false,
                waitData: "",
                waitFail: false,
                waitFailCount: 0,
                messageData: {}
            }
        };
        this.wrap_flag = 0;
        
        this.battery_last = 0;
        this.temperature_last = 0;
    }

    resetArrays = () => {
        this.self.axArray = [];
        this.self.ayArray = [];
        this.self.azArray = [];
        this.self.battArray = [];
        this.self.tempArray = [];
        this.self.pitchArray = [];
        this.self.rollArray = [];
        this.self.yawArray =  [];
    };

    // System Endianness Factor For Data Type Conversions
    get_endian_factor = () => {
        let uInt32 = new Uint32Array([0x11223344]);
        let uInt8 = new Uint8Array(uInt32.buffer);
        let endianFactor = 0;
        if (uInt8[0] = 0x11) {
            endianFactor = 3
        }
        return endianFactor;
    }


    writePacket = async (data) => {
        let packet = Uint8Array.from(data);
        await this.webserial_write(packet);
    }

    webserial_write = async (data) => {
        //console.log("data write", data)

        if (this.can_process === false) {
            this.can_process = true
        }
        try {
            const writer = window.serialport.writable.getWriter();
            await writer.write(data);
            writer.releaseLock();
        } catch {}
    }

    drone_scan = async () => {

        this.sharedData.drones['scanEnd'] = false
        this.sharedData.drones['scanData'] = []

        let packet = [TELLO_HEADERS.SSID_LIST_START, 1, 0];
        this.writePacket(packet);

        let me = this;
        this.timeout_count = 0;
        
        return new Promise(function (resolve, reject) {     
            var f = function () {
                if (me.sharedData.drones['scanEnd'] === true) {
                    resolve("done");
                } else {
                    if (me.timeout_count < 30000) { // 
                        me.ldtTimeoutID = window.setTimeout(f, 5);
                    } else {
                        resolve(me.TIMEOUT_STR);
                    }                    
                    me.timeout_count += 10;
                }
            }
            window.setTimeout(f, 5);
        });
    }

    connect_wifi = async (i) => {
        
        this.sharedData.drones['connectEnd'] = false
        this.setupWait();

        let me = this;
        
        return new Promise(function (resolve, reject) {
            
            let drone_ssid = me.sharedData.drones['scanData'][i]
            let packet = [TELLO_HEADERS.TELLO_CONNECT, drone_ssid.length];
            for (let t = 0; t < drone_ssid.length; t++) {
                packet.push(drone_ssid.charCodeAt(t));
            }
            me.writePacket(packet);  
            
            var m = function () {
                resolve(me.sharedData.drones['scanData'][i].includes("TELLO"));
            }

            var f = function () {
                if (me.sharedData.drones['connectEnd'] === true) {
                    if (me.sharedData.drones['connectState'] !== CONNECT_STATES.CONNECT_OK) {
                        reject("Could Not Connect To The Drone. Please Try Again.");
                    } else {
                        if (me.sharedData.drones['scanData'][i].includes("TELLO") === true) {
                            me.options = me.suboptions;
                            me.optionNames = me.optionNamesSub
                        } else {
                            me.options = me.superoptions;
                            me.optionNames = me.optionNamesSuper
                        }

                        me.ldtTimeoutID = window.setTimeout(m, 2000);
                    }
                } else {
                    me.ldtTimeoutID = window.setTimeout(f, 5);
                }
            }

            me.ldtTimeoutID = window.setTimeout(f, 5);
        });
    }


    connect_usb = async () => {
        this.can_process = false
        this.drones = [];
        this.connected_wait =  false;
        this.drone = {};

        if (window.serialport) {
            if (window.reader) {
                await window.reader.cancel();
                window.reader = null;
            }
            // Close the port.
            try {
                await window.serialport.close();
            } catch (err) {}
            window.serialport = null;        
        }
            
        // - Request a port and open a connection.
        try {
            const usbVendorId = 0x10C4;
            window.serialport = await navigator.serial.requestPort({ filters: [{ usbVendorId }]});
        } catch(err) {
            console.log("error", err)
        }
        // - Wait for the port to open.
        await window.serialport.open({ baudRate: 115200, bufferSize: 2048 });
        
        window.reader = window.serialport.readable.getReader();
        
        this.readLoop()
    }

    connect = async () => {

        let me = this;
        return new Promise(function (resolve, reject) {

            var m = function() {
                resolve("done");
            }
            return me.connect_usb().then(() => {
                me.ldtTimeoutID = window.setTimeout(m, 1000);
            }).catch((err) => {
                resolve("error")
            });
        });
    }

    webserial_disconnect = async () => {
        try {
          // Close the input stream (reader).
          if (window.reader) {
            try {
                await window.reader.cancel();                
            } catch (err) {
            }
            window.reader = null;
          }
          // Close the output stream.
          if (window.outputStream) {
            await window.outputStream.getWriter().close();
            await window.outputDone;
            window.outputStream = null;
            window.outputDone = null;
          }
          // Close the port.
          await window.serialport.close();
          window.serialport = null;
          //log.textContent = "Dongle Disconnected!";
        } catch (err) {
        }
    }

    disconnect_usb = async () => {
        await this.webserial_disconnect();
    }

    disconnect = async () => {
        await this.webserial_disconnect();
    }

    disconnect_drone = async () => {

        let packet = [TELLO_HEADERS.TELLO_DISCONNECT, 1, 0];
        this.writePacket(packet);

        var me = this;

        return new Promise(function (resolve, reject) {
            var f = function () {
                resolve("done")
            }
            
            me.ldtTimeoutID = window.setTimeout(f, 50);
        });
    }


    get_data_array = (type) => {
        
        switch(type) {
            case this.self['DATA_ACCEL'].value:
                return [this.self.axArray, this.self.ayArray, this.self.azArray];
            case this.self['DATA_BATTERY'].value:
                return [this.self.battArray];
            case this.self['DATA_TEMP'].value:
                return [this.self.tempArray];
            case this.self['DATA_ATTITUDE'].value:
                return [this.self.pitchArray, this.self.rollArray, this.self.yawArray];
        }
    };

    drone_get_data = async (data_reference) => {

        this.sharedData.drones['receivedData'] = false
        this.setupWait()

        let packet = [TELLO_HEADERS.TELLO_DATA, 1, DATA_TYPES[data_reference]];
        this.writePacket(packet);
        this.timeout_count = 0
        let me = this

        return new Promise(function (resolve, reject) {
            var f = function () {
                
                if (me.sharedData.drones['receivedData'] === false) {
                    if (me.sharedData.drones['waitFail'] === true) {
                        me.sharedData.drones['waitFail'] = false
                        me.sharedData.drones['waitFailCount'] += 1
                        if (me.sharedData.drones['waitFailCount'] < WAIT_FAIL_LIM) {
                            me.writePacket(packet);
                            me.ldtTimeoutID = window.setTimeout(f, 5);
                        } else {
                            //resolve(me.TIMEOUT_STR);
                            this.battery_last = "NaN"
                            this.temperature_last = "NaN"
                            resolve(me.FAILURE_STR);
                        }
                    } else {
                        me.ldtTimeoutID = window.setTimeout(f, 5);
                    }
                } else {
                    me.wrap_flag = 0;
                    me.sharedData.drones['receivedData'] = false
                    resolve("done");
                }
            }
            me.ldtTimeoutID = window.setTimeout(f, 5);
        });
    }

    drone_monitor_vitals = async () => {

        await this.drone_get_data("DATA_BATTERY")
        await this.drone_get_data("DATA_TEMP")

        return "Battery Level (%) " + this.battery_last + ",  Temperature (C): " + this.temperature_last
        //return [this.battery_last, this.temperature_last]
        //return 
    }

    drone_check_vitals = () => {

        if (this.battery_last < this.BATTERY_THRESH) {
            return this.VITALS_LOW_BATT
        } else if (this.temperature_last > this.TEMP_THRESH) {
            return this.VITALS_HIGH_TEMP
        } else {
            return this.VITALS_OK
        }
    }

    get_vitals_timer_ID = () => {
        return this.monitor_timer_ID
    }

    set_vitals_timer_ID = (timer_id) => {
        this.monitor_timer_ID = timer_id
    }

    drone_tt_mled_set = async (matrix_str) => {

        if (matrix_str.length > TELLO_BOUNDS.MTX_SET_MAX) {
            matrix_str = matrix_str.slice(0, TELLO_BOUNDS.MTX_SET_MAX)
        }

        let packet = [TELLO_HEADERS.TELLO_MTX_SET, matrix_str.length]

        for (let m = 0; m < matrix_str.length; m++) {
            if (MLED_OPTS.includes(matrix_str[m])) {
                packet.push(matrix_str[m].charCodeAt())
            } else {
                packet.push(MLED.OFF.charCodeAt())
            }
        }
        this.writePacket(packet);

        this.setupWait();
        
        let me = this;

        return new Promise(function (resolve, reject) {
            var f = function() {
                if (me.sharedData.drones['waitFlag'] === true) {
                    if (me.sharedData.drones['waitFail'] === true) {
                        me.sharedData.drones['waitFail'] = false
                        me.sharedData.drones['waitFailCount'] += 1
                        if (me.sharedData.drones['waitFailCount'] < WAIT_FAIL_LIM) {
                            me.writePacket(packet);
                        } else {
                            resolve(me.FAILURE_STR);
                        }
                    }
                    me.ldtTimeoutID = window.setTimeout(f, 5);
                    
                    //

                } else {
                    resolve("done");
                }
            }
            window.setTimeout(f, 5);
        }).then(() => {
            return me.sharedData.drones['waitData']
        });

    }


    drone_tt_led_set = async (r, g, b) => {

        let packet = [TELLO_HEADERS.TELLO_LED_SET, 3, r, g, b];

        this.writePacket(packet);

        this.setupWait();

        this.sharedData.drones['waitFlag'] = true;
        this.mled_timeout_count = 0;
        let me = this;

        return new Promise(function (resolve, reject) {
            var f = function() {
                if (me.sharedData.drones['waitFlag'] === true) {
                    if (me.sharedData.drones['waitFail'] === true) {
                        me.sharedData.drones['waitFail'] = false
                        me.sharedData.drones['waitFailCount'] += 1
                        if (me.sharedData.drones['waitFailCount'] < WAIT_FAIL_LIM) {
                            me.writePacket(packet);
                        } else {
                            resolve(me.FAILURE_STR);
                        }
                    }
                    me.ldtTimeoutID = window.setTimeout(f, 5);
                } else {
                    resolve("done");
                }
            }
            me.ldtTimeoutID = window.setTimeout(f, 5);
        }).then(() => {
            return me.sharedData.drones['waitData']
        });

    }

    
    readLoop = async() => {
    
        while (true) {
            
            let output = await this.update_buffer();
            if (output === -1) {
                window.reader.releaseLock();
                break;
            } else if (output === -2) {
                try {
                    window.reader = window.serialport.readable.getReader();
                } catch {
                    window.reader.releaseLock();
                    break;                    
                }
            }
            if (this.can_process === true) {
                this.process_buffer();
            }
    
        }
    }

    update_buffer = async () => {

        try {
            const { value, done } = await window.reader.read()
            let nowPacket = new Uint8Array(value.buffer);
            //console.log(nowPacket)
            if (this.can_process === true) {
                this.read_buffer.data = [...this.read_buffer.data , ...nowPacket]
            }
            if (done) {
                return -1;
            } else {
                return 0;
            }
        } catch (err) {
            //console.log("read error", err)
            return -2
        }
    
    }

    process_buffer = () => {
    
        if (this.read_loop.hasHeader === false) {
            // Have Header Now
            this.read_loop.header = this.read_buffer.data[0]
            this.read_loop.hasHeader = true
    
            // Not enough data for length byte
            if (this.read_buffer.data.length < 3) {
                return;
            }
    
            this.read_loop.targetLen = this.read_buffer.data[1]
            this.read_loop.targetLenOff = 2
            this.read_loop.hasTargetLength = true
    
            // Check if the rest of the packet is in the buffer - if not, return
            let fullLength = (this.read_loop.targetLen + this.read_loop.targetLenOff)
            if (this.read_buffer.data.length < fullLength) {
                return;
            }
    
            // Read Current Packet
            let myPacket = this.read_buffer.data.slice(0, fullLength)
    
            // Process Packet
            this.processSerial(myPacket)
    
            // Update Buffer without throwing out extra, if extra
            this.read_buffer.data = this.read_buffer.data.slice(fullLength, this.read_buffer.data.length)
    
            // Reset Parameters
            this.clear_packet_info()       
    
        } else {
            // Does Not Already Have Target Length?
            if (this.read_loop.hasTargetLength !== true) {
                
                // Not enough data for length byte
                if (this.read_buffer.data.length < 3) {
                    return;
                }
    
                this.read_loop.targetLen = this.read_buffer.data[1]
                this.read_loop.targetLenOff = 2
                this.read_loop.hasTargetLength = true
            }
            
            // Check if the rest of the packet is in the buffer - if not, return
            let fullLength = (this.read_loop.targetLen + this.read_loop.targetLenOff)
            if (this.read_buffer.data.length < fullLength) {
                return;
            }
            
            // Read Current Packet
            let myPacket = this.read_buffer.data.slice(0, fullLength)
    
            // Process Packet
            this.processSerial(myPacket)
    
            // Update Buffer without throwing out extra, if extra
            this.read_buffer.data = this.read_buffer.data.slice(fullLength, this.read_buffer.data.length)
    
            // Reset Parameters
            this.clear_packet_info()
        }
    }

    clear_packet_info = () => {
        this.read_loop.header = 0
        this.read_loop.hasHeader = false
        this.read_loop.targetLen = 0
        this.read_loop.hasTargetLength = false
        this.read_loop.targetLenOff = 0
    }

    processSerial = (dataBytes) => {
        //console.log("serial", dataBytes)
        this.packetFlag = false
        this.packetHaveLen = false
        this.currentLen = 0
        //console.log(dataBytes)
        // Switch on Header Value
        switch (dataBytes[0]) {
            case TELLO_HEADERS.TELLO_RESP_FAIL: {
                this.sharedData.drones['waitFail'] = true;
                this.sharedData.drones['waitFailCount'] += 1;
                break
            }
            case TELLO_HEADERS.SSID_LIST_ITEM: {
                let name = dataBytes.slice(2, 2 + dataBytes[1] - 1);
                name = String.fromCharCode.apply(null, name);
                this.sharedData.drones['scanData'].push(name)
                break
            }
            case TELLO_HEADERS.SSID_LIST_END: {            
                this.sharedData.drones['scanEnd'] = true;
                break
            }
            case TELLO_HEADERS.TELLO_CONNECT: {
                this.sharedData.drones['connectEnd'] = true;
                this.sharedData.drones['connectState'] = dataBytes[2];
                break
            }
            case TELLO_HEADERS.TELLO_DATA: {
                this.process_data(dataBytes[1], dataBytes.slice(2, dataBytes.length));
                break
            }
            case TELLO_HEADERS.TELLO_MTX_SET: {
                let data = String.fromCharCode.apply(null, dataBytes.slice(2, dataBytes.length));
                this.sharedData.drones['waitData'] = data
                this.sharedData.drones['waitFlag'] = false;
                break
            }
            case TELLO_HEADERS.TELLO_LED_SET: {
                let data = String.fromCharCode.apply(null, dataBytes.slice(2, dataBytes.length));
                this.sharedData.drones['waitData'] = data
                this.sharedData.drones['waitFlag'] = false;
                break
            }
            case TELLO_HEADERS.TELLO_TAKEOFF: {
                const data = String.fromCharCode.apply(null, dataBytes.slice(2, dataBytes.length));
                this.sharedData.drones['waitData'] = data
                this.sharedData.drones['waitFlag'] = false;
                break
            }
            case TELLO_HEADERS.TELLO_LAND: {
                const data = String.fromCharCode.apply(null, dataBytes.slice(2, dataBytes.length));
                this.sharedData.drones['waitData'] = data
                this.sharedData.drones['waitFlag'] = false;
                break
            }            
            case TELLO_HEADERS.TELLO_FLIP: {
                const data = String.fromCharCode.apply(null, dataBytes.slice(2, dataBytes.length));
                this.sharedData.drones['waitData'] = data
                this.sharedData.drones['waitFlag'] = false;
                break
            }
        }
    }
    
    getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }

    process_data = (data_len, data_packet) => {

        let data = ""
        for (let i = 1; i < data_len; i++) {// - 2; i++) {
            data += String.fromCharCode(data_packet[i])
        }
        
        let data_string = this.getKeyByValue(DATA_TYPES, data_packet[0]);
    
        switch (data_string) {
            case "DATA_BATTERY": {
                if (this.self.battArray.length > this.bufferSize) {    
                    this.self.battArray.splice(0, 1);
                }     
                this.self.battArray.push(parseFloat(data))
                //this.sharedData.drones['messageData'] = {"battery": parseFloat(data)}
                this.battery_last = parseFloat(data)
                break
            }
            case "DATA_BARO": {
                this.sharedData.drones['messageData'] = {"barometer": parseFloat(data)}
                break
            }
            case "DATA_ATTITUDE": {
                let startIndices = []
                let endIndices = []
                for(var i=0; i<data.length;i++) {
                    if (data[i] === ":") startIndices.push(i + 1);
                    if (data[i] === ",") endIndices.push(i);
                    if (data[i] === ";") endIndices.push(i);
                }
                if (this.self.pitchArray.length > this.bufferSize) {    
                    this.self.pitchArray.splice(0, 1);
                    this.self.rollArray.splice(0, 1);
                    this.self.yawArray.splice(0, 1);
                }
                this.self.pitchArray.push(parseFloat(data.slice(startIndices[0], endIndices[0])));
                this.self.rollArray.push(parseFloat(data.slice(startIndices[1], endIndices[1])));
                this.self.yawArray.push(parseFloat(data.slice(startIndices[2], endIndices[2])));
                break
            }
            case "DATA_HEIGHT": {
                let endIndex = data.indexOf('dm')
                this.sharedData.drones['messageData'] = {"height": parseFloat(data.slice(0, endIndex))}
                break
            }
            case "DATA_TEMP": {
                let firstNumIndexEnd = data.indexOf('~')
                let firstNum = parseFloat(data.slice(0, firstNumIndexEnd))
                let secondNum = parseFloat(data.slice(firstNumIndexEnd + 1, data.length - 1))
                let avg_temp = (firstNum + secondNum) / 2
                if (this.self.tempArray.length > this.bufferSize) {    
                    this.self.tempArray.splice(0, 1);
                }
                this.self.tempArray.push(avg_temp)
                //this.sharedData.drones['messageData'] = {"temperature": avg_temp}
                this.temperature_last = parseFloat((firstNum + secondNum) / 2)
                break
            }
            case "DATA_SPEED": {
                this.sharedData.drones['messageData'] = {"speed": parseFloat(data)}
                break
            }
            case "DATA_TIME": {
                this.sharedData.drones['messageData'] = {"time": parseFloat(data)}
                break
            }
            case "DATA_ACCEL": {
                if (this.self.axArray.length > this.bufferSize) {    
                    this.self.axArray.splice(0, 1);
                    this.self.ayArray.splice(0, 1);
                    this.self.azArray.splice(0, 1);
                }
                let startIndices = []
                let endIndices = []
                for(var i=0; i<data.length;i++) {
                    if (data[i] === ":") startIndices.push(i + 1);
                    if (data[i] === ";") endIndices.push(i);
                }
                this.self.axArray.push(parseFloat(data.slice(startIndices[0], endIndices[0])) / 1000);
                this.self.ayArray.push(-1.0 * parseFloat(data.slice(startIndices[1], endIndices[1])) / 1000);
                this.self.azArray.push(-1.0 * parseFloat(data.slice(startIndices[2], endIndices[2])) / 1000);
                //this.sharedData.drones['messageData'] = {"accel": data_list}
                break
            }
            case "DATA_TOF": {
                this.sharedData.drones['messageData'] = {"tof": parseFloat(data)}
                break
            }
            case "DATA_WIFI": {
                this.sharedData.drones['messageData'] = {"wifi": parseFloat(data)}
                break
            }
            case "DATA_SDK": {
                this.sharedData.drones['messageData'] = {"sdk": parseFloat(data)}
                break
            }
    
        }
        this.sharedData.drones['receivedData'] = true;    
    }

    drone_takeoff = async() => {

        var me = this;

        return new Promise(function(resolve, reject) {

            var p = function() {
                let packet = [TELLO_HEADERS.TELLO_RC, 4, 0, 0, 0, 0];
                me.writePacket(packet);
                me.ldtTimeoutID = setTimeout(r, 100);
            }
            var r = function() {
                let packet = [TELLO_HEADERS.TELLO_TAKEOFF, 1, 0];
                me.writePacket(packet);    
                me.sharedData.drones['waitFlag'] = true;  
                me.ldtTimeoutID = setTimeout(f, 100);  
            }
            var f = function() {
                if (me.sharedData.drones['waitFlag'] === true) {
                    me.ldtTimeoutID = setTimeout(f, 100);
                } else {
                    me.ldtTimeoutID = setTimeout(m, 100);
                }
            }
            var m = function() {
                let packet = [TELLO_HEADERS.TELLO_RC, 4, 0, 0, 0, 0];
                me.writePacket(packet);
                me.ldtTimeoutID = setTimeout(h, 100);
            }
            var h = function() {
                resolve("done");
            }

            me.ldtTimeoutID = setTimeout(p, 100);

        });
    }

    drone_land = async() => {
        
        var me = this;

        return new Promise(function(resolve, reject) {

            var p = function() {
                let packet = [TELLO_HEADERS.TELLO_RC, 4, 0, 0, 0, 0];
                me.writePacket(packet);
                me.ldtTimeoutID = setTimeout(r, 100);
            }
            var r = function() {
                let packet = [TELLO_HEADERS.TELLO_LAND, 1, 0];
                me.writePacket(packet);    
                me.sharedData.drones['waitFlag'] = true;  
                me.ldtTimeoutID = setTimeout(f, 100);  
            }
            var f = function() {
                if (me.sharedData.drones['waitFlag'] === true) {
                    me.ldtTimeoutID = setTimeout(f, 100);
                } else {
                    me.ldtTimeoutID = setTimeout(m, 100);
                }
            }
            var m = function() {
                let packet = [TELLO_HEADERS.TELLO_RC, 4, 0, 0, 0, 0];
                me.writePacket(packet);
                me.ldtTimeoutID = setTimeout(h, 100);
            }
            var h = function() {
                resolve("done");
            }

            me.ldtTimeoutID = setTimeout(p, 100);

        });
    }

    
    drone_flip = async(flip_direction) => {

        var me = this;

        return new Promise(function(resolve, reject) {

            var p = function() {
                let packet = [TELLO_HEADERS.TELLO_FLIP, 1, FLIP_DIRECTIONS[flip_direction]]
                me.writePacket(packet);
                me.sharedData.drones['waitFlag'] = true;  
                me.ldtTimeoutID = setTimeout(f, 100);  
            }
            var f = function() {
                if (me.sharedData.drones['waitFlag'] === true) {
                    me.ldtTimeoutID = setTimeout(f, 100);
                } else {
                    me.ldtTimeoutID = setTimeout(m, 100);
                }
            }
            var m = function() {
                resolve("done");
            }
            
            me.ldtTimeoutID = setTimeout(p, 100);
        });
    }

    drone_rc = async(a, b, c, d) => {
        
        var me = this;

        return new Promise(function(resolve, reject) {
            var f = function () {
                resolve("done");
            }
            let packet = [TELLO_HEADERS.TELLO_RC, 4, a, b, c, d];
            me.writePacket(packet);
            me.ldtTimeoutID = setTimeout(f, 50);
        });
    }


}


var FLIP_DIRECTIONS = {
    FLIP_LEFT: 0,
    FLIP_RIGHT: 1,
    FLIP_FORWARD: 2,
    FLIP_BACK: 3
};

var TELLO_BOUNDS = {
    DISTANCE_MIN: 20,
    DISTANCE_MAX: 500,
    YAW_MIN: 1, 
    YAW_MAX: 360,
    RGB_MIN: 0,
    RGB_MAX: 255,
    MTX_SET_MAX: 64,
    MTX_STR_MAX: 70,
    RGB_PULSE_T_MIN: 0.1,
    RGB_PULSE_T_MAX: 2.5,
    RGB_BLINK_T_MIN: 0.1,
    MTX_STR_T_MIN: 0.1,
    MTX_STR_T_MAX: 2.5,
    RGB_BLINK_T_MAX: 10.0,
    RC_MIN: -100,
    RC_MAX: 100,
    CURVE_MIN: -500,
    CURVE_MAX: 500,
    CURVE_SPEED_MIN: 10,
    CURVE_SPEED_MAX: 60,
    GO_SPEED_MIN: 10,
    GO_SPEED_MAX: 100,
};

var TELLO_HEADERS = {
    TELLO_RESP_FAIL: 90,
    SSID_LIST_START: 83,
    SSID_LIST_ITEM: 84,
    SSID_LIST_END: 85,
    TELLO_CONNECT: 67,
    TELLO_DISCONNECT: 68,
    TELLO_TAKEOFF: 69,
    TELLO_LAND: 70,
    TELLO_MOVE: 71,
    TELLO_DATA: 72,
    TELLO_HOVER: 73,
    TELLO_YAW: 74,
    TELLO_FLIP: 75,
    TELLO_RC: 76,
    TELLO_CURVE: 77,
    TELLO_GO: 78,
    TELLO_LED_SET: 79,
    TELLO_LED_PULSE: 80,
    TELLO_LED_BLINK: 82,
    TELLO_MTX_SET: 109,
    TELLO_MTX_SET_STR: 110,
    TELLO_MTX_SET_IMG: 111,
    TELLO_MTX_ASCII: 86,
    TELLO_MTX_BOOT: 87,
    TELLO_MTX_CLR_BOOT: 88,
    TELLO_MTX_BR: 89,
    TELLO_IR_TOF: 90,
    TELLO_REBOOT: 97,
    TELLO_MOTOR_ON: 98, // motors on
    TELLO_MOTOR_OFF: 99, // motors off
    TELLO_THROW_FLY: 100, // 
    TELLO_STOP: 101,
    TELLO_GO_MPAD: 102,
    TELLO_CURVE_MPAD: 103,
    TELLO_JUMP_MPAD: 104,
    TELLO_MPAD_ON: 105, // Enables mission pad
    TELLO_MPAD_OFF: 106, // Disables mission pad
    TELLO_MPAD_DIR: 107,
};

var DATA_TYPES = {
    DATA_BATTERY: 0,
    DATA_BARO: 1,
    DATA_ATTITUDE: 2,
    DATA_HEIGHT: 3,
    DATA_TEMP: 4,
    DATA_SPEED: 5,
    DATA_TIME: 6, 
    DATA_ACCEL: 7,
    DATA_TOF: 8,
    DATA_WIFI: 9,
    DATA_SDK: 10
};

var MLED = {
    RED: 'r',
    BLUE: 'b',
    PURPLE: 'p',
    OFF: '0',
};

var MLED_CLRS = ['r', 'b', 'p'];
var MLED_OPTS = ['r', 'b', 'p', '0'];

var CONNECT_STATES = {
    CONNECT_FAIL: 0,
    CONNECT_OK: 1 
};


export default LocoDroneTT;