
// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';



// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    button: {
        square:true,
    },
    text: {
        color: "white",
        backgroundColor: theme.palette.primary["light"],
    },

  });

// Component Class
class DataVisLW extends React.Component {
    
    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);


        // Bind Certain Functions to "this"
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        
    }

    // Class state
    state = {
        isOpen: false,
        displayMsg: "Calibrating Controller...",
    }

    // For Displaying Self (Modal), Called by Parent ProgramCoding
    handleOpen = () => {
        this.setState( {isOpen: true} );
        // set timeout
        setTimeout(this.handleClose, 3000);
    };

    // For Stopping Program Run
    handleClose = () => {
        this.setState( {isOpen: false} );
    };

    render() {

        const { classes } = this.props;

        return (
            <div>
                <Drawer
                    anchor='top' 
                    open={this.state.isOpen}
                >
                    <Grid 
                        container 
                        spacing={0}
                        direction="row"
                    >
                    <Grid item xs={12}>
                        <Typography
                            className={classes.text}
                            style={{fontSize: '34px', textAlign: 'center'}}
                        >
                            {this.state.displayMsg}
                        </Typography>
                    </Grid>
                    </Grid>
                </Drawer>
            </div>
        );
    }

}
export default withStyles(styles)(DataVisLW);
