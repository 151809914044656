// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';





// Material-UI CSS-type Style Specifications
const styles = (theme) => ({

});


// Component Class
class LDTRGB extends React.Component {

    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);


    }

    state = {
        slid1: 0,
        slid2: 0,
        slid3: 0,
        mouseIsPressed: false, 
    }


    
    handleSlider = (event, value, name) => {
        
        this.setState({[name]: value});

    }

    startHold = () => {
        this.setState( {mouseIsPressed: true} )
    }

    stopHold = () => {
        this.setState( {mouseIsPressed: false} )
    }


    render() {

        //onChangeCommitted={(event, value) => this.handleSlider(event, value, 'slid1')}
                                

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
            <div>
                <Box style = {{
                    display: 'flex', 
                    justifyContent: 'center',
                }}>
                <Box>
                    <Grid 
                        container 
                        spacing={0}
                        direction="row"
                    >
                    <Grid item xs = {12}>
                        <Box style = {{
                            display: 'flex', 
                            justifyContent: 'center',
                        }}>
                            <Box>
                                <Typography 
                                    className={classes.regularText}
                                    style={{
                                        backgroundColor: "white",
                                        color: "rgb(255, 0, 0)",
                                    }}
                                >
                                    R
                                </Typography>
                                <Slider
                                    defaultValue={255}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    value={this.state.slid1}
                                    style={{color:"rgb(255, 0, 0)", marginTop: '-20px', minWidth: '200px'}}
                                    step={1}
                                    min={0}
                                    max={255}
                                    onMouseDown={this.startHold}
                                    onMouseUp={this.stopHold}
                                    onMouseLeave={this.stopHold}
                                    onChange={(event, value) => this.handleSlider(event, value, 'slid1')}
                                />
                                <Typography 
                                    className={classes.regularText}
                                    style={{
                                        backgroundColor: "white",
                                        color: "rgb(0, 255, 0)",
                                    }}
                                >
                                    G
                                </Typography>
                                <Slider
                                    defaultValue={255}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    value={this.state.slid2}
                                    style={{color:"rgb(0, 255, 0)", marginTop: '-20px', minWidth: '200px'}}
                                    step={1}
                                    min={0}
                                    max={255}
                                    onMouseDown={this.startHold}
                                    onMouseUp={this.stopHold}
                                    onMouseLeave={this.stopHold}
                                    onChange={(event, value) => this.handleSlider(event, value, 'slid2')}
                                />
                                <Typography 
                                    className={classes.regularText}
                                    style={{
                                        backgroundColor: "white",
                                        color: "rgb(0, 0, 255)",
                                    }}
                                >
                                    B
                                </Typography>
                                <Slider
                                    defaultValue={255}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    value={this.state.slid3}
                                    style={{color:"rgb(0, 0, 255)", marginTop: '-20px', minWidth: '200px'}}
                                    step={1}
                                    min={0}
                                    max={255}
                                    onMouseDown={this.startHold}
                                    onMouseUp={this.stopHold}
                                    onMouseLeave={this.stopHold}
                                    onChange={(event, value) => this.handleSlider(event, value, 'slid3')}
                                />
                            </Box>
                            <Box
                                style = {{padding: 20}}
                            />
                            <Box
                                style={{
                                    backgroundColor: `rgb(${this.state.slid1}, 
                                                            ${this.state.slid2},
                                                            ${this.state.slid3})`,
                                    borderRadius: '50%',
                                    border: '1px solid black',
                                    minWidth: '200px',
                                    minHeight: '200px',
                                }}
                            />
                        </Box>
                    </Grid>
                    </Grid>
                    </Box>
                </Box>
            </div>
        );
    }

}


export default withStyles(styles)(LDTRGB);