// Child Component of: 
// --- ProgramGUI

// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

import BlockAdded from '../drawers/BlockAdded';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    actionButton: {
      justify: "right",
      marginTop: theme.spacing(2),
      color: "red",
    },
    media: {
        height: 0,
        paddingTop: '100%', // 
        marginTop:'30',
    },
  });
  
  // Component Class - ProgramDisplay
  class ProgramGUIModal extends React.Component {
    
    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);


        // Bind Certain Functions to "this"
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleSelection = this.handleSelection.bind(this);

        
        //
        this.blockAddedElement = React.createRef();
        
    }

    // Class state
    state = {
        isOpen: false,
    }


    // For Displaying Self (Modal), Called by Parent ProgramCoding
    handleOpen = () => {
        this.setState( {isOpen: true} );
    };

    // For Stopping Program Run
    handleClose = () => {
        this.setState( {isOpen: false} );
    };


    handleSelection = (event) => {
        //console.log(event);
        this.props.handleBlockAdded(event);
        this.blockAddedElement.current.handleOpen();
    };


    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display - The Modal: Header, Button, "pre" tagged Text
        return (
            <div>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper}>
                        <Grid 
                            container 
                            spacing={2}
                            direction="row"
                        >
                            <Grid item xs={10}>
                                <h2 id="transition-modal-title">Block Selection</h2>
                            </Grid>
                            <Grid item xs="auto">
                                <IconButton 
                                    className={classes.actionButton} 
                                    onClick={this.handleClose}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                            {this.props.imgFiles.map((val) => {
                                return (
                                    <Grid item xs={3} key={val}>
                                        <Card 
                                            className={classes.root}
                                            name = {val}
                                            style={{borderRadius: 15}}
                                        >
                                            <CardActionArea
                                                className={classes.root}
                                                onClick = {() => this.handleSelection(val)}
                                                name = {val}
                                            >
                                                <CardMedia
                                                    className={classes.media}
                                                    image={require(`../../images/blocks/${this.props.currentHardware}/${val}`)}
                                                    title={`${val}`}
                                                />
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                </Fade>
            </Modal>
            <BlockAdded
                ref={this.blockAddedElement}
            />
            
        </div>
        );
    }
}
export default withStyles(styles)(ProgramGUIModal);
