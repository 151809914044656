export async function webserial_disconnect() {
  try {
    // Close the input stream (reader).
    if (window.reader) {
      await window.reader.cancel();
      window.reader = null;
    }
    // Close the output stream.
    if (window.outputStream) {
      await window.outputStream.getWriter().close();
      await window.outputDone;
      window.outputStream = null;
      window.outputDone = null;
    }
    // Close the port.
    await window.serialport.close();
    window.serialport = null;
    //log.textContent = "Dongle Disconnected!";
  } catch (err) {}
}

