// Child Component of: 
// --- 

// Import React and Material-UI Modules
import React from 'react';
//import ReactDOM from 'react-dom'

import { withStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';

import OpenWithIcon from '@material-ui/icons/OpenWith';

import Draggable from "react-draggable";

// Import Context to Access Auth/User Information
import { AuthContext } from "../context/auth";

import LocoWear from "../libraries/LocoWear";
import DataVisLW from "../components/drawers/DataVisLW";

import SnakeGame from "./games/Snake/SnakeGame";
import { Reacteroids } from './games/Asteroid/Reacteroids';
import Game from "./games/Dodgy/containers/Game";
//import Tetris from "./games/Tetris/Tetris";

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '840px',
    minWidth: '400px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    square: false,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  actionButton: {
    justify: "right",
    margin: theme.spacing(2),
    color: "red",
  },
  backDrop: {
    //background: 'rgba(255,255,255,0)',
  }
});

// Component Class - 
class GameWindow extends React.Component {

    


    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);

        // 
        this.gameElement = React.createRef();

        this.visLWElement = React.createRef();
                
    }

    // For Use of AuthContext
    static contextType = AuthContext;

    // Class state
    state = {
        isOpen: false,
        gameSettings: {},
        LRObj: null,
        hardwareString: '',
    }

    


    // For Displaying Self (Modal), Called by Parent
    handleOpen = () => {


        this.setState( {isOpen: true} );
    };

    initLR = (hardwareStr) => {
        
        if (hardwareStr === '') {
        } else if (hardwareStr === 'LocoWear') {
            if (this.context.AuthInstance.userAccess.indexOf('LocoWear') !== -1) {
                let tempObj = new LocoWear();
                this.setState( {LRObj: tempObj, hardwareString: hardwareStr} );
            }
        }
    }

    initGame = () => {
        this.gameElement.current.initGame(this.state.hardwareString)
    }

    handleConnect = () => {

        //try {
            if (this.state.hardwareString === 'LocoWear') {
                this.state.LRObj.connect().then(() => {
                    setTimeout(this.postConnect, 1000);
                });
            } else {
                window.game_wait = false
            }
        //} catch {    
            //console.log("false from error")
            //window.game_wait = false
        //}
    }

    postConnect = () => {
        this.state.LRObj.send_command(99);
        this.state.LRObj.send_command(98);
        this.visLWElement.current.handleUpdateMsgLooking();
        setTimeout(this.checkForBind, 100);
    }

    checkForBind = () => {

        if (this.state.LRObj.self.bound === true) {
            this.visLWElement.current.handleUpdateMsgFound()
        } else {
            setTimeout(this.checkForBind, 100);
        }
    }

    handleGameSettings = (settings) => {

        let settingsObj = JSON.parse(settings)        
        this.setState( {gameSettings: settingsObj} )
        this.gameElement.current.updateSettings(settingsObj);
    }

    // For Stopping
    handleClose = () => {

    };


    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display - The Modal: Header, Button, "pre" tagged Text
        return (            
            <React.Fragment>
                <Draggable handle="#handle">
                <Card variant="outlined"
                    style = {{
                        minWidth: this.props.size[0], maxWidth: this.props.size[0], 
                        minHeight: this.props.size[1], maxHeight: this.props.size[1],
                        position: 'absolute', left: this.props.topleft[0], top: this.props.topleft[1],
                    }}
                >
                <span id='handle'>
                    <IconButton>
                        Move
                        <OpenWithIcon/>
                    </IconButton>
                </span>

                {(window.game_selection === 'snake') && 
                    <SnakeGame 
                        ref = {this.gameElement}
                        LRObj = {this.state.LRObj}
                    />
                }

                {(window.game_selection === 'asteroids') && 
                    <Reacteroids 
                        ref = {this.gameElement}
                        LRObj = {this.state.LRObj}
                    />
                }

                {(window.game_selection === 'dodgy') && 
                    <Game 
                        ref = {this.gameElement}
                        LRObj = {this.state.LRObj}
                        boardSize={11} playerSize={25} 
                    />
                }

                {(window.game_selection === 'tictactoe') && 
                    <div></div>
                }
                
                {(window.game_selection === 'tetris') && 
                    <div></div>
                }

                </Card>
                </Draggable> 
                <DataVisLW 
                    ref={this.visLWElement}
                    onEnd={this.visLWEnd}
                />
            </React.Fragment>               
        );
    };
}

export default withStyles(styles)(GameWindow);
