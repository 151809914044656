import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
//import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Topbar from "./Topbar";

import { AuthContext } from "../context/auth";

import LocoDrone from "../libraries/LocoDrone";
import LocoDroneT from "../libraries/LocoDroneT";
import LocoDroneTT from "../libraries/LocoDroneTT";
import LocoIoT from "../libraries/LocoIoT";
import LocoArm from "../libraries/LocoArm";
import LocoArmM from "../libraries/LocoArmM";
import LocoWear from "../libraries/LocoWear";
import { LocoXtreme } from "../libraries/LocoXtreme";

import DataVisLW from "../components/drawers/DataVisLW";
import DataVisLD from "../components/drawers/DataVisLD";
import DataVisLDPrompt from "../components/drawers/DataVisLDPrompt";
import LXSettingsModal from './modals/LXSettingsModal';

import GeneralMessage from "./drawers/GeneralMessage"
import LDTSettingsModal from "./modals/LDTSettingsModal";
import LDTMatrix from "./LDTMatrix";
import LDTRGB from "./LDTRGB";
import { webserial_disconnect } from "../webserial/webserial";

const backgroundShape = require("../images/shape.svg");


// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey["100"],
        overflow: "hidden",
        background: `url(${backgroundShape}) no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "0 400px",
        paddingBottom: 200
    },
    formControl: {
        width: "95%",
        justify: "center",
        margin: theme.spacing(0),
    },
    inputField: {
        width: "90%",
        marginLeft: theme.spacing(2),
    },
    inputHelper: {
        marginLeft: theme.spacing(4),
    },
    grid: {        
        flexGrow: 1,
        margin: theme.spacing(4),
    },
    paper: {
        flexGrow: 1,
        padding: theme.spacing(1),
        textAlign: "center",
        justify: "center",
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],        
        height: theme.spacing(42),
        width: theme.spacing(52),
    },
    actionButton: {
        justify: "center",
        margin: theme.spacing(1),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
    },
    input: {
        justify: "center",
        margin: theme.spacing(2),
        color: theme.palette.primary["contrastText"],
        backgroundColor: theme.palette.primary["light"],
    },
    title: {
        color: theme.palette.primary["contrastText"],
        backgroundColor: theme.palette.primary["light"],
    },
    media: {
        height: 0,
        paddingTop: '30%', // 
        marginTop:'30',
    },
    inline: {
      display: "inline"
    },
    buttonProgress: {
      color: "white",
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: 40,
      marginLeft: -12,
    },
  });





// Component Class - Login
class DataVis extends Component {

    static contextType = AuthContext;

    constructor(props, context) {

        super(props, context);

        this.visLWElement = React.createRef();
        this.visLDElement = React.createRef();
        this.visLDPromptElement = React.createRef();
        //
        this.LXSelectionElement = React.createRef();
        this.LXScanMsgElement = React.createRef();
        this.LXConnMsgElement = React.createRef();
        this.LXNoConnMsgElement = React.createRef();

        this.LXConnError = false;

        this.LDTSelectionElement = React.createRef();
        this.LDTScanMsgElement = React.createRef();
        this.LDTConnMsgElement = React.createRef();
        this.LDTNoConnMsgElement = React.createRef();

        this.matrixElement = React.createRef();
        this.rgbElement = React.createRef();

        this.charRef0 = React.createRef();
        this.charRef1 = React.createRef();
        this.charRef2 = React.createRef();

        this.skip_count = 0;
        this.skip_LIMIT = 4;
    }

    // Class state
    state = {
        userAccessIndx: 1,
        hardwareSelection: this.context.AuthInstance.userAccess[1],
        LRobj: null,
        selected: false,
        colorSelection: 'red',
        colorOptions: ['red', 'green', 'blue'], 
        colorCodes: {red:'rgb(200, 0, 0)', green:'rgb(0, 200, 0)', blue:'rgb(0, 0, 200)'},
        sensorSelection: '',
        bufferSize: 100,
        bufferMin: 10,
        bufferMax: 100,
        updateRate: 250,
        plottingText: "Start Plotting",
        isPlotting: false,
        isConnected: false,
        connectText: "Connect",
        isIoT: false,
        updateID: null,
        extraID: null,
        iotIsSetup: false,
        message_str_1: "",
    }


    componentDidMount() {
    };

    componentWillUnmount() {
        try {
            if (this.state.isPlotting === true) {
                this.setState( {isPlotting: false} );
                clearTimeout(this.state.updateID);
            }
            if (this.state.isConnected === true) {
                if (this.state.hardwareSelection === 'LocoXtreme') {
                    try {
                        this.state.LRobj.reset_ble().then(() => {
                            this.state.LRobj.disconnect();
                        });
                    } catch {}
                } else {
                    try {
                        this.state.LRobj.disconnect();
                    } catch {}
                }
                this.setState( {isConnected: false} );
            }
        } catch {}
    };

    handleChange = event => {
        this.setState( {[event.target.name]: event.target.value} );
    };
    
    handleChangeBuffer = event => {
        this.setState( {bufferSize: event.target.value} );
    };

    handleBack = () => {
        if (this.state.isPlotting === true) {
            this.setState( {isPlotting: false, plottingText: "Start Plotting"} );
            clearTimeout(this.state.updateID);
        }
        if (this.state.isConnected === true) {
            try {
                this.state.LRobj.disconnect();
            } catch {}
            this.setState( {isConnected: false, connectText: "Connect"} );
        }
        this.setState( {selected: false, isIoT: false} );
        this.setState( {iotIsSetup: false} );
    }


    handleConnect = async () => {

        if (this.state.isConnected === false ) {
            if (this.state.hardwareSelection === 'LocoXtreme') {
                this.LXConnMsgElement.current.handleOpenStay('blue', 'Connecting...');
            }
            if ((this.state.hardwareSelection === 'LocoDroneT') || 
            (this.state.hardwareSelection === 'LocoDroneTT')) {
                this.LDTConnMsgElement.current.handleOpenStay('blue', 'Connecting...');
            }
            return this.state.LRobj.connect().then(() => {
                if ((this.state.hardwareSelection !== 'LocoXtreme') && 
                    (this.state.hardwareSelection !== 'LocoDroneT') &&
                    (this.state.hardwareSelection !== 'LocoDroneTT') ) {
                    this.setState( {isConnected: true, connectText: "Disconnect"} );
                } else {
                    this.setState( {connectText: "Connecting..."} );
                }
                if (this.state.hardwareSelection === 'LocoDrone') {
                    this.state.LRobj.send_command(98);
                    //this.visLDElement.current.handleOpen();
                    this.visLDPromptElement.current.handleOpen();
                } else if (this.state.hardwareSelection === 'LocoWear') {
                    setTimeout(this.postConnect, 1000);
                } else if (this.state.hardwareSelection === 'LocoXtreme') {
                    setTimeout(this.postConnectLX, 250);//100);
                } else if ((this.state.hardwareSelection === 'LocoDroneT') ||
                            (this.state.hardwareSelection === 'LocoDroneTT')) {
                    setTimeout(this.postConnectLDT, 250);//100);
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            if (this.state.hardwareSelection === 'LocoWear') {
                this.state.LRobj.self.bound = false;
            } else if ((this.state.hardwareSelection === 'LocoDroneT') || 
                        (this.state.hardwareSelection === 'LocoDroneTT')) {
                this.state.LRobj.clearLDTTimeouts()
                this.state.LRobj.clearData()
                clearTimeout(this.state.LRobj.get_vitals_timer_ID);
                if (this.state.hardwareSelection === 'LocoDroneT') {
                    let tempObj = new LocoDroneT();
                    this.setState( {LRobj: tempObj} );
                } else if (this.state.hardwareSelection === 'LocoDroneTT') {
                    let tempObj = new LocoDroneTT();
                    this.setState( {LRobj: tempObj} );
                }
            }
            this.setState( {isPlotting: false, plottingText: "Start Plotting", isConnected: false} );
            this.state.LRobj.resetArrays();
           

            setTimeout( this.changeConnect, 50);
        }
    }

    postConnect = () => {
        this.state.LRobj.send_command(99);
        this.state.LRobj.send_command(98);
        this.visLWElement.current.handleUpdateMsgLooking();
        setTimeout(this.checkForBind, 100);
    }


    postConnectLX = () => {
        
        this.LXConnMsgElement.current.handleClose();
        if (!window.serialport) {
            this.setState( {isConnected: false, connectText: "Connect"} );
            return
        }

        const SCAN_TIME = 4000;
        const SCAN_MSG_TIME = 5000;
        this.LXScanMsgElement.current.handleOpen('green', 'Scanning...', SCAN_MSG_TIME)
        this.state.LRobj.scan_start(SCAN_TIME);
    }

    postConnectLDT = () => {
        
        this.LDTConnMsgElement.current.handleClose();
        if (!window.serialport) {
            this.setState( {isConnected: false, connectText: "Connect"} );
            return
        }

        this.LDTScanMsgElement.current.handleOpenStay('green', 'Scanning...')
        this.state.LRobj.drone_scan().then((res) => {
            this.LDTScanMsgElement.current.handleClose();
            if (res === "timeout") {
                let msgStr = 'Error Completing Scan, Please Try Again. If The Problem Persists, Reset Your Controller.'
                this.LDTSelectionElement.current.handleMessage(msgStr, 4000)
            }
        });
    }

    
    LXSelection = async (r_event) => {
                
        //this.setState( {lxselected: r_event, lxwaiting: false} );
        let targetIndex = -1;
        for (let i = 0; i < this.state.LRobj.robots.length; i++) {
            if (this.state.LRobj.robots[i]['name'] === r_event) {
                targetIndex = i
                break
            }
        }
        
        if (targetIndex !== -1) {
            this.state.LRobj.connect_ble(targetIndex).then(() => {
                this.setState( {isConnected: true, connectText: "Disconnect"} );
            }).catch((err) => {
                this.LXNoConnMsgElement.current.handleOpen('red', 'Could Not Connect To Robot, Please Try Again', 1000);
                this.setState( {isConnected: false, connectText: "Connect"} );
                try {
                    this.state.LRobj.scan_start(100).then(() => {
                        setTimeout(this.tryLXClear, 200);
                    });
                } catch {}
            });

        } else {
            await this.state.LRobj.disconnect();
            this.setState( {isConnected: false, connectText: "Connect"} );
        }
    }

    LDTSelection = async (r_event) => {
        let targetIndex = -1
        for (let i = 0; i < this.state.LRobj.sharedData.drones['scanData'].length; i++) {
            if (this.state.LRobj.sharedData.drones['scanData'][i] === r_event) {
                targetIndex = i
                break
            }
        }
        if (targetIndex !== -1) {
            this.state.LRobj.connect_wifi(targetIndex).then((res) => {
                if (res === true) {
                    if (this.state.sensorSelection >= this.state.LRobj.optionNames.length) {
                        this.setState( {sensorSelection: this.state.LRobj.optionNames[0]} );    
                    }
                }
                this.setState( {isConnected: true, connectText: "Disconnect"} );
                this.skip_count = this.skip_LIMIT
                this.ldt_monitor()
            }).catch((err) => {
                this.LDTNoConnMsgElement.current.handleOpen('red', 'Could Not Connect To Drone, Please Try Again', 1000);
                this.setState( {isConnected: false, connectText: "Connect"} );
            });

        } else {
            await this.state.LRobj.disconnect_drone();
            await this.state.LRobj.disconnect();
            this.setState( {isConnected: false, connectText: "Connect"} );
        }
    }

    tryLXClear = () => {
        try {
            this.state.LRobj.reset_ble().then(() => {
                this.state.LRobj.disconnect().then(() => {
                    let tempObj = new LocoXtreme();
                    this.setState( {LRobj: tempObj} );
                });
            });          
        } catch {}
    }

    openLXSelection = () => {
        
        this.LXSelectionElement.current.handleOpen("PICK", this.state.LRobj.robots);

    }

    
    openLDTSelection = () => {
        this.LDTSelectionElement.current.handleOpen("PICK", this.state.LRobj.sharedData.drones['scanData']);

    }

    checkForBind = () => {

        if (this.state.LRobj.self.bound === true) {
            this.visLWElement.current.handleUpdateMsgFound();
        } else {
            setTimeout(this.checkForBind, 100);
        }
    }

    changeConnect = () => {
        try {            
            if (this.state.hardwareSelection === 'LocoXtreme') {
                this.state.LRobj.reset_ble().then(() => {
                    this.state.LRobj.disconnect();
                });
            } else {
                this.state.LRobj.disconnect();
                this.setState( {iotIsSetup: false} );
            }
        } catch {}
        this.setState( {isConnected: false, connectText: "Connect"} );
    }

    handleStartPlotting = async () => {

        if (this.state.isPlotting === false) {

            if (this.state.hardwareSelection === 'LocoXtreme') {
                await this.state.LRobj.enable_sensor(this.state.LRobj.self[this.state.LRobj.options[this.state.sensorSelection]['refer']].value, 1)
            }
            if ((this.state.hardwareSelection === 'LocoDroneT') || 
                (this.state.hardwareSelection === 'LocoDroneTT')) {
                clearTimeout(this.state.LRobj.get_vitals_timer_ID);
                this.state.LRobj.clearLDTTimeouts()
            }

            let tempLRobj = this.state.LRobj;
            if (this.state.bufferSize > this.state.bufferMax) {
                tempLRobj.set_buffer_size(this.state.bufferMax);
            } else if (this.state.bufferSize < this.state.bufferMin) {
                tempLRobj.set_buffer_size(this.state.bufferMin);
            } else {
                tempLRobj.set_buffer_size(this.state.bufferSize);
            }
            this.setState( {LRobj: tempLRobj} );
            this.setState( {bufferSize: tempLRobj.get_buffer_size(), isPlotting: true, plottingText: "Stop Plotting"} );

            let timeoutID = setTimeout(this.createPlots, 500);
            this.setState( {updateID: timeoutID} );

        } else {
            clearTimeout(this.state.updateID);
            if (this.state.hardwareSelection === 'LocoXtreme') {          
                for (let i = 0; i < this.state.LRobj.options.length; i++) {
                    await this.state.LRobj.enable_sensor(this.state.LRobj.self[this.state.LRobj.options[this.state.sensorSelection]['refer']].value, 0)
                }
            } else if ((this.state.hardwareSelection === 'LocoDroneT') ||
                        (this.state.hardwareSelection === 'LocoDroneTT')) {
                //
                await this.state.LRobj.clearLDTTimeouts()
                await this.state.LRobj.clearData()
                clearTimeout(this.state.updateID);
                //
                let timeoutID = setTimeout(this.ldt_monitor, this.state.LRobj.VITALS_MONITOR_TIME_A);
                this.setState( {updateID: timeoutID} );
            }
            this.setState( {isPlotting: false, plottingText: "Start Plotting"} );
            this.state.LRobj.resetArrays();
        }

    };


    createPlots = async () => {

        let fontObj = {
            family: 'Courier New, monospace',
            size: 18,
            color: '#070707'
        };
        let marginObj = {
            t: 50, b: 50 
        };
        let heightVal = 300;
        let xLabelObj = { title: 'Sample (#)', };

        for (let i = 0; i < this.state.LRobj.options[this.state.sensorSelection].count; i++) {            
            let data = [{
                y: [0],
                type: 'scatter',
                line: {color: this.state.colorCodes[this.state.colorSelection],},
                mode: 'lines',
            }];

            let layout = {
                xaxis: xLabelObj, 
                height: heightVal,
                margin: marginObj,
                mode: 'lines',
                font: fontObj,
                yaxis: { title: this.state.LRobj.self[this.state.LRobj.options[this.state.sensorSelection].refer].ylabels[i], } 
            };
            await window.Plotly.newPlot(this[`charRef${i}`].current, data, layout);
            
            if (this.state.hardwareSelection === 'LocoIoT') {
                
                if ((this.state.sensorSelection === 'Accelerometer') || (this.state.sensorSelection === 'Gyroscope')) {
                    this.state.LRobj.send_enable( this.state.LRobj.IOT_Codes_Constants['SUBTYPE_MPU']);
                } else {
                    this.state.LRobj.send_enable( this.state.LRobj.IOT_Codes_Constants[this.state.LRobj.options[this.state.sensorSelection].refer]);
                }

                if (this.state.iotIsSetup === false) {
                    this.setupIoT();
                }
            }
            
            let timeoutID = setTimeout(this.updatePlot, this.state.updateRate);
            this.setState( {updateID: timeoutID} );
        }
        if (typeof this.state.LRobj.options[this.state.sensorSelection].count === 'string' || this.state.LRobj.options[this.state.sensorSelection].count instanceof String) {
            let timeoutID = setTimeout(this.updatePlot, this.state.updateRate);
            this.setState( {updateID: timeoutID} );
        }
    }

    setupIoT = () => {
        this.state.LRobj.send_start();
        this.setState( {iotIsSetup: true} );
    };

    ldt_monitor = async () => {
        if (this.state.isConnected === true) {
            let msg = await this.state.LRobj.drone_monitor_vitals();
            if (msg.includes("NaN")) {
                let msgStr = 'Drone Communication Degraded, Please Charge Drone'
                this.LDTSelectionElement.current.handleMessage(msgStr, 3000)
                return 0
            }
            /*if (this.state.hardwareSelection === 'LocoDroneT') {
                let vitals_state = this.state.LRobj.drone_check_vitals();
                if (vitals_state === this.state.LRobj.VITALS_OK) {
                    this.setState( {message_str_1: msg} )
                    if (this.state.isPlotting === false) {
                        this.state.LRobj.set_vitals_timer_ID(setTimeout(this.ldt_monitor, this.state.LRobj.VITALS_MONITOR_TIME_A))
                    }
                }
            *///} else {
            this.setState( {message_str_1: msg} )
            if (this.state.isPlotting === false) {
                this.state.LRobj.set_vitals_timer_ID(setTimeout(this.ldt_monitor, this.state.LRobj.VITALS_MONITOR_TIME_A))
            }
            //}
            
            /*else {
                // close connection
                this.handleConnect()
                // drawer here
                this.LDTNoConnMsgElement.current.handleOpen('red', vitals_state, 3000);
                return 0
            }*/
        }
        return 1
    }

    getData = async () => {

        if ((this.state.hardwareSelection === 'LocoDrone') || (this.state.hardwareSelection === 'LocoArm')
            || (this.state.hardwareSelection === 'LocoArmS') || (this.state.hardwareSelection === 'LocoArmM') 
            || (this.state.hardwareSelection === 'LocoArmMS')) {
            this.state.LRobj.send_command(this.state.LRobj.self[this.state.LRobj.options[this.state.sensorSelection].refer].value);
            return 1
        } else if (this.state.hardwareSelection === 'LocoWear') {
            this.state.LRobj.send_command(this.state.LRobj.self.GET_DATA.value);
            return 1
        } else if (this.state.hardwareSelection === 'LocoIoT') {
            let data = null;
            if (this.state.sensorSelection === 'Accelerometer') {
                data = [1, 0];
                this.state.LRobj.send_request(this.state.LRobj.IOT_Codes_Constants.SUBTYPE_MPU, data);
            } else if (this.state.sensorSelection === 'Gyroscope') {
                data = [0, 1];
                this.state.LRobj.send_request(this.state.LRobj.IOT_Codes_Constants.SUBTYPE_MPU, data);
            } else {
                this.state.LRobj.send_request(this.state.LRobj.IOT_Codes_Constants[this.state.LRobj.options[this.state.sensorSelection].refer], data);
            }
            return 1
        } else if ((this.state.hardwareSelection === 'LocoDroneT') || 
                    (this.state.hardwareSelection === 'LocoDroneTT')) {
            try {
                const res = await this.ldt_monitor()
                if (res === 0) {
                    this.LDTNoConnMsgElement.current.handleOpen(
                        'red', 'Drone Communication Degraded, Please Charge Drone', 3000
                    );
                    return 0
                }
            } catch (err) {
            }

            //
            let read_state = await this.state.LRobj.drone_get_data(this.state.LRobj.self[this.state.LRobj.options[this.state.sensorSelection].refer].value)
            if (read_state === "timeout") {
                if (this.state.hardwareSelection === 'LocoDroneTT') {
                    let msgStr = 'Communication Issue Between The Drone and Controller.'
                    this.LDTSelectionElement.current.handleMessage(msgStr, 4000)
                }
                var me = this
                new Promise((resolve, reject) => {
                        me.extraID = setTimeout(() => {
                            resolve(1);
                        }, 4500);
                  }).then(() => {
                        return 1
                  });
            } else if (read_state === "failure") {
                let msgStr = 'Communication To The Drone Was Lost'
                this.LDTSelectionElement.current.handleMessage(msgStr, 4000)
                return 0
            } else {
                return 1
            }
        } else {
            return 1
        }
    };


    updatePlot = async () => {

        //console.log("here in update", this.state.isPlotting)
        if (this.state.isPlotting === true) {

            if (this.state.hardwareSelection === 'LocoDroneTT') {

                if (this.state.sensorSelection === 'Matrix') {
                    if (this.matrixElement.current.state.mouseIsPressed === false) {       
                        if (this.state.isPlotting === true) {
                            await this.state.LRobj.drone_tt_mled_set(this.matrixElement.current.gridData)
                        }
                    }
                    if (this.state.isPlotting === true) {
                        await this.ldt_monitor()
                    }

                    //
                    //this.skip_count += 1
                    //if (this.skip_count >= this.skip_LIMIT) {
                    //    this.skip_count = 0
                    //    let monitor_state = await this.ldt_monitor()
                        //if (monitor_state === 0) {
                        //    return 0
                        //}
                    //}

                    if (this.state.isPlotting === true) {
                        let timeoutID = setTimeout(this.updatePlot, this.state.updateRate);
                        this.setState( {updateID: timeoutID} );
                    }
                    return
                } else if (this.state.sensorSelection === 'RGB') {

                    
                    if (this.state.isPlotting === true) {
                        if (this.rgbElement.current.state.mouseIsPressed === false) {
                            await this.state.LRobj.drone_tt_led_set(
                                Number(this.rgbElement.current.state.slid1),
                                Number(this.rgbElement.current.state.slid2),
                                Number(this.rgbElement.current.state.slid3))
                        }
                    }
                    
                    if (this.state.isPlotting === true) {
                        await this.ldt_monitor()
                    }
                    //
                    //this.skip_count += 1
                    //if (this.skip_count >= this.skip_LIMIT) {
                        //this.skip_count = 0
                        //let monitor_state = await this.ldt_monitor()
                        //if (monitor_state === 0) {
                        //    return 0
                        //}
                    //}
                    
                    if (this.state.isPlotting === true) {
                        let timeoutID = setTimeout(this.updatePlot, this.state.updateRate);
                        this.setState( {updateID: timeoutID} );
                    }
                    return    
                }

            }

            let dataArrays = [];

            if (this.state.hardwareSelection === 'LocoIoT') {
                if (this.state.sensorSelection === 'Accelerometer') {
                    dataArrays = this.state.LRobj.get_data_array('Accelerometer');
                } else if (this.state.sensorSelection === 'Gyroscope') {
                    dataArrays = this.state.LRobj.get_data_array('Gyroscope');
                } else {
                    dataArrays = this.state.LRobj.get_data_array(this.state.LRobj.self[this.state.LRobj.options[this.state.sensorSelection].refer].value);
                }

            } else {
                dataArrays = this.state.LRobj.get_data_array(this.state.LRobj.self[this.state.LRobj.options[this.state.sensorSelection].refer].value);
            }
            

            let hasYsettings = false;
            let rangeTemp;
            let axisOffsets;
            if (this.state.LRobj.options[this.state.sensorSelection].hasOwnProperty('range')) {
                //dataArrays[i]
                rangeTemp = this.state.LRobj.options[this.state.sensorSelection].range;
                axisOffsets = this.state.LRobj.options[this.state.sensorSelection].offset;
                hasYsettings = true;
            }


            for (let i = 0; i < dataArrays.length; i++) {                
                
                let data_update;
                let layout;

                if (hasYsettings === true) {
                    
                    if (rangeTemp[i] === 'min') {

                        let minVal = Math.min.apply(Math, dataArrays[i]);
                        let maxVal = Math.max.apply(Math, dataArrays[i]);
                        
                        if (Math.abs(maxVal - minVal) < axisOffsets * 2) {
                            data_update = {
                                y: [dataArrays[i]],
                            }
                            // Setup Plot Layout
                            layout = {
                                yaxis: {title: this.state.LRobj.self[this.state.LRobj.options[this.state.sensorSelection].refer].ylabels[i], autorange: false, range: [minVal - axisOffsets, maxVal + axisOffsets]},
                            };
    
                        } else {
                            data_update = {
                                y: [dataArrays[i]],
                            }
                            layout = {
                                yaxis: {title: this.state.LRobj.self[this.state.LRobj.options[this.state.sensorSelection].refer].ylabels[i], autorange: true,},
                            };
                        }  
                    } else {
                        data_update = {
                            y: [dataArrays[i]],
                        }
                        layout = {
                            yaxis: {title: this.state.LRobj.self[this.state.LRobj.options[this.state.sensorSelection].refer].ylabels[i], range: this.state.LRobj.options[this.state.sensorSelection].fixed},
                        };
                    }
                } else {
                    data_update = {
                        y: [dataArrays[i]],
                    }
                }

                try {
                    window.Plotly.update(this[`charRef${i}`].current, data_update, layout);
                } catch (err) {

                }

            }
            
            if (this.state.isPlotting === true) {
                const res = await this.getData();
                if (res !== 0) {
                    let timeoutID = setTimeout(this.updatePlot, this.state.updateRate);
                    this.setState( {updateID: timeoutID} );
                } else {
                    clearTimeout(this.state.updateID);
                    this.state.LRobj.clearLDTTimeouts()
                    this.state.LRobj.clearData()
                    clearTimeout(this.state.LRobj.get_vitals_timer_ID);
                    this.setState( {isPlotting: false, plottingText: "Start Plotting", isConnected: false} );
                    this.state.LRobj.resetArrays();
                    setTimeout( this.changeConnect, 50);
                }
            }
        }
    };




    openDisplay = async () => {
        if (this.state.hardwareSelection === 'LocoDrone') {
            let tempObj = new LocoDrone();
            this.setState( {LRobj: tempObj} );
        } else if (this.state.hardwareSelection === 'LocoDroneT') {
            let tempObj = new LocoDroneT();
            this.setState( {LRobj: tempObj} );
        } else if (this.state.hardwareSelection === 'LocoDroneTT') {
            let tempObj = new LocoDroneTT();
            this.setState( {LRobj: tempObj} );
        } else if (this.state.hardwareSelection === 'LocoIoT') {
            let tempObj = new LocoIoT();
            this.setState( {LRobj: tempObj, isIoT: true} );
        } else if ((this.state.hardwareSelection === 'LocoArm') || (this.state.hardwareSelection === 'LocoArmS')) {
            let tempObj = new LocoArm();
            this.setState( {LRobj: tempObj} );
        } else if ((this.state.hardwareSelection === 'LocoArmM') || (this.state.hardwareSelection === 'LocoArmMS')) {
            let tempObj = new LocoArmM();
            this.setState( {LRobj: tempObj} );
        } else if (this.state.hardwareSelection === 'LocoWear') {
            let tempObj = new LocoWear();
            this.setState( {LRobj: tempObj} );
        } else if (this.state.hardwareSelection === 'LocoXtreme') {
            let tempObj = new LocoXtreme();
            this.setState( {LRobj: tempObj} );
        }
        setTimeout(this.changeSelect, 50);
        
    }

    changeSelect = () => {
        this.setState( {sensorSelection: this.state.LRobj.optionNames[0]} );
        this.setState( {selected: true} );
    }


    visLWEnd = (endType) => {
    }

    visLDEnd = () => {        
    }

    visLDPromptEnd = (event) => {

        this.visLDPromptElement.current.handleClose();
        this.visLDElement.current.handleOpen();
        
        setTimeout(this.state.LRobj.calibrate, 1000);
    }

    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        // For TopBar Route Highlight
        const currentPath = this.props.location.pathname;

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                    {this.state.selected === false && (
                        <Grid
                            spacing = {1}
                            justify="center"
                            container
                            className={classes.grid}
                        >
                        <Grid item xs={9}>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <Select
                                    value={this.state.hardwareSelection}
                                    onChange={this.handleChange}
                                    aria-describedby="hardware-pn-helper-text"
                                    input={
                                    <OutlinedInput
                                        labelWidth={this.state.labelWidth}
                                        name="hardwareSelection"
                                    />
                                    }
                                >
                                    {this.context.AuthInstance.userAccess.map((val) => {
                                        if (val !== 'PythonOnly') {
                                            return (
                                                <MenuItem key={val} value={val}>
                                                    {val}
                                                </MenuItem>
                                            );
                                        }
                                    })}
                                </Select>
                                <FormHelperText className={classes.inputHelperSelect} id="hardware-pn-helper-text">Product Type</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.openDisplay}
                            >
                                Select
                            </Button>
                        </Grid>
                    </Grid>
                    )}
                    {this.state.selected === true && (
                        <Grid
                            spacing = {1}
                            justify="center"
                            container
                            className={classes.grid}
                        >
                            <Grid item xs={3}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <Select
                                        value={this.state.sensorSelection}
                                        onChange={this.handleChange}
                                        aria-describedby="sensor-pn-helper-text"
                                        disabled={this.state.isPlotting}
                                        input={
                                        <OutlinedInput
                                            labelWidth={this.state.labelWidth}
                                            name="sensorSelection"
                                        />
                                        }
                                    >
                                        {this.state.LRobj.optionNames.map((val) => {
                                            return (
                                                <MenuItem key={val} value={val}>
                                                    {val}
                                                </MenuItem>
                                            );
                                        })}                        
                                    </Select>
                                    <FormHelperText className={classes.inputHelperSelect} id="sensor-pn-helper-text">Data Type</FormHelperText>
                                </FormControl>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <Select
                                        value={this.state.colorSelection}
                                        onChange={this.handleChange}
                                        aria-describedby="sensor-pn-helper-text"
                                        disabled={this.state.isPlotting}
                                        input={
                                        <OutlinedInput
                                            labelWidth={this.state.labelWidth}
                                            name="colorSelection"
                                        />
                                        }
                                    >
                                        {this.state.colorOptions.map((val) => {
                                            return (
                                                <MenuItem key={val} value={val}>
                                                    {val}
                                                </MenuItem>
                                            );
                                        })}                        
                                    </Select>
                                    <FormHelperText className={classes.inputHelperSelect} id="sensor-pn-helper-text">Plot Color</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth className={classes.formControl}> 
                                    <Input className={classes.inputField}
                                        id="standard-adornment-pn"
                                        aria-describedby="standard-pn-helper-text"
                                        disabled={this.state.isPlotting}
                                        inputProps={{
                                            'aria-label': 'pn',
                                        }}
                                        onChange = {this.handleChangeBuffer}
                                        value = {this.state.bufferSize}
                                    />
                                    <FormHelperText className={classes.inputHelper} id="standard-pn-helper-text">Buffer Size (10-100)</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={this.handleConnect}
                                    >
                                        {this.state.connectText}
                                    </Button>     
                                </FormControl>  
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >                         
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={this.handleStartPlotting}
                                        disabled={!this.state.isConnected}
                                    >
                                        {this.state.plottingText}
                                    </Button>    
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleBack}
                                >
                                    Back
                                </Button>   
                            </Grid>
                            {this.state.isIoT === true && this.state.isPlotting === false && (
                                <img src={this.state.LRobj.options[this.state.sensorSelection].img} 
                                    alt=""
                                    height='400px'></img>                                    
                            )}
                            <Grid item xs={12}></Grid>
                            {(((this.state.hardwareSelection === "LocoDroneT") || 
                                (this.state.hardwareSelection === "LocoDroneTT")) && 
                                (this.state.isConnected === true)) && (
                                <Grid item xs={12}>
                                    {this.state.message_str_1}
                                </Grid>
                            )}
                            {this.state.LRobj.options[this.state.sensorSelection].count === "matrix" && this.state.isPlotting === true && (
                                <LDTMatrix
                                    ref = {this.matrixElement}
                                />
                            )}
                            {this.state.LRobj.options[this.state.sensorSelection].count === "rgb" && this.state.isPlotting === true && (
                                <LDTRGB
                                    ref = {this.rgbElement}
                                />
                            )}
                            {this.state.LRobj.options[this.state.sensorSelection].count >= 1 && this.state.isPlotting === true && (
                            <Grid item xs={4}>
                                <Paper className={classes.paper}>
                                    <div ref={this.charRef0}></div>
                                </Paper>
                            </Grid>
                            )}
                            {this.state.LRobj.options[this.state.sensorSelection].count > 1 && this.state.isPlotting === true && (
                            <Grid item xs={4}>
                                <Paper className={classes.paper}>
                                    <div ref={this.charRef1}></div>
                                </Paper>
                            </Grid>
                            )}
                            {this.state.LRobj.options[this.state.sensorSelection].count > 2 && this.state.isPlotting === true && (
                            <Grid item xs={4}>
                                <Paper className={classes.paper}>
                                    <div ref={this.charRef2}></div>
                                </Paper>
                            </Grid>
                            )}
                        </Grid>
                    )}
                <DataVisLW 
                    ref={this.visLWElement}
                    onEnd={this.visLWEnd}
                />
                <DataVisLD
                    ref={this.visLDElement}
                    onEnd={this.visLDEnd}
                />
                <DataVisLDPrompt
                    ref={this.visLDPromptElement}
                    onClose={this.visLDPromptEnd}
                />
                <LXSettingsModal
                    ref={this.LXSelectionElement}
                    onClose={this.LXSelection}
                />
                <LDTSettingsModal
                    ref = {this.LDTSelectionElement}
                    onClose= {this.LDTSelection}
                />
                <GeneralMessage
                    ref = {this.LXScanMsgElement}
                    onClose = {this.openLXSelection}
                />
                <GeneralMessage
                    ref = {this.LDTScanMsgElement}
                    onClose = {this.openLDTSelection}
                />
                <GeneralMessage
                    ref = {this.LXConnMsgElement}
                    onClose = {() => void 0}
                />
                <GeneralMessage
                    ref = {this.LXNoConnMsgElement}
                    onClose = {() => void 0}
                />
                <GeneralMessage
                    ref = {this.LDTConnMsgElement}
                    onClose = {() => void 0}
                />
                <GeneralMessage
                    ref = {this.LDTNoConnMsgElement}
                    onClose = {() => void 0}
                />
            </React.Fragment>
            
        );
    }

}


export default withRouter(withStyles(styles)(DataVis));

