// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';



// Import Context to Access Auth/User Information
import { AuthContext } from "../../context/auth";




// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #058af4',
      square: false,
      boxShadow: theme.shadows[5],
      maxHeight: 200,
      overflow: 'scroll',
      paddingLeft: 10,
    },
    media: {
        height: 0,
        paddingTop: '100%', // 
        marginTop:'30',
    },
    text: {
    },
    actionButton: {
        type: "submit",
        justify: "center",
        margin: theme.spacing(0),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
        fontSize: '15px',
    },
  });






// Component Class
class AccessListModal extends React.Component {

    static contextType = AuthContext;

    // Class state
    state = {
        isOpen: false,
    }

 
    // For Displaying Self (Modal), Called by Parent
    handleOpen = () => {
        setTimeout(this.openSelf, 100);
    };   

    openSelf = () => {
        this.setState( {isOpen: true} );
    }

    handleClose = () => {
        this.setState( {isOpen: false} );
    };    

    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display - The Modal: Header, Button, "pre" tagged Text
        return (
            <div>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper}>
                        {this.context.AuthInstance.userAccess.map((value, i) => {
                            return (
                                <Typography key = {i}>
                                    {value}
                                </Typography>
                            );
                        })}
                    </div>
                
                </Fade>
                </Modal>
            </div>
        );
    }

}

export default withStyles(styles)(AccessListModal);