// Child Component of: 
// --- ProgramCoding

// Import React and Material-UI Modules
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';

import OpenWithIcon from '@material-ui/icons/OpenWith';

import Draggable from "react-draggable";

import GameWindow from "../GameWindow";

import ldtEmergency from '../../images/LocoDroneT/drone_emergency.svg';


// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '840px',
    minWidth: '400px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    square: false,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  actionButton: {
    justify: "right",
    margin: theme.spacing(2),
    color: "red",
  },
  backDrop: {
    //background: 'rgba(255,255,255,0)',
  }
});

// Component Class - ProgramDisplay
class ProgramDisplay extends React.Component {

    


    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);


        // Bind Certain Functions to "this"
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.updateText = this.updateText.bind(this);
        this.clearText = this.clearText.bind(this);
        this.setClose = this.setClose.bind(this);

        this.gameElement = React.createRef();
        
    }
    // Class state
    state = {
        isOpen: false,
        stdOut: "",
        stopClose: "Stop",
        bodyColor: "red",
        scrollDiv: React.createRef(),
        canScroll: false,
        plotSize: [0,0],
        plotLeft: '300%',
        gameSize: [0,0],
        gameTopLeft: ['300%', '-25%'],
        gameStarted: false,
        gameSettingsStr: '',
        ldtExtraEnabled: false,
    }

    
    scrollToBottom = () => {
        if (this.state.canScroll === true) {
            try {
                const scrollheight = this.state.scrollDiv.current.scrollHeight;
                this.state.scrollDiv.current.scrollTo({top: scrollheight, behavior: "smooth"});
            } catch {}
        }
    }

    componentDidMount() {
        window.plotDivRef = React.createRef();
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }
    
    componentWillUnmount() {
        try {
            this.setState( {canScroll: false} );
        } catch {}        
    }


    // For Displaying Self (Modal), Called by Parent ProgramCoding
    handleOpen = () => {
        this.setState({plotSize: [0,0], plotLeft: '300%'})
        this.setState({gameSize: [0,0], gameTopLeft: ['300%', '-25%']})
        this.setState( {
            isOpen: true,
            canScroll: true,
            gameSettingsStr: '',
            ldtExtraEnabled: false,
        } );
        
    };

    handleOpenPlot = () => {
        this.setState({plotSize: [window.plotSize[0],window.plotSize[1]], plotLeft: '25%'})
    }

    handleOpenGame = () => {
        this.setState( {gameStarted: true, gameSize: [window.game_size[0], window.game_size[1]], gameTopLeft: ['-15%', '-25%']} )
        this.gameElement.current.initGame();
    }

    handleInitGame = (hardwareStr) => {
        this.gameElement.current.initLR(hardwareStr);
    }

    handlePassSettings = (settingsStr) => {
        this.setState( {gameSettingsStr: settingsStr} )
        this.gameElement.current.handleGameSettings(settingsStr);
    }

    handleConnect = () => {
        this.gameElement.current.handleConnect();
    }

    // For Stopping Program Run
    handleClose = () => {
        if (this.state.stopClose === "Stop") {
            // Tell Parent ProgramCoding to Call its stopRunning() function
            this.props.onStop();
            this.setState( {stopClose: "Close"} );
        } else {
            // Update Self (Modal) Open state
            this.setState( {isOpen: false} );
            this.setState( {canScroll: false} );
        }
    };

    handleEmergency = () => {
        // 
        this.setState( {
            isOpen: false,
            canScroll: false,
            ldtExtraEnabled: false,
        } );
        
        this.props.onEmergency();
    }

    handlePlot = () => {        
        //window.plotAdded[0] = ;
        //window.plotAdded[1] = ;
    }

    // For Updating state of Modal behavior settings, Called by Parent ProgramCoding
    updateText = (text) => {
        this.setState( {stdOut: text.text, bodyColor: text.bodyColor, stopClose: text.stopClose} );
    };

    // For removing body text from Modal, Called by Parent ProgramCoding
    clearText = () => {
        this.setState( {stdOut: ""} );
    };

    // Update stopClose state for on-Modal Button text
    setClose = (text) => {
        this.setState( {stopClose: text} );
    }

    handleLDTExtra = () => {
        this.setState( {ldtExtraEnabled: true} )
    }

    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display - The Modal: Header, Button, "pre" tagged Text
        return (
            <div>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        classes: {
                            root: classes.backDrop
                        },
                        timeout: 500,
                    }}
                    style ={{
                        minWidth: '200px', maxHeight: '200px',
                        position: 'absolute', left: '0%', top: '45%',
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper} 
                    style = {{borderRadius: "25px",}}>
                        <Grid 
                            container 
                            spacing={2}
                            direction="row"
                        >
                            <Grid item xs={9}>
                                {this.state.ldtExtraEnabled && (
                                    <Button
                                        variant ="contained" 
                                        className={classes.actionButton} 
                                        onClick={this.handleEmergency}
                                        style={{fontSize: '20px'}}
                                    >
                                        <img 
                                            src = {ldtEmergency} 
                                            width={"20px"}
                                            style = {{background: 'white'}}
                                        />
                                    </Button>
                                )}
                                <Button 
                                    className={classes.actionButton} 
                                    onClick={this.handleClose}
                                    style={{fontSize: '20px'}}
                                >
                                    {this.state.stopClose}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">

                            </Grid>
                            <Grid item xs={12}>
                                <div style ={{display: 'flex', justifyContent:'center', alignItems:'center', }}>
                                    <pre ref={this.state.scrollDiv}
                                        style={{color: this.state.bodyColor, fontSize: '15px', minWidth:'500px', minHeight: '300px', maxHeight: '300px', overflow: 'auto'}}
                                    >
                                        {this.state.stdOut}
                                    </pre>                                 
                                </div>
                            </Grid>
                        </Grid> 
                        <Draggable handle="#handle">
                            <Card variant="outlined" 
                                style ={{width: this.state.plotSize[0], height: this.state.plotSize[1],
                                        position: 'absolute', left: this.state.plotLeft, top: '-20%',
                                    }}>
                                <span id='handle'>
                                    <IconButton>
                                        Move
                                        <OpenWithIcon/>
                                    </IconButton>
                                </span>
                                <div 
                                    ref={window.plotDivRef}
                                ></div>
                            </Card>
                        </Draggable>
                        <GameWindow 
                            ref = {this.gameElement}
                            size = {this.state.gameSize}
                            topleft = {this.state.gameTopLeft}
                        />
                    </div>
                </Fade>
                </Modal>
            </div>
        );
    };
}

export default withStyles(styles)(ProgramDisplay);
