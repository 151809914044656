import React, { Component } from 'react';
import Ship from './Ship';
import Asteroid from './Asteroid';
import GameOver from '../shared/GameOver.js'
import { randomNumBetweenExcluding } from './helpers'

const KEY = {
  LEFT:  37,
  RIGHT: 39,
  UP: 38,
  A: 65,
  D: 68,
  W: 87,
  SPACE: 32,
  ENTER: 13,
};

export class Reacteroids extends Component {
  constructor() {
    super();
    this._isMounted = false;
    this.state = {
      screen: {
        width: 800, //window.innerWidth,
        height: 480, //window.innerHeight,
        ratio: window.devicePixelRatio || 1,
      },
      context: null,
      keys : {
        left  : 0,
        right : 0,
        up    : 0,
        down  : 0,
        space : 0,
      },
      lastkeys: {
        left: false,
        right: false,
        up: false,
        down: false,
        space: false,
      },
      asteroidCount: 0,
      currentScore: 0,
      highScore: localStorage['topscore'] || 0,
      newHighScore: false,
      inGame: false,
      backgroundColor: '#000',
      laserColor: '#FFF',
      mode: '',
      settings: {},
      width: 600,
      height: 400,
      btnLevel: 0,
      message: 'Press Enter to restart',  
    }
    this.ship = [];
    this.asteroids = [];
    this.bullets = [];
    this.particles = [];
    this.nextFrame = 0;
  }


  updateSettings = (settings) => {
    
    this.setState( {settings: settings} )
    if (typeof settings['settings'] !== "undefined") {

        for (let i = 0; i < settings['settings'].length; i++) {
            if ('particle color' in settings['settings'][i]) {
                this.setState( {particleColor: settings['settings'][i]['particle color']} )
            }
            if ('laser color' in settings['settings'][i]) {
                this.setState( {laserColor: settings['settings'][i]['laser color']} )
            }
            if ('background color' in settings['settings'][i]) {
                this.setState( {backgroundColor: settings['settings'][i]['background color']} )
            }
 

        }

    }


  }

  handleResize(value, e){
    this.setState({
      screen : {
        width: window.innerWidth,
        height: window.innerHeight,
        ratio: window.devicePixelRatio || 1,
      }
    });
  }
  /*
  handleKeys = (value, e) => {
    let keys = this.state.keys;
    if(e.keyCode === KEY.LEFT   || e.keyCode === KEY.A) keys.left  = value;
    if(e.keyCode === KEY.RIGHT  || e.keyCode === KEY.D) keys.right = value;
    if(e.keyCode === KEY.UP     || e.keyCode === KEY.W) keys.up    = value;
    if(e.keyCode === KEY.SPACE) keys.space = value;
    this.setState({
      keys : keys
    });
  }
  */
 
  handleKeys = (e) => {
    let keys = this.state.keys;
    if(e.keyCode === KEY.LEFT   || e.keyCode === KEY.A) keys.left  = true;
    if(e.keyCode === KEY.RIGHT  || e.keyCode === KEY.D) keys.right = true;
    if(e.keyCode === KEY.UP     || e.keyCode === KEY.W) keys.up    = true;
    if(e.keyCode === KEY.SPACE) keys.space = true;
    // if 
    if (e.keyCode === KEY.ENTER) {
      if (this.state.inGame === false) {
        //setTimeout(this.gameOver, 50)
        setTimeout(this.startGame, 200);
      }
      //keys.space = true;
    }
    this.setState({
      keys : keys
    });
  }

  handleKeysUp = (e) => {
    let keys = this.state.keys;
    if(e.keyCode === KEY.LEFT   || e.keyCode === KEY.A) keys.left  = false;
    if(e.keyCode === KEY.RIGHT  || e.keyCode === KEY.D) keys.right = false;
    if(e.keyCode === KEY.UP     || e.keyCode === KEY.W) keys.up    = false;
    if(e.keyCode === KEY.SPACE) keys.space = false;
    this.setState({
      keys : keys
    });
  }

  componentDidMount() {
    //this._isMounted = true;
      /*
    window.addEventListener('keyup',   this.handleKeys.bind(this, false));
    window.addEventListener('keydown', this.handleKeys.bind(this, true));
    window.addEventListener('resize',  this.handleResize.bind(this, false));

    const context = this.refs.canvas.getContext('2d');
    this.setState({ context: context });
    this.startGame();
    requestAnimationFrame(() => {this.update()});
    */
  }

  initGame = (mode) => {  
    
    this._isMounted = true;  
    this.setState( {mode: mode} ) 
    if (mode === 'LocoWear') {
      this.setState( {message: 'Press Top Button to restart'} )
      this.props.LRObj.send_command(this.props.LRObj.self.GET_DATA.value);
    } else {  
      //window.addEventListener('keyup',   this.handleKeys.bind(this, false));
      //window.addEventListener('keydown', this.handleKeys.bind(this, true));
      //window.addEventListener('keyup',   this.handleKeys);
      window.addEventListener('keydown', this.handleKeys);
      window.addEventListener('keyup', this.handleKeysUp);
    }
    //window.addEventListener('resize',  this.handleResize.bind(this, false));

    const context = this.refs.canvas.getContext('2d');
    this.setState({ context: context });
    this.startGame();
    this.nextFrame = requestAnimationFrame(() => {this.update()});
  }

  componentWillUnmount() {
    this._isMounted = false;
    try {
      window.cancelAnimationFrame(this.nextFrame);
    } catch {}
    if (this.state.mode === '') {
      //window.removeEventListener('keyup', this.handleKeys);
      window.removeEventListener('keyup', this.handleKeysUp);
      window.removeEventListener('keydown', this.handleKeys);
    } else {
      try {
        this.props.LRObj.send_command(this.props.LRObj.self, this.props.LRObj.self.SET_CANCEL);
      } catch {}
    }



    //window.removeEventListener('resize', this.handleResize);
  }


  get_rp = (xyz) => {

    let rp = [0,0];

    rp[0] = Math.atan2(xyz[0], Math.sqrt(xyz[1] * xyz[1] + xyz[2] * xyz[2])) * 180.0 / Math.PI
    rp[1] = Math.atan2(xyz[1], Math.sqrt(xyz[0] * xyz[0] + xyz[2] * xyz[2])) * 180.0 / Math.PI
    if ((xyz[1] === 0) && (xyz[2] === 0)) {
        rp[0] = 0
    }
    if ((xyz[0] === 0) && (xyz[2] === 0)) {
        rp[1] = 0
    }

    if (rp[0] > 30) {
        rp[0] = 30
    } else if (rp[0] < -30) {
        rp[0] = -30
    }
    if (rp[1] > 45) {
        rp[1] = 45
    } else if (rp[1] < -45) {
        rp[1] = -45
    }

    return rp;
  }

  update() {
    if (this._isMounted === true) {
      if (this.state.mode === 'LocoWear') {
        let rp = this.get_rp(this.props.LRObj.self.accData);

        let btn = this.props.LRObj.self.topBtnState;
        if ((btn === 1) && (this.state.btnLevel === 0)) {
          /*if (this.state.inGame === true) {
            let ship = this.ship[0];
            ship.destroy();
          }*/
          if (this.state.inGame === false) {
            setTimeout(this.startGame, 250);
          }
          /*
          if (this.state.inGame === false) {
          } else if (this.state.inGame === true) {
            setTimeout(this.gameOver, 50)
            //setTimeout(this.startGame, 250);
          }*/       
        }
        this.setState( {btnLevel: btn} );

        let keys = this.state.keys
        let lastkeys = this.state.lastkeys
        if ((rp[0] > 25) && (lastkeys.left === false)) {
          keys.left  = 1;
          lastkeys.left = true;
        } else {
          keys.left  = 0;
          lastkeys.left = false;
        }
        if ((rp[0] < -25) && (lastkeys.right === false)) {
          keys.right  = 1;
          lastkeys.right = true;
        } else {
          keys.right  = 0;
          lastkeys.right = false;
        }
        if ((rp[1] > 25) && (lastkeys.down === false)) {
          keys.down = 1;
          lastkeys.down = true;
        } else {
          keys.down = 0;
          lastkeys.down = false;
        }      
        if ((rp[1] < -25) && (lastkeys.up === false)) {
          keys.up = 1;
          lastkeys.up = true;
        } else {
          keys.up = 0;
          lastkeys.up = false;
        }

        if ((this.props.LRObj.self.sideBtnState === 1) && (lastkeys.space === false)) {
          keys.space = 1;
          lastkeys.space = true;
        } else {
          keys.space = 0;
          lastkeys.space = false;
        }
        this.setState({
          keys : keys,
          lastkeys: lastkeys,
        });

        this.props.LRObj.send_command(this.props.LRObj.self.GET_DATA.value);
      }

      const context = this.state.context;
      const keys = this.state.keys;
      const ship = this.ship[0];

      context.save();
      context.scale(this.state.screen.ratio, this.state.screen.ratio);

      // Motion trail
      context.fillStyle = this.state.backgroundColor;
      context.globalAlpha = 0.4;
      context.fillRect(0, 0, this.state.screen.width, this.state.screen.height);
      context.globalAlpha = 1;

      // Next set of asteroids
      if(!this.asteroids.length){
        let count = this.state.asteroidCount + 1;
        this.setState({ asteroidCount: count });
        this.generateAsteroids(count)
      }

      // Check for colisions
      this.checkCollisionsWith(this.bullets, this.asteroids);
      this.checkCollisionsWith(this.ship, this.asteroids);

      // Remove or render
      this.updateObjects(this.particles, 'particles')
      this.updateObjects(this.asteroids, 'asteroids')
      this.updateObjects(this.bullets, 'bullets')
      this.updateObjects(this.ship, 'ship')

      context.restore();

      try {
        window.game_state = {
          'score': this.state.currentScore,
          'asteroids': this.state.asteroidCount,
          'ship x': this.ship[0].position.x,
          'ship y': this.ship[0].position.y,
        }
      } catch {
        window.game_state = {
          'score': this.state.currentScore,
          'asteroids': this.state.asteroidCount,
          'ship x': 0,
          'ship y': 0,
        }
      }

      // Next frame
      this.nextFrame = requestAnimationFrame(() => {this.update()});
    }
  }

  addScore(points){
    if(this.state.inGame){
      this.setState({
        currentScore: this.state.currentScore + points,
      });
    }
  }

  startGame = () => {
    this.setState({
      inGame: true,
      currentScore: 0,
      screen: {
        width: 800, //window.innerWidth,
        height: 480, //window.innerHeight,
        ratio: window.devicePixelRatio || 1,
      },
    });

    // Make ship
    let ship = new Ship({
      position: {
        x: this.state.screen.width/2,
        y: this.state.screen.height/2
      },
      create: this.createObject.bind(this),
      onDie: this.gameOver.bind(this),
      particleColor: this.state.particleColor || '#FFF',
      laserColor: this.state.laserColor || '#FFF',
    });
    this.createObject(ship, 'ship');

    // Make asteroids
    this.asteroids = [];
    this.generateAsteroids(this.state.asteroidCount)
    this.updateSettings(this.state.settings);
  }

  gameOver = () => {
    this.setState({
      inGame: false,
      newHighscore: false,
      screen: {
        width: 800, //window.innerWidth,
        height: 0, //window.innerHeight,
        ratio: window.devicePixelRatio || 1,
      },
    });

    // Replace top score
    if(this.state.currentScore > this.state.highScore){
      this.setState({
        highScore: this.state.currentScore,
        newHighScore: true,
      });
      localStorage['topscore'] = this.state.currentScore;
    }
  }

  generateAsteroids(howMany){
    let asteroids = [];
    let ship = this.ship[0];
    for (let i = 0; i < howMany; i++) {
      let asteroid = new Asteroid({
        size: 80,
        position: {
          x: randomNumBetweenExcluding(0, this.state.screen.width, ship.position.x-60, ship.position.x+60),
          y: randomNumBetweenExcluding(0, this.state.screen.height, ship.position.y-60, ship.position.y+60)
        },
        create: this.createObject.bind(this),
        addScore: this.addScore.bind(this)
      });
      this.createObject(asteroid, 'asteroids');
    }
  }

  createObject(item, group){
    this[group].push(item);
  }

  updateObjects(items, group){
    let index = 0;
    for (let item of items) {
      if (item.delete) {
        this[group].splice(index, 1);
      }else{
        items[index].render(this.state);
      }
      index++;
    }
  }

  checkCollisionsWith(items1, items2) {
    var a = items1.length - 1;
    var b;
    for(a; a > -1; --a){
      b = items2.length - 1;
      for(b; b > -1; --b){
        var item1 = items1[a];
        var item2 = items2[b];
        if(this.checkCollision(item1, item2)){
          item1.destroy();
          item2.destroy();
        }
      }
    }
  }

  checkCollision(obj1, obj2){
    var vx = obj1.position.x - obj2.position.x;
    var vy = obj1.position.y - obj2.position.y;
    var length = Math.sqrt(vx * vx + vy * vy);
    if(length < obj1.radius + obj2.radius){
      return true;
    }
    return false;
  }

  render() {
    let endgame;
    //let message;

    //message = 'Top score with ' + this.state.currentScore + ' points';

    if(!this.state.inGame){
      endgame = (
        <GameOver
          width={this.state.width}
          height={this.state.height}
          highScore={this.state.highScore}
          newHighScore={this.state.newHighScore}
          score={this.state.currentScore}
          message={this.state.message}
        />
      )
    }

    return (
      <React.Fragment>
      <div style = {{display: 'grid', alignItems: "center", transform: `translate('-50%', '-50%')`, justifyContent: 'center'}}> 
        <div> 
          { endgame }
        </div>
        <div> 
          <canvas ref="canvas"
            width={this.state.screen.width * this.state.screen.ratio}
            height={this.state.screen.height * this.state.screen.ratio}
          />
          {(this.state.inGame === true) && (
          <div id='Score' style={{ fontSize: this.state.width / 20 }}>
            HIGH-SCORE: {this.state.highScore}&ensp;&ensp;&ensp;&ensp;SCORE:{' '}
            {this.state.currentScore}
          </div>
          )}
        </div>
      </div>
      </React.Fragment>
    );
  }
}