
class LocoWear {

    constructor () {
        this.bufferSize = 100;
        this.alpha = 0.3;

        this.optionNames = ['Accelerometer', 'Battery', 'Buttons'];

        this.options = {'Accelerometer': {refer: 'GET_ACC', count: 3, range: ['min', 'min', 'min'], offset: 0.25, },
                        'Battery': {refer: 'GET_BATT', count: 1, range: ['fixed'], fixed: [3.15, 4.25], offset: 0.25,},
                        'Buttons': {refer: 'GET_BUTTONS', count: 3, range: ['fixed', 'fixed', 'fixed'], fixed: [-0.5, 1.5] },
                        };
        
        this.self = {
            GET_ACC: {
                value: 97,
                names: ['ax', 'ay', 'az'],
                ylabels: ["X-Axis Acc. (g)", "Y-Axis Acc. (g)", "Z-Axis Acc. (g)"],
            },
            GET_BATT: {
                value: 98,
                names: ['Voltage'],
                ylabels: ["Battery Lvl. (V)"],
            },
            GET_BUTTONS: {
                value: 99,
                names: ['Side Btn', 'Top Btn', 'AND Btns'],
                ylabels: ["Side Btn State (#)", "Top Btn State (#)", "Binary AND of Btns (#)"],
            },
            GET_DATA: {
                value: 100,
            },
            GET_BIND: 98,
            bound: false,
            accData: [0,0,0],
            axArray: [],
            ayArray: [],
            azArray: [],
            battArray: [],
            topBtnState: 0,
            topBtnArray: [],
            sideBtnState: 0,
            sideBtnArray: [],
            bothBtnArray: [],
        };
    };

    resetArrays = () => {
        this.self.axArray = [];
        this.self.ayArray = [];
        this.self.azArray = [];
        this.self.battArray = [];
        this.self.topBtnArray = [];
        this.self.sideBtnArray = [];
        this.self.bothBtnArray = [];
    };

    map_data = (x, in_min, in_max, out_min, out_max) => {
        return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    };

    
    set_buffer_size = (buffer_size) => {
        this.bufferSize = buffer_size
    }

    get_buffer_size = () => {
        return this.bufferSize
    }

    connect = async () => {
        
        try {
            window.port.disconnect();
        } catch {
        }

        window.port = await window.serial.requestPort();
        await window.port.connect();
        window.port.onReceive = data => {
            this.processData(data);
        }
        window.port.onReceiveError = (error) => {            
        }
    }

    disconnect = () => {
        window.port.disconnect().then(() => {
            window.port = {};
            return;
        });
    }

    send_command = (data_msg) => {
        let data = new Uint8Array(1);
        data[0] = data_msg;
        window.port.send(data);
    };

    get_data_array = (type) => {
        
        switch(type) {    
            case this.self.GET_ACC.value:
                return [this.self.axArray, this.self.ayArray, this.self.azArray];
            case this.self.GET_BATT.value:
                return [this.self.battArray];
            case this.self.GET_BUTTONS.value:
                return [this.self.topBtnArray, this.self.sideBtnArray, this.self.bothBtnArray];
            default: 
                return [0,0,0];
        }
    };

    processData = (data) => {
        let dataBytes = new Uint8Array(data.buffer);
        switch(dataBytes[0]) {
    
            case this.self.GET_DATA.value:
                const axSlice = dataBytes.slice(1, 5);
                const aySlice = dataBytes.slice(5, 9);
                const azSlice = dataBytes.slice(9, 13);
    
                const axArr = new Float32Array(axSlice.buffer);
                const ayArr = new Float32Array(aySlice.buffer);
                const azArr = new Float32Array(azSlice.buffer);
                if (this.self.axArray.length > this.bufferSize) {    
                    this.self.axArray.splice(0, 1);
                    this.self.ayArray.splice(0, 1);
                    this.self.azArray.splice(0, 1);
                    this.self.battArray.splice(0, 1);
                    this.self.topBtnArray.splice(0, 1);
                    this.self.sideBtnArray.splice(0, 1);
                    this.self.bothBtnArray.splice(0, 1);
                }

                if (this.self.axArray.length > 0) {
                    let lastValx = this.self.axArray[this.self.axArray.length-1];
                    let lastValy = this.self.ayArray[this.self.ayArray.length-1];
                    let lastValz = this.self.azArray[this.self.azArray.length-1];
                    let nowValx = axArr[0];
                    let nowValy = ayArr[0];
                    let nowValz = azArr[0];
                    
                    axArr[0] = this.alpha * nowValx + (1 - this.alpha) * lastValx;
                    ayArr[0] = this.alpha * nowValy + (1 - this.alpha) * lastValy;
                    azArr[0] = this.alpha * nowValz + (1 - this.alpha) * lastValz;
                }
                this.self.axArray.push(axArr[0]);
                this.self.ayArray.push(ayArr[0]);
                this.self.azArray.push(azArr[0]);
                this.self.accData = [axArr[0],ayArr[0],azArr[0]]
                
                const battSlice = dataBytes.slice(13, 17);

                const battLvlArr = new Float32Array(battSlice.buffer);

                if (this.self.battArray.length > 0) {
                    let lastValb = this.self.battArray[this.self.battArray.length-1];
                    let nowValb = battLvlArr[0];
                    battLvlArr[0] = this.alpha * nowValb + (1 - this.alpha) * lastValb;
                }
                this.self.battArray.push(battLvlArr[0]);

                this.self.topBtnState = 1 - dataBytes[18]
                this.self.sideBtnState = 1 - dataBytes[17]

                this.self.topBtnArray.push(1 - dataBytes[17]);
                this.self.sideBtnArray.push(1 - dataBytes[18]);
                this.self.bothBtnArray.push((1 - dataBytes[17]) && (1 - dataBytes[18]));
    
                break;
            case this.self.GET_BIND:
                this.self.bound = true;
            default: 
                break;
        }
    
    };

}

export default LocoWear;