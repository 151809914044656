import btnSchm from '../images/schematics/BTN.svg';
import dhtSchm from '../images/schematics/DHT.svg';
import ntcSchm from '../images/schematics/NTC.svg';
import ldrSchm from '../images/schematics/LDR.svg';
import ussSchm from '../images/schematics/USS.svg';
import micSchm from '../images/schematics/MIC.svg';
import mpuSchm from '../images/schematics/MPU.svg';
import joySchm from '../images/schematics/JOY.svg';
import pirSchm from '../images/schematics/PIR.svg';
import adc1Schm from '../images/schematics/ADC1.svg';
import adc2Schm from '../images/schematics/ADC2.svg';
import adc3Schm from '../images/schematics/ADC3.svg';



class LocoIoT {

    constructor () {
        this.port = {};
        this.bufferSize = 100;

        this.optionNames = ['Button', 'Light', 'Temperature', 
                            'Temp. and Humd.', 'Distance', 
                            'Microphone', 'Accelerometer', 
                            'Gyroscope', 'ADC 3.3V', 'ADC Voltage Divider',
                            'ADC Potentiometer', 'PIR', 'Joystick'];
        this.options = {'Button' : {img: btnSchm, refer: 'SUBTYPE_SW_1',count: 1},
                        'Light': {img: ldrSchm, refer: 'SUBTYPE_PHOTO', count: 1},
                        'Temperature': {img: ntcSchm, refer: 'SUBTYPE_NTC', count: 1},
                        'Temp. and Humd.': {img: dhtSchm, refer: 'SUBTYPE_DHT', count: 2},
                        'Distance': {img: ussSchm, refer: 'SUBTYPE_ULTRA', count: 1},
                        'Microphone': {img: micSchm, refer: 'SUBTYPE_MIC', count: 1},
                        'Accelerometer': {img: mpuSchm, refer: 'SUBTYPE_ACC', count: 3},
                        'Gyroscope': {img: mpuSchm, refer: 'SUBTYPE_GYR', count: 3},
                        'ADC 3.3V': {img: adc1Schm, refer: 'SUBTYPE_ADC_1', count: 1},
                        'ADC Voltage Divider': {img: adc2Schm, refer: 'SUBTYPE_ADC_1', count: 1},
                        'ADC Potentiometer': {img: adc3Schm, refer: 'SUBTYPE_ADC_1', count: 1},
                        'PIR': {img: pirSchm, refer: 'SUBTYPE_PIR', count: 1},
                        'Joystick': {img: joySchm, refer: 'SUBTYPE_JOYSTICK', count: 3},
                        };
        
        this.IOT_Codes_Constants = IOT_Codes_Constants;
        
        this.self = {
            SUBTYPE_ACC: {
                value: IOT_Codes_Constants.SUBTYPE_MPU,
                names: ['ax', 'ay', 'az'],
                ylabels: ["AX (g)", "AY (g)", "AZ (g)"],
            },
            SUBTYPE_GYR: {
                value: IOT_Codes_Constants.SUBTYPE_MPU,
                names: ['gx', 'gy', 'gz'],
                ylabels: ["GX (deg/s)", "GY (deg/s)", "GZ (deg/s)"],
            },
            SUBTYPE_SW_1: {
                value: IOT_Codes_Constants.SUBTYPE_SW_1,
                names: ['Btn State'],
                ylabels: ["State (#)"],
            },
            SUBTYPE_PHOTO: {
                value: IOT_Codes_Constants.SUBTYPE_PHOTO,
                names: ['#'],
                ylabels: ["Photocell Reading (#)"],
            },
            SUBTYPE_NTC: {
                value: IOT_Codes_Constants.SUBTYPE_NTC,
                names: ['Temperature (C)'],
                ylabels: ["Temperature NTC (C)"],
            },
            SUBTYPE_DHT: {
                value: IOT_Codes_Constants.SUBTYPE_DHT,
                names: ['Temperature (C)', 'Humidity (%)'],
                ylabels: ["Temperature (C)", 'Humidity (%)'],
            },
            SUBTYPE_MIC: {
                value: IOT_Codes_Constants.SUBTYPE_MIC,
                names: ['Mic. Analog (#)'],
                ylabels: ['Mic. Analog (#)'],
            },
            SUBTYPE_ULTRA: {
                value: IOT_Codes_Constants.SUBTYPE_ULTRA,
                names: ['Distance (cm)'],
                ylabels: ['Distance (cm)'],
            },
            SUBTYPE_JOYSTICK: {
                value: IOT_Codes_Constants.SUBTYPE_JOYSTICK,
                names: ['X Value', 'Y Value', 'Button J'],
                ylabels: ['X Value', 'Y Value', 'Button J'],
            },
            SUBTYPE_PIR: {
                value: IOT_Codes_Constants.SUBTYPE_PIR,
                names: ['PIR State'],
                ylabels: ['PIR State'],
            },
            SUBTYPE_ADC_1: {
                value: IOT_Codes_Constants.SUBTYPE_ADC_1,
                names: ['ADC1'],
                ylabels: ['ADC1'],
            },

            btnArray: [],
            ntcArray: [],
            photoArray: [],
            distArray: [],
            micArray: [],
            tempArray: [],
            humdArray: [],
            axArray: [],
            ayArray: [],
            azArray: [],
            gxArray: [],
            gyArray: [],
            gzArray: [],
            jxvArray: [],
            jyvArray: [],
            jbvArray: [],
            pirArray: [],
            adcArray: [],
        };

    };

    resetArrays = () => {
        this.self.btnArray = [];
        this.self.ntcArray = [];
        this.self.photoArray = [];
        this.self.distArray = [];
        this.self.micArray = [];
        this.self.tempArray = [];
        this.self.humdArray = [];
        this.self.axArray = [];
        this.self.ayArray = [];
        this.self.azArray = [];
        this.self.gxArray = [];
        this.self.gyArray = [];
        this.self.gzArray = [];
        this.jxvArray = [];
        this.jyvArray = [];
        this.jbvArray = [];
        this.pirArray = [];
        this.adcArray = [];
    }

    set_buffer_size = (buffer_size) => {
        this.bufferSize = buffer_size
    }

    get_buffer_size = () => {
        return this.bufferSize
    }


    connect = async () => {
        try {
            window.port.disconnect();
        } catch {
        }
        this.port = await window.serial.requestPort();
        await this.port.connect();
        this.port.onReceive = data => {
            this.processData(data);
        }
        this.port.onReceiveError = (error) => {            
        }
    };

    disconnect = () => {
        return this.port.disconnect();
    };

    get_endian_factor = (buf) => {
        let uInt32 = new Uint32Array([0x11223344]);
        let uInt8 = new Uint8Array(uInt32.buffer);
        let endianFactor = 3;
        if (uInt8[0] === 0x11) {
            endianFactor = 0;
        }
        return endianFactor;
    };

    send_request = ( msg_subtype, data = null) => {
        this.write_packet( IOT_Codes_Constants.TYPE_REQUEST, msg_subtype, data);
    };

    send_enable = ( msg_subtype, data = null) => {
        this.write_packet( IOT_Codes_Constants.TYPE_ENABLE, msg_subtype, data);
    };

    send_start = () => {
        this.write_packet( IOT_Codes_Constants.TYPE_START, 0);
    }
    
    write_packet = ( msg_type, msg_subtype, data = null) => {
    
        let packet_len = 2
        
        if (data !== null) {
            // TODO - other special cases
            if (msg_subtype === IOT_Codes_Constants.SUBTYPE_PAS_BUZZ) {
                packet_len = packet_len + data.length + 4;
    
            } else {
                packet_len = packet_len + data.length;
            }
            
        }
    
        let data_packet = [IOT_Codes_Constants.P_HEADER, packet_len, msg_type, msg_subtype];
    
        if (data !== null) {
            // TODO - other special cases
            if (msg_subtype === IOT_Codes_Constants.SUBTYPE_PAS_BUZZ) {
                          
                const buffer = new ArrayBuffer(4);
                const view = new DataView(buffer);
                view.setInt32(0, data[0]);
                
                for (let i = 0; i < 4; i++) {
                    data_packet.push(view.getUint8(3 - i));
                }
    
                view.setInt16(0, data[1]);
                
                for (let i = 0; i < 2; i++) {
                    data_packet.push(view.getUint8(1 - i));
                }
    
    
            } else {
                for (let i = 0; i < data.length; i++) {
                    data_packet.push(data[i]);
                }
            }
        }
    
        let packet = Uint8Array.from(data_packet);
        
        this.port.send(packet);
    };


    get_data_array = (type) => {
        
        switch(type) {    
            case 'Accelerometer':
                return [this.self.axArray, this.self.ayArray, this.self.azArray];

            case 'Gyroscope':
                return [this.self.gxArray, this.self.gyArray, this.self.gzArray];

            case IOT_Codes_Constants.SUBTYPE_SW_1:
                return [this.self.btnArray];
                
            case IOT_Codes_Constants.SUBTYPE_NTC:
                return [this.self.ntcArray];

            case IOT_Codes_Constants.SUBTYPE_PHOTO:
                return [this.self.photoArray];

            case IOT_Codes_Constants.SUBTYPE_ULTRA:
                return [this.self.distArray];

            case IOT_Codes_Constants.SUBTYPE_MIC:
                return [this.self.micArray];
    
            case IOT_Codes_Constants.SUBTYPE_DHT:
                return [this.self.tempArray, this.self.humdArray];

            case IOT_Codes_Constants.SUBTYPE_JOYSTICK:
                return [this.self.jxvArray, this.self.jyvArray, this.self.jbvArray];

            case IOT_Codes_Constants.SUBTYPE_ADC_1:
                return [this.self.adcArray];

            case IOT_Codes_Constants.SUBTYPE_PIR:
                return [this.self.pirArray];
    
            default:
                return [0];
        }
    };


    processData = (data) => {
        
        let dataBytes = new Uint8Array(data.buffer);
        if (dataBytes[0] !== IOT_Codes_Constants.P_HEADER) {
            // Bad packet
        } else {
            this.self.newData = {};
            switch(dataBytes[3]) {
                case IOT_Codes_Constants.SUBTYPE_SW_1:
                    if (this.self.btnArray.length > this.bufferSize) {
                        this.self.btnArray.splice(0, 1);
                    }
                    this.self.btnArray.push(dataBytes[4]);
                    break;
                case IOT_Codes_Constants.SUBTYPE_ULTRA:
                    if (this.self.distArray.length > this.bufferSize) {
                        this.self.distArray.splice(0, 1);
                    }
                    this.self.distArray.push(dataBytes[4]);
                    break;
                case IOT_Codes_Constants.SUBTYPE_PHOTO:
                    if (this.self.photoArray.length > this.bufferSize) {
                        this.self.photoArray.splice(0, 1);
                    }
                    this.self.photoArray.push((dataBytes[5] << 8) | dataBytes[4]);
                    break;
                case IOT_Codes_Constants.SUBTYPE_NTC:
                    let data = dataBytes.slice(4, 8);        
                    let buf = new ArrayBuffer(4);
                    let view = new DataView(buf);
                    let endianFactor = this.get_endian_factor(buf);
                    data.forEach(function (b, i) {
                        view.setUint8(endianFactor - i, b);
                    });
                    if (this.self.ntcArray.length > this.bufferSize) {
                        this.self.ntcArray.splice(0, 1);
                    }
                    this.self.ntcArray.push(view.getFloat32(0));
                    break;
                case IOT_Codes_Constants.SUBTYPE_DHT:
                    if (this.self.tempArray.length > this.bufferSize) {
                        this.self.tempArray.splice(0, 1);
                        this.self.humdArray.splice(0, 1);
                    }
                    let specialDataCheck1 = (dataBytes[5] << 8) | dataBytes[4];
                    let specialDataCheck2 = (dataBytes[7] << 8) | dataBytes[6];
                    if ((specialDataCheck1 !== 0) && (specialDataCheck2 !== 0)) {
                        this.self.tempArray.push(specialDataCheck1);
                        this.self.humdArray.push(specialDataCheck2);
                    } else {
                        this.self.tempArray.push(this.self.tempArray[this.self.tempArray.length - 1]);
                        this.self.humdArray.push(this.self.humdArray[this.self.humdArray.length - 1]);
                    }
                    break;
                case IOT_Codes_Constants.SUBTYPE_MPU:
                    let isAcc = dataBytes[4];
                    let isGyro = dataBytes[5];
                    let offset = 0;
                    
                    if (isAcc === 1) {
                        if (this.self.axArray.length > this.bufferSize) {
                            this.self.axArray.splice(0, 1);
                            this.self.ayArray.splice(0, 1);
                            this.self.azArray.splice(0, 1);
                        }

                        let bufAcc = new ArrayBuffer(4);
                        let viewAcc = new DataView(bufAcc);
                        let endianFactor = this.get_endian_factor(bufAcc);

                        let dataAcc = dataBytes.slice(6, 10);
                        dataAcc.forEach(function (b, i) {
                            viewAcc.setUint8(Math.abs(endianFactor - i), b);
                        });
                        this.self.axArray.push(viewAcc.getFloat32(0));

                        dataAcc = dataBytes.slice(10, 14);
                        dataAcc.forEach(function (b, i) {
                            viewAcc.setUint8(Math.abs(endianFactor - i), b);
                        });
                        this.self.ayArray.push(viewAcc.getFloat32(0));

                        dataAcc = dataBytes.slice(14, 18);
                        dataAcc.forEach(function (b, i) {
                            viewAcc.setUint8(Math.abs(endianFactor - i), b);
                        });
                        this.self.azArray.push(viewAcc.getFloat32(0));
                        offset = 12;
                    }
                    if (isGyro === 1) {
                        if (this.self.gxArray.length > this.bufferSize) {
                            this.self.gxArray.splice(0, 1);
                            this.self.gyArray.splice(0, 1);
                            this.self.gzArray.splice(0, 1);
                        }

                        let bufGyr = new ArrayBuffer(4);
                        let viewGyr = new DataView(bufGyr);
                        let endianFactor = this.get_endian_factor(bufGyr);

                        let dataGyr = dataBytes.slice(offset + 6, offset + 10);
                        dataGyr.forEach(function (b, i) {
                            viewGyr.setUint8(Math.abs(endianFactor - i), b);
                        });
                        this.self.gxArray.push(viewGyr.getFloat32(0));

                        dataGyr = dataBytes.slice(offset + 10, offset + 14);
                        dataGyr.forEach(function (b, i) {
                            viewGyr.setUint8(Math.abs(endianFactor - i), b);
                        });
                        this.self.gyArray.push(viewGyr.getFloat32(0));

                        dataGyr = dataBytes.slice(offset + 14, offset + 18);
                        dataGyr.forEach(function (b, i) {
                            viewGyr.setUint8(Math.abs(endianFactor - i), b);
                        });
                        this.self.gzArray.push(viewGyr.getFloat32(0));
                    }
                    break;
                case IOT_Codes_Constants.SUBTYPE_MIC:
                    if (this.self.micArray.length > this.bufferSize) {
                        this.self.micArray.splice(0, 1);
                    }
                    this.self.micArray.push((dataBytes[6] << 8) | dataBytes[5]);
                    break;
                case IOT_Codes_Constants.SUBTYPE_PIR:
                    if (this.self.pirArray.length > this.bufferSize) {
                        this.self.pirArray.splice(0, 1);
                    }
                    this.self.pirArray.push(dataBytes[4]);
                    break;
                case IOT_Codes_Constants.SUBTYPE_ADC_1:
                    if (this.self.adcArray.length > this.bufferSize) {
                        this.self.adcArray.splice(0, 1);
                    }
                    this.self.adcArray.push((dataBytes[5] << 8) | dataBytes[4]);
                    break;
                case IOT_Codes_Constants.SUBTYPE_JOYSTICK:
                    if (this.self.jxvArray.length > this.bufferSize) {
                        this.self.jxvArray.splice(0, 1);
                    }
                    this.self.jbvArray.push(dataBytes[4]);
                    this.self.jxvArray.push((dataBytes[6] << 8) | dataBytes[5]);
                    this.self.jyvArray.push((dataBytes[8] << 8) | dataBytes[7]);
                    break;
                default:
                    break;
            }
            this.self.waitData = false;
        }
    };


};


var IOT_Codes_Constants = {
    P_HEADER: 252,

    TYPE_ENABLE: 0,
    TYPE_START:  1,
    TYPE_CONTROL:  2,
    TYPE_REQUEST:  3,

    SUBTYPE_RGB:  0,
    SUBTYPE_DO_1:  1,
    SUBTYPE_DO_2:  2,
    SUBTYPE_DO_3:  3,
    SUBTYPE_DO_4:  4,
    SUBTYPE_SW_1:  5,
    SUBTYPE_SW_2:  6,
    SUBTYPE_SW_3:  7,
    SUBTYPE_SW_4:  8,
    SUBTYPE_SW_5:  9,
    SUBTYPE_SW_6:  10,
    SUBTYPE_PAS_BUZZ:  11,
    SUBTYPE_SERVO:  12,
    SUBTYPE_ULTRA:  13,
    SUBTYPE_KEYPAD:  14,
    SUBTYPE_DHT:  15,
    SUBTYPE_JOYSTICK:  16,
    SUBTYPE_IR_RECEV:  17,
    SUBTYPE_LED_MATRIX:  18,
    SUBTYPE_MPU:  19,
    SUBTYPE_PIR:  20,
    SUBTYPE_WATER:  21,
    SUBTYPE_RTC:  22,
    SUBTYPE_MIC:  23,
    SUBTYPE_RFID:  24,
    SUBTYPE_LCD:  25,
    SUBTYPE_NTC:  26,
    SUBTYPE_LED_EIGHT:  27,
    SUBTYPE_PHOTO:  28,
    SUBTYPE_ONE_SSEG:  29,
    SUBTYPE_FOUR_SSEG:  30,
    SUBTYPE_DC_MOTOR:  31,
    SUBTYPE_STEPPER:  32,
    SUBTYPE_ROT:  33,
    SUBTYPE_ADC_1:  34,
    SUBTYPE_ADC_2:  35,

    IR_PWR:  21,
    IR_FUNC_STOP:  20,
    IR_VOL_U:  19,
    IR_FAST_BACK:  18,
    IR_PAUSE:  17,
    IR_FAST_FOR:  16,
    IR_DOWN:  15,
    IR_VOL_D:  14,
    IR_UP:  13,
    IR_EQ:  12,
    IR_ST_REPT:  11,
    IR_0:  0,
    IR_1:  1,
    IR_2:  2,
    IR_3:  3,
    IR_4:  4,
    IR_5:  5,
    IR_6:  6,
    IR_7:  7,
    IR_8:  8,
    IR_9:  9,
    IR_REPEAT:  10,
    IR_OTHER:  22,

    MIC_ANALOG:  0,
    MIC_DIGITAL:  1,

    LCD_R_MIN:  0,
    LCD_R_MAX:  1,
    LCD_C_MIN:  0,
    LCD_C_MAX:  15,

    RGB_MIN:  0,
    RGB_MAX:  255,

    DC_MIN:  0,
    DC_MAX:  1,

    DC_FORWARD:  1,
    DC_BACKWARD:  0,

    ACC_ON:  1,
    ACC_OFF:  0,
    GYR_ON:  1,
    GYR_OFF:  0,

    SEV_SEG_NUM:  1,
    SEV_SEG_CHAR:  0,

    RFID_ERR_OK:  0,
    RFID_ERR_NO_NEW:  1,
    RFID_ERR_NO_SELECT:  2,
    RFID_ERR_FAIL_WRITE:  3,
    RFID_ERR_FAIL_WRITE_AUTH:  4,
    RFID_ERR_FAIL_READ:  5,
    RFID_ERR_FAIL_READ_AUTH:  6,

    RFID_MSG_UID:  0,
    RFID_MSG_WRITE:  1,
    RFID_MSG_READ:  2,
};

export default LocoIoT;