// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';

import LocoDroneT from "../../libraries/LocoDroneT";
import GeneralMessage from "../drawers/GeneralMessage"



// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 500,//400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: 'visible',
      maxHeight: '375px',
    },
    actionButton: {
      //justify: "center",
      margin: theme.spacing(0),
      color: "white",
    },  
    cancelButton: {
        marginTop: theme.spacing(-0.5),
        color: "white",
    },
    titleText: {
      color: "white",
      margin: theme.spacing(1),
    },
    lightIndices: {
        borderRadius: '70%',
        width: '25px',
        height: '25px',
    }, 
    inputField: {
        width: "90%",
        marginLeft: theme.spacing(2),
    },
    grid: {
        margin: theme.spacing(0),
        justify: "space-between",
        justifyContent: "space-between",
    },
    
});

// Constants for Modal Top Text
const STATE_TEXT_OPTS = ["Scan For Drones", "Scanning...", "Select Drone", "Connecting...", "Connected"];
const STATE_TEXT_ERRS = ["No Drones Found, Try Scanning Again", "Could Not Connect to Drone, Try Again", "Data Error, Disconnected"];

// Component Class
class LDTSettingsModal extends React.Component {

    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);

        // Modal Behavior Options
        this.MODE_OPTIONS = ["CONVERSION", "SEARCH", "SETTINGS", "PICK", "GUI", "SELECT"];
        
        // React Element For Copy-to-Clipboard Drawer
        this.copiedNameElement = React.createRef();
    }

    // Class state
    state = {
        isOpen: false,  // Modal display state
        LDTObj: null, // Class instance object for LocoDroneT.js
        scanning: false, // Flag for scanning state
        disable_for_scan: false, // Flag for scanning state to control buttons enable states
        droneNames: [], // List of found drones
        connectionState: 0, // What layout the modal should display as
        stateMessage: STATE_TEXT_OPTS[0], // Top message in modal
        functionality_mode: "", // Modal behavior string state storage
        droneSelected: "" // Drone selected
    }

    componentDidMount() {
        // Make empty window.serialport
        //window.serialport = {}
    }

    // Handle Making Modal Visible with provided 'functionality_mode' state
    handleOpen = (functionality_mode, droneList=[]) => {
        
        // Re-initialize certain state variables
        this.setState( {connectionState: 0} )
        
        // Handle Based on certain modal functionality_mode
        if (functionality_mode === this.MODE_OPTIONS[4]) {
            
            // If empty droneList, display error message in modal
            if (droneList.length < 4) {
                this.setState( {stateMessage: STATE_TEXT_ERRS[0]} )
            // If droneList, process (string type input)
            } else {
                const LIST_SPLIT = ", ";
                let listStart = droneList.indexOf("[") + 1
                let listEnd = droneList.lastIndexOf("]")

                droneList = droneList.substring(listStart, listEnd)
                let splitDrones = droneList.split(LIST_SPLIT)
                // Updte state values
                this.setState( {droneNames: splitDrones, stateMessage: STATE_TEXT_OPTS[2]} )
            }
            // Update modal render state value
            this.setState( {connectionState: 1} )
        // Handle Based on certain modal functionality_mode
        } else if (functionality_mode === this.MODE_OPTIONS[3]) {
            // If empty droneList, display error message in modal
            if (droneList.length < 1) {
                this.setState( {stateMessage: STATE_TEXT_ERRS[0]} )
            // If droneList, process
            } else {
                this.setState( {droneNames: droneList, stateMessage: STATE_TEXT_OPTS[2]} )
            }
            this.setState( {connectionState: 1} )
        }
        // Update modal state to open, create new LocoDroneT class instance object, and store state functionality_mode
        this.setState( {isOpen: true, LDTObj: new LocoDroneT(), functionality_mode: functionality_mode} );
    }

    // webserial connection process
    handleConnect = async () => {        
        //if (Object.keys(window.serialport).length === 0) {
        try {
            await this.state.LDTObj.connect()
            if (window.serialport) {
                this.setState( {disable_for_scan: true} )
                await this.handleScan()
            }
        } catch {
            return
        }
        //}
    }

    // Scan for drones
    handleScan = async () => {
        // Update state and wait for drone scan to complete
        this.setState( {scanning: true, stateMessage: STATE_TEXT_OPTS[1]} );
        await this.state.LDTObj.drone_scan();

        // Get scan data and update state if drones found or not
        let droneNamesTemp = [...this.state.LDTObj.sharedData.drones['scanData']]
        if (droneNamesTemp.length !== 0) {
            this.setState( {connectionState: 1, stateMessage: STATE_TEXT_OPTS[2]} )
        } else {
            this.setState( {stateMessage: STATE_TEXT_ERRS[0]} )
        }
        // Update state for scan complete and any drones found
        this.setState( {disable_for_scan: false, droneNames: droneNamesTemp} )
        this.setState( {scanning: false} );
    }

    // Handle the closing of the modal (visibility)
    handleClose = async (droneSelected = "") => {
        // Handle any odd inputs
        if (droneSelected.length === undefined) {
            droneSelected = ""
        }
        // Update state to disable buttons
        this.setState( {disable_for_scan: true} );
        // For certain modal behavior modes, call props onClose with selected drone 
        if ((this.state.functionality_mode === this.MODE_OPTIONS[0]) || 
            (this.state.functionality_mode === this.MODE_OPTIONS[3]) || 
            (this.state.functionality_mode === this.MODE_OPTIONS[4]) || 
            (this.state.functionality_mode === this.MODE_OPTIONS[5])) {
            this.props.onClose(droneSelected);
        // Unused
        } else if ((this.state.functionality_mode === this.MODE_OPTIONS[2]) || (this.state.functionality_mode === this.MODE_OPTIONS[1])) {
            try { 
                //await this.state.LXObj.reset_ble();
                //await this.state.LXObj.disconnect_usb();
                //this.state.LXObj.clearLXTimeouts();
            } catch {}
        }
        // Reset state variables
        this.setState( {isOpen: false, droneNames: [], scanning: false, disable_for_scan: false, stateMessage: STATE_TEXT_OPTS[0]} );
    }

    // 
    handleCancel = () => {
        // For certain modal behavior modes
        if ((this.state.functionality_mode === this.MODE_OPTIONS[0]) || (this.state.functionality_mode === this.MODE_OPTIONS[2])) {
            // Unused
            if (this.state.connectionState === 2) {
            // Handle state update or call close based on modal layout state
            } else {   
                if (this.state.connectionState > 0) {
                    this.setState( {connectionState: 0, stateMessage: STATE_TEXT_OPTS[0]} );
                } else {
                    this.handleClose();
                }
            }
        // For rest of modal behavior modes
        } else { 
            // Handle state update or call close based on modal layout state
            if (this.state.connectionState > 0) {
                if ((this.state.functionality_mode === this.MODE_OPTIONS[3]) || 
                    (this.state.functionality_mode === this.MODE_OPTIONS[4]) || 
                    (this.state.functionality_mode === this.MODE_OPTIONS[5])) {
                    this.handleClose();
                } else {
                    this.setState( {connectionState: 0, stateMessage: STATE_TEXT_OPTS[0]} );
                }
            } else {
                this.handleClose();
            }
        }
    }

    // Drone button selected
    handleBtn = (event, name) => {
        // If scan isn't active
        if (this.state.disable_for_scan === false) {
            // If certain modal behavior mode, pass out drone name to handleClose()
            if ((this.state.functionality_mode === this.MODE_OPTIONS[0]) || 
                (this.state.functionality_mode === this.MODE_OPTIONS[3]) ||
                (this.state.functionality_mode === this.MODE_OPTIONS[4]) ||
                (this.state.functionality_mode === this.MODE_OPTIONS[5])) {
                this.setState( { droneSelected: name } )
                this.handleClose(name);
            // If certain modal behavior mode, copy to clipboard and display drawer message
            } else if (this.state.functionality_mode === this.MODE_OPTIONS[1]) {
                let quoteName = "\"" + name + "\""
                // Copy to Clipboard
                navigator.clipboard.writeText(quoteName)        
                // Drawer String
                let msgStr = "Copied drone named " + quoteName + " to the clipboard"        
                // Open Temporary Drawer (pass name)
                this.copiedNameElement.current.handleOpen("green", msgStr, 1000)    
            }
        }
    }

    // For opening drawer message
    handleMessage = (msgStr, timeSpan) => {
        // Open Temporary Drawer 
        this.copiedNameElement.current.handleOpen("red", msgStr, timeSpan)
    }

    // render HTML
    render() {
        
        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display
        return (
            <div className={classes.root}>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper}>
                        <Grid
                            spacing = {1}
                            direction="row"
                            className={classes.grid}
                            container
                        >
                        <Grid item xs={9}>
                            <Typography>
                                {this.state.stateMessage}
                            </Typography> 
                        </Grid>
                        <Grid item xs={3} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.cancelButton}
                                onClick={this.handleCancel}
                                disabled={this.state.disable_for_scan}
                            >
                                Cancel
                            </Button>  
                        </Grid>
                        {(this.state.connectionState === 0) && (
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.handleConnect}
                                disabled={this.state.disable_for_scan}
                            >
                                Scan for Drones
                            </Button>
                            
                        )}
                        {(this.state.connectionState === 1) && (
                            
                            <Grid item xs={12} style = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Paper
                                style = {{overflowY: 'auto', overflowX: 'hidden', maxHeight: '275px'}}
                            >
                            {this.state.droneNames.map((r) => 
                                <Card 
                                    style = {{border: '1px solid black', marginTop: '5px', width: '325px'}}
                                    key = {r}
                                >
                                <Grid 
                                    container 
                                    spacing={1}
                                    className={classes.grid}
                                    direction="row"
                                    align = "center" justify = "center"
                                    key = {'rg' + r}
                                    style = {{backgroundColor: '#34495e'}}
                                >
                                <Grid item xs={12}
                                    onClick={(e) => this.handleBtn(e, r)}
                                    key = {'r' + r}
                                >
                                    <Typography
                                        className={classes.titleText}
                                        key = {'n' + r}
                                    > 
                                        {r}
                                    </Typography>
                                </Grid>
                                </Grid>  
                                </Card>
                            )}
                            </Paper>
                            </Grid>
                        )}
                        </Grid>
                    </div>
                </Fade>
                </Modal>
                <GeneralMessage
                    ref = {this.copiedNameElement}
                    onClose = {() => void 0}
                />
            </div>
        );
    }

}


export default withStyles(styles)(LDTSettingsModal);