export function getExtension(fileName) {
	let parts = fileName.split(".");
	return parts[parts.length - 1];
}

export async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export function map_uint8(x, in_min, in_max, out_min, out_max) {
	const map_out = (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
	let buf = new ArrayBuffer(1);
	let view = new DataView(buf);
	view.setUint8(0, map_out);
	return view.getUint8(0);
}