// Child Component of: 
// --- ProgramCoding/ProgramGUI

// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';



// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    button: {
        square:true,
    },
    text: {
        color: "white",
        backgroundColor: theme.palette.primary["light"],
    },

  });

// Component Class
class UnshareProgram extends React.Component {
    
    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);


        // Bind Certain Functions to "this"
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        
    }

    // Class state
    state = {
        isOpen: false,
    }

    // For Displaying Self (Modal), Called by Parent ProgramCoding
    handleOpen = () => {
        this.setState( {isOpen: true} );
    };

    // For Stopping Program Run
    handleClose = () => {
        this.setState( {isOpen: false} );
    };

    //
    handleButton = (event) => {
        this.props.onClose(event);
        this.handleClose();
    }

    render() {

        const { classes } = this.props;

        return (
            <div>
                <Drawer
                    anchor='top' 
                    open={this.state.isOpen}
                >
                    <Grid 
                        container 
                        spacing={0}
                        direction="row"
                    >
                    <Grid item xs={12}>
                        <Typography
                            className={classes.text}
                            style={{fontSize: '34px', textAlign: 'center'}}
                        >
                            Unshare Program?
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            className={classes.button}
                            fullWidth 
                            name='OK'
                            onClick={() => this.handleButton('OK')}
                            style={{backgroundColor: "green", color:"white", borderRadius: '0px', fontSize: '34px'}}
                        >
                            YES
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            className={classes.button}
                            fullWidth 
                            name='CANCEL'
                            onClick={() => this.handleButton('CANCEL')}
                            style={{backgroundColor: "red", color:"white", borderRadius: '0px', fontSize: '34px'}}
                        >
                            NO
                        </Button>
                    </Grid>
                    </Grid>
                </Drawer>
            </div>
        );
    }

}
export default withStyles(styles)(UnshareProgram);
