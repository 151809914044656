// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


const C_OPTS_MODES = {
    'Set': ['r', 'p', 'b', '0'], 
    'String': ['r', 'p', 'b'], 
    'Image': ['r', 'p', 'b', '0'],
    'ASCII': ['r', 'p', 'b'],
};

const C_OPTS_OBJ = {
    r: {name:'Red', code:'rgb(255,0,0)', color: '#FF0000', strName: 'r'}, 
    p: {name:'Purple', code:'rgb(255,0,255)', color: '#FF00FF', strName: 'p'}, 
    b: {name:'Blue', code:'rgb(0,0,255)', color: '#0000FF', strName: 'b'}, 
    0: {name:'Off', code:'rgb(100,100,100)', color: '#000000', strName: '0'}, 
};



// Material-UI CSS-type Style Specifications
const styles = (theme) => ({

});


// Component Class
class LDTMatrix extends React.Component {

    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);
    
        this.gridBoxW = '30px'
        this.gridBoxH = '30px'
        this.gridRows = 8
        this.gridCols = 8
        this.gridR = Array(this.gridRows).fill(0)
        this.gridC = Array(this.gridCols).fill(0)
        this.gridData = Array.from({ length: 8 }, () => Array.from({ length: 8 }, () => '0'))
    }

    state = {
        mouseIsPressed: false,
        control: {},
        pickedColor: C_OPTS_MODES['Set'][0],
        pickedNumOpts: C_OPTS_MODES['Set'].length,
        pickedIndx: 0,
        gridArray: Array.from({ length: 8 }, () => Array.from({ length: 8 }, () => C_OPTS_OBJ['0'])),
    }

    handleGridClick = (e, i, j) => {

        let tempGrid = this.state.gridArray
        tempGrid[i][j] = C_OPTS_OBJ[this.state.pickedColor]
        this.setState( {mouseIsPressed: true, gridArray: tempGrid} )
        this.updateGridString(tempGrid)
    }

    handleColor = () => {
        let tempPickedIndx = this.state.pickedIndx + 1
        if (tempPickedIndx >= this.state.pickedNumOpts) {
            tempPickedIndx = 0
        }
        this.setState( {
            pickedIndx: tempPickedIndx,
            pickedColor: C_OPTS_MODES['Set'][tempPickedIndx],
        } )
    }

    updateGridString = (tempGrid) => {
        let gridStr = ''
        for (let i = 0; i < 8; i++) {
            for (let j = 0; j < 8; j++) {
                gridStr += tempGrid[i][j].strName
            }
        }
        this.gridData = gridStr
    }

    handleFill = () => {
        let tempGrid = this.state.gridArray;

        for (let k = 0; k < 8; k++) {
            for (let m = 0; m < 8; m++) {
                tempGrid[k][m] = C_OPTS_OBJ[this.state.pickedColor]
            }
        }
        this.setState( {gridArray: tempGrid} )
        this.updateGridString(tempGrid)
    }

    handleClear = () => {
        let tempGrid = this.state.gridArray;

        for (let k = 0; k < 8; k++) {
            for (let m = 0; m < 8; m++) {
                tempGrid[k][m] = C_OPTS_OBJ['0']
            }
        }
        this.setState( {gridArray: tempGrid} )
        this.updateGridString(tempGrid)
    }

    startHold = () => {
        this.setState( {mouseIsPressed: true} )
    }

    stopHold = () => {
        this.setState( {mouseIsPressed: false} )
    }

    onHover = (e, i, j) => {
        if (this.state.mouseIsPressed === true) {
            let tempGrid = this.state.gridArray
            tempGrid[i][j] = C_OPTS_OBJ[this.state.pickedColor]
            this.setState( {gridArray: tempGrid} )
            this.updateGridString(tempGrid)
        }
    }
    
    
    
    // render HTML
    render() {
        
        // Referenced below for setting styles
        const { classes } = this.props;

        const mode = this.state.control.mode

        // What to Display
        return (
            <div className={classes.root}>

                <Box style = {{
                    display: 'flex', 
                    justifyContent: 'center',
                }}>
                    <Box>
                        <Grid 
                            container 
                            spacing={0}
                            direction="row"
                        >
                        <Grid item xs = {12}>
                            <Box style = {{
                                display: 'flex', 
                                justifyContent: 'center',
                            }}>
                                <Box>
                                    <Button
                                        style = {{backgroundColor: C_OPTS_OBJ[this.state.pickedColor].code}}
                                        onClick = {this.handleColor}
                                    >
                                        {C_OPTS_OBJ[this.state.pickedColor].name}
                                    </Button>
                                    <Button
                                        onClick = {this.handleFill}
                                    >
                                        Fill
                                    </Button>
                                    <Button
                                        onClick = {this.handleClear}
                                    >
                                        Clear
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs="auto"
                            onMouseDown={this.startHold}
                            onMouseUp={this.stopHold}
                            onMouseLeave={this.stopHold}
                        >
                            {this.gridR.map((v, i) => {
                                return (
                                    <div style = {{display:"flex"}} key = {'div'+i}>
                                        {this.gridC.map((u, j) => {
                                            return (
                                                <Box
                                                    key = {i + ',' + j}
                                                    onMouseDown={(e) => this.handleGridClick(e, i, j)}
                                                    onMouseEnter={(e) => this.onHover(e, i, j)}
                                                    style = {{
                                                        minHeight: this.gridBoxH,
                                                        minWidth: this.gridBoxW,
                                                        backgroundColor: this.state.gridArray[i][j]['color'],
                                                        border: "1px solid green"
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </Grid>
                        </Grid>
                    </Box>
                </Box>

            </div>

        );
    }
}


export default withStyles(styles)(LDTMatrix);