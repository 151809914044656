import React, { Component, PropTypes } from 'react';
import Square from '../components/Square'
import { UP, DOWN, LEFT, RIGHT, ENTER } from '../helpers/constants'

class Player extends Component {    
    handleKeyDown = (e) => {
        let newDirection;
        
        switch(e.keyCode) {
            case 13:
                newDirection = { top: 0, left: 0 , dir: ENTER};
                break;
            case 37:
            case 65:
                newDirection = { top: 0, left: -1 , dir: LEFT};
                break;
            case 38:
            case 87:
                newDirection = { top: -1, left: 0 , dir: UP};
                break;
            case 39:
            case 68:
                newDirection = { top: 0, left: 1, dir: RIGHT};
                break;
            case 40:
                case 83:
                newDirection = { top: 1, left: 0, dir: DOWN };
                break;
            default:
                return;
        }

        this.props.handlePlayerMovement(newDirection);
    }
    
    render() {        
        const { size, position: { top, left }} = this.props;
        
        return (
            <div ref={ n => { this.player = n }} >
                <Square 
                    size={size}
                    position={{ top, left }}
                    color={this.props.color} />
            </div>
            
        );
    }

    initControls = (mode) => {
        if (mode === '') {
            window.onkeydown = this.handleKeyDown;
        }
    }
    
    componentDidMount() {
        
    }
}

/*
Player.propTypes = {
    size: PropTypes.number.isRequired,
    position: PropTypes.shape({
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired
    })
};
*/

export default Player;