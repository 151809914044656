// For TopBar Routing
const MenuCustom = [
  /*
  {
    label: "Home",
    pathname: "/",
    image: "HomeIcon",
  },
  */
  {
    label: "Program",
    pathname: "/program",
    image: "CodeIcon",
  },
  {
    label: "Data View",
    pathname: "/datavis",
    image: "TimelineIcon",
  },
  {
    label: "Academy",
    pathname: "https://learn-locorobo.com",
    image: "SchoolIcon",
    external: true
  },
  {
    label: "Drone RC",
    pathname: "/dronerc",
    image: "GamesIcon",
    conditional: true,
  },
];

export default MenuCustom;