// Child Component of: 
// --- ProgramCoding/ProgramGUI

// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import GeneralMessage from "./GeneralMessage"


// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    button: {
        square:true,
    },
    text: {
        color: "white",
        backgroundColor: theme.palette.primary["light"],
    },

  });

// Component Class
class MarkGraded extends React.Component {
    
    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);

        this.WrongInputMsgElement = React.createRef();

        // Bind Certain Functions to "this"
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        
    }

    // Class state
    state = {
        isOpen: false,
        buffer: '',
    }

    // For Displaying Self (Modal), Called by Parent ProgramCoding
    handleOpen = () => {
        this.setState( {isOpen: true} );
    };

    // For Stopping Program Run
    handleClose = () => {
        this.setState( {isOpen: false} );
    };

    //
    handleButton = (e) => {

        let event = {
            'resp': e,
            'grade': 0,
        }
        if (e === 'YES') {
            let gradeNum = 0;
            try {
                gradeNum = parseInt(this.state.buffer)
                if (!isNaN(gradeNum)) {
                    if (gradeNum < 0) {
                        gradeNum = 0;
                    } else if (gradeNum > 100) {
                        gradeNum = 100;
                    }                        
                    event['grade'] = gradeNum;
                    this.props.onClose(event);
                } else {
                    this.WrongInputMsgElement.current.handleOpen('red', 'Incorrect Grade Input', 1000);
    
                }
            } catch {
                this.WrongInputMsgElement.current.handleOpen('red', 'Incorrect Grade Input', 1000);    
            } 
        } else {
            this.props.onClose(event);
        }
    }

    //
    handleChange = (event) => {
        this.setState( {buffer: String(event.target.value)} );
    };

    render() {

        const { classes } = this.props;

        return (
            <div>
                <Drawer
                    anchor='top' 
                    open={this.state.isOpen}
                >
                    <Grid 
                        container 
                        spacing={0}
                        direction="row"
                    >
                    <Grid item xs={12}>
                        <Typography
                            className={classes.text}
                            style={{fontSize: '34px', textAlign: 'center'}}
                        >
                            Enter a Grade (0 to 100)
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="standard-basic"
                            onChange={this.handleChange}
                            inputProps={{ style: {textAlign: 'center', fontSize: '34px'} }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            className={classes.button}
                            fullWidth 
                            name='YES'
                            onClick={() => this.handleButton('YES')}
                            style={{backgroundColor: "green", color:"white", borderRadius: '0px', fontSize: '34px'}}
                        >
                            OK
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            className={classes.button}
                            fullWidth 
                            name='NO'
                            onClick={() => this.handleButton('NO')}
                            style={{backgroundColor: "red", color:"white", borderRadius: '0px', fontSize: '34px'}}
                        >
                            CANCEL
                        </Button>
                    </Grid>
                    </Grid>
                    <GeneralMessage
                        ref = {this.WrongInputMsgElement}
                        onClose = {() => void 0}
                    />
                </Drawer>
            </div>
        );
    }

}
export default withStyles(styles)(MarkGraded);
