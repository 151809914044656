import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import { Link as MaterialLink } from "@material-ui/core";
import MenuCustom from "./MenuCustom";
import MenuCustomExt from "./MenuCustomExt";

import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import TimelineIcon from '@material-ui/icons/Timeline';
import SchoolIcon from '@material-ui/icons/School';
import GamesIcon from '@material-ui/icons/Games';

import { AuthContext } from "../context/auth";

import UserProfile from "../components/modals/UserProfile";
import AccessListModal from "../components/modals/AccessListModal";

import { DRONE_RC_ITEMS } from "./store/drone_rc_store";

//const logo = require("../images/logo.png");
//import logo from '../images/logo.png';
import logo from '../images/locorobo-logo.svg'

const styles = theme => ({
  appBar: {
    position: "relative",
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.grey["100"]}`,
    backgroundColor: theme.palette.primary["light"],//"white"
    color: "white",
    height: window.navBarOffset,
  },
  inline: {
    display: "inline"
  },
  userOptions: {
    justify: 'flex-end',
  },
  flex: {
    display: "flex",
  },
  link: {
    textDecoration: "none",
    color: "inherit"
  },
  productLogo: {
    display: "inline-block",
    borderLeft: `1px solid ${theme.palette.grey["A100"]}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up("md")]: {
      paddingTop: "1.5em"
    }
  },
  tagline: {
    display: "inline-block",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      paddingTop: "0.8em"
    }
  },
  iconContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  iconButton: {
    float: "right"
  },
  tabContainer: {
    color: "white",
    marginLeft: 32,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  tabItem: {
    minWidth: "auto"
  },
  iconItem: {
    paddingTop: 7,
    minWidth: "auto"
  }
});

var components = {
  "HomeIcon": HomeIcon,
  "CodeIcon": CodeIcon,
  "TimelineIcon": TimelineIcon,
  "SchoolIcon": SchoolIcon,
  "GamesIcon": GamesIcon,
};



class Topbar extends Component {

  static contextType = AuthContext;

  constructor(props, context) {
    super(props);

    //
    this.userProfileElement = React.createRef();
    this.userAccessElement = React.createRef();
  }

  state = {
    value: 0,
    menuDrawer: false,
    userMenu: false,
    userAnchor: null,
  };

  handleChange = (event, value) => {
    this.setState({ value });
    //if (value == 2) {
    //  window.open("https://learn-locorobo.com/", "_blank", "noopener noreferrer");
    //}
  };

  mobileMenuOpen = event => {
    this.setState({ menuDrawer: true });
  };

  mobileMenuClose = event => {
    this.setState({ menuDrawer: false });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleUserMenuBtn = (event) => {
    
    this.setState( {userMenu: true, userAnchor: event.currentTarget} );
  };
  
  handleUserMenuClose = () => {

    this.setState( {userMenu: false} );
  };

  handleUserLogout = () => {
    this.context.AuthInstance.logout();
    this.setState( {userMenu: false} );
    this.props.history.replace('/login');
  };

  handleUserProfile = () => {
    this.userProfileElement.current.handleOpen();
    this.setState( {userMenu: false} );
  };

  handleUserAccessList = () => {
    this.userAccessElement.current.handleOpen();
    this.setState( {userMenu: false} );
  };


  current = () => {
    if (this.props.currentPath === "/program") {
      return 0;
    }
    if (this.props.currentPath === "/datavis") {
      return 1;
    }
    if (this.props.currentPath === "/academy") {
      return 2;
    }
    if (this.props.currentPath === "/dronerc") {
      return 3;
    }
    if (this.props.currentPath === "/") {
      return 0;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={12} className={classes.flex}>
              <Box className={classes.iconItem}>
                <img src={logo} style={{width:'150px', height: '50px', }} alt="LocoRobo Logo" />
              </Box>
              {!this.props.noTabs && (
                <React.Fragment>
                  <div className={classes.productLogo}>
                  </div>
                  <div className={classes.iconContainer}>
                    <IconButton
                      onClick={this.mobileMenuOpen}
                      className={classes.iconButton}
                      color="inherit"
                      aria-label="Menu"
                    >
                    </IconButton>
                  </div>
                  <div className={classes.tabContainer}>
                    <Tabs
                      value={this.current()}
                      indicatorColor="secondary"
                      textColor="secondary"
                      onChange={this.handleChange}
                    >
                      {MenuCustom.map((item, index) => {

                        const ItemImage = components[item.image];
                        
                        if (Object.keys(item).indexOf("conditional") > -1) {
                          if (DRONE_RC_ITEMS.some( ai => this.context.AuthInstance.userAccess.includes(ai) ) === false) {
                            return <></>   
                          }
                        }

                        return (
                          <Tab
                            key={index}
                            component={item.external ? MaterialLink : Link}
                            href={item.external ? item.pathname : null}
                            to={
                              item.external
                                ? null
                                : {
                                    pathname: item.pathname,
                                    search: this.props.location.search
                                  }
                            }
                            target={item.external ? "_blank": null}
                            rel = {item.external ? "noopener noreferrer": null}
                            classes={{ root: classes.tabItem }}
                            label={item.label}
                            icon={<ItemImage/>}
                          />
                        );
                      })}
                    </Tabs>
                  </div>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="secondary"                
            className={classes.userOptions}
            onClick={this.handleUserMenuBtn}
            style={{textTransform: "none"}}            
          >
            <AccountCircleIcon fontSize="large" />{this.context.AuthInstance.userName}
            <ExpandMoreIcon />
          </IconButton>
          <Menu
            id="customized-menu"
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            anchorEl={this.state.userAnchor}
            open={this.state.userMenu}
            onClose={this.handleUserMenuClose}
          >
            {this.context.AuthInstance.profile_access === true && (
              <MenuItem onClick={this.handleUserProfile}>Profile</MenuItem>
            )}
            <MenuItem onClick={this.handleUserAccessList}>Check Access</MenuItem>
            <MenuItem onClick={this.handleUserLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
        <UserProfile
          ref = {this.userProfileElement}
        />
        <AccessListModal
          ref = {this.userAccessElement}
        />
      </AppBar>      
    );
  }
}

export default withRouter(withStyles(styles)(Topbar));
