// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloseIcon from '@material-ui/icons/Close';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    root: {
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    mainDiv: {
        minHeight: 450,
        minWidth: 600,
        maxHeight: 450,
        maxWidth: 600,
        backgroundColor: "white",
        borderRadius: 15,
        padding: 10,
    },
    gridBox: {

    },


});


const C_OPTS_MODES = {
    'Set': ['r', 'p', 'b', '0'], 
    'String': ['r', 'p', 'b'], 
    'Image': ['r', 'p', 'b', '0'],
    'ASCII': ['r', 'p', 'b'],
};

const C_OPTS_OBJ = {
    r: {name:'Red', code:'rgb(255,0,0)', color: '#FF0000', strName: 'r'}, 
    p: {name:'Purple', code:'rgb(255,0,255)', color: '#FF00FF', strName: 'p'}, 
    b: {name:'Blue', code:'rgb(0,0,255)', color: '#0000FF', strName: 'b'}, 
    0: {name:'Off', code:'rgb(100,100,100)', color: '#000000', strName: '0'}, 
};

const SCROLL_DIR = {
    0: {Text: 'Left', IconName: ArrowBackIcon},
    1: {Text: 'Right', IconName: ArrowForwardIcon},
    2: {Text: 'Up', IconName: ArrowUpwardIcon},
    3: {Text: 'Down', IconName: ArrowDownwardIcon},
}

const FR_RNG = [0.1, 2.5];

const IN_MAIN_STR = {'String': 70, 'ASCII': 1}

// Component Class
class MledSelect extends React.Component {

    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);

        this.gridBoxW = '30px'
        this.gridBoxH = '30px'
        this.gridRows = 8
        this.gridCols = 8
        this.gridR = Array(this.gridRows).fill(0)
        this.gridC = Array(this.gridCols).fill(0)
    }

    state = {
        isOpen: false,
        mouseIsPressed: false,
        control: {},
        gridArray: Array.from({ length: 8 }, () => Array.from({ length: 8 }, () => C_OPTS_OBJ['0'])),
        pickedColor: C_OPTS_MODES['Set'][0],
        pickedNumOpts: 0,
        pickedIndx: 0,
        scrollDir: SCROLL_DIR[0],
        scrollDirMax: 3,
        scrollDirIndx: 0,
        in1: 0.1,
        inMain: "",
        slid1: 0,
    }

    handleOpen = (control) => {
        
        let tempString = ''
        if (control.mode === 'ASCII') {
            tempString = control.ASCIIData
        } else if (control.mode === 'String') {
            tempString = control.stringData
        }
        
        let gridStr = control.gridData
        let gridStrLen = gridStr.length;
        let tempGridArray = Array.from({ length: 8 }, () => Array.from({ length: 8 }, () => C_OPTS_OBJ['0']))

        let i = 0; let j = 0;
        for (let k = 0; k < gridStrLen; k++) {
            tempGridArray[i][j] = C_OPTS_OBJ[gridStr[k]]
            j += 1
            if (j > 7) {
                j = 0
                i += 1
            }
        }
        this.setState( {
            isOpen: true, control: control,
            pickedIndx: C_OPTS_MODES[control.mode].indexOf(control.strColor),
            pickedColor: control.strColor, //C_OPTS_MODES[control.mode][0],
            pickedNumOpts: C_OPTS_MODES[control.mode].length,
            in1: String(control.rateData),
            inMain: tempString,
            scrollDir: SCROLL_DIR[control.dirData],
            gridArray: tempGridArray,
            slid1: control.slid1,
        } );
    }

    handleClose = () => {

        let tempControl = this.state.control;

        if (tempControl.mode === 'ASCII') {
            tempControl.ASCIIData = this.state.inMain
        } else if (tempControl.mode === 'String') {
            tempControl.stringData = this.state.inMain
        }
        tempControl.dirData = this.state.scrollDirIndx
        tempControl.rateData = Number(this.state.in1)
        tempControl.strColor = this.state.pickedColor
        
        tempControl.gridData = ''
        for (let i = 0; i < 8; i++) {
            for (let j = 0; j < 8; j++) {
                tempControl.gridData += this.state.gridArray[i][j].strName
            }
        }

        this.props.onClose(tempControl);
        this.setState( {isOpen: false} )
    }

    handleGridClick = (e, i, j) => {
        
        let tempGrid = this.state.gridArray
        tempGrid[i][j] = C_OPTS_OBJ[this.state.pickedColor]
        this.setState( {gridArray: tempGrid} )
    }

    handleColor = () => {
        let tempPickedIndx = this.state.pickedIndx + 1
        if (tempPickedIndx >= this.state.pickedNumOpts) {
            tempPickedIndx = 0
        }
        this.setState( {
            pickedIndx: tempPickedIndx,
            pickedColor: C_OPTS_MODES[this.state.control.mode][tempPickedIndx],
        } )
    }

    handleFill = () => {
        let tempGrid = this.state.gridArray;

        for (let k = 0; k < 8; k++) {
            for (let m = 0; m < 8; m++) {
                tempGrid[k][m] = C_OPTS_OBJ[this.state.pickedColor]
            }
        }
        this.setState( {gridArray: tempGrid} )
    }

    handleClear = () => {
        let tempGrid = this.state.gridArray;

        for (let k = 0; k < 8; k++) {
            for (let m = 0; m < 8; m++) {
                tempGrid[k][m] = C_OPTS_OBJ['0']
            }
        }
        this.setState( {gridArray: tempGrid} )
    }

    startHold = () => {
        this.setState( {mouseIsPressed: true} )
    }

    stopHold = () => {
        this.setState( {mouseIsPressed: false} )
    }

    onHover = (e, i, j) => {
        if (this.state.mouseIsPressed === true) {
            let tempGrid = this.state.gridArray
            tempGrid[i][j] = C_OPTS_OBJ[this.state.pickedColor]
            this.setState( {gridArray: tempGrid} )
        }
    }

    handleScrollDir = () => {
        let tempPickedIndx = this.state.scrollDirIndx + 1
        if (tempPickedIndx > this.state.scrollDirMax) {
            tempPickedIndx = 0
        }
        this.setState( {
            scrollDirIndx: tempPickedIndx,
            scrollDir: SCROLL_DIR[tempPickedIndx],
        } )
    }

    handleIn = (event) => {
        let m = event.target.value;
        this.setState( {[event.target.name]: m} );
    }

    handleInOnBlur = (event) => {

        let m = event.target.value
        
        if (!isNaN(parseFloat(m)) && isFinite(m)) {
            let n = Number(m);

            if (n < FR_RNG[0]) {
                m = String(FR_RNG[0])
            } else if (n > FR_RNG[1]) {
                m = String(FR_RNG[1])
            }
        } else {
            m = String(FR_RNG[0])
        }

        this.setState( {[event.target.name]: m} )
    }

    handleInMain = (event) => {
        let m = event.target.value;
        const lim = IN_MAIN_STR[this.state.control.mode]
        if (m.length > lim) {
            m = m.slice(0, lim)
        }

        this.setState( {[event.target.name]: m} );
    }
    
    // render HTML
    render() {
        
        // Referenced below for setting styles
        const { classes } = this.props;

        const mode = this.state.control.mode

        // What to Display
        return (
            <div className={classes.root}>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    >
                    <Fade in={this.state.isOpen}>
                        <div 
                            className={classes.mainDiv}
                        >
                        <Grid 
                            container 
                            spacing={2}
                            direction="row"
                        >
                        <Grid item xs={12}>
                            <Grid 
                                container 
                                spacing={0}
                                className={classes.grid}
                                justify="flex-start"
                                direction="row"
                            >
                            <Grid item xs={9}>
                                <div style = {{display: 'flex'}}>
                                    <div style={{flex: 1}}>
                                    <IconButton 
                                        className={classes.actionButton} 
                                        onClick={this.handleClose}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                    </div>
                                    <div style={{flex: 1}}>
                                    <Typography 
                                        className={classes.titleText}
                                        variant='h4'
                                    >
                                        Settings
                                    </Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography 
                                    className={classes.titleText}
                                    variant='h5'
                                    style = {{fontStyle: 'italic'}}
                                >
                                    Mode: {mode}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style = {{minHeight: 15}}></Grid>
                            {((mode === 'String') || 
                            (mode === 'Image')) && (
                                
                            <Grid item xs={12}>
                                <Box style = {{
                                    display: 'flex', 
                                    justifyContent: 'space-between',
                                }}>
                                    <Box
                                        style = {{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        <Typography
                                            style = {{marginTop: 18}}
                                        >
                                            {`Scroll Direction: ${this.state.scrollDir.Text}`}
                                        </Typography>
                                        <IconButton
                                            onClick = {this.handleScrollDir}
                                        >
                                            <this.state.scrollDir.IconName 
                                                fontSize = "large"
                                                style = {{
                                                    color: "blue",
                                                    backgroundColor: "white",
                                                }}
                                            />
                                        </IconButton>
                                    </Box>
                                    
                                    <Box
                                        style = {{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        <Typography
                                            style = {{marginTop: 18}}
                                        >
                                            Frame Rate (Hz):
                                        </Typography>
                                        <Input 
                                            className={classes.inputField}
                                            name='in1'
                                            inputProps= {{
                                                style: { 
                                                    marginTop: '15px',
                                                    textAlign: 'center' }
                                            }}
                                            style = {{
                                                width: '40px',
                                                marginBottom: '15px',
                                                marginLeft: '10px'
                                            }}
                                            onChange = {this.handleIn}
                                            onBlur = {this.handleInOnBlur}
                                            value={this.state.in1}
                                            autoComplete="off"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            )}
                        </Grid>
                        
                        {((mode === 'Set') || 
                            (mode === 'Image')) && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                
                                <Box style = {{
                                    display: 'flex', 
                                    justifyContent: 'center',
                                }}>
                                    <Box>
                                        <Button
                                            style = {{backgroundColor: C_OPTS_OBJ[this.state.pickedColor].code}}
                                            onClick = {this.handleColor}
                                        >
                                            {C_OPTS_OBJ[this.state.pickedColor].name}
                                        </Button>
                                        <Button
                                            onClick = {this.handleFill}
                                        >
                                            Fill
                                        </Button>
                                        <Button
                                            onClick = {this.handleClear}
                                        >
                                            Clear
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box style = {{
                                    display: 'flex', 
                                    justifyContent: 'center',
                                }}>
                                    <Box>
                                        <Grid 
                                            container 
                                            spacing={0}
                                            direction="row"
                                        >
                                        <Grid item xs="auto"
                                            onMouseDown={this.startHold}
                                            onMouseUp={this.stopHold}
                                            onMouseLeave={this.stopHold}
                                        >
                                            {((mode === 'Set') || 
                                            (mode  === 'Image')) && (
                                                this.gridR.map((v, i) => {
                                                    return (
                                                        <div style = {{display:"flex"}} key = {'div'+i}>
                                                            {this.gridC.map((u, j) => {
                                                                return (
                                                                    <Box
                                                                        key = {i + ',' + j}
                                                                        onClick={(e) => this.handleGridClick(e, i, j)}
                                                                        onMouseEnter={(e) => this.onHover(e, i, j)}
                                                                        onMouseDown={(e) => this.handleGridClick(e, i, j)}
                                                                        style = {{
                                                                            minHeight: this.gridBoxH,
                                                                            minWidth: this.gridBoxW,
                                                                            backgroundColor: this.state.gridArray[i][j]['color'],
                                                                            border: "1px solid green"
                                                                        }}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })
                                            )}
                                        </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                        </React.Fragment>
                        )}
                        {((mode === 'String') || (mode === 'ASCII')) && (
                            <React.Fragment>
                                <Grid item xs={12} style = {{minHeight: 15}}></Grid>
                                <Grid item xs={12}>
                                    <Box style = {{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                    <Box style = {{
                                        display: 'flex',
                                        flexDirection: 'column', 
                                        justifyContent: 'center',
                                    }}>
                                        <Box style = {{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}>
                                            <Button
                                                style = {{backgroundColor: C_OPTS_OBJ[this.state.pickedColor].code}}
                                                onClick = {this.handleColor}
                                            >
                                                {C_OPTS_OBJ[this.state.pickedColor].name}
                                            </Button>
                                        </Box>
                                        <Box style = {{
                                            marginTop: 10,
                                        }}>
                                            <TextField
                                                id="standard-multiline-flexible"
                                                label={`Display ${mode}`}
                                                variant="outlined"
                                                multiline
                                                rows={6}
                                                inputProps= {{
                                                    style: {
                                                        color: C_OPTS_OBJ[this.state.pickedColor].code
                                                    }
                                                }}
                                                name='inMain'
                                                value={this.state.inMain}
                                                onChange={this.handleInMain}
                                            />
                                        </Box>
                                    </Box>
                                    </Box>
                                </Grid>
                            </React.Fragment>
                        )}


                        </Grid>
                        </Grid>
                        
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}


export default withStyles(styles)(MledSelect);






