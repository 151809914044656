import React, { Component } from 'react';
import Board from '../components/Board'
import GameInfo from '../components/GameInfo'
import Player from '../components/Player'
import Enemy from '../components/Enemy'
import DebugState from '../components/DebugState'
import { UP, DOWN, LEFT, RIGHT, ENTER } from '../helpers/constants';
import { pluck } from '../helpers/utils';
import GameOver from '../../shared/GameOver.js'


const getDefaultState = ({ boardSize, playerSize, highScore = 0 }) => {
    const half = Math.floor(boardSize / 2) * playerSize;
    return {
        size: {
            board: boardSize,
            player: playerSize,
            maxDim: boardSize * playerSize
        },
        positions: {
            player: {
                top: half,
                left: half
            },
            enemies: []
        },
        btnLevel: 0,
        btnLevelT: 0,
        playerScore: 0,
        highScore,
        timeElapsed: 0,
        enemySpeed: 5,
        enemyIndex: 0,
        activeEnemies: 1,
        baseScore: 10,
        mode: '',
        playerColor: 'darkgray',
        enemyColor: 'firebrick',
        topLevel: true,
        leftLevel: true,
        enemyTimeout: 50,
        enemyTimeoutId: 0,
        timeTimeout: 1000,
        timeTimeoutId: 0,
        gameTimeout: 250,
        gameTimeoutId: 0,
        resetTimeout: 100,
        resetTimeoutId: 0,
        settings: {},    
        isGameOver: false,
        width: 600,
        height: 400, 
        newHighScore: false, 
        message: 'Press Enter to restart',   
    }
};

export default class Game extends Component {
    constructor(props) {
        super(props);
        
        this._isMounted = false;

        const half = Math.floor(props.boardSize / 2) * props.playerSize;
        const { boardSize, playerSize } = props;
        
        this.playerElement = React.createRef();

        this.in_reset = false;

        this.state = getDefaultState({ boardSize, playerSize })
    }
    
    placeEnemy = () => {
        // enemies always launch at player
        const { player, maxDim } = this.state.size;
        const { player: playerPos } = this.state.positions;

        // assign to a random side
        const side = pluck([UP, DOWN, LEFT, RIGHT]);

        // generate enemy object
        const newEnemy = this.generateNewEnemy(playerPos, side);

        // add new enemy to state
        this.setState({
            positions: {
                ...this.state.positions,
                enemies: [...this.state.positions.enemies].concat(newEnemy)
            }
        });
    }

    generateNewEnemy = (position, side) => {
        this.setState({
            enemyIndex: this.state.enemyIndex + 1
        });

        const newEnemy = { key: this.state.enemyIndex, dir: side };
        const { maxDim, player } = this.state.size;

        switch(side) {
            case UP:
                newEnemy.top = maxDim;
                newEnemy.left = position.left;
                break;
            case DOWN:
                newEnemy.top = 0 - player;
                newEnemy.left = position.left;
                break; 
            case LEFT:
                newEnemy.top = position.top;
                newEnemy.left = maxDim;
                break;
            case RIGHT:
                newEnemy.top = position.top;
                newEnemy.left = 0 - player;
                break;
        }

        return newEnemy;
    }

    handlePlayerMovement = (dirObj) => {
        const { top, left } = this.state.positions.player;
        const { player, maxDim } = this.state.size;
        
        // check walls
        switch (dirObj.dir) {
            case UP:
                if (top === 0) return;
                break;
            case DOWN:
                if (top === maxDim - player) return;
                break;
            case LEFT:
                if (left === 0) return;
                break;
            case RIGHT:
                if (left === maxDim - player) return;
                break;
            case ENTER:
                /*if (this.in_reset === false) {
                    this.in_reset = true;
                    this.setState({            
                        isGameOver: true,  
                        highScore: this.state.playerScore > this.state.highScore ? this.state.playerScore : this.state.highScore,
                        newHighScore: this.state.playerScore > this.state.highScore ? true : false,
                    });
                } else {*/
                if (this.in_reset === true) {
                    this.setState({message: 'Restarting...'})
                    setTimeout(this.resetGame, 1000)
                }
                break;
        }
        
        this.setState({
            positions: {
                ...this.state.positions,
                player: {
                    top: top + (player * dirObj.top),
                    left: left + (player * dirObj.left)
                }
            }
        });
    }

    handlePlayerCollision = () => {
        if (this.in_reset === false) {
            this.in_reset = true;
            this.setState({            
                isGameOver: true,  
                highScore: this.state.playerScore > this.state.highScore ? this.state.playerScore : this.state.highScore,
                newHighScore: this.state.playerScore > this.state.highScore ? true : false,
            });
            //this.resetGame();
        }
    }

    startGame = () => {
        /*
        if (this.in_reset === false) {
            this.updateEnemyPositions()
            this.updateGame()
            this.updateEnemiesInPlay()
        } else {            
            this.in_reset = false;
        }
        */
       if (this.in_reset === true) {
        this.in_reset = false;
       }
       const { boardSize, playerSize } = this.props;
       const half = Math.floor(boardSize / 2) * playerSize;
       this.setState({
           positions: {
               ...this.state.positions,
               player: {
                   top: half,
                   left: half
               }
           }
       });  
       this.updateEnemyPositions()
       this.updateGame()
       this.updateEnemiesInPlay()
    }

    get_rp = (xyz) => {

        let rp = [0,0];
    
        rp[0] = Math.atan2(xyz[0], Math.sqrt(xyz[1] * xyz[1] + xyz[2] * xyz[2])) * 180.0 / Math.PI
        rp[1] = Math.atan2(xyz[1], Math.sqrt(xyz[0] * xyz[0] + xyz[2] * xyz[2])) * 180.0 / Math.PI
        if ((xyz[1] === 0) && (xyz[2] === 0)) {
            rp[0] = 0
        }
        if ((xyz[0] === 0) && (xyz[2] === 0)) {
            rp[1] = 0
        }
    
        if (rp[0] > 30) {
            rp[0] = 30
        } else if (rp[0] < -30) {
            rp[0] = -30
        }
        if (rp[1] > 45) {
            rp[1] = 45
        } else if (rp[1] < -45) {
            rp[1] = -45
        }
    
        return rp;
      }
    
    updateGame = () => {
        if (this.in_reset === false) {
            let timeoutId = setTimeout(() => {
                if (this._isMounted === true) {
                    const { timeElapsed } = this.state;

                    this.updateTimeAndScore();

                    if (timeElapsed > 0) {

                        // increment enemy speed
                        if (timeElapsed % 3 === 0) {
                            this.incrementEnemySpeed();
                        }

                        // increment max active enemies every 10 seconds
                        if (timeElapsed % 10 === 0) {
                            this.incrementActiveEnemies();
                        }
                    }
                    
                    window.game_state = {
                        'score': this.state.playerScore,
                        'enemies': this.state.activeEnemies,
                        'enemy speed': this.state.enemySpeed,
                        'time': this.state.timeElapsed,
                    }
                    
                    if (this.in_reset === false) {
                        this.updateGame()
                    }
                }
            }, this.state.timeTimeout);
            this.setState( {timeTimeoutId: timeoutId} )
        }
    }

    updateEnemyPositions = () => {
        if (this.in_reset === false) {
            let timeoutId = setTimeout(() => {
                if (this._isMounted === true) {
                    if (this.state.mode === 'LocoWear') {

                        const { top, left } = this.state.positions.player;
                        const { player, maxDim } = this.state.size;

                        let rp = this.get_rp(this.props.LRObj.self.accData);

                        let btn = this.props.LRObj.self.sideBtnState;

                        const offset = 20;
                        let newDirection = {top: 0, left: 0};
                        let canUpdateP = true;
                        let canUpdateR = true;

                        if (rp[0] > offset) {
                            newDirection ['left'] = -1 // LEFT
                            if (left === 0) {
                                canUpdateR = false;   
                            }
                        } else if (rp[0] <= -offset) {
                            newDirection ['left'] = 1 // RIGHT
                            if (left === maxDim - player) {
                                canUpdateR = false;   
                            }
                        } 
                        if (rp[1] <= -offset) {
                            newDirection['top'] = -1 // UP
                            if (top === 0) {
                                canUpdateP = false;   
                            }
                        } else if (rp[1] > offset) {
                            newDirection['top'] = 1 // DOWN
                            if (top === maxDim - player) {
                                canUpdateP = false;   
                            }
                        } 
                        
                        let newP = top;
                        let newR = left;                    
                        if ((btn === 1) && (this.state.btnLevel === 0)) {
                            if (canUpdateR === true) {
                                newR = left + (player * newDirection.left)
                            }
                            if (canUpdateP === true) {
                                newP = top + (player * newDirection.top);
                            }
                        }
                        this.setState( {btnLevel: btn} );
                        
                        this.setState({
                            positions: {
                                ...this.state.positions,
                                player: {
                                    top: newP,
                                    left: newR
                                }
                            }
                        });
                        this.props.LRObj.send_command(this.props.LRObj.self.GET_DATA.value);
                        
                    }

                    
                    const { enemySpeed, positions: { enemies }, size: { player, maxDim }} = this.state;

                    this.setState({
                        positions: {
                            ...this.state.positions,
                            enemies: enemies.filter(enemy => !enemy.remove).map(enemy => {
                                if (enemy.top < (0 - player) || 
                                    enemy.top > maxDim + player || 
                                    enemy.left < (0 - player) || 
                                    enemy.left > maxDim + player ) {
                                    enemy.remove = true;
                                    return enemy;
                                }

                                // based on direction, increment the correct value (top / left)
                                switch(enemy.dir) {
                                    case UP: 
                                        enemy.top -= enemySpeed;
                                        break;
                                    case DOWN: 
                                        enemy.top += enemySpeed;
                                        break;
                                    case LEFT:
                                        enemy.left -= enemySpeed;
                                        break;
                                    case RIGHT:
                                        enemy.left += enemySpeed;
                                        break;
                                }

                                return enemy;
                            })
                        }
                    });
                    if (this.in_reset === false) {
                        this.updateEnemyPositions()
                    }
                }
            }, this.state.enemyTimeout);
            this.setState( {enemyTimeoutId: timeoutId} )
        }
    }

    updateEnemiesInPlay = () => {
        if (this.in_reset === false) {
            let timeoutId = setTimeout(() => {
                if (this._isMounted === true) {
                    const { activeEnemies } = this.state;
                    const { enemies } = this.state.positions;

                    if (enemies.length < activeEnemies) {
                        this.placeEnemy();
                    }
                    if (this.in_reset === false) {
                        this.updateEnemiesInPlay()
                    }
                }
            }, this.state.gameTimeout);
            this.setState( {gameTimeoutId: timeoutId} )
        }
    }

    updateTimeAndScore = () => {
        const { timeElapsed, playerScore, baseScore } = this.state;
        if (this.in_reset === false) {
            this.setState({
                timeElapsed: timeElapsed + 1,
                playerScore: playerScore + baseScore,
            });
        }
    }

    incrementEnemySpeed = () => {
        const { enemySpeed } = this.state;

        this.setState({
            enemySpeed: parseFloat((enemySpeed + 0.25).toFixed(2))
        });
    }

    incrementActiveEnemies = () => {
        this.setState({
            activeEnemies: this.state.activeEnemies + 1
        });
    }

    resetGame = () => {
        const { boardSize, playerSize } = this.props;
        const { playerScore, highScore, debug } = this.state;
        

        clearTimeout(this.state.enemyTimeoutId)
        clearTimeout(this.state.timeTimeoutId)
        clearTimeout(this.state.gameTimeoutId)

        let tempMode = this.state.mode;
        let tempSettings = this.state.settings
        // reset state
        this.setState({
            ...getDefaultState({ boardSize, playerSize, highScore }),
            // persist debug state and high scores
            debug,
            highScore: playerScore > highScore ? playerScore : highScore,
            newHighScore: playerScore > highScore ? true : false,
        });


        this.setState( {mode: tempMode})
        if (tempMode === 'LocoWear') {
            this.setState( {message: 'Press Top Button to restart'} )
        }

        this.updateSettings(tempSettings);

        // restart game
        this.startGame();

    }

    handleDebugToggle = () => {
        this.setState({
            debug: this.debug.checked
        });
    }



    style = () => {
        return {
            width: '85%',
            maxWidth: '600px',
            margin: '0 auto'
        };
    }
    
    render() {
        const { 
            size: { board, player }, 
            positions: { player: playerPos },
            playerScore,
            timeElapsed,
            highScore,
        } = this.state;

        // Game over
        if (this.state.isGameOver) {
            return (
            <GameOver
                width={this.state.width}
                height={this.state.height}
                highScore={this.state.highScore}
                newHighScore={this.state.newHighScore}
                score={this.state.playerScore}
                message={this.state.message}
            />
            )
        }

        return (
            <div style={this.style()}>
                <GameInfo 
                    playerScore={playerScore} 
                    timeElapsed={timeElapsed}
                    highScore={highScore}
                    mode={this.state.mode}
                />

                <Board dimension={board * player}>
                    <Player 
                        ref = {this.playerElement}
                        color={this.state.playerColor}
                        size={player} 
                        position={playerPos}
                        handlePlayerMovement={this.handlePlayerMovement} />

                    {
                        this.state.positions.enemies.map(enemy => 
                            <Enemy key={enemy.key}
                                size={player}
                                info={enemy}
                                playerPosition={playerPos}
                                color={this.state.enemyColor}
                                onCollide={this.handlePlayerCollision} />
                        )
                    }
                </Board>
                {false && <p style={{ position: 'fixed', bottom: 0, left: 16 }}>Debug: <input type="checkbox" onChange={this.handleDebugToggle} ref={ n => this.debug = n }/></p>}
                {this.state.debug && <DebugState data={this.state} />}
            </div>
        )
    }
    
    componentDidMount() {        
        this._isMounted = true;
    }

    trackResetLW = () => {
        let timeoutId = setTimeout(() => {
            
            let btn = this.props.LRObj.self.topBtnState;
            
            if ((btn === 1) && (this.state.btnLevelT === 0)) {
                /*if (this.in_reset === false) {
                    this.in_reset = true;
                    this.setState({
                        isGameOver: true,  
                        highScore: this.state.playerScore > this.state.highScore ? this.state.playerScore : this.state.highScore,
                        newHighScore: this.state.playerScore > this.state.highScore ? true : false,
                    });
                } else {*/
                if (this.in_reset === true) {
                    this.setState({message: 'Restarting...'})
                    setTimeout(this.resetGame, 1000)
                } 
            }
            this.setState( {btnLevelT: btn} )
            if (this.in_reset === true) {
                this.props.LRObj.send_command(this.props.LRObj.self.GET_DATA.value);
            }

            this.trackResetLW();
        }, this.state.resetTimeout);
        this.setState( {resetTimeoutId: timeoutId} )
    }

    initGame(mode) {
        this.setState({mode: mode})

        if (mode === 'LocoWear') {
            this.setState( {message: 'Press Top Button to restart'} )
            this.props.LRObj.send_command(this.props.LRObj.self.GET_DATA.value);

            this.trackResetLW();
        }

        this.playerElement.current.initControls(mode)


        this.startGame();
    }

    updateSettings = (settings) => {

        this.setState( {settings: settings} )
        if (typeof settings['settings'] !== "undefined") {

            for (let i = 0; i < settings['settings'].length; i++) {
                if ('player color' in settings['settings'][i]) {
                    this.setState( {playerColor: settings['settings'][i]['player color']} )
                }  
                if ('enemy color' in settings['settings'][i]) {
                    this.setState( {enemyColor: settings['settings'][i]['enemy color']} )
                }  
                if ('enemy speed' in settings['settings'][i]) {
                    this.setState( {enemySpeed: settings['settings'][i]['enemy speed']} )
                }  
                if ('base score' in settings['settings'][i]) {
                    this.setState( {baseScore: settings['settings'][i]['base score']} )
                }  



            }
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.state.enemyTimeoutId)
        clearTimeout(this.state.timeTimeoutId)
        clearTimeout(this.state.gameTimeoutId)
        clearTimeout(this.state.resetTimeoutId)
    }
}