// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import ProfileChanged from '../drawers/ProfileChanged'

// Import Context to Access Auth/User Information
import { AuthContext } from "../../context/auth";

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    media: {
        height: 0,
        paddingTop: '100%', // 
        marginTop:'30',
    },
    text: {
    },
    actionButton: {
        type: "submit",
        justify: "center",
        margin: theme.spacing(0),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
        fontSize: '15px',
    },
  });
  
  // Component Class
  class UserProfile extends React.Component {
    
    static contextType = AuthContext;

    constructor(props) {
        super(props);


        this.profileChangedElement = React.createRef();
    }

    // Class state
    state = {
        isOpen: false,
        fn: "",
        ln: "",
    }
    
    componentDidMount() {
        this.setState({ fn: this.context.AuthInstance.fn, ln: this.context.AuthInstance.ln,});
    }


    // For Displaying Self (Modal), Called by Parent
    handleOpen = () => {
        setTimeout(this.openSelf, 100);
    };

    openSelf = () => {
        this.setState( {isOpen: true} );
    }

    handleClose = () => {
        this.setState( {isOpen: false} );
    };

    handleSave = () => {

        this.context.AuthInstance.saveUserProfile(this.state.fn, this.state.ln).then(() => {
            this.profileChangedElement.current.handleOpen();
        });
    };

 
    handleInputUpdates = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display - The Modal: Header, Button, "pre" tagged Text
        return (
            <div>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper}>
                        <Grid 
                            container 
                            spacing={2}
                            direction="row"
                        >
                            <Grid item xs={6}>
                                <Typography className={classes.text}
                                    style={{fontWeight: 'bold', fontSize: '25px'}}
                                >
                                    First Name: 
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth className={classes.form}>
                                    <Input className={classes.inputField}
                                        id="standard-adornment-fn"
                                        name="fn"
                                        aria-describedby="standard-fn-helper-text"
                                        inputProps={{
                                            'aria-label': 'fn',
                                        }}
                                        value = {this.state.fn}
                                        onChange = {this.handleInputUpdates}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.text}
                                    style={{fontWeight: 'bold', fontSize: '25px'}}
                                >
                                    Last Name: 
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth className={classes.form}>
                                    <Input className={classes.inputField}
                                        id="standard-adornment-ln"
                                        name="ln"
                                        aria-describedby="standard-ln-helper-text"
                                        inputProps={{
                                            'aria-label': 'ln',
                                        }}
                                        value = {this.state.ln}
                                        onChange = {this.handleInputUpdates}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    className={classes.actionButton}
                                    onClick={this.handleSave}
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    className={classes.actionButton}
                                    onClick={this.handleClose}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
                </Modal>
                <ProfileChanged
                    ref={this.profileChangedElement}
                />
            </div>
        );
    }

}
export default withStyles(styles)(UserProfile);