
class LocoDrone {

    constructor () {
        this.port = {};
        this.bufferSize = 100;
        this.alpha = 0.3;
        this.acc_offsets = [0, 0, 0];
        this.gyr_offsets = [0, 0, 0];
        this.rpt_offsets = [0, 0, 0];
        
        this.optionNames = ['Drone Data', 'Accelerometer', 'Gyroscope', 'Buttons'];

        this.options = {'Drone Data': {refer: 'GET_JOY_ACC_R_P_T', count: 3, range: ['min', 'min', 'fixed'], fixed: [-1.25, 1.25], offset: 5, },
                        'Accelerometer': {refer: 'GET_ACC', count: 3, range: ['min', 'min', 'min'], offset: 0.25,},
                        'Gyroscope': {refer: 'GET_GYR', count: 3, range: ['min', 'min', 'min'], offset: 15,},
                        'Buttons': {refer: 'GET_BUTTONS', count: 3, range: ['fixed', 'fixed', 'fixed'], fixed: [-0.5, 1.5] },
                        };
        
        this.self = {
            GET_ACC: {
                value: 100,
                names: ['ax', 'ay', 'az'],
                ylabels: ["X-Axis Acc. (g)", "Y-Axis Acc. (g)", "Z-Axis Acc. (g)"],
            },
            GET_GYR: {
                value: 101,
                names: ['gx', 'gy', 'gz'],
                ylabels: ["X-Axis Ang-Vel (deg/s)", "Y-Axis Ang-Vel (deg/s)", "Z-Axis Ang-Vel (deg/s)"],
            },
            GET_JOY_ACC_R_P_T: {
                value: 102,
                names: ['Roll', 'Pitch', 'Throttle'],
                ylabels: ["Roll Ang. (deg)", "Pitch Ang. (deg)", "Throttle (#)"],
            },
            GET_BUTTONS: {
                value: 106,
                names: ['Left Btn', 'Right Btn', 'AND Btns'],
                ylabels: ["Left Btn State (#)", "Right Btn State (#)", "Btns AND (#)"],
            },
            PITCH_MAX_ANGLE: 30.0,
            ROLL_MAX_ANGLE: 45.0,
            axArray: [],
            ayArray: [],
            azArray: [],
            gxArray: [],
            gyArray: [],
            gzArray: [],
            rollArray: [],
            pitchArray: [],
            throttleArray: [],
            leftBtnArray: [],
            rightBtnArray: [],
            bothBtnArray: [],
        };
    };

    resetArrays = () => {
        this.self.axArray = [];
        this.self.ayArray = [];
        this.self.azArray = [];
        this.self.gxArray = [];
        this.self.gyArray = [];
        this.self.gzArray = [];
        this.self.rollArray = [];
        this.self.pitchArray = [];
        this.self.throttleArray = [];
        this.self.leftBtnArray = [];
        this.self.rightBtnArray = [];
        this.self.bothBtnArray = [];
    };

    
    set_buffer_size = (buffer_size) => {
        this.bufferSize = buffer_size
    }

    get_buffer_size = () => {
        return this.bufferSize
    }

    map_data = (x, in_min, in_max, out_min, out_max) => {
        return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    };

    connect = async () => {
        
        try {
            window.port.disconnect();
        } catch {
        }

        this.port = await window.serial.requestPort();
        await this.port.connect();
        this.port.onReceive = data => {
            this.processData(data);
        }
        this.port.onReceiveError = (error) => {            
        }
    }

    disconnect = () => {
        this.port.disconnect().then(() => {
            window.port = {};
            return;
        });
    }

    send_command = (data_msg) => {
        let data = new Uint8Array(1);
        data[0] = data_msg;
        this.port.send(data);
    };

    get_data_array = (type) => {
        
        switch(type) {    
            case this.self.GET_ACC.value:
                return [this.self.axArray, this.self.ayArray, this.self.azArray];
            case this.self.GET_GYR.value:
                return [this.self.gxArray, this.self.gyArray, this.self.gzArray];
            case this.self.GET_JOY_ACC_R_P_T.value:
                return [this.self.rollArray, this.self.pitchArray, this.self.throttleArray];
            case this.self.GET_BUTTONS.value:
                return [this.self.leftBtnArray, this.self.rightBtnArray, this.self.bothBtnArray];
            default: 
                return [0,0,0];
        }
    };

    calibrate = () => {

        let calCount = 0;
        let calLimit = 4;
        this.acc_offsets = [0, 0, 0]
        this.gyr_offsets = [0, 0, 0]
        this.rpt_offsets = [0, 0, 0]
        let acc_sum = [0,0,0]
        let gyr_sum = [0,0,0]
        let rpt_sum = [0,0,0]
        let that = this;
        let acc_flag = 0;

        return new Promise(function (resolve, reject) {
            var updateFromSample = function () {
                if (acc_flag === 0) {
                    let lArray = that.self.axArray.length - 1;                    
                    acc_sum[0] = acc_sum[0] + that.self.axArray[lArray];
                    acc_sum[1] = acc_sum[1] + that.self.ayArray[lArray];
                    acc_sum[2] = acc_sum[2] + that.self.azArray[lArray];
                } else if (acc_flag === 1) {
                    let lArray = that.self.gxArray.length - 1;
                    gyr_sum[0] = gyr_sum[0] + that.self.gxArray[lArray];
                    gyr_sum[1] = gyr_sum[1] + that.self.gyArray[lArray];
                    gyr_sum[2] = gyr_sum[2] + that.self.gzArray[lArray];
                } else if (acc_flag === 2) {
                    let lArray = that.self.rollArray.length - 1;
                    rpt_sum[0] = rpt_sum[0] + that.self.rollArray[lArray];
                    rpt_sum[1] = rpt_sum[1] + that.self.pitchArray[lArray];
                    rpt_sum[2] = rpt_sum[2] + that.self.throttleArray[lArray];
                }
                
                if (calCount < calLimit) {
                    calCount = calCount + 1;
                    setTimeout(sample, 1);
                } else {
                    if (acc_flag === 0) {
                        that.acc_offsets[0] = acc_sum[0] / (calLimit + 1);
                        that.acc_offsets[1] = acc_sum[1] / (calLimit + 1);
                        that.acc_offsets[2] = (acc_sum[2] / (calLimit + 1)) - 1;
                        if (isNaN(that.acc_offsets[0])) {
                            that.acc_offsets = [0,0,0]
                        }
                        acc_flag = 1
                        calCount = 0
                        setTimeout(sample, 1);
                    } else if (acc_flag === 1) {
                        that.gyr_offsets[0] = gyr_sum[0] / (calLimit + 1);
                        that.gyr_offsets[1] = gyr_sum[1] / (calLimit + 1);
                        that.gyr_offsets[2] = gyr_sum[2] / (calLimit + 1);
                        if (isNaN(that.gyr_offsets[0])) {
                            that.gyr_offsets = [0,0,0]
                        }
                        acc_flag = 2
                        calCount = 0
                        setTimeout(sample, 1);
                    } else if (acc_flag === 2) {
                        that.rpt_offsets[0] = rpt_sum[0] / (calLimit + 1);
                        that.rpt_offsets[1] = rpt_sum[1] / (calLimit + 1);
                        that.rpt_offsets[2] = rpt_sum[2] / (calLimit + 1);
                        if (isNaN(that.rpt_offsets[0])) {
                            that.rpt_offsets = [0,0,0]
                        }
                        that.resetArrays()
                        resolve("done")
                    }
                }
            };
            
            var sample = function () {
                if (acc_flag === 0) {
                    that.send_command(that.self.GET_ACC.value);
                } else if (acc_flag === 1) {
                    that.send_command(that.self.GET_GYR.value);
                } else if (acc_flag === 2) {
                    that.send_command(that.self.GET_JOY_ACC_R_P_T.value);
                }
                setTimeout(updateFromSample, 10);
            };
            
            setTimeout(sample, 1500);
        });
    }

    processData = (data) => {
        let dataBytes = new Uint8Array(data.buffer);
        switch(dataBytes[0]) {
    
            case this.self.GET_ACC.value:
                const axSlice = dataBytes.slice(1, 5);
                const aySlice = dataBytes.slice(5, 9);
                const azSlice = dataBytes.slice(9, 13);
    
                const axArr = new Float32Array(axSlice.buffer);
                const ayArr = new Float32Array(aySlice.buffer);
                const azArr = new Float32Array(azSlice.buffer);
                
                axArr[0] = axArr[0] - this.acc_offsets[0];
                ayArr[0] = ayArr[0] - this.acc_offsets[1];
                azArr[0] = azArr[0] - this.acc_offsets[2];
                
                if (this.self.axArray.length > this.bufferSize) {    
                    this.self.axArray.splice(0, 1);
                    this.self.ayArray.splice(0, 1);
                    this.self.azArray.splice(0, 1);
                }

                if (this.self.axArray.length > 0) {
                    let lastValx = this.self.axArray[this.self.axArray.length-1];
                    let lastValy = this.self.ayArray[this.self.ayArray.length-1];
                    let lastValz = this.self.azArray[this.self.azArray.length-1];
                    let nowValx = axArr[0];
                    let nowValy = ayArr[0];
                    let nowValz = azArr[0];
                    
                    axArr[0] = this.alpha * nowValx + (1 - this.alpha) * lastValx;
                    ayArr[0] = this.alpha * nowValy + (1 - this.alpha) * lastValy;
                    azArr[0] = this.alpha * nowValz + (1 - this.alpha) * lastValz;
                }
                this.self.axArray.push(axArr[0]);
                this.self.ayArray.push(ayArr[0]);
                this.self.azArray.push(azArr[0]);
    
                break;
    
            case this.self.GET_GYR.value:
                const gxSlice = dataBytes.slice(1, 5);
                const gySlice = dataBytes.slice(5, 9);
                const gzSlice = dataBytes.slice(9, 13);
    
                var gxArr = new Float32Array(gxSlice.buffer);
                var gyArr = new Float32Array(gySlice.buffer);
                var gzArr = new Float32Array(gzSlice.buffer);

                gxArr[0] = gxArr[0] - this.gyr_offsets[0];
                gyArr[0] = gyArr[0] - this.gyr_offsets[1];
                gzArr[0] = gzArr[0] - this.gyr_offsets[2];
    
                if (this.self.gxArray.length > this.bufferSize) {    
                    this.self.gxArray.splice(0, 1);
                    this.self.gyArray.splice(0, 1);
                    this.self.gzArray.splice(0, 1);
                }

                if (this.self.gxArray.length > 0) {
                    let lastValx = this.self.gxArray[this.self.gxArray.length-1];
                    let lastValy = this.self.gyArray[this.self.gyArray.length-1];
                    let lastValz = this.self.gzArray[this.self.gzArray.length-1];
                    let nowValx = gxArr[0];
                    let nowValy = gyArr[0];
                    let nowValz = gzArr[0];
                    
                    gxArr[0] = this.alpha * nowValx + (1 - this.alpha) * lastValx;
                    gyArr[0] = this.alpha * nowValy + (1 - this.alpha) * lastValy;
                    gzArr[0] = this.alpha * nowValz + (1 - this.alpha) * lastValz;
                }

                this.self.gxArray.push(gxArr[0]);
                this.self.gyArray.push(gyArr[0]);
                this.self.gzArray.push(gzArr[0]);
    
                break;
    
            case this.self.GET_JOY_ACC_R_P_T.value:
                
                if (this.self.rollArray.length > this.bufferSize) {    
                    this.self.rollArray.splice(0, 1);
                    this.self.pitchArray.splice(0, 1);
                    this.self.throttleArray.splice(0, 1);
                }
                let roll = (dataBytes[2] << 8) | dataBytes[1];
                let pitch = (dataBytes[4] << 8) | dataBytes[3];                
                roll = this.map_data(roll, 0, 1023, -this.self.ROLL_MAX_ANGLE, this.self.ROLL_MAX_ANGLE)
                pitch = this.map_data(pitch, 0, 1023, -this.self.PITCH_MAX_ANGLE, this.self.PITCH_MAX_ANGLE)
                roll = roll - this.rpt_offsets[0];
                pitch = pitch - this.rpt_offsets[1];
                this.self.rollArray.push( roll );
                this.self.pitchArray.push( pitch );

                let throt = new Int8Array(1);
                throt[0] = (dataBytes[6] << 8) | dataBytes[5];
                //let throttle = throt[0] - this.rpt_offsets[2];
                //this.self.throttleArray.push( throttle );
                this.self.throttleArray.push( throt[0] );
                break;
    
            case this.self.GET_BUTTONS.value:
                if (this.self.rightBtnArray.length > this.bufferSize) {    
                    this.self.rightBtnArray.splice(0, 1);
                    this.self.leftBtnArray.splice(0, 1);
                    this.self.bothBtnArray.splice(0, 1);
                }

                this.self.rightBtnArray.push( dataBytes[1] );
                this.self.leftBtnArray.push( dataBytes[2] );
                this.self.bothBtnArray.push( dataBytes[1] && dataBytes[2] );
                break;
            default: 
                break;
        }
    
    };

}

export default LocoDrone;