
// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import caliImg from '../../images/LocoWear/lw_calibration.svg';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    button: {
        square:true,
    },
    text: {
        color: "white",
        backgroundColor: theme.palette.primary["light"],
    },

  });

// Component Class
class DataVisLW extends React.Component {
    
    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);


        // Bind Certain Functions to "this"
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.timeoutID = null;
    }

    // Class state
    state = {
        isOpen: false,
        displayMsg: "Waiting For Controller...",
        looking: true,
        calibrating: false,
    }

    // For Displaying Self (Modal), Called by Parent ProgramCoding
    handleOpen = () => {
        this.setState( {isOpen: true, looking: true,} );
    };

    // For Stopping Program Run
    handleClose = () => {
        if (this.state.looking === false) {
            if (window.game_wait === true) {
                window.game_wait = false
            }
        }
        this.setState( {isOpen: false} );
    };

    handleUpdateMsgLooking = () => {

        this.handleOpen();
        this.setState( {displayMsg: "Power on your Controller and hold your hand flat as depicted.", calibrating: false} );

        // set timeout 8s
        this.timeoutID = setTimeout(this.handleLookingClose, 30000);//8000);
    }

    handleLookingClose = () => {
        if (window.game_wait === true) {            
            window.game_wait = false
        }
        if (this.state.looking === true) {
            this.setState( {isOpen: false} );
        }
    }

    handleUpdateMsgFound = () => {

        clearTimeout(this.timeoutID)
        
        if (this.state.isOpen === false) {
            this.handleOpen();
        }
        this.setState( {displayMsg: "Found Controller. Calibrating, Please Wait...", looking: false, calibrating: true} );

        // set timeout 4s
        setTimeout(this.handleClose, 4000);
    }

    render() {

        const { classes } = this.props;

        return (
            <div>
                <Drawer
                    anchor='top' 
                    open={this.state.isOpen}
                >
                    <Grid 
                        container 
                        spacing={0}
                        direction="row"
                    >
                    <Grid item xs={12}>
                        <Typography
                            className={classes.text}
                            style={{fontSize: '34px', textAlign: 'center'}}
                        >
                            {this.state.displayMsg}
                        </Typography>
                        {(this.state.calibrating === false) && (
                            <img src={caliImg} 
                            alt=""
                            height='400px'></img>
                        )}
                        {(this.state.calibrating === true) && (
                            <Typography
                                className={classes.text}
                                style={{fontSize: '34px', textAlign: 'center'}}
                            >
                                Please keep the controller flat and stationary.
                            </Typography>
                        )}
                    </Grid>
                    </Grid>
                </Drawer>
            </div>
        );
    }

}
export default withStyles(styles)(DataVisLW);
