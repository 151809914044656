// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

import ShareProgramSent from "../drawers/ShareProgramSent";
import GeneralMessage from "../drawers/GeneralMessage";


// Import Context to Access Auth/User Information
import { AuthContext } from "../../context/auth";

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    media: {
        height: 0,
        paddingTop: '100%', // 
        marginTop:'30',
    },
    text: {
    },
    actionButton: {
        type: "submit",
        justify: "center",
        margin: theme.spacing(0),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
        fontSize: '15px',
    },
  });
  
  // Component Class - ProgramDisplay
  class ShareProgram extends React.Component {
    
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        //
        this.shareAttemptElement = React.createRef();

        //
        this.sharingInProgressElement = React.createRef();
    }

    // Class state
    state = {
        isOpen: false,
        uri: '',
        usernameBuffer: '',
        prog_name: '',
        teacher_name: '',
        account_type: 'student',
    }


    // For Displaying Self (Modal), Called by Parent ProgramCoding
    handleOpen = (resource_uri, prog_name) => {
        if (prog_name.length > 23) {
            prog_name = prog_name.slice(0, 20);
            prog_name = prog_name + "..."
        }
        this.setState( {uri: resource_uri, progName: prog_name} );
        this.setState( {account_type: this.context.AuthInstance.account_type} );
        setTimeout(this.openSelf, 100);
    };

    openSelf = () => {
        this.setState( {isOpen: true} );
    }

    handleClose = () => {
        this.setState( {isOpen: false} );
    };

    handleTargetNameUpdate = (event) => {
        this.setState({usernameBuffer: event.target.value});
    }


    handleSubShare = () => {
        const data = {
            shared_program: this.state.uri,
            type: 'shared',
            shared_to: this.state.usernameBuffer,
            user_fn: '',
            user_ln: '',
        };

        
        this.context.AuthInstance.createSharingProgram(data).then(res => {
            this.shareAttemptElement.current.handleOpen("SUCCESS");
        }).catch((err) => {
            this.shareAttemptElement.current.handleOpen("FAILURE");
        });
            
    }

    handleStudentShare = async () => {

        let data = {
            shared_program: this.state.uri,
            type: 'shared',
            shared_to: this.state.usernameBuffer,
            user_fn: this.context.AuthInstance.fn,
            user_ln: this.context.AuthInstance.ln,
        };
        
        this.sharingInProgressElement.current.handleOpenStay("green", "Sharing...");
        try {
            for (let i = 0; i < this.context.AuthInstance.students.length; i++) {
                data['shared_to'] = this.context.AuthInstance.students[i];
                await this.context.AuthInstance.createSharingProgram(data);
            }
            this.sharingInProgressElement.current.handleClose();

            this.shareAttemptElement.current.handleOpen("SUCCESS");
        } catch {
            this.sharingInProgressElement.current.handleClose();
            this.shareAttemptElement.current.handleOpen("FAILURE");
        }
    }


    handleSubAssess = () => {

        let temp_fn = '_';
        let temp_ln = '_';

        if (this.context.AuthInstance.profile_access === true) {
            temp_fn = this.context.AuthInstance.fn;
            temp_ln = this.context.AuthInstance.ln;
        }

        const data = {
            copied_program: this.state.uri,
            type: 'copied',
            shared_to: this.context.AuthInstance.teacher_name,
            user_fn: temp_fn,
            user_ln: temp_ln,
        };

        this.context.AuthInstance.createSharingProgram(data).then(res => {
            this.shareAttemptElement.current.handleOpen("SUCCESS");
        }).catch((err) => {
            this.shareAttemptElement.current.handleOpen("FAILURE");
        });
    }



    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display - The Modal: Header, Button, "pre" tagged Text
        return (
            <div>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper}>
                        <Grid 
                            container 
                            spacing={2}
                            direction="row"
                        >
                            <Grid item xs={6}>
                                <Typography className={classes.text}
                                    style={{fontWeight: 'bold', fontSize: '25px'}}
                                >
                                    Share Program
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    className={classes.text}
                                    style={{fontSize: '25px'}}
                                >
                                    {this.state.progName}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.text}
                                    style={{fontWeight: 'bold', fontSize: '25px'}}
                                >
                                    Share with
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>                                
                                <FormControl fullWidth className={classes.form}>
                                    <Input className={classes.inputField}
                                    id="standard-adornment-pn"
                                    aria-describedby="standard-pn-helper-text"
                                    inputProps={{
                                        'aria-label': 'pn',
                                    }}
                                        onChange = {this.handleTargetNameUpdate}
                                    />
                                    <FormHelperText 
                                        className={classes.inputHelper}
                                        style={{fontWeight: 'bold', fontSize: '20px'}} 
                                        id="standard-pn-helper-text"
                                    >
                                        Username
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.text}
                                    style={{fontWeight: 'bold', fontSize: '25px'}}
                                >
                                    Submit
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    className={classes.actionButton}
                                    onClick={this.handleSubShare}
                                >
                                    Share Program
                                </Button>
                            </Grid>
                            {this.state.account_type === 'teacher' && (
                                <Grid item xs={6}>
                                    <Button
                                        className={classes.actionButton}
                                        onClick={this.handleStudentShare}
                                    >
                                        Share with All Students
                                    </Button>
                                </Grid>
                            )}
                            {this.state.account_type === 'student' && (
                                <Grid item xs={6}>
                                    <Typography className={classes.text}
                                        style={{fontWeight: 'bold', fontSize: '25px'}}
                                    >
                                        Submit
                                    </Typography>
                                </Grid>
                            )}
                            {this.state.account_type === 'student' && (
                                <Grid item xs={6}>
                                    <Button
                                        className={classes.actionButton}
                                        onClick={this.handleSubAssess}
                                    >
                                        Submit to Teacher
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    className={classes.actionButton}
                                    onClick={this.handleClose}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
                </Modal>
                <ShareProgramSent
                    ref={this.shareAttemptElement}
                />                    
                <GeneralMessage
                    ref = {this.sharingInProgressElement}
                    onClose = {() => void 0}
                />
            </div>
        );
    }

}
export default withStyles(styles)(ShareProgram);
