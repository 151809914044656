// Parent Component of:
// --- ProgramGUIModal

// Child Component of: 
// --- Program

// Import React and Material-UI Modules
import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/styles/withStyles";

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import ShareIcon from '@material-ui/icons/Share';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

// Import Context to Access Auth/User Information
import { AuthContext } from "../context/auth";

// Import Custom Components
import ProgramGUIModal from "./modals/ProgramGUIModal";
import ProgramGUIDisplay from "./modals/ProgramGUIDisplay";
import ShareProgram from './modals/ShareProgram';

import BaseBlock from "./blocks/BaseBlock";

import NameChange from "./drawers/NameChange";
import DeleteProgram from './drawers/DeleteProgram';
import SaveProgram from './drawers/SaveProgram';
import MarkGraded from './drawers/MarkGraded';

import SavedProgram from './drawers/SavedProgram';
import LXSettingsModal from './modals/LXSettingsModal';
import LDTSettingsModal from './modals/LDTSettingsModal';

import GeneralMessage from "./drawers/GeneralMessage"

import { webserial_disconnect } from '../webserial/webserial'


const IMU_ERROR_STR = "Vision Positioning System Not Active!\nYou may need to move to a flying area with more light or a more optimal flying surface.";
const IMU_ERROR_CASE = "error No valid imu";
const WAIT_FAIL_CASE = "Communication Failure";
const WAIT_FAIL_STR = "Communication Issue Between The Drone and Controller.";
const TT_VERSION_ISSUE = "RMTT Module Not Connected"

// Material-UI CSS-type Style Specifications
const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: "white",
      backgroundSize: "cover",
      backgroundPosition: "0 400px",
      justify: 'center',
      direction: 'row',
      //paddingBottom: 200,
    },
    paper: {
        border: 0,
        elevation: 0,
        direction: "column",
        justify: 'center',
        maxHeight: '800',
        overflow: "auto",
        alignItems: 'flex-start',
        margin: theme.spacing(2),
        backgroundColor: "white", //theme.palette.primary["light"],
        color: theme.palette.primary["contrastText"],
    },
    appBar: {
        position: "relative",
        boxShadow: "none",
        backgroundColor: theme.palette.primary["light"],//"white"
        color: "white",
        height: window.programBarOffset,
        borderLeft: `1px solid ${theme.palette.grey["100"]}`,
        borderRight: `1px solid ${theme.palette.grey["100"]}`,
        minWidth: 636,
    },
    inline: {
        display: "inline"
    },
    flex: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center"
        }
    },
    grow: {
        flexGrow: 1,
    },
    actionButton: {
        justify: "right",
        color: "white",
        backgroundColor: theme.palette.primary["light"],
        margin: theme.spacing(0),
        marginTop: theme.spacing(2),
        //margin: theme.spacing(2),
    },
    nameButton: {
        justify: "left",
        color: "white",
        backgroundColor: theme.palette.primary["light"],
        margin: theme.spacing(0),
    },
    ldtName: {
        //alignItems: 'center',
        //justifyContent: 'center',
        color: "white",
        backgroundColor: theme.palette.primary["light"],
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    lxName: {
        //alignItems: 'center',
        //justifyContent: 'center',
        color: "white",
        backgroundColor: theme.palette.primary["light"],
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
});

// Setup Messages - Passed to BaseBlock Based on Hardware Selection
const setupMessages = {
    LocoDrone : ['Connection and Calibration...', 'Please Hold The Controller Flat and Level'],
    LocoArm : ['Connecting...'],
    LocoXtreme : ['Connecting to Dongle and Scanning for Robots'],
    LocoDroneT : ['Connecting to Device and Scanning for Drones'],
    LocoDroneTT : ['Connecting to Device and Scanning for Drones'],
}

const startMessageColor = '#009788';

// Starting and Ending Program Code - Passed to BaseBlock Based on Hardware Selection
const templateCode = {
    LocoDrone : {
        start: [
            '# Module Imports',
            'import LocoDrone',
            'import time',
            '',
            '# Create LocoDrone Class Instance',
            'loco_drone = LocoDrone.LocoDrone()',
            '# Connect to the Controller',
            'loco_drone.connect()',
            '',
            '# Calibrate the Controller',
            'loco_drone.controller_calibrate()',
            '',
            '# Calibrate the Drone',
            'loco_drone.drone_calibrate()',
            '',
            '# Set Mode',
            'loco_drone.set_mode(loco_drone.MODE_CONTROL)',
            ],
        end: [
            '',
            '# Disconnect from the Controller',
            'loco_drone.disconnect()',
        ],
    },
    LocoDroneT : {
        start1: [
            '# Module Imports',
            'import LocoDroneT',
            'import time',
            '',
            '# Create LocoDrone Class Instance',
            'loco_drone = LocoDroneT.LD()',
            '',
            '# Connect to Serial Device',
            'loco_drone.setup()',
            '',
            '# Scan for Drones',
            'drones = loco_drone.drone_scan()',
            '',
            '# Connect to Drone',
            ],
        startN: [
            'loco_drone.drone_connect(drones, ',
            ')',
        ],
        startB: [
            'batt = loco_drone.drone_get_data(loco_drone.DATA_BATTERY)',
            'if (batt <= 10):',
            '    loco_drone.throw_error("Low Battery")',
            'elif (batt <= 15):',
            '    print("b")',
            '    loco_drone.local_sleep(2000)',
            'temp = loco_drone.drone_get_data(loco_drone.DATA_TEMP)',
            'if (temp > 92):',
            '    loco_drone.throw_error("Overheated")',
        ],
        end: [
            '',
            '',
            '',
            '# Disconnect From Drone',        
            'loco_drone.drone_disconnect()',
            '',
            '',
            '# Disconnect from the USB Device',
            'loco_drone.close()',
        ],
        startC: [
            '# Module Imports',
            'import LocoDroneT',
            'import time',
            '',
            '# Create LocoDrone Class Instance',
            'loco_drone = LocoDroneT.LD()',
            '',
            '# Connect to Serial Device',
            'loco_drone.setup()',
            '',
            '# Scan for Drones',
            'drones = loco_drone.drone_scan()',
            'print("s", drones)',
            '',
            'd_name = input()',
            '',
            '# Connect to Drone',
            'state = loco_drone.drone_connect(drones, d_name)',
            'print("c", state)',
            '',
            'batt = loco_drone.drone_get_data(loco_drone.DATA_BATTERY)',
            'if (batt < 5):',
            '    loco_drone.throw_error("Low Battery")',
        ],
    },
    LocoDroneTT : {
        start1: [
            '# Module Imports',
            'import LocoDroneTT',
            'import time',
            '',
            '# Create LocoDrone Class Instance',
            'loco_drone = LocoDroneTT.LD()',
            '',
            '# Connect to Serial Device',
            'loco_drone.setup()',
            '',
            '# Scan for Drones',
            'drones = loco_drone.drone_scan()',
            '',
            '# Connect to Drone',
            ],
        startN: [
            'loco_drone.drone_connect(drones, ',
            ')',
        ],
        startB: [
            'batt = loco_drone.drone_get_data(loco_drone.DATA_BATTERY)',
            'if (batt <= 10):',
            '    loco_drone.throw_error("Low Battery")',
            'elif (batt <= 15):',
            '    print("b")',
            '    loco_drone.local_sleep(2000)',
            'temp = loco_drone.drone_get_data(loco_drone.DATA_TEMP)',
            'if (temp > 91):',
            '    loco_drone.throw_error("Overheated")',
        ],
        end: [
            '',
            '',
            '',
            '# Disconnect From Drone',        
            'loco_drone.drone_disconnect()',
            '',
            '',
            '# Disconnect from the USB Device',
            'loco_drone.close()',
        ],
        startC: [
            '# Module Imports',
            'import LocoDroneTT',
            'import time',
            '',
            '# Create LocoDrone Class Instance',
            'loco_drone = LocoDroneTT.LD()',
            '',
            '# Connect to Serial Device',
            'loco_drone.setup()',
            '',
            '# Scan for Drones',
            'drones = loco_drone.drone_scan()',
            'print("s", drones)',
            '',
            'd_name = input()',
            '',
            '# Connect to Drone',
            'state = loco_drone.drone_connect(drones, d_name)',
            'print("c", state)',
            '',
            'batt = loco_drone.drone_get_data(loco_drone.DATA_BATTERY)',
            'if (batt < 5):',
            '    loco_drone.throw_error("Low Battery")',
        ],
    },
    LocoArm : {
        start: [
            '# Module Import',
            'import LocoArm',
            '',
            '# Class Instance Creation',
            'loco_arm = LocoArm.LocoArm()',
            '', 
            '# Initiate Connection to the Microcontroller', 
            'loco_arm.connect()',
            ],
        end: [
            '', 
            '',         
            '', 
            '', 
            '# Close Connection to the Microcontroller', 
            'loco_arm.disconnect()'
        ], 
    },
    LocoXtreme : {
        start1C: [
            '# Module Imports',
            'from LocoXtreme import Connection',
            'from LocoXtreme import LocoXtreme',
            'from LocoXtreme import MotorDirection as MD',
            'from LocoXtreme import Data',
            'from LocoXtreme import WaitType as WT',
            'from LocoXtreme import Song',
            'from LocoXtreme import Note',
            'import time',
            '',
            '# Create Connection Instance',
            'connection = Connection()',
            '',
            '# USB Connection Setup',
            'connection.setup()',
            '',
            '# Scan for Robots',
            'robots = connection.scan(4000)',
            '',
            '# Get Named Robot',
        ], 
        startNC: [
            'robot = connection.get_robot(robots, ',
            ')',
        ],
        start2C: [
            '',
            '# Create LocoXtreme Object',
            'locoxtreme = LocoXtreme(robot)',
            '',
            '# Connect to LocoXtreme',
            'locoxtreme.connect()',
            '',
            '# Activate Motors',
            'locoxtreme.activate_motors()',
            '', 
            '# Enable Temperature Sensor',
            'locoxtreme.enable_sensor(Data.TEMPERATURE, 1)',
            'time.sleep(0.5)',
        ],
        start1 : [
            '# Module Imports',
            'from LocoXtreme import Connection',
            'from LocoXtreme import LocoXtreme',
            'from LocoXtreme import MotorDirection as MD',
            'from LocoXtreme import Data',
            'from LocoXtreme import WaitType as WT',
            'from LocoXtreme import Song',
            'from LocoXtreme import Note',
            'import time',
            '',
            '# Create Connection Instance',
            'connection = Connection()',
            '',
            '# USB Connection Setup',
            'connection.setup_reader()',
            //'connection.setup()',
            //'connection.ble_clear()',
            '',
            '# Scan for Robots',
			'robots = connection.scan(4000)',
            '',
			'# Get Named Robot',
        ],
        startN: [
            'locoxtreme = LocoXtreme(',
            ')',
        ],
        start2: [
            '',
            '# Connect to LocoXtreme',
            'locoxtreme.connect()',
            '',
            '# Activate Motors',
            'locoxtreme.activate_motors()',
            '', 
            '# Enable Temperature Sensor',
            'locoxtreme.enable_sensor(Data.TEMPERATURE, 1)',
            'time.sleep(0.5)',
        ],
        startC: [
            '# Module Imports',
            'from LocoXtreme import Connection',
            'from LocoXtreme import LocoXtreme',
            'from LocoXtreme import MotorDirection as MD',
            'from LocoXtreme import Data',
            'from LocoXtreme import WaitType as WT',
            'from LocoXtreme import Song',
            'from LocoXtreme import Note',
            'import time',
            '',
            '# Create Connection Instance',
            'connection = Connection()',
            '',
            '# USB Connection Setup',
            'connection.setup()',
            '',
            '# Scan for Robots',
            'robots = connection.scan(2000)',
            'print("s", robots)',
            '',
            'r_name = input()',
            '',
			'# Get Named Robot',
			'r_dict = connection.get_robot(robots, r_name)',
            '',
			'# Create LocoXtreme Object',
            'locoxtreme = LocoXtreme(r_dict)',
            '',
            '# Connect to LocoXtreme',
            'locoxtreme.connect()',
            '',
            '# Activate Motors',
            'locoxtreme.activate_motors()',
            '', 
            '# Enable Temperature Sensor',
            'locoxtreme.enable_sensor(Data.TEMPERATURE, 1)',
            'time.sleep(0.5)',
        ],
        end : [
            '',
            '# Deactivate Motors',
            'locoxtreme.deactivate_motors()',
            '',
            '# Disconnect From LocoXtreme',
            'locoxtreme.disconnect()',
        ],
    }
}

var lxconvname = 'lr dc:00'
var ldtconvname = 'TELLO-000000'

const LDT_BUTTON_OPTS = ["Select Drone", "Change Drone"]
const LX_BUTTON_OPTS = ["Select Robot", "Change Robot"]


// Variables for Wrappers and IDs to Allow Manual Complete Shutdown of Any Running Skulpt
var oldSetInterval;
var oldSetTimeout;
var saveUncaughtException = window.Sk.uncaughtException;
var intervalFuncVars = [];
var timeoutFuncVars = [];

// Component Class - ProgramCoding
class ProgramGUI extends React.Component {
  
    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);

        // Passed Below as 'ref' to ProgramGUIModal Child Component, Giving Parent Access
        this.blockAddElement = React.createRef();

        // Passed Below as 'ref' to ProgramGUIDisplay Child Component, Giving Parent Access
        this.progDisplayElement = React.createRef();

        //
        this.nameChangeElement = React.createRef();

        //
        this.deleteProgramElement = React.createRef();

        //
        this.saveProgramElement = React.createRef();

        //
        this.shareProgramElement = React.createRef();

        //
        this.savedProgramElement = React.createRef();

        //
        this.markGradedElement = React.createRef();

        //
        this.LXSelectionElement = React.createRef();

        //
        this.LXConversionElement = React.createRef();

        //
        this.LDTSelectionElement = React.createRef();

        //
        this.LDTConversionElement = React.createRef();
        
        //
        this.programMessageElement = React.createRef();

        //
        this.lxSettingsElement = React.createRef();

        //
        this.currentCount = 0;

        // Wrappers for Skulpt Quitting
        oldSetInterval = window.setInterval;
        oldSetTimeout = window.setTimeout;
        window.Sk.uncaughtException = function (e) {
            //var msg = e.toString();
            this.stopit();
            this.restoreAsync();
            saveUncaughtException(e);
        }

        this.autoSaveTimerID = null;
        this.canAutoSave = false;
        this.autoSavePeriod = 120000;//300000; // ms, 5 mins
        //this.localTimeoutsID = null;

    }

    // For Use of AuthContext
    static contextType = AuthContext;

    // Stops any asynchronous functions still running
    stopit = async () => {
        try {
            for (var i = 0; i < intervalFuncVars.length; i++) {
                window.clearInterval (intervalFuncVars[i]);
            }
            for (i = 0; i < timeoutFuncVars.length; i++) {
                window.clearTimeout (timeoutFuncVars[i]);
            }
        } catch {}
        intervalFuncVars = [];
        timeoutFuncVars = [];
    }    

    // Restore setInterval/setTimeout to Original
    restoreAsync = () => {
        try {
            window.setInterval = setInterval = oldSetInterval;
            window.setTimeout = setTimeout = oldSetTimeout;
        } catch {} //
    }



    // Class State
    state = {
        cardWidth: 515,//600
        buffer: "",
        convBuffer: "",
        isLinked: this.props.linkedState,
        activeStep: this.props.activeStep,
        fileName: '',
        isRunning: false,
        progDispOpen: false,
        progOutputMsg: "",
        isLoading: false,
        blockOptions: {
            LocoDrone: 
            [
                "itakeoff.svg", "iland.svg", "ihover.svg",
                "ijoystick.svg", "itilt.svg", "ivertical.svg",
                "iroll.svg", "ipitch.svg", "irp.svg", 
                "iloop.svg", "iflip.svg", "itrim.svg",                       
            ],
            LocoDroneT: 
            [
                "itakeoff.svg", "iland.svg", "ihover.svg",
                "iloop.svg", "iflip.svg",  "irc.svg",
                "imove.svg", "igo.svg",  "iyaw.svg",
            ],
            LocoDroneTT: 
            [
                "itakeoff.svg", "iland.svg", "ihover.svg",
                "iloop.svg", "iflip.svg",  "irc.svg",
                "imove.svg", "igo.svg",  "iyaw.svg",
                "irgb.svg", "imatrix.svg",
            ],
            LocoXtreme:
            [
                "idrive.svg", "ilights_rgb.svg", "iloop.svg",
                "imove.svg", "inote.svg", "irotate.svg",
                "isong.svg", "ipause.svg"
            ],
        },
        blocksList: [],
        selection: {},
        isSaved: true,
        nameStyle: "normal",
        loadComplete: false,
        blocksToLoad: 0,
        blocksLoaded: 0,
        shared: false,
        shareState: 'accepted',
        idTracking: 0,
        lxrobots: '',
        lxwaiting: true,
        lxselected: this.props.progDetails.lxselected || '',
        lxselectedDetails: this.props.progDetails.lxselectedDetails || {},
        lxSelectMsg: this.props.progDetails.lxmsg || LX_BUTTON_OPTS[0],
        lxSet: this.props.progDetails.lxSet || false,
        lxFromPlay: false,
        lxFromConv: false,
        ldtdrones: '',
        ldtwaiting: true,
        ldtselected: this.props.progDetails.ldtselected || '',
        ldtSelectMsg: this.props.progDetails.ldtmsg || LDT_BUTTON_OPTS[0],
        ldtSet: this.props.progDetails.ldtset || false,
        ldtFromPlay: false,
        ldtFromConv: false,
        isAutoSaving: false,
    }

    componentDidMount() {
        
        let nameExtension = ""
        //let selection = this.props.progDetails.selection;
        let selection = Object.assign({}, this.props.progDetails.selection);
        if (this.props.progDetails.type === "new") {
            this.setState( {idTracking: 0} );
            // Empty blockslist
            this.props.progDetails.selection.instructions = "[]";
            this.context.AuthInstance.createProgram(this.props.progDetails.selection.name, 
                'editor', 
                this.props.progDetails.selection.language, 
                this.props.progDetails.selection.instructions,
                this.props.progDetails.hardware,
                )
            .then(res => {
                // Get resource_uri for saving
                selection.resource_uri = res.resource_uri;
                
                this.setState( {loadComplete: true} );
            }); 
        } else {
            if ('shared' in this.props.progDetails) {
                this.setState( {shared: true, shareState: this.context.AuthInstance.sharedProgramList[this.props.progDetails.sharedIndex].state} );
                if (this.props.progDetails.selection.fname !== '') {
                    if (this.props.progDetails.selection.fname === '_') {
                        nameExtension = ': ' + this.context.AuthInstance.sharedProgramList[this.props.progDetails.sharedIndex].shared_from
                    } else {
                        nameExtension = ': ' + this.props.progDetails.selection.fname + ' ' + this.props.progDetails.selection.lname
                    }
                }
            }
            this.convertLoadedProgram(selection);
            setTimeout(this.updateConvertBuffer, 100); // new
        }
        this.setState( {selection: selection} );
        this.setState( {fileName: this.props.progDetails.selection.name + nameExtension} );
        if (this.state.isLinked === false) {
            this.startAutoSave();
        }
    }

    writerReset = () => {
        if (window.serialwriter !== null) {
            try {
                window.serialwriter.releaseLock();
            } catch {}
            window.serialwriter = null;
        }
    }

    componentWillUnmount() {
        this.writerReset();
        this.stopAutoSave();
        //clearTimeout(this.localTimeoutsID)
        //try {
        //    this.stopit()
        //} catch {}//
    }


    startAutoSave = () => {
        if (this.state.isLinked === false) {            
            if (this.state.shared === false) {
                if (!this.props.progDetails.hasOwnProperty('notSaved')) {
                    this.canAutoSave = true;
                    this.autoSaveTimerID = setTimeout(this.autoSave, this.autoSavePeriod);
                }
            }
        }
    }

    autoSave = () => {    
        if (this.state.isSaved === false) {

            let instructions = this.convertBlocksToSave();
            if (instructions === "") {
                instructions = "\n";
            }
            const data = {
                name: this.state.selection.name,
                instructions: instructions,
                revision: this.state.selection.revision,
                deleted: this.state.selection.deleted,
            }
            
            this.setState({isAutoSaving: true});
            this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(() => {

                this.setState({
                    isAutoSaving: false,
                    isSaved: true,            
                    nameStyle: "normal",
                });

                let tempSelection = this.state.selection;
                tempSelection.revision = this.state.selection.revision + 1;
                this.setState( {selection: tempSelection} );
    
            });            
        }
        this.autoSaveTimerID = setTimeout(this.autoSave, this.autoSavePeriod);
    }

    stopAutoSave = async () => {
        try {
            clearTimeout(this.autoSaveTimerID);
        } catch {};
        this.canAutoSave = false;
    }

    
    // Function passed below to ProgramDisplay to let button click trigger program wrap-up
    stopRunning = async () =>
    {

        // Quit All timeouts and intervals in "window" using accumulated ID's
        await this.stopit();
        this.restoreAsync();

        if ((this.props.progDetails.hardware === 'LocoDroneT') ||
            (this.props.progDetails.hardware === 'LocoDroneTT')) {
            try {
                let packet = Uint8Array.from([76, 4, 0, 0, 0, 0]);
                //const writer = window.serialport.writable.getWriter();
                window.serialwriter = window.serialport.writable.getWriter();
                window.serialwriter.write(packet).then(() => {
                    try {
                        //writer.releaseLock();
                        let packetLand = Uint8Array.from([70, 1, 0]);
                        /*const writerLand = window.serialport.writable.getWriter();
                        writerLand.write(packetLand).then(() => {
                            writerLand.releaseLock();
                            return
                        });*/
                        
                        window.serialwriter.write(packetLand).then(() => {
                            //writer.releaseLock();
                            return
                        });
                    } catch {
                        return
                    }
                });
            } catch (err) {
            } finally {
                try {
                    webserial_disconnect()
                } catch (err) {}
            }
        } 
        
        try {
            if (this.props.progDetails.hardware === 'LocoXtreme') {
                let packet = Uint8Array.from([5, 1, 0]);
                const writer = window.serialport.writable.getWriter();
                writer.write(packet).then(() => {
                    writer.releaseLock();
                    return
                });
            }
        } catch (err) {
        }
        
        if (this.state.isLinked === false) {
            this.startAutoSave();
        }
    }

    stopEmergency = async () => {

        // Quit All timeouts and intervals in "window" using accumulated ID's
        this.stopit();
        this.restoreAsync();

        let packet = Uint8Array.from([101, 1, 1]);
        const writer = window.serialport.writable.getWriter();
        writer.write(packet).then(() => {
            try {
                writer.releaseLock();
            } catch {
            }
            
            if (this.state.isLinked === false) {
                this.startAutoSave();
            }
            return
        })

    }

    // Function called when Name button pressed
    handleNameBtn = () => {
        if (this.state.shared === false) {
            this.stopAutoSave();
            this.nameChangeElement.current.handleOpen();
        }
    }

    
    //
    nameChangeEvent = (event) => {
        
        if (event.state === "OK") {
            
            this.nameChangeElement.current.handleClose();
            if (/^[a-zA-Z0-9_]+$/.test(event.name)) {                
                if (event.name !== this.state.selection.name) {
                    try {
                        let tempSelection = this.state.selection;
                        tempSelection.name = event.name;

                        this.context.AuthInstance.createProgram(event.name, 
                                                                'editor', 
                                                                this.state.selection.language, 
                                                                this.state.selection.instructions,
                                                                this.props.progDetails.hardware,
                                                                )
                        .then((createRes) => {
                            tempSelection.resource_uri = createRes.resource_uri;
                            tempSelection.revision = 1;
                            this.setState( {selection: tempSelection} );
                            this.setState( {fileName: event.name});
    
                            if (this.state.shared === true) {
                                this.setState( {shared: false} );
                            }
                        });

                    } catch {
                        alert("Could not create program");
                    }
                }                
            } else {
                setTimeout(this.reOpenNameChange, 250);
            }
        } else {
            this.nameChangeElement.current.handleClose();
        }
        this.startAutoSave();
    }


    reOpenNameChange = () => {
        this.nameChangeElement.current.handleOpen();
    }


    checkGradeProgram = (event) => {

        if (event['resp'] === "YES") {
            try {
                //
                const uri = this.context.AuthInstance.sharedProgramList[this.props.progDetails.sharedIndex].resource_uri;
                const stateData = 'graded';
                const gradeData = event['grade'];
                return this.context.AuthInstance.saveSharedProgram(uri, stateData, gradeData).then(() => {
                    return this.context.AuthInstance.getSharedProgramList().then(() => {
                        this.setState( {shareState: stateData} );
                        this.markGradedElement.current.handleClose();
                    });
                });
                        
            } catch {
                alert("Could not grade program");
            }

        }
        this.markGradedElement.current.handleClose();
    }


    checkDeleteProgram = (event) => {

        if (event === "OK") {

            
            try {
                //
                const data = {
                    deleted: true,
                    revision: this.state.selection.revision,
                };
                this.context.AuthInstance.deleteProgram(this.state.selection.resource_uri, data).then(res => {
                    // Refresh For ProgramList
                    this.context.AuthInstance.getProgramList().then(res => {
                        if (this.state.activeStep !== 3) {
                            // Send Information to Parent Program component, through passed down "onCloseDeleteProgram" function
                            var closeDetails = {progAction: "KeepProg"};
                            this.props.onCloseDeleteProgram(closeDetails)
                        } else {
                            this.props.handleSplitClose('coding');
                        }
                    });
                });              
            } catch {
                alert("Could not delete program");
            }
        }
        this.deleteProgramElement.current.handleClose();
    }

    checkSaveProgram = (event) => {
        
        if (event === "OK") {

            try {
                let instructions = this.convertBlocksToSave();
                if (instructions === "") {
                    instructions = "\n";
                }
         
                const data = {
                    name: this.state.selection.name,
                    instructions: instructions,
                    revision: this.state.selection.revision,
                    deleted: this.state.selection.deleted,
                }
                                
                this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(res => {
                    // Refresh For ProgramList
                    this.context.AuthInstance.getProgramList().then(res => {

                        let tempSelection = this.state.selection;
                        tempSelection.revision = this.state.selection.revision + 1;
                        this.setState( {selection: tempSelection} );

                        if (this.state.activeStep !== 3) {
                            // Send Information to Parent Program component, through passed down "onCloseDeleteProgram" function
                            var closeDetails = {progAction: "KeepProg"};
                            this.props.onCloseDeleteProgram(closeDetails)
                        } else {
                            this.props.handleSplitClose('coding');
                        }
                    });
                });
            } catch {
                alert("Could not save program");
            }
        } else {

            // Refresh For ProgramList
            this.context.AuthInstance.getProgramList().then(res => {
                if (this.state.activeStep !== 3) {
                    // Send Information to Parent Program component, through passed down "onCloseDeleteProgram" function
                    var closeDetails = {progAction: "KeepProg"};
                    this.props.onCloseDeleteProgram(closeDetails)
                } else {
                    this.props.handleSplitClose('coding');
                }
            });
        }
        this.saveProgramElement.current.handleClose();
    }

    updateConvertBuffer = () => {
        let progText = "";
        if (this.props.progDetails.hardware !== 'LocoXtreme') {
            if ((this.props.progDetails.hardware !== 'LocoDroneT') && 
                (this.props.progDetails.hardware !== 'LocoDroneTT')) {
                progText += templateCode[this.props.progDetails.hardware].start.join('\n');
                progText += '\n';
            } else {
                progText += templateCode[this.props.progDetails.hardware].start1.join('\n') + '\n';
                progText += templateCode[this.props.progDetails.hardware].startN[0] + 
                            "\"" + ldtconvname + "\"" + 
                            templateCode[this.props.progDetails.hardware].startN[1] + '\n'
                progText += '\n';

            }
        } else {
            progText += templateCode[this.props.progDetails.hardware].start1C.join('\n') + '\n';
            progText += templateCode[this.props.progDetails.hardware].startNC[0] + 
                        "\"" + lxconvname + "\"" + 
                        templateCode[this.props.progDetails.hardware].startNC[1] + '\n'
            progText += templateCode[this.props.progDetails.hardware].start2C.join('\n');
            progText += '\n';
        }
        
        if (this.state.blocksList.length > 0) {
            for (let y = 0; y < this.state.blocksList.length; y++) {

                progText += '\n';

                let indentStr = ' '.repeat(this.state.blocksList[y].indentation * 4);
                let tempArray = [];
                for (let j = 0; j < this.state.blocksList[y].code.length; j++) {
                    //this.state.blocksList[y].code[j] = indentStr + this.state.blocksList[y].code[j];
                    tempArray.push(indentStr + this.state.blocksList[y].code[j]);
                }

                if ((y + 1) < this.state.blocksList.length) {
                    if (this.state.blocksList[y].name === 'iloop') {
                        if (this.state.blocksList[y + 1].name === 'iloopend') {
                            indentStr += ' '.repeat(4);
                            tempArray.push(indentStr + 'pass');
                        }
                    }
                }

                //progText += this.state.blocksList[y].code.join('\n');
                progText += tempArray.join('\n');
                if (this.state.blocksList[y].name !== 'iloopend') {
                    progText += '\n';
                }
            }
        }
        progText += templateCode[this.props.progDetails.hardware].end.join('\n'); 
        this.setState( {convBuffer: progText} );
        if (this.state.isLinked === true) {
            this.props.handleUpdateLinked(progText);
        }
    }

    // Function called when Play button pressed
    handlePlayBtn = () => {
        this.writerReset();
        let hasSaveCase = false;
        if (this.state.isLinked === false) {            
            if (this.state.shared === false) {
                if (!this.props.progDetails.hasOwnProperty('notSaved')) {                    
                    if (this.state.isSaved === false) {
                        let instructions = this.convertBlocksToSave();
                        if (instructions === "") {
                            instructions = "\n";
                        }
                        const data = {
                            name: this.state.selection.name,
                            instructions: instructions,
                            revision: this.state.selection.revision,
                            deleted: this.state.selection.deleted,
                        }
                        
                        this.setState({isAutoSaving: true});
                        hasSaveCase = true
                        this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(() => {
                            // 
                            //this.savedProgramElement.current.handleOpen();
        
                            this.setState({
                                isSaved: true,            
                                nameStyle: "normal",
                            });
    
                            let tempSelection = this.state.selection;
                            tempSelection.revision = this.state.selection.revision + 1;
                            this.setState( {selection: tempSelection} );

                            this.setState({isAutoSaving: false});
                            this.runPlayBtn()
                        });   
                    }
                }
            }
        }
        if (hasSaveCase === false) {
            this.runPlayBtn()
        }

    }


    runPlayBtn = async () => {
        this.stopAutoSave();
        
        if (this.props.progDetails.hardware === 'LocoXtreme') {
            
            if (this.state.lxSet === false) {
                
                this.LXSelectionElement.current.handleOpen("SELECT");
                this.setState( {lxFromPlay: true} );
                return
            }

            this.setState( {lxrobots: ''});
        } else if ((this.props.progDetails.hardware === 'LocoDroneT') ||
                    (this.props.progDetails.hardware === 'LocoDroneTT')) {

            if (this.state.ldtSet === false) {
                this.writerReset();
                this.LDTSelectionElement.current.handleOpen("SELECT");
                this.setState( {ldtFromPlay: true} );
                return
            }


            this.setState( {ldtdrones: ''});
        }

        
        // Probably not used, run if external library has dependencies
        function loadDependency(filename) {
            return new Promise(function(resolve, reject) {
              var scriptElement = document.createElement("script");
              scriptElement.type = "text/javascript";
              scriptElement.src = filename;
              scriptElement.onload = function() {
                resolve(true);
              }
              scriptElement.onerror = function() {
                resolve(false);
              }
              document.body.appendChild(scriptElement);
            });
        }
        
        // Add Libraries Based on User Access
        let externalLibs = {};
        if (this.context.AuthInstance.userAccess.includes("LocoDrone")) {
            externalLibs['src/builtin/LocoDrone.js'] = '/libraries/LocoDrone/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoDroneT")) {
            externalLibs['src/builtin/LocoDroneT.js'] = '/libraries/LocoDroneT/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoDroneTT")) {
            externalLibs['src/builtin/LocoDroneTT.js'] = '/libraries/LocoDroneTT/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoArm")) {
            externalLibs['src/builtin/LocoArm.js'] = '/libraries/LocoArm/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoXtreme")) {
            externalLibs['src/builtin/LocoXtreme.js'] = '/libraries/LocoXtreme/__init__.js';
        }
        
        // Configure Sk (Skulpt)
        window.Sk.configure({
            output:this.outf,
            read: function(x) {
                if (window.Sk.builtinFiles["files"][x] !== undefined)
                    return window.Sk.builtinFiles["files"][x];
                        
                    if(x in externalLibs){
                        let extLib = externalLibs[x];                    
                    
                    if (typeof extLib === "string") {
                        var fileToLoad = extLib;
                        var dependencies = [];
                    } else {
                        var fileToLoad = extLib.path;
                        var dependencies = extLib.dependencies;
                    }
                    if (dependencies.length > 0) {
                        return window.Sk.misceval.promiseToSuspension(
                            // load the dependencies in order
                            dependencies
                                .reduce(function (acc, filename) {
                                return acc.then(function() {
                                    return loadDependency(filename);
                                });
                                }, Promise.resolve())
                                .then(res => fetch(fileToLoad))
                                .then(res => res.text())
                        );
                    } else {
                        return window.Sk.misceval.promiseToSuspension(fetch(fileToLoad).then(res => res.text()));
                    }                
                }
            },
            __future__: window.Sk.python3
        });

        // Create Python Code from BlocksList
        this.createGUIProgram();

        this.setState( {isRunning: true} );
        
        // Return once Promise has resolved
        return Promise.resolve()
            .then(() => {
                // Clear Output Text Storage
                this.setState( {progOutputMsg: ""} );

                // Call Child Component ProgramDisplay's handleOpen() to Display Modal
                this.progDisplayElement.current.handleOpen(setupMessages[this.props.progDetails.hardware].join('\n'), startMessageColor);
                // Allow blocks to be displayed in Modal
                this.progDisplayElement.current.updateCanDisplay(true);
            })
            .then(() => {
                
                var self = this;
                
                // Wrapper for Complete Skulpt-Quitting Behavior
                window.setInterval = setInterval = function (f,t) {
                    var handle = 
                    oldSetInterval (function () {
                        try {
                            f()
                        } catch (err) {
                            // Report error and abort
                            self.restoreAsync();
                            self.outf(err.toString());
                            self.stopit();
                        }
                    },t);
                    intervalFuncVars.push(handle);
                }.bind(this)
        
                // Wrapper for Complete Skulpt-Quitting Behavior
                window.setTimeout = setTimeout = function (f,t) {
                    var handle = 
                    oldSetTimeout (function () {
                        try {
                            f();
                        } catch (err) {
                            // Report error and abort
                            console.log(err.toString())
                            self.restoreAsync();
                            self.outf(err.toString());
                            self.stopit();
                        }
                    },t);
                    timeoutFuncVars.push(handle);
                }.bind(this)

                // Run Skulpt on state.buffer, populated from ACE
                return window.Sk.misceval.asyncToPromise(() => {
                    return window.Sk.importMainWithBody('<stdin>', false, this.state.buffer, true);
                });

            }).catch((err) => {                
                this.setState( {isRunning: false} );
                // Try to generate error message
                try {
                    //console.log(err);
                    // Assemble error message to send to Modal
                    let errText = err.args.v[0].v;
                    if (err.traceback.length > 0) {
                        errText = errText + ' on line ' + err.traceback[0].lineno;
                        if (err.traceback[0].colno) {
                            errText = errText + ' column ' + err.traceback[0].colno;
                        }
                    }
                    //console.log(errText)
                    // Use referneced to ProgramDisplay to send error message and modal behavior information
                    //let textObj = {text: errText, bodyColor: "red", stopClose: "Close"};

                    // Need to try to disconnect in case program connected and didn't disconnect, so next run can have clean connection
                    try {
                        if (this.props.progDetails.hardware === 'LocoXtreme') {
                            
                            try {
                                let packet = Uint8Array.from([5, 1, 0]);
                                const writer = window.serialport.writable.getWriter();
                                writer.write(packet).then(() => {
                                    writer.releaseLock();
                                    return
                                });
                            } catch (err) {
                            } finally {
                                webserial_disconnect()
                            }    
                            if (err.nativeError === "Could Not Connect To The Robot. Please Try Running the Program Again.") {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", "Could Not Connect To Robot", 3000)
                            }
                        } else if (this.props.progDetails.hardware === 'LocoDroneT') {
                            
                            if (err.nativeError === "Could Not Connect To Drone") {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", "Could Not Connect To Drone", 3000)
                            } else if (err.nativeError === "Low Battery! Charge Your Drone.") {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", "Low Battery, Please Charge Drone", 3000)
                            } else if (err.nativeError === "Overheated") {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", "Overheated, Please Cool Drone", 3000)
                            } else if (err.args.v[0].v === IMU_ERROR_CASE) {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", IMU_ERROR_STR, 13000)
                            }

                            try {
                                let packet = Uint8Array.from([70, 1, 0]);
                                const writer = window.serialport.writable.getWriter();
                                writer.write(packet).then(() => {
                                    writer.releaseLock();
                                    return
                                });
                            } catch (err) {
                            } finally {
                                webserial_disconnect()
                            }
                        } else if (this.props.progDetails.hardware === 'LocoDroneTT') {
                            
                            if (err.nativeError === "Could Not Connect To Drone") {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", "Could Not Connect To Drone", 3000)
                            } else if (err.nativeError === "Low Battery! Charge Your Drone.") {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", "Low Battery, Please Charge Drone", 3000)
                            } else if (err.nativeError === "Overheated") {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", "Overheated, Please Cool Drone", 3000)
                            } else if (err.args.v[0].v === IMU_ERROR_CASE) {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", IMU_ERROR_STR, 13000)
                            } else if (err.args.v[0].v === WAIT_FAIL_CASE) {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", WAIT_FAIL_STR, 8000)
                            } else if (err.args.v[0].v === TT_VERSION_ISSUE) {
                                // open drawer
                                this.programMessageElement.current.handleOpen("red", TT_VERSION_ISSUE, 6000)
                            }
                            

                            try {
                                let packet = Uint8Array.from([70, 1, 0]);
                                const writer = window.serialport.writable.getWriter();
                                writer.write(packet).then(() => {
                                    writer.releaseLock();
                                    return
                                });
                            } catch (err) {
                            } finally {
                                webserial_disconnect()
                            }
                        } else {
                            window.port.disconnect();
                        }
                    } catch (err) {
                    }
                } catch(err) {
                    // TODO: make separate popup - might not be needed anymore
                    console.log(err);
                }
            }).then(() => {
                // Handle program run wrap-up
                window.Sk.hardInterrupt = false;
                this.setState( {isRunning: false} );
                // Change Stop/Close Button of Modal
                try {
                    this.progDisplayElement.current.setClose("Close");
                    this.progDisplayElement.current.updateCanDisplay(false);
                    //this.progDisplayElement.current.handleClose();
                } catch (err) {}
                // Restore setTimeout and setInterval behaviors
                this.restoreAsync();

                // reboot drone
                if (this.props.progDetails.hardware === 'LocoDroneTT') {
                    try {
                        let packet = Uint8Array.from([97, 1, 0]);
                        const writer = window.serialport.writable.getWriter();
                        writer.write(packet).then(() => {
                            writer.releaseLock();
                            return
                        });
                    } catch (err) {
                    }
                }
                
                if (this.state.isLinked === false) {
                    this.startAutoSave();
                }
            });
    }


    // Function called by Sk (skulpt) when program is running
    outf = (text) => { 
        // Only update if program is running
        if (this.state.isRunning === true) {
            
            // Check what "block" am on, tell modal what block information to display
            if (text[0] === 'x') {
                let indx = Number(text.slice(1, text.length));
                let currentBlock = null;
                if (indx > 0) {
                    currentBlock = this.state.blocksList[indx - 1];
                }
                
                this.progDisplayElement.current.updateDisplay(indx, currentBlock);
            } else if (text[0] === 's') {
                if (this.props.progDetails.hardware === 'LocoXtreme') {
                    this.setState( {lxrobots: text.substring(2, text.length)})
                } else if ((this.props.progDetails.hardware === 'LocoDroneT') ||
                           (this.props.progDetails.hardware === 'LocoDroneTT')) {
                    this.setState( {ldtdrones: text.substring(2, text.length)})
                }
            } else if (text[0] === 'b') {
                if ((this.props.progDetails.hardware === 'LocoDroneT') ||
                    (this.props.progDetails.hardware === 'LocoDroneTT')) {
                    this.progDisplayElement.current.handleUpdateMessage('The battery level is low. Charge your drone soon.', "orange")
                    //text.substring(2, text.length)
                }
            } else if (text === 'The battery level is low. Charge your drone soon.') {
                if ((this.props.progDetails.hardware === 'LocoDroneT') ||
                    (this.props.progDetails.hardware === 'LocoDroneTT')) {
                    this.progDisplayElement.current.handleUpdateMessage('The battery level is low. Charge your drone soon.', "orange")
                    //text.substring(2, text.length)
                }
            } else if (text === "RMTT Module Not Connected") {
                let indx = Number(text.slice(1, text.length));
                let currentBlock = null;
                this.progDisplayElement.current.updateDisplay(indx, currentBlock);
                this.progDisplayElement.current.handleUpdateMessage(text, "orange")                    
            } else if (text.slice(0, 10) === window.ldt_esp_ver_code) { 
                // Firmware Version has been read, update state if applicable
                if (window.ldt_esp_ver >= 4) {
                    this.progDisplayElement.current.handleLDTExtra()
                }
            } 
        }
    }

    programInput = () => {
        /*
        if (this.props.progDetails.hardware === 'LocoXtreme') {
            this.LXSelectionElement.current.handleOpen("GUI", this.state.lxrobots);
    
            return new Promise( (resolve,reject) => {
    
                var f = () => {
    
                    if (this.state.lxwaiting === true) {
                        window.Sk.setTimeout(f, 100);
                    } else {
                        resolve(window.Sk.ffi.remapToPy(this.state.lxselected));
                    }
                }
    
                this.setState( {lxwaiting: true} );
                window.Sk.setTimeout(f, 100);
            })
        } else if (this.props.progDetails.hardware === 'LocoDroneT') {
            this.LDTSelectionElement.current.handleOpen("GUI", this.state.ldtdrones);
    
            return new Promise( (resolve,reject) => {
    
                var f = () => {
    
                    if (this.state.ldtwaiting === true) {
                        window.Sk.setTimeout(f, 100);
                    } else {
                        resolve(window.Sk.ffi.remapToPy(this.state.ldtselected));
                    }
                }
    
                this.setState( {ldtwaiting: true} );
                window.Sk.setTimeout(f, 100);
            })
        }*/
    }

    LXSelection = (r_event) => {

        //this.setState( {lxselected: r_event, lxwaiting: false} );
        
        //if (r_event !== '') {
        if (Object.keys(r_event).length > 0) {
            
            let lxnowDetails = JSON.stringify(r_event)
            this.setState( {lxselected: r_event.name, lxselectedDetails: lxnowDetails, lxSelectMsg: LX_BUTTON_OPTS[1], lxSet: true} );

            if (this.state.lxFromPlay === true) {
                this.setState( {lxFromPlay: false} )
                setTimeout(this.handlePlayBtn, 100)
            }
            if (this.state.lxFromConv === true) {
                this.setState( {lxFromConv: false} )
                setTimeout(this.handleConvertBtn, 100)
            }
        } else {
            this.setState( {lxselected: "", lxselectedDetails: {}, lxSelectMsg: LX_BUTTON_OPTS[0], lxSet: false} );
        }
    }
    
    LDTSelection = (drone_name) => {
        
        //this.setState( {ldtselected: r_event, ldtwaiting: false} );
        if (drone_name !== '') {
            
            this.setState( {ldtselected: drone_name, ldtSelectMsg: LDT_BUTTON_OPTS[1], ldtSet: true} );

            if (this.state.ldtFromPlay === true) {
                this.setState( {ldtFromPlay: false} )
                setTimeout(this.handlePlayBtn, 100)
            }
            if (this.state.ldtFromConv === true) {
                this.setState( {ldtFromConv: false} )
                setTimeout(this.handleConvertBtn, 100)
            }

        }
    }

    // Function called when Play button pressed
    handleAddBtn = () => {        
        this.blockAddElement.current.handleOpen();
    }

    setNotSaved = () => {
        this.setState({
            isSaved: false,            
            nameStyle: "italic",
        });
        setTimeout(this.updateConvertBuffer, 100);
    }

    // Used by Block Adding Modal to Provide Selection Information
    handleBlockAdded = (blockType) => {
        //let tempList = this.state.blocksList;
        let tempTracker = this.state.idTracking + 1;
        this.setState( {idTracking: tempTracker} );
        let tempList = JSON.parse(JSON.stringify(this.state.blocksList));
        tempList.push({
            id: tempTracker,
            name: blockType,
            code: [],
            control: {},
            index: tempList.length,
            indentation: 0
        });
        setTimeout(this.addLoopEnd, 5)
        this.setState( {blocksList: tempList} );
        this.setNotSaved();
    }

    addLoopEnd = () => {
        let tempList = JSON.parse(JSON.stringify(this.state.blocksList));
        // Special Case for For Loops, Add a Loop "End"
        if (tempList[tempList.length - 1].name == 'iloop') {
            let tempTracker = this.state.idTracking + 1;
            this.setState( {idTracking: tempTracker} );
            tempList.push({
                id: tempTracker,
                name: 'iloopend',
                code: [],
                control: {},
                index: tempList.length,
                indentation: 0
            });
        }
        this.setState( {blocksList: tempList} );
        this.setNotSaved();
    }

    // Logic for Finding Nearest Loop "Start" of Same Indentation When Deleting a Loop "End"
    findClosestLoopStart = (index) => {
        let myIndent = this.state.blocksList[index].indentation;
        let foundIndx = index;
        for (let i = index; i > -1; i--) {
            if (this.state.blocksList[i].name === 'iloop') {
                if (this.state.blocksList[i].indentation === myIndent) {
                    foundIndx = i;
                    break;
                }
            }
        }
        return foundIndx;        
    }

    // Logic for Finding Nearest Loop "End" of Same Indentation When Deleting a Loop "Start"
    findClosestLoopEnd = (index) => {
        let myIndent = this.state.blocksList[index].indentation;
        let foundIndx = index;
        for (let i = index; i < this.state.blocksList.length; i++) {
            if (this.state.blocksList[i].name === 'iloopend') {
                if (this.state.blocksList[i].indentation === myIndent) {
                    foundIndx = i;
                    break;
                }
            }
        }
        return foundIndx; 
    }

    // Used by BaseBlock X Button Press To Remove Respecitive Block from BlocksList
    handleDeleteBlock = (id) => {

        let index = 0;
        for (let m = 0; m < this.state.blocksList.length; m++) {
            if (this.state.blocksList[m].id === id) {
                index = m;
                break;
            }
        } 

        let tempList = this.state.blocksList;
        // Handle Special Cases for For Loops
        if (tempList[index].name === 'iloopend') {
            let matching = this.findClosestLoopStart(index);
            tempList.splice(index, 1);
            tempList.splice(matching, 1);
            this.applyIndents();
        } else if (tempList[index].name === 'iloop') {
            let matching = this.findClosestLoopEnd(index);
            tempList.splice(matching, 1);
            tempList.splice(index, 1);
            this.applyIndents();
        } else { // Non-Loop Block
            tempList.splice(index, 1);
        }        
        this.setState( {blocksList: tempList} );
        this.setNotSaved();
    }

    // Used By BaseBlock Up Arrow Button Press To Move Up Respective Block in BLocksList
    handleMoveBlockUp = (id) => {

        let index = 0;
        for (let m = 0; m < this.state.blocksList.length; m++) {
            if (this.state.blocksList[m].id === id) {
                index = m;
                break;
            }
        }        

        // Can Only Move Up When Not At Top
        if (index > 0) {
            // Handle For Loop Start/End Block Moves 
                // Prevent  An End With No Starts Before of Same Indetation
                // Prevent  A Start With No End After of Same Indetation
            if (this.state.blocksList[index].name === 'iloopend') {
                let num_starts_above = 0;
                for (let k = 0; k < index; k++) {
                    if (this.state.blocksList[k].name === 'iloop') {
                        num_starts_above++;
                    }
                }
                if (num_starts_above > 1) {
                    //let tempList = this.state.blocksList;
                    let tempList = JSON.parse(JSON.stringify(this.state.blocksList));
                    [tempList[index], tempList[index - 1]] = [tempList[index - 1], tempList[index]];
                    for (let m = 0; m < tempList.length; m++) {
                        tempList[m].index = m;
                    }
                    this.setState( {blocksList: tempList} );
                } else if (num_starts_above === 1) {
                    if (this.state.blocksList[index - 1].name !== 'iloop') {
                        //let tempList = this.state.blocksList;
                        let tempList = JSON.parse(JSON.stringify(this.state.blocksList));
                        [tempList[index], tempList[index - 1]] = [tempList[index - 1], tempList[index]];
                        for (let m = 0; m < tempList.length; m++) {
                            tempList[m].index = m;
                        }
                        this.setState( {blocksList: tempList} );
                    }
                }
            } else { // Non-Loop Case
                //let tempList = this.state.blocksList;
                let tempList = JSON.parse(JSON.stringify(this.state.blocksList));
                [tempList[index], tempList[index - 1]] = [tempList[index - 1], tempList[index]];
                for (let m = 0; m < tempList.length; m++) {
                    tempList[m].index = m;
                }
                
                this.setState( {blocksList: tempList} );
                
                
            }
            this.setNotSaved();
        }
        
        // Re-Calculate Indentation Per Block Based on Any For Loop Positions
        setTimeout(this.applyIndents, 15);//this.applyIndents();
    }

    // Used By BaseBlock Down Arrow Button Press To Move Down Respective Block in BLocksList
    handleMoveBlockDown = (id) => {

        let index = 0;
        for (let m = 0; m < this.state.blocksList.length; m++) {
            if (this.state.blocksList[m].id === id) {
                index = m;
                break;
            }
        } 

        // Only Move Down If Not The Last Block
        if (index < this.state.blocksList.length - 1) {      
            // Handle For Loop Start/End Block Moves 
                // Prevent  An End With No Starts Before of Same Indetation
                // Prevent  A Start With No End After of Same Indetation      
            if (this.state.blocksList[index].name === 'iloop') {
                let num_ends_below = 0;
                for (let k = index; k < this.state.blocksList.length; k++) {
                    if (this.state.blocksList[k].name === 'iloopend') {
                        num_ends_below++;
                    }
                }
                if (num_ends_below > 1) {
                    let tempList = this.state.blocksList;
                    [tempList[index], tempList[index + 1]] = [tempList[index + 1], tempList[index]];
                    this.setState( {blocksList: tempList} );
                } else if (num_ends_below === 1) {

                    if (this.state.blocksList[index + 1].name !== 'iloopend') {
                        let tempList = this.state.blocksList;
                        [tempList[index], tempList[index + 1]] = [tempList[index + 1], tempList[index]];
                        this.setState( {blocksList: tempList} );
                    }
                }
            } else { // Non-Loop Case
                let tempList = this.state.blocksList;
                [tempList[index], tempList[index + 1]] = [tempList[index + 1], tempList[index]];
                this.setState( {blocksList: tempList} );
            }
            this.setNotSaved();
        }
        // Re-Calculate Indentation Per Block Based on Any For Loop Positions
        setTimeout(this.applyIndents, 15);
    }

    // Calculate Indentation Per Block Based on Any For Loop Positions
    applyIndents = () => {
        let start_indices = [];
        for (let i = 0; i < this.state.blocksList.length; i++) {
            if (this.state.blocksList[i].name === 'iloop') {
                start_indices.push(i);
            }
            this.state.blocksList[i].indentation = 0;
        }
        
        if (start_indices.length === 0) {
            return;
        }
        for (let j = 0; j < start_indices.length; j++) {
            let found_start = 0;
            let found_end = 0;
            let my_end = 0;
            for (let k = start_indices[j] + 1; k < this.state.blocksList.length; k++) {

                if (this.state.blocksList[k].name === 'iloop') {
                    found_start += 1;
                } else if (this.state.blocksList[k].name === 'iloopend') {
                    found_end += 1;

                    if ((found_end - found_start) === 1) {
                        my_end = k;
                        break;
                    }
                }
            }
            for (let m = start_indices[j] + 1; m < my_end; m++) {
                this.state.blocksList[m].indentation += 1;
            }
        }
    }

    // Allow BaseBlock To Pass Up State Information for A Block
    handleUpdateBlockState = (updateObj) => {
        //console.log(this.state.blocksList)
        //let tempList = this.state.blocksList;        
        let ind = 0;
        for (let m = 0; m < this.state.blocksList.length; m++) {
            if (this.state.blocksList[m].id === updateObj.id) {
                ind = m;
                break;
            }
        } 
         
        let tempListUpdate = JSON.parse(JSON.stringify(this.state.blocksList));
        let indent = tempListUpdate[ind].indentation;
        tempListUpdate[ind] = updateObj;
        tempListUpdate[ind].indentation = indent;
        tempListUpdate[ind].index = ind;

        //console.log(tempListUpdate)

        this.setState( {blocksList: tempListUpdate} );        

        if (this.state.loadComplete === true) {
            this.setNotSaved();
        } else {            
            this.currentCount += 1;
            if (this.currentCount === this.state.blocksToLoad) {
                this.setState( {loadComplete: true} );
                if (this.state.isLinked === true) {
                    setTimeout(this.updateConvertBuffer, 100);
                }
            }
        }
    }

    // Assemble Blocks Into a Program With Their Python Code and "prints" For Handling Which Block to Display
    createGUIProgram = () => {


        let spStr = '\n\n'

        let progText = `${spStr}print("x${0}")${spStr}`;
        if ((this.props.progDetails.hardware === 'LocoDroneT') || 
            (this.props.progDetails.hardware === 'LocoDroneTT')) {
            progText += templateCode[this.props.progDetails.hardware].start1.join('\n') + '\n';
            progText += templateCode[this.props.progDetails.hardware].startN[0] + 
                        "\"" + this.state.ldtselected + "\"" + 
                        templateCode[this.props.progDetails.hardware].startN[1] + '\n'
            progText += templateCode[this.props.progDetails.hardware].startB.join('\n') + '\n';
            progText += '\n';            
        } else if (this.props.progDetails.hardware === 'LocoXtreme') {
            progText += templateCode[this.props.progDetails.hardware].start1.join('\n') + '\n';
            progText += templateCode[this.props.progDetails.hardware].startN[0] + 
                        this.state.lxselectedDetails + 
                        templateCode[this.props.progDetails.hardware].startN[1] + '\n'
            progText += templateCode[this.props.progDetails.hardware].start2.join('\n') + '\n';
            progText += '\n';
        } else {
            if ('startC' in templateCode[this.props.progDetails.hardware]) {
                progText += templateCode[this.props.progDetails.hardware].startC.join('\n');
            } else {
                progText += templateCode[this.props.progDetails.hardware].start.join('\n');
            }
        }
        if (this.state.blocksList.length > 0) {
            for (let y = 0; y < this.state.blocksList.length; y++) {
                
                let indentStr = ' '.repeat(this.state.blocksList[y].indentation * 4);
                let subText = spStr + indentStr + `print("x${y + 1}")${spStr}`;
                let tempStr = [];
                for (let j = 0; j < this.state.blocksList[y].code.length; j++) {
                    //this.state.blocksList[y].code[j] = indentStr + this.state.blocksList[y].code[j];
                    tempStr.push(indentStr + this.state.blocksList[y].code[j])
                }

                if ((y + 1) < this.state.blocksList.length) {
                    if (this.state.blocksList[y].name === 'iloop') {
                        if (this.state.blocksList[y + 1].name === 'iloopend') {
                            indentStr += ' '.repeat(4);
                            tempStr.push(indentStr + 'pass');
                        }
                    }
                }

                //subText += this.state.blocksList[y].code.join('\n');
                subText += tempStr.join('\n');
                progText += subText;
            }
        } else { // Short Pause If No Blocks Are Added To Prevent WebUSB Connect-Disconnect-Too-Fast Error
            progText += `${spStr}time.sleep(2)${spStr}`;
        }
        progText += templateCode[this.props.progDetails.hardware].end.join('\n');
        //console.log(progText)
        this.setState( {buffer: progText} );
    }


    getProgDetails = () => {
        let instructions = this.convertBlocksToSave();
        if (instructions === "") {
            instructions = "\n";
        }
        let tempSelection = this.state.selection;
        tempSelection.instructions = instructions; 
        let progDetails = {type: "load", selection: tempSelection, hardware: this.props.progDetails.hardware};
        if (this.props.progDetails.hardware === 'LocoXtreme') { 
            progDetails["lxSet"] = true
            progDetails["lxselected"] = this.state.lxselected
            progDetails["lxselectedDetails"] = this.state.lxselectedDetails
            progDetails["lxmsg"] = LX_BUTTON_OPTS[1]
        }
        if ((this.props.progDetails.hardware === 'LocoDroneT') ||
            (this.props.progDetails.hardware === 'LocoDroneTT')) {
            progDetails["ldtset"] = true
            progDetails["ldtselected"] = this.state.ldtselected
            progDetails["ldtmsg"] = LDT_BUTTON_OPTS[1]
        }


        return progDetails;
    }


    // 
    handleConvertBtn = async () => {

        if (this.state.isLinked === false) {

            if ((this.props.progDetails.hardware !== 'LocoXtreme') && (this.props.progDetails.hardware !== 'LocoDroneT')
                && (this.props.progDetails.hardware !== 'LocoDroneTT')) {
                let instructions = this.convertBlocksToSave();
                if (instructions === "") {
                    instructions = "\n";
                }
                let tempSelection = this.state.selection;
                tempSelection.instructions = instructions;

                const data = {
                    name: this.state.selection.name,
                    instructions: instructions,
                    revision: this.state.selection.revision,
                    deleted: this.state.selection.deleted,
                }
                
                this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(res => {
                    
                    tempSelection.revision = this.state.selection.revision + 1;

                    let progDetails = {type: "load", selection: tempSelection, hardware: this.props.progDetails.hardware, notSaved: true};
                
                    this.props.handleCreateLink(progDetails);

                });

            } else if (this.props.progDetails.hardware === 'LocoXtreme') {

                if (this.state.lxSet === true) {
                    this.lxSelectedForConv(this.state.lxselected)
                } else {
                    this.LXSelectionElement.current.handleOpen("SELECT");
                    this.setState( {lxFromConv: true} )
                }
                // Open Modal for LX Name Choosing
                //this.LXConversionElement.current.handleOpen("CONVERSION");

            } else if ((this.props.progDetails.hardware === 'LocoDroneT') || 
                       (this.props.progDetails.hardware === 'LocoDroneTT')) {

                if (this.state.ldtSet === true) {
                    this.ldtSelectedForConv(this.state.ldtselected)
                } else {
                    this.writerReset();
                    this.LDTSelectionElement.current.handleOpen("SELECT");
                    this.setState( {ldtFromConv: true} )
                }
                // Open Modal for LDT Name Choosing
                //this.LDTConversionElement.current.handleOpen("CONVERSION");
            }
        }

    }

    
    // Handle From LX Name Choosing Modal
    lxSelectedForConv = (robot_name) => {

        lxconvname = robot_name
        
        if (lxconvname !== "") {
            let instructions = this.convertBlocksToSave();
            if (instructions === "") {
                instructions = "\n";
            }
            let tempSelection = this.state.selection;
            tempSelection.instructions = instructions;
    
            const data = {
                name: this.state.selection.name,
                instructions: instructions,
                revision: this.state.selection.revision,
                deleted: this.state.selection.deleted,
            }
            
            this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(res => {
                
                tempSelection.revision = this.state.selection.revision + 1;
    
                let progDetails = {
                    type: "load", selection: tempSelection, hardware: this.props.progDetails.hardware, notSaved: true, 
                    lxSet: true, lxselected: this.state.lxselected, lxselectedDetails: this.state.lxselectedDetails, lxmsg: LX_BUTTON_OPTS[1]
                };
            
                this.props.handleCreateLink(progDetails);
    
            });

        }  
    }

    // Handle from LDT Name Choosing Modal
    ldtSelectedForConv = (drone_name) => {
        
        ldtconvname = drone_name

        if (ldtconvname !== "") {
            let instructions = this.convertBlocksToSave();
            if (instructions === "") {
                instructions = "\n";
            }
            let tempSelection = this.state.selection;
            tempSelection.instructions = instructions;
    
            const data = {
                name: this.state.selection.name,
                instructions: instructions,
                revision: this.state.selection.revision,
                deleted: this.state.selection.deleted,
            }
            
            this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(res => {
                
                tempSelection.revision = this.state.selection.revision + 1;
    
                let progDetails = {
                    type: "load", selection: tempSelection, hardware: this.props.progDetails.hardware, notSaved: true, 
                    ldtset: true, ldtselected: this.state.ldtselected, ldtmsg: LDT_BUTTON_OPTS[1]
                };
            
                this.props.handleCreateLink(progDetails);
    
            });
        }
    }

    convertLoadedProgram = (selection) => {
        let tempBlocks = JSON.parse(selection.instructions)

        if (JSON.parse(selection.instructions).length === 0) {
            
            this.setState( {loadComplete: true} );
        } else {
            let maxId = 0;
            let m = 0;
            if ('id' in tempBlocks[0]) {
                
                for (m = 0; m < tempBlocks.length; m++) {
                    maxId = Math.max(maxId, tempBlocks[m].id)
                }
            } else {            
                for (m = 0; m < tempBlocks.length; m++) {
                    tempBlocks[m].id = maxId;
                    maxId += 1;
                }
            }
            this.setState( {idTracking: maxId} );
        }

        this.setState( {blocksToLoad: tempBlocks.length} );
        this.setState( {blocksList: tempBlocks} );
    }


    convertBlocksToSave = () => {
        return JSON.stringify(this.state.blocksList);
    }


    // 
    handleSaveBtn = () => {
        if (this.state.shared === false) {
            let instructions = this.convertBlocksToSave();
            if (instructions === "") {
                instructions = "\n";
            }
            const data = {
                name: this.state.selection.name,
                instructions: instructions,
                revision: this.state.selection.revision,
                deleted: this.state.selection.deleted,
            }
            
            this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(() => {
                // 
                this.savedProgramElement.current.handleOpen();
            });
            
            this.setState({
                isSaved: true,            
                nameStyle: "normal",
            });

            let tempSelection = this.state.selection;
            tempSelection.revision = this.state.selection.revision + 1;
            this.setState( {selection: tempSelection} );
        } else {
            this.nameChangeElement.current.handleOpen();
        }
    }

    // Function called when X close button is pressed
    handleCloseBtn = async (event) => {
        
        await this.stopAutoSave();
        //clearTimeout(this.localTimeoutsID)
        //await this.stopit()
        
        if ((this.props.progDetails.hardware === 'LocoXtreme') || 
        (this.props.progDetails.hardware === 'LocoDroneT') ||
        (this.props.progDetails.hardware === 'LocoDroneTT')) {
            try {
                webserial_disconnect()
            } catch (err) {}
        } else {
            try {
                window.port.disconnect();
            } catch {}
        }
        if (this.state.isSaved === false) {
            this.saveProgramElement.current.handleOpen();
        } else {
            // Refresh For ProgramList
            this.context.AuthInstance.getProgramList().then(res => {
                if (this.state.activeStep !== 3) {
                    // Send Information to Parent Program component, through passed down "onCloseDeleteProgram" function
                    var closeDetails = {progAction: "KeepProg"};
                    if (this.state.shared === true) {
                        closeDetails.backToShare = true
                    }
                    this.props.onCloseDeleteProgram(closeDetails)
                } else {
                    this.props.handleSplitClose('coding');
                }
            });
        }
    }

    // 
    handleMarkGradedBtn = event => {          
        //
        this.markGradedElement.current.handleOpen();
    }

    // Function called when trash-can delete button is pressed
    handleDeleteBtn = event => {        
        //
        this.stopAutoSave();
        this.deleteProgramElement.current.handleOpen();
    }

    handleShareBtn = () => {
        //if (this.state.isSaved === true) {
        //    this.shareProgramElement.current.handleOpen(this.state.selection.resource_uri, this.state.selection.name);
        //} else {
        this.stopAutoSave();
        let instructions = this.convertBlocksToSave();
        if (instructions === "") {
            instructions = "\n";
        }
    
        const data = {
            name: this.state.selection.name,    
            fname: this.context.AuthInstance.fn,
            lname: this.context.AuthInstance.ln,
            instructions: instructions,
            revision: this.state.selection.revision,
            deleted: this.state.selection.deleted,
        }
                        
        this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(res => {
            // Refresh For ProgramList
            this.context.AuthInstance.getProgramList().then(res => {

                this.setState({
                    isSaved: true,            
                    nameStyle: "normal",
                });

                let tempSelection = this.state.selection;
                tempSelection.instructions = instructions;
                tempSelection.revision = this.state.selection.revision + 1;
                this.setState( {selection: tempSelection} );

                this.shareProgramElement.current.handleOpen(this.state.selection.resource_uri, this.state.selection.name);
                this.startAutoSave();
            });
        });
        //}
    }

    handleLXEdit = () => {

        if (this.state.lxSet === true) {
            this.lxSettingsElement.current.handleOpen("SETTINGS", JSON.parse(this.state.lxselectedDetails));
        } else {
            this.lxSettingsElement.current.handleOpen("SETTINGS");
        }


    }

    handleSelectLDT = () => {
        this.writerReset();
        this.LDTSelectionElement.current.handleOpen("SELECT");
    }

    handleSelectLX = () => {
        
        this.LXSelectionElement.current.handleOpen("SELECT");
    }




    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display - A Toolbar, AceEditor, and initially-hidden ProgramDisplay Child component
        return (
            <div id="ace-div" className={classes.root} style={{maxHeight: '100vh', overflow: 'visible'}}>
                <AppBar position="static" color="default" className={classes.appBar}>
                    <Toolbar>
                        <div className={classes.inline}>
                            <Button
                                name="nameBtn"
                                color="primary"
                                variant="contained"
                                className={classes.nameButton}
                                onClick={this.handleNameBtn}
                                disabled={this.state.isAutoSaving}
                                style={{fontStyle: this.state.nameStyle, fontSize: '25px', textTransform: "none"}}
                            >
                                {this.state.fileName}
                            </Button>
                            {(this.state.shared === true 
                                && this.state.shareState === 'accepted' &&
                                this.context.AuthInstance.account_type !== 'student') && (
                                <Button
                                    name="gradeBtn"
                                    color="primary"
                                    variant="contained"
                                    className={classes.nameButton}
                                    onClick={this.handleMarkGradedBtn}
                                    style={{fontStyle: this.state.nameStyle, fontSize: '25px', textTransform: "none"}}
                                >
                                    Mark Graded
                                </Button>
                            )}
                        </div>
                        <div className={classes.grow} />
                        <div style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            {((this.state.isLinked === false) && (this.props.progDetails.hardware === 'LocoXtreme')) && (
                                <Button
                                    name="lxEditBtn"
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleLXEdit}
                                    disabled={this.state.isAutoSaving}
                                    style={{textTransform: "none"}}
                                >
                                    Robot Settings
                                </Button>
                            )}
                            {((this.props.progDetails.hardware === 'LocoDroneT') ||
                              (this.props.progDetails.hardware === 'LocoDroneTT')) && (
                                <Button
                                    name="ldtSelectBtn"
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleSelectLDT}
                                    disabled={this.state.isAutoSaving}
                                    style={{textTransform: "none"}}
                                >
                                    {this.state.ldtSelectMsg}
                                </Button>
                            )}
                            {(((this.props.progDetails.hardware === 'LocoDroneT') || 
                                (this.props.progDetails.hardware === 'LocoDroneTT')) && 
                                (this.state.ldtSet === true)) && (
                                <Typography
                                    name="droneName"
                                    color="primary"
                                    className={classes.ldtName}
                                >
                                    {this.state.ldtselected}
                                </Typography>
                            )}
                            {((this.props.progDetails.hardware === 'LocoXtreme') && (this.state.isLinked === false)) && (
                                <Button
                                    name="lxSelectBtn"
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleSelectLX}
                                    disabled={this.state.isAutoSaving}
                                    style={{textTransform: "none"}}
                                >
                                    {this.state.lxSelectMsg}
                                </Button>
                            )}
                            {((this.props.progDetails.hardware === 'LocoXtreme') && (this.state.lxSet === true)) && (
                                <Typography
                                    name="lxName"
                                    color="primary"
                                    className={classes.lxName}
                                >
                                    {this.state.lxselected}
                                </Typography>
                            )}
                            {this.state.shared === false && (
                                <IconButton
                                    name="addBtn"
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleAddBtn}
                                    disabled={this.state.isAutoSaving}
                                >
                                    <AddIcon fontSize="inherit" />
                                </IconButton>
                            )}
                            {this.state.shared === false && (
                                <IconButton
                                    name="convertBtn"
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleConvertBtn}
                                    disabled={this.state.isAutoSaving}
                                >
                                    <VisibilityIcon fontSize="inherit" />
                                </IconButton>
                            )}
                            <IconButton
                                name="playBtn"
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.handlePlayBtn}
                                disabled={this.state.isAutoSaving}
                            >
                                <PlayArrowIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                                name="saveBtn"                
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.handleSaveBtn}
                                disabled={this.state.isAutoSaving}
                            >
                                <SaveIcon fontSize="inherit" />
                            </IconButton>
                            {this.state.shared === false && (
                                <IconButton
                                    name="shareBtn"                
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleShareBtn}
                                    disabled={this.state.isAutoSaving}
                                >
                                    <ShareIcon fontSize="inherit" />
                                </IconButton>
                            )}
                            {this.state.shared === false && (
                                <IconButton
                                    name="deleteBtn"                
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleDeleteBtn}
                                    disabled={this.state.isAutoSaving}
                                >
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            )}
                            <IconButton
                                name="closeBtn"                
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.handleCloseBtn}
                                disabled={this.state.isAutoSaving}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                        
                    </Toolbar>
                </AppBar>

                <Paper elevation={0} 
                    style={{maxHeight: `calc(100vh - ${window.navBarOffset} - ${window.programBarOffset} - ${window.extraOffset})`, 
                            minHeight: `calc(100vh - ${window.navBarOffset} - ${window.programBarOffset} - ${window.extraOffset})`, 
                            overflow: 'auto', backgroundColor: "white"}}>
                    {this.state.blocksList.map((val, i) => {
                        return (
                            <BaseBlock 
                                key = {val.id}
                                hardwareSelection={this.props.progDetails.hardware}
                                blockType={val.name}
                                cardWidth={this.state.cardWidth}
                                id={val.id}
                                indentationLevel={val.indentation}
                                isDisabled={this.state.isAutoSaving}
                                control={val.control}
                                handleDeleteBlock={this.handleDeleteBlock}
                                handleMoveBlockUp={this.handleMoveBlockUp}
                                handleMoveBlockDown={this.handleMoveBlockDown}
                                handleUpdateState={this.handleUpdateBlockState}
                            />
                        );
                    })}
                </Paper>

                <ProgramGUIModal
                    ref={this.blockAddElement}
                    imgFiles={this.state.blockOptions[this.props.progDetails.hardware]}
                    currentHardware={this.props.progDetails.hardware}
                    handleBlockAdded={this.handleBlockAdded}
                />

                <ProgramGUIDisplay 
                    ref={this.progDisplayElement}
                    startMsg={setupMessages[this.props.progDetails.hardware].join('\n')}
                    cardWidth={this.state.cardWidth}
                    currentHardware={this.props.progDetails.hardware}
                    onStop={this.stopRunning}
                    onEmergency={this.stopEmergency}
                    handleUpdateState={this.handleUpdateBlockState}
                />
                <NameChange
                    ref={this.nameChangeElement}
                    onClose={this.nameChangeEvent}
                />
                <DeleteProgram
                    ref={this.deleteProgramElement}
                    onClose={this.checkDeleteProgram}
                />
                <SaveProgram
                    ref={this.saveProgramElement}
                    onClose={this.checkSaveProgram}
                />
                <ShareProgram
                    ref={this.shareProgramElement}
                />
                <SavedProgram
                    ref={this.savedProgramElement}
                />
                <MarkGraded
                    ref={this.markGradedElement}
                    onClose={this.checkGradeProgram}
                />

                <LXSettingsModal
                    ref={this.LXSelectionElement}
                    onClose={this.LXSelection}
                />
                <LXSettingsModal
                    ref={this.LXConversionElement}
                    onClose={this.lxSelectedForConv}
                />
                <LXSettingsModal
                    ref = {this.lxSettingsElement}
                    onClose={this.LXSelection}
                />
                <LDTSettingsModal
                    ref = {this.LDTConversionElement}
                    onClose={this.ldtSelectedForConv}
                />
                <LDTSettingsModal
                    ref = {this.LDTSelectionElement}
                    onClose={this.LDTSelection}
                />
                <GeneralMessage
                    ref = {this.programMessageElement}
                    onClose = {() => void 0}
                />
            </div>
        );
    }
}

export default withStyles(styles)(ProgramGUI);

