import { map_uint8 } from "../helpers";

const COMMS_DELAY = 10//5; // ms

export class LocoXtreme {

    constructor () {
        this.bufferSize = 100;

        this.optionNames = ['Ultrasonic', 'UltrasonicFiltered', 'Accelerometer', 
                            'Gyroscope', 'Temperature', 'Battery', 'Encoders', 'Magnetometer'];
        this.options = {
            'Ultrasonic': {refer: 'Data.Ultrasonic', count: 1, range: ['fixed'], fixed: [0, 150], offset: 5, },
            'UltrasonicFiltered': {refer: 'Data.UltrasonicFiltered', count: 1, range: ['fixed'], fixed: [0, 150], offset: 5, },
            'Accelerometer': {refer: 'Data.Accelerometer', count: 3, range: ['min', 'min', 'min'], offset: 0.25,},
            'Gyroscope': {refer: 'Data.Gyroscope', count: 3, range: ['min', 'min', 'min'], offset: 15,},
            'Temperature': {refer: 'Data.Temperature', count: 1, range: ['min', 'min', 'min'], offset: 15,},
            'Battery': {refer: 'Data.Battery', count: 1, range: ['fixed',], fixed: [4, 7], offset: 2},
            'Encoders': {refer: 'Data.Encoders', count: 2, range: ['min', 'min'], offset: 10},
            'Magnetometer': {refer: 'Data.Magnetometer', count: 3, range: ['min', 'min', 'min'], offset: 1,},
        };

        this.TIMEOUT_STR = "timeout";
        this.TIMEOUT_MAX = 1000;
        this.timeout_count = 0;

        this.TIMEOUT_MOVE = 5000;
        
        this.sharedData = {
            robots: {},
        };

        this.global = {
            connected_wait: false,
            connected_wait_count: 0,
        };

        this.lxTimeoutID = null;

        this.robots = [];
        this.connected_wait =  false;
        this.robot = {};
        // For System Endianness
        this.endianFactor = this.get_endian_factor();
        

        this.read_loop = {
            targetLen: 0,
            targetLenOff: 0,
            header: 0,
            hasTargetLength: false,
            hasHeader: false,
        }
        
        this.read_buffer = {
            data: [],
        }


        this.self = {

            leftMotorDir: "",
            rightMotorDir: "",
            ultrasonicFilter: 20,

            'Data.Ultrasonic': {
                value: Data.Ultrasonic,
                names: ['dist'],
                ylabels: ["Distance (cm)"],
            },
            'Data.UltrasonicFiltered': {
                value: Data.UltrasonicFiltered,
                names: ['dist'],
                ylabels: ["Distance (cm)"],
            },
            'Data.Accelerometer': {
                value: Data.Accelerometer,
                names: ['ax', 'ay', 'az'],
                ylabels: ["AX (g)", "AY (g)", "AZ (g)"],
            },
            'Data.Gyroscope': {
                value: Data.Gyroscope,
                names: ['gx', 'gy', 'gz'],
                ylabels: ["GX (deg/s)", "GY (deg/s)", "GZ (deg/s)"],
            },
            'Data.Temperature': {
                value: Data.Temperature,
                names: ['temp'],
                ylabels: ["Temp (C)"],
            },
            'Data.Battery': {
                value: Data.Battery,
                names: ['batt'],
                ylabels: ["Voltage (V)"],
            },
            'Data.Encoders': {
                value: Data.Encoders,
                names: ['ticksL', 'ticksR'],
                ylabels: ['L ticks (#)', 'R ticks (#)'],
            },
            'Data.Magnetometer': {
                value: Data.Magnetometer,
                names: ['mx', 'my', 'mz'],
                ylabels: ["MX (gauss)", "MY (gauss)", "MZ (gauss)"],
            },

            ultraArray: [],
            ultraFiltArray: [],
            axArray: [],
            ayArray: [],
            azArray: [],
            gxArray: [],
            gyArray: [],
            gzArray: [],
            tempArray: [],
            batteryArray: [],
            encLArray: [],
            encRArray: [],
            mxArray: [],
            myArray: [],
            mzArray: [],

        };
    }

    clearLXTimeouts = () => {
        try {
            clearTimeout(this.lxTimeoutID);
        } catch {}
    }

    resetArrays = () => {
        this.self.ultraArray = [];
        this.self.ultraFiltArray = [];
        this.self.axArray = [];
        this.self.ayArray = [];
        this.self.azArray = [];
        this.self.gxArray = [];
        this.self.gyArray = [];
        this.self.gzArray = [];
        this.self.tempArray = [];
        this.self.batteryArray = [];
        this.self.encLArray = [];
        this.self.encRArray = [];
        this.self.mxArray = [];
        this.self.myArray = [];
        this.self.mzArray = [];
    };

    // System Endianness Factor For Data Type Conversions
    get_endian_factor = () => {
        let uInt32 = new Uint32Array([0x11223344]);
        let uInt8 = new Uint8Array(uInt32.buffer);
        let endianFactor = 0;
        if (uInt8[0] = 0x11) {
            endianFactor = 3
        }
        return endianFactor;
    }


    writePacket = async (data) => {
        // Create TypedArray for Sending
        let packet = Uint8Array.from(data);
        //packet = new Uint8Array(packet.buffer);
    
        //console.log("write", data)
        // Send Data Through WebUSB
        //webserial_write(data)
        await this.webserial_write(packet)
    }

    webserial_write = async (data) => {
        // Write to output stream
        //const writer = window.outputStream.getWriter();
        
        //data = new TextDecoder().decode(data)// + "\r\n"
        //console.log("data write", data)
        const writer = window.serialport.writable.getWriter();
    
        await writer.write(data);
        writer.releaseLock();
    }


    scan_stop = async () => {
        let data = [OutgoingUSBMessageType.EndScanning, 1, 0];
        this.writePacket(data);
    }

    scan_start = async (time_delay) => {

        let data = [OutgoingUSBMessageType.BeginScanning, 1, 0];
        this.writePacket(data);
        this.lxTimeoutID = setTimeout(this.scan_stop, time_delay);

    }

    set_buffer_size = (buffer_size) => {
        this.bufferSize = buffer_size
    }

    get_buffer_size = () => {
        return this.bufferSize
    }


    connect_reset_reader = async () => {
        
        this.robots = [];
        this.connected_wait =  false;
        this.robot = {};

        if (window.serialport) {
            if (window.reader) {
                await window.reader.cancel();
                window.reader = null;
            }
        } else {
            // - Request a port and open a connection.
            const usbVendorId = 0x2458;
            window.serialport = await navigator.serial.requestPort({ filters: [{ usbVendorId }]});
            // - Wait for the port to open.
            await window.serialport.open({ baudRate: 57600, bufferSize: 2048 }); 
        }

        window.reader = window.serialport.readable.getReader();
        //window.readLoopFlag = true;
        this.global['connected_wait'] = true
        this.global['connected_wait_count'] = 0
        
        this.readLoop()

    }

    connect_usb = async () => {
    
        this.robots = [];
        this.connected_wait =  false;
        this.robot = {};

        if (window.serialport) {
            if (window.reader) {
                await window.reader.cancel();
                window.reader = null;
            }
            // Close the output stream.
            /*
            if (window.outputStream) {
                await window.outputStream.getWriter().close();
                await window.outputDone;
                window.outputStream = null;
                window.outputDone = null;
            }
            */
            // Close the port.
            try {
                await window.serialport.close();
            } catch (err) {}
            window.serialport = null;        
        }
            
        // - Request a port and open a connection.
        const usbVendorId = 0x2458;
        window.serialport = await navigator.serial.requestPort({ filters: [{ usbVendorId }]});
        // - Wait for the port to open.
        await window.serialport.open({ baudRate: 57600, bufferSize: 2048 });
        
        window.reader = window.serialport.readable.getReader();
        //window.readLoopFlag = true;
        this.global['connected_wait'] = true
        this.global['connected_wait_count'] = 0
        
        this.readLoop()
        /*
        window.readLoopFlag = true;
        this.readLoop().catch((error) => {
            window.serialport = null;
            console.log("read error")
        });
        */
    }

    webserial_disconnect = async () => {
        try {
          // Close the input stream (reader).
          if (window.reader) {
            await window.reader.cancel();
            window.reader = null;
          }
          // Close the output stream.
          if (window.outputStream) {
            await window.outputStream.getWriter().close();
            await window.outputDone;
            window.outputStream = null;
            window.outputDone = null;
          }
          // Close the port.
          await window.serialport.close();
          window.serialport = null;
          //log.textContent = "Dongle Disconnected!";
        } catch (err) {}
    }

    reset_ble = async () => {
        let me = this;
        return new Promise(async function (resolve, reject) {
            var m = function() {
                resolve("done");
            }

            // Reset BLE
            let data = [OutgoingUSBMessageType.ResetBLE, 1, 0];
            return me.writePacket(data).then(() => {
                // Give time
                me.lxTimeoutID = setTimeout(m, 1000);//100);
            }).catch((err) => {
                resolve("error")
            });
        });
    }

    connect = async () => {

        let me = this;
        return new Promise(async function (resolve, reject) {
            var j = function () {
                // Reset BLE
                let data = [OutgoingUSBMessageType.ResetBLE, 1, 0];
                return me.writePacket(data).then(() => {
                    // Give time
                    me.lxTimeoutID = setTimeout(m, 2000);
                });
            }
            var m = function() {
                resolve("done");
            }
            return me.connect_usb().then(() => {
                me.lxTimeoutID = setTimeout(j, 1000);
            }).catch((err) => {
                resolve("error")
            });
        });
    }

    disconnect_usb = async () => {
        await this.webserial_disconnect();
    }

    disconnect = async () => {
        await this.webserial_disconnect();
    }

           
    // Send Data To the "Dongle" to Be Passed to the Robot
    passThrough = async (data) => {
        let packet = [OutgoingUSBMessageType.PassThrough, this.sharedData.robots[this.robot['name']].conn_handle, data.length]
        for (let i = 0; i < data.length; i++) {
            packet.push(data[i])
        }
        await this.writePacket(packet);
    }

    enable_sensor = async (sensorName, sensorState) => {        
        let me = this;
        return new Promise(async function (resolve, reject) {
            return me.passThrough([MessageType.Data, sensorName, 1, sensorState]).then(() => {
                resolve("done")
            });
        });
    }

    get_data_array = (type) => {
        
        switch(type) {    
            case this.self['Data.Ultrasonic'].value:
                return [this.self.ultraArray];
            case this.self['Data.UltrasonicFiltered'].value:
                return [this.self.ultraFiltArray];
            case this.self['Data.Accelerometer'].value:
                return [this.self.axArray, this.self.ayArray, this.self.azArray];
            case this.self['Data.Gyroscope'].value:
                return [this.self.gxArray, this.self.gyArray, this.self.gzArray];
            case this.self['Data.Temperature'].value:
                return [this.self.tempArray];
            case this.self['Data.Battery'].value:
                return [this.self.batteryArray];
            case this.self['Data.Encoders'].value:
                return [this.self.encLArray, this.self.encRArray];
            case this.self['Data.Magnetometer'].value:
                return [this.self.mxArray, this.self.myArray, this.self.mzArray];
        }
    };



    set_name = async (new_name) => {

        let me = this;

        return new Promise(function (resolve, reject) {     

            var f = function () {
                resolve("done");
            }

            let data = [MessageType.Preference, Preference.Name, new_name.length + 1]
            for (let t = 0; t < new_name.length; t++) {
                data.push(new_name.charCodeAt(t));
            }
            data.push(0);
            return me.passThrough(data).then(() => {
                me.lxTimeoutID = setTimeout(f, 600);
            });

        });
    }

    set_motor = async (motor) => {

        let me = this;

        return new Promise(async function (resolve, reject) {     

            var f = function () {
                resolve("done");
            }

            let data = []
            if (motor === "left") {
                data = [MessageType.Preference, Preference.MotorLeftFlip, 1, 0]
            } else if (motor === "right") {
                data = [MessageType.Preference, Preference.MotorRightFlip, 1, 0]
            }

            return me.passThrough(data).then(() => {
                me.lxTimeoutID = setTimeout(f, 200);
            });
        });
    }

    get_motor = async (direction) => {

        let me = this;
        this.timeout_count = 0

        return new Promise(async function (resolve, reject) {     

            let packet = []
            if (direction === "left") {
                packet = [MessageType.PreferenceRequest, Preference.MotorLeftFlip, 1, 0];
            } else if (direction === "right") {
                packet = [MessageType.PreferenceRequest, Preference.MotorRightFlip, 1, 0];
            }
            
            var f = function () {
                // Check for Preference Wait Complete Flag
                if (me.sharedData.robots[me.robot['name']].Preference.waiting === false) {
                    if (direction === "left") {
                        resolve(me.sharedData.robots[me.robot['name']].Preference[Preference.MotorLeftFlip]);
                    } else if (direction === "right") {
                        resolve(me.sharedData.robots[me.robot['name']].Preference[Preference.MotorRightFlip]);
                    }
                    resolve(0);
                } else {
                    if (me.timeout_count < me.TIMEOUT_MAX) {
                        me.lxTimeoutID = setTimeout(f, 10);
                    } else {
                        resolve(me.TIMEOUT_STR);
                    }                    
                    me.timeout_count += 10;
                }
            }

            return me.passThrough(packet).then(() => {
                me.sharedData.robots[me.robot['name']].Preference.waiting = true;
                me.lxTimeoutID = setTimeout(f, 10);
            }).then(() => {

            });
        });
    }

    set_ultrasonic_filter = async (filter_value) => {

        let me = this;

        return new Promise(async function (resolve, reject) {     

            var f = function () {
                resolve("done");
            }
            
            let packet = [MessageType.Preference, Preference.UltrasonicFiltering, 1, filter_value];

            return me.passThrough(packet).then(() => {
                me.lxTimeoutID = setTimeout(f, 200);
            });
        });
                
    }


    get_ultrasonic_filter = async () => {
        
        let me = this;
        this.timeout_count = 0

        return new Promise(async function (resolve, reject) {     

            let packet = [MessageType.PreferenceRequest, Preference.UltrasonicFiltering, 1, 0];
            
            var f = function () {
                // Check for Preference Wait Complete Flag
                if (me.sharedData.robots[me.robot['name']].Preference.waiting === false) {
                    resolve(me.sharedData.robots[me.robot['name']].Preference[Preference.UltrasonicFiltering]);
                } else {
                    if (me.timeout_count < me.TIMEOUT_MAX) {
                        me.lxTimeoutID = setTimeout(f, 10);
                    } else {
                        resolve(me.TIMEOUT_STR);
                    }                    
                    me.timeout_count += 10;
                }
            }

            return me.passThrough(packet).then(() => {
                me.sharedData.robots[me.robot['name']].Preference.waiting = true;
                me.lxTimeoutID = setTimeout(f, 10);
            });
        });
    }

    setup_wait = async (sensor, value=0) => {
        
        let me = this;
        // Scale value
        value = value * 1000;
        // 
        return new Promise(async function (resolve, reject) {     

            var f = function () {
                resolve("done");
            }
            // Initial Packet
            let packet = [MessageType.Control, Control.SetupWait, 5, sensor]

            // Append "value" to packet and Send
            const buffer = new ArrayBuffer(4);
            const view = new DataView(buffer);
            view.setInt32(0, value);
            for (let i = 0; i < 4; i++) {
                packet.push(view.getUint8(i));
            }
            // Send Packet
            return me.passThrough(packet).then(() => {
                me.sharedData.robots[me.robot['name']].last_wait = {
                    sensor: sensor,
                    value: value,
                }
                me.lxTimeoutID = setTimeout(f, 200);
            });
        });
    }

    move = async (lmd, rmd, lms, rms, wait=false) => {
        
        let me = this;
        this.timeout_count = 0
        
        return new Promise(async function (resolve, reject) {     

            var f = function () {    
                // Check for Wait Complete Flag
                if (me.sharedData.robots[me.robot['name']].Program.waiting === 0) {
                    resolve("done");
                } else {
                    if (me.timeout_count < me.TIMEOUT_MOVE) {
                        me.lxTimeoutID = setTimeout(f, 10);
                    } else {
                        resolve(me.TIMEOUT_STR);
                    }
                    me.timeout_count += 10;
                }
            }

            // Check for "Bad" Inputs
            if ((wait === true) && (Object.keys(me.sharedData.robots[me.robot['name']].last_wait).length === 0)) {
                throw 'Must call setup_wait before using move with wait';
            } else if ((wait === true) && 
                        (Object.keys(me.sharedData.robots[me.robot['name']].last_wait).length !== 0) && 
                        (me.sharedData.robots[me.robot['name']].last_wait['sensor'] === WaitTypeC.Rotation) && 
                        ((lmd === rmd) || (rms != lms))) {
                throw 'Move must be called with different directions and same speeds when using Rotation based wait';
            } else if ((wait === true) && 
                        (Object.keys(me.sharedData.robots[me.robot['name']].last_wait).length !== 0) && 
                        (me.sharedData.robots[me.robot['name']].last_wait['sensor'] === WaitTypeC.Distance) && 
                        ((lmd !== rmd) || (rms !== lms))) {
                throw 'Move must be called with same directions and same speeds when using Distance based wait';
            }

            // Prepare Packet
            let packet = [MessageType.Control, Control.Motors, 6, lmd, rmd]
            
            //let buf = new ArrayBuffer(1);
            //let view = new DataView(buf);
            const mapped_l = map_uint8(lms * 100, 0, 100, 0, 80)
            //view.setUint8(0, (lms * 100));
            //packet.push(view.getUint8(0));
            packet.push(mapped_l);
            
            const mapped_r = map_uint8(rms * 100, 0, 100, 0, 80)
            //view.setUint8(0, (rms * 100));
            //packet.push(view.getUint8(0));
            packet.push(mapped_r);
            
            // Prepare If Using Wait Or Not and Send Packet
            if (wait === false) {
                wait = 0
            } else {
                wait = 1;
            }
            packet.push(wait);
            packet.push(1);

            return me.passThrough(packet).then(() => {
                // Handle Behavior Based on Wait or No Wait
                if (wait === 1) {
                    me.sharedData.robots[me.robot['name']].Program.waiting = 1;
                    me.lxTimeoutID = setTimeout(f, 10);
                } else {
                    me.lxTimeoutID = setTimeout( () => resolve( "done" ), COMMS_DELAY );
                }
            });

        });
    }

    motor_controllers = async (type, option, data = 0) => {

        let me = this;
        this.timeout_count = 0
        
        return new Promise(async function (resolve, reject) { 

            var f = function () {
                // Check for Preference Wait Complete Flag
                if (me.sharedData.robots[me.robot['name']].Preference.waiting === false) {
                    if (option === PreferenceSettingC.PreferenceGet) {
                        if (type === Preference.RTicks) {
                            resolve(me.sharedData.robots[me.robot['name']].Preference[Preference.RTicks]);
                        } else if (type === Preference.MMPerRev) {
                            resolve(me.sharedData.robots[me.robot['name']].Preference[Preference.MMPerRev]);
                        } else if (type === Preference.PIDDist) {
                            resolve(me.sharedData.robots[me.robot['name']].Preference[Preference.PIDDist]);
                        } else if (type === Preference.PIDVel) {
                            resolve(me.sharedData.robots[me.robot['name']].Preference[Preference.PIDVel]);
                        } else if (type === Preference.VelTargets) {
                            resolve(me.sharedData.robots[me.robot['name']].Preference[Preference.VelTargets]);
                        }
                    } else {
                        resolve("done");
                    }
                } else {
                    if (me.timeout_count < me.TIMEOUT_MAX) {
                        me.lxTimeoutID = setTimeout(f, 10);
                    } else {
                        resolve(me.TIMEOUT_STR);
                    }
                    me.timeout_count += 10;
                }
            }

            
            let packet = [];
            if (option === PreferenceSettingC.PreferenceSet) {
                packet.push(MessageType.Preference);
                packet.push(type);

                // Append Extra Data for SET Case
                if ((type === Preference.RTicks) || (type === Preference.MMPerRev)) {
                    packet.push(2);
                    const buffer = new ArrayBuffer(2);
                    const view = new DataView(buffer);
                    view.setUint16(0, data);
                    packet.push(view.getUint8(0));
                    packet.push(view.getUint8(1));
                } else if (type === Preference.PIDDist)  {
                    packet.push(12);
                    const buffer = new ArrayBuffer(2);
                    const view = new DataView(buffer);
                    for (let k = 0; k < 6; k++) {
                        view.setUint16(0, data[k]);
                        for (let i = 0; i < 2; i++) {
                            packet.push(view.getUint8(i));
                        }
                    }
                } else if (type === Preference.PIDVel) {
                    packet.push(12);
                    const buffer = new ArrayBuffer(2);
                    const view = new DataView(buffer);
                    for (let k = 0; k < 6; k++) {
                        view.setUint16(0, data[k]);
                        for (let i = 0; i < 2; i++) {
                            packet.push(view.getUint8(i));
                        }
                    }
                } else if (type === Preference.VelTargets) {
                    packet.push(4);
                    const buffer = new ArrayBuffer(2);
                    const view = new DataView(buffer);
                    for (let k = 0; k < 2; k++) {
                        view.setUint16(0, data[k]);
                        for (let i = 0; i < 2; i++) {
                            packet.push(view.getUint8(i));
                        }
                    }
                }
                // Send Packet
                return me.passThrough(packet).then(() => {
                    me.lxTimeoutID = setTimeout( () => resolve( "done" ), COMMS_DELAY );
                });
            } else {
                packet.push(MessageType.PreferenceRequest);
                packet.push(type);
                packet.push(1);
                packet.push(0);
                
                // Send Packet
                return me.passThrough(packet).then(() => {
                    me.sharedData.robots[me.robot['name']].Preference.waiting = true;
                    me.lxTimeoutID = setTimeout(f, 10);
                });
            }
        })
    }

    activate_motors = async () => {
        
        let me = this;

        return new Promise(async function (resolve, reject) {
            return me.passThrough([MessageType.Control, Control.ActivateMotors, 0]).then(() => {
                me.lxTimeoutID = setTimeout( () => resolve( "done" ), COMMS_DELAY );
            });
        });
    }

    deactivate_motors = async () => {
        
        let me = this;

        return new Promise(async function (resolve, reject) {
            return me.passThrough([MessageType.Control, Control.DeactivateMotors, 0]).then(() => {
                me.lxTimeoutID = setTimeout( () => resolve( "done" ), COMMS_DELAY );
            });
        });
    }

    connect_ble = async (i) => {
        
        let me = this;

        this.global['connected_wait'] = false
        this.global['connected_wait_count'] = 0

        return new Promise(function (resolve, reject) {            

            var f = function () {
                if (me.connected_wait === false) {
                    if ((me.global['connected_wait_count'] === 1) ||
                        (me.global['connected_wait_count'] === 9) ||
                        (me.global['connected_wait_count'] === 17))
                    {
                        me.writePacket(data);                            
                    } else if (me.global['connected_wait_count'] === 26) {
                        reject("Could Not Connect To The Robot. Please Try Running the Program Again.");
                    }
                    if (me.global['connected_wait_count'] !== 26) {
                        me.global['connected_wait_count'] += 1
                        me.lxTimeoutID = setTimeout(f, 250);
                    } else {
                        me.global['connected_wait'] = true
                        me.global['connected_wait_count'] = 0
                    }
                } else {
                    resolve("done")
                }
            }
            me.robot = me.robots[i];

            let data = [OutgoingUSBMessageType.Connect, 7, me.robot['macType']];            
            // Append macAddr to packet
            let macKeys = Object.keys(me.robot['macAddr']);
            for (let r = 0; r < macKeys.length; r++) {
                data.push(me.robot['macAddr'][macKeys[r]]);
            }

            me.writePacket(data).then(() => {
                me.lxTimeoutID = setTimeout(f, 3200);//3200);
            })
        });
    }

    update_buffer = async () => {

        try {
            const { value, done } = await window.reader.read()
            let nowPacket = new Uint8Array(value.buffer);
            this.read_buffer.data = [...this.read_buffer.data , ...nowPacket]
            if (done) {
                return -1;
            } else {
                return 0;
            }
        } catch (err) {
            //console.log("read error", err)
            return -1
        }
    
    }
    
    process_buffer = () => {
    
        if (this.read_loop.hasHeader === false) {
            // Have Header Now
            this.read_loop.header = this.read_buffer.data[0]
            this.read_loop.hasHeader = true
    
            // Not enough data for length byte
            if (this.read_buffer.data.length < 3) {
                return;
            }
            
            // Get Length
            if (this.read_loop.header in USBIndexType2) {
                this.read_loop.targetLen = this.read_buffer.data[1]
                this.read_loop.targetLenOff = 2
            } else {
                this.read_loop.targetLen = this.read_buffer.data[2]
                this.read_loop.targetLenOff = 3
            }
            this.read_loop.hasTargetLength = true
    
            // Check if the rest of the packet is in the buffer - if not, return
            let fullLength = (this.read_loop.targetLen + this.read_loop.targetLenOff)
            if (this.read_buffer.data.length < fullLength) {
                return;
            }
    
            // Read Current Packet
            let myPacket = this.read_buffer.data.slice(0, fullLength)
    
            // Process Packet
            this.processSerial(myPacket)
    
            // Update Buffer without throwing out extra, if extra
            this.read_buffer.data = this.read_buffer.data.slice(fullLength, this.read_buffer.data.length)
    
            // Reset Parameters
            this.clear_packet_info()
    
        } else { // Has Header Already
    
            // Does Not Already Have Target Length?
            if (this.read_loop.hasTargetLength !== true) {
                
                // Not enough data for length byte
                if (this.read_buffer.data.length < 3) {
                    return;
                }
    
                // Has Enough, Get Length
                if (this.read_loop.header in USBIndexType2) {
                    this.read_loop.targetLen = this.read_buffer.data[1]
                    this.read_loop.targetLenOff = 2
                } else {
                    this.read_loop.targetLen = this.read_buffer.data[2]
                    this.read_loop.targetLenOff = 3
                }
                this.read_loop.hasTargetLength = true
            }
            
            // Check if the rest of the packet is in the buffer - if not, return
            let fullLength = (this.read_loop.targetLen + this.read_loop.targetLenOff)
            if (this.read_buffer.data.length < fullLength) {
                return;
            }
            
            // Read Current Packet
            let myPacket = this.read_buffer.data.slice(0, fullLength)
    
            // Process Packet
            this.processSerial(myPacket)
    
            // Update Buffer without throwing out extra, if extra
            this.read_buffer.data = this.read_buffer.data.slice(fullLength, this.read_buffer.data.length)
    
            // Reset Parameters
            this.clear_packet_info()
        }
    }

    clear_packet_info = () => {
        this.read_loop.header = 0
        this.read_loop.hasHeader = false
        this.read_loop.targetLen = 0
        this.read_loop.hasTargetLength = false
        this.read_loop.targetLenOff = 0
    }


    readLoop = async() => {
    
        while (true) {
            
            let output = await this.update_buffer();
            if (output === -1) {
                window.reader.releaseLock();
                break;
            }
            this.process_buffer();
    
        }
    }

    processSerial = (dataBytes) => {
        //console.log("serial", dataBytes)
        this.packetFlag = false
        this.packetHaveLen = false
        this.currentLen = 0
        //console.log(dataBytes)
        // Switch on Header Value
        switch (dataBytes[0]) {

            // "Dongle" Says New Robot Was Found
            case IncomingUSBMessageType.NewRobot: {
                // Get MacType, Address, and Robot Name and put in sharedData
                let msgLen = dataBytes[1];
                let macType = dataBytes[2];
                let macAddr = dataBytes.slice(3, 9);

                let name = dataBytes.slice(9, msgLen + 2);
                name = String.fromCharCode.apply(null, name);
                
                this.robots.push({macAddr: macAddr, macType: macType, name: name});
                this.sharedData.robots[name] = {macAddr: macAddr, macType: macType, Data: {}, Program: {}, Preference: {}, Calibration: {}};
                break;
            }
            // "Dongle" Says It Connected to a Robot
            case IncomingUSBMessageType.RobotConnected: {
                
                // Based on Robot Found, Initialize Corresponding Entry in SharedData
                let msgLen = dataBytes[1];
                let macAddr = dataBytes.slice(2, msgLen + 1);
                //console.log("got connected packet", dataBytes)
                // Loop Through All Scan-Found Robots To Find Matching
                let names = Object.keys(this.sharedData.robots)
                for (let k = 0; k < names.length; k++) {
                    
                    // Find Matching Mac Address
                    let found_count = 0;
                    for (let j = 0; j < 6; j++) {
                    //for (let j = 1; j < 6; j++) {
                        if (this.sharedData.robots[names[k]].macAddr[j] === macAddr[j]) {
                            found_count++;
                        }
                    }
                    // Found a Match
                    if (found_count === 6) {
                    //if (found_count === 5) {
                        // Initialize Connection Handle Number
                        this.sharedData.robots[names[k]].conn_handle = dataBytes[8];
                                                                        
                        // Initialize Sensor Data
                        this.sharedData.robots[names[k]].Data[Data.Ultrasonic] = 400;
                        this.sharedData.robots[names[k]].Data[Data.UltrasonicFiltered] = 400;
                        this.sharedData.robots[names[k]].Data[Data.Accelerometer] = {x: 0, y: 0, z: 0};
                        this.sharedData.robots[names[k]].Data[Data.Gyroscope] = {x: 0, y: 0, z: 0};
                        this.sharedData.robots[names[k]].Data[Data.Encoders] = {left: 0, right: 0};
                        this.sharedData.robots[names[k]].Data[Data.Temperature] = 0;
                        this.sharedData.robots[names[k]].Data[Data.Battery] = 0;
                        this.sharedData.robots[names[k]].Data[Data.Magnetometer] = {x: 0, y: 0, z: 0};
                        // Initialize Wait For setup_wait()
                        this.sharedData.robots[names[k]].Program.waiting = 0;
                        // Initialize Preferences and Wait - Mostly Only Care When Retrieving
                        this.sharedData.robots[names[k]].Preference[Preference.MotorLeftFlip] = null;
                        this.sharedData.robots[names[k]].Preference[Preference.MotorRightFlip] = null;
                        this.sharedData.robots[names[k]].Preference[Preference.Name] = null;
                        this.sharedData.robots[names[k]].Preference[Preference.UltrasonicFiltering] = null;
                        this.sharedData.robots[names[k]].Preference[Preference.Reset] = null;
                        this.sharedData.robots[names[k]].Preference[Preference.RTicks] = 0;
                        this.sharedData.robots[names[k]].Preference[Preference.MMPerRev] = 0;
                        this.sharedData.robots[names[k]].Preference[Preference.PIDDist] = {
                            'kp_l': 0, 'ki_l': 0, 'kd_l': 0,
                            'kp_r': 0, 'ki_r': 0, 'kd_r': 0,
                        };
                        this.sharedData.robots[names[k]].Preference[Preference.PIDVel] = {
                            'kp_l': 0, 'ki_l': 0, 'kd_l': 0,
                            'kp_r': 0, 'ki_r': 0, 'kd_r': 0, 
                        };
                        this.sharedData.robots[names[k]].Preference[Preference.VelTargets] = {
                            'v_target_l': 0, 'v_target_r': 0,
                        }
                        this.sharedData.robots[names[k]].Preference.waiting = false;
                        // Initialize Calibration Values and Wait
                        this.sharedData.robots[names[k]].Calibration.waiting = false;
                        this.sharedData.robots[names[k]].Calibration[Data.Accelerometer] = {x: 0, y: 0, z: 0};
                        //
                        this.sharedData.robots[names[k]].last_wait = {};
                        break;
                    }
                }
                this.connected_wait = true
                break;
            }
            // "Dongle" Sent a Pass-Through Packet From A Connected Robot
            case IncomingUSBMessageType.RobotPacket: {
                // Connection Handle
                let conn_handle = dataBytes[1];
                // Find Corresponding sharedData Entry based on the Connection Handle
                let names = Object.keys(this.sharedData.robots)
                
                for (let k = 0; k < names.length; k++) {
                
                    if (this.sharedData.robots[names[k]].conn_handle === conn_handle) {
                        let data_len = dataBytes[2];
                        // Process Data Received For Robot Name Based on Connection Handle
                        this.processPacket(names[k], dataBytes.slice(3, 3 + data_len));
                        break;
                    }
                }
                break;
            }
        }
    }

    // Process a Received Pass-Through Packet
    processPacket = (ref_name, data) => {
        
        // Switch on MessageType
        switch (data[0]) {
            // Received "Data" Message
            case MessageType.Data: {
                // Process Sensor Data to Store in sharedData
                this.processSensor(ref_name, data);
                break;
            }
            // Received "Program" Message
            case MessageType.Program: {
                this.sharedData.robots[ref_name].Program.waiting = 0;
                break;
            }
            // Received "Preference" Message
            case MessageType.Preference: {
                
                // If It is UltrasonicFiltering, stop waiting
                if (data[1] === Preference.UltrasonicFiltering) {
                    // Store in sharedData
                    this.sharedData.robots[ref_name].Preference[data[1]] = data[3];
                    this.sharedData.robots[ref_name].Preference.waiting = false;

                } else if (data[1] === Preference.MotorLeftFlip) {
                    this.sharedData.robots[ref_name].Preference[data[1]] = data[3];                    
                    this.sharedData.robots[ref_name].Preference.waiting = false;

                } else if (data[1] === Preference.MotorRightFlip) {
                    this.sharedData.robots[ref_name].Preference[data[1]] = data[3];
                    this.sharedData.robots[ref_name].Preference.waiting = false;

                } else if ((data[1] === Preference.RTicks) || (data[1] === Preference.MMPerRev)) {
                    
                    this.sharedData.robots[ref_name].Preference[data[1]] = (data[3] << 8) | data[4];
                    
                    this.sharedData.robots[ref_name].Preference.waiting = false;
                } else if ((data[1] === Preference.PIDDist) || (data[1] === Preference.PIDVel)) {

                    let buf = new ArrayBuffer(6);
                    let view = new DataView(buf);

                    let kp = data.slice(3, 5);
                    let ki = data.slice(5, 7);
                    let kd = data.slice(7, 9);
                    kp.forEach(function (b, i) {
                        view.setUint8(i, b);
                    });
                    let kp_int = view.getUint16(0);
                    ki.forEach(function (b, i) {
                        view.setUint8(i, b);
                    });
                    let ki_int = view.getUint16(0);
                    kd.forEach(function (b, i) {
                        view.setUint8(i, b);
                    });
                    let kd_int = view.getUint16(0);

                    this.sharedData.robots[ref_name].Preference[data[1]].kp = kp_int;
                    this.sharedData.robots[ref_name].Preference[data[1]].ki = ki_int;
                    this.sharedData.robots[ref_name].Preference[data[1]].kd = kd_int;

                    this.sharedData.robots[ref_name].Preference.waiting = false;
                } else if (data[1] === Preference.PIDVel) {

                    let buf = new ArrayBuffer(6);
                    let view = new DataView(buf);
                    let kp = data.slice(3, 5);
                    let ki = data.slice(5, 7);
                    let kd = data.slice(7, 9);
                    kp.forEach(function (b, i) {view.setUint8(i, b)});
                    let kp_int_l = view.getUint16(0);
                    ki.forEach(function (b, i) {view.setUint8(i, b)});
                    let ki_int_l = view.getUint16(0);
                    kd.forEach(function (b, i) {view.setUint8(i, b)});
                    let kd_int_l = view.getUint16(0);
    
                    kp = data.slice(9, 11);
                    ki = data.slice(11, 13);
                    kd = data.slice(13, 15);
                    kp.forEach(function (b, i) {view.setUint8(i, b)});
                    let kp_int_r = view.getUint16(0);
                    ki.forEach(function (b, i) {view.setUint8(i, b)});
                    let ki_int_r = view.getUint16(0);
                    kd.forEach(function (b, i) {view.setUint8(i, b)});
                    let kd_int_r = view.getUint16(0);
    
                    this.sharedData.robots[ref_name].Preference[data[1]].kp_l = kp_int_l;
                    this.sharedData.robots[ref_name].Preference[data[1]].ki_l = ki_int_l;
                    this.sharedData.robots[ref_name].Preference[data[1]].kd_l = kd_int_l;
                    this.sharedData.robots[ref_name].Preference[data[1]].kp_r = kp_int_r;
                    this.sharedData.robots[ref_name].Preference[data[1]].ki_r = ki_int_r;
                    this.sharedData.robots[ref_name].Preference[data[1]].kd_r = kd_int_r;
    
                    this.sharedData.robots[ref_name].Preference.waiting = false;
                } else if (data[1] === Preference.VelTargets) {
    
                    let buf = new ArrayBuffer(6);
                    let view = new DataView(buf);
                    
                    let v_t_l = data.slice(3, 5);
                    let v_t_r = data.slice(5, 7);
                    v_t_l.forEach(function (b, i) {view.setUint8(i, b)});
                    let v_target_l = view.getUint16(0);
                    v_t_r.forEach(function (b, i) {view.setUint8(i, b)});
                    let v_target_r = view.getUint16(0);
                    
                    this.sharedData.robots[ref_name].Preference[data[1]].v_target_l = v_target_l;
                    this.sharedData.robots[ref_name].Preference[data[1]].v_target_r = v_target_r;
                    
                    this.sharedData.robots[ref_name].Preference.waiting = false;
                }
                break;
            }
            // Received "Calibration" Message
            case MessageType.Calibration: {
                // Convert Uin8 Array X, Y, and Z Axes Offset Data
                let buf = new ArrayBuffer(4);
                let view = new DataView(buf);
                let dataX = data.slice(4, 8);
                let dataY = data.slice(8, 12);
                let dataZ = data.slice(12, 16);
                let me = this;
                dataX.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let x = view.getFloat32(0);
                dataY.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let y = view.getFloat32(0);
                dataZ.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let z = view.getFloat32(0);
                // Update sharedData with Values
                this.sharedData.robots[ref_name].Calibration[data[1]].x = x;
                this.sharedData.robots[ref_name].Calibration[data[1]].y = y;
                this.sharedData.robots[ref_name].Calibration[data[1]].z = z;
                // Stop waiting for Calibration Data
                this.sharedData.robots[ref_name].Calibration.waiting = false;
                break;
            }
            // case MessageType.State
        }
    }

    // Handle Conversions For Sensor Data
    processSensor = (ref_name, data) => {
        
        // Switch on Data Sources
        switch (data[1]) {
            // Ultrasonic Sensor Data - 16-bit Uint
            case Data.Ultrasonic: {
                let valid = data[3]
                let dist;
                if (valid === 1) {
                    dist = data[4] << 8 | data[5];
                } else {
                    //dist = 400;
                    dist = this.self.ultraArray[this.self.ultraArray.length - 1];
                }
                this.sharedData.robots[ref_name].Data[Data.Ultrasonic] = dist;

                if (this.self.ultraArray.length > this.bufferSize) {
                    this.self.ultraArray.splice(0, 1)
                }
                this.self.ultraArray.push(dist);

                break;
            }
            // Filtered Ultrasonic Sensor Data - 16-bit Uint
            case Data.UltrasonicFiltered: {
                let valid = data[3]
                let dist;
                if (valid === 1) {
                    dist = data[4] << 8 | data[5];
                } else {
                    //dist = 400;                    
                    dist = this.self.ultraFiltArray[this.self.ultraFiltArray.length - 1];
                }
                this.sharedData.robots[ref_name].Data[Data.UltrasonicFiltered] = dist;

                if (this.self.ultraFiltArray.length > this.bufferSize) {
                    this.self.ultraFiltArray.splice(0, 1)
                }
                this.self.ultraFiltArray.push(dist);
                
                break;
            }
            // Accelerometer Sensor Data - 3 32-bit Floats
            case Data.Accelerometer: {
                let buf = new ArrayBuffer(4);
                let view = new DataView(buf);
                let dataX = data.slice(3, 7);
                let dataY = data.slice(7, 11);
                let dataZ = data.slice(11, 15);                
                let me = this;
                dataX.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let ax = view.getFloat32(0);
                dataY.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let ay = view.getFloat32(0);
                dataZ.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let az = view.getFloat32(0);
                this.sharedData.robots[ref_name].Data[Data.Accelerometer].x = ax;
                this.sharedData.robots[ref_name].Data[Data.Accelerometer].y = ay;
                this.sharedData.robots[ref_name].Data[Data.Accelerometer].z = az;

                if (this.self.axArray.length > this.bufferSize) {    
                    this.self.axArray.splice(0, 1);
                    this.self.ayArray.splice(0, 1);
                    this.self.azArray.splice(0, 1);
                }                
                this.self.axArray.push(ax);
                this.self.ayArray.push(ay);
                this.self.azArray.push(az);
                
                break;
            }
            // Gyroscope Sensor Data - 3 32-bit Floats
            case Data.Gyroscope: {
                let buf = new ArrayBuffer(4);
                let view = new DataView(buf);
                let dataX = data.slice(3, 7);
                let dataY = data.slice(7, 11);
                let dataZ = data.slice(11, 15);
                let me = this;
                dataX.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let gx = view.getFloat32(0);
                dataY.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let gy = view.getFloat32(0);
                dataZ.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let gz = view.getFloat32(0);
                this.sharedData.robots[ref_name].Data[Data.Gyroscope].x = gx;
                this.sharedData.robots[ref_name].Data[Data.Gyroscope].y = gy;
                this.sharedData.robots[ref_name].Data[Data.Gyroscope].z = gz;

                if (this.self.gxArray.length > this.bufferSize) {    
                    this.self.gxArray.splice(0, 1);
                    this.self.gyArray.splice(0, 1);
                    this.self.gzArray.splice(0, 1);
                }                
                this.self.gxArray.push(gx);
                this.self.gyArray.push(gy);
                this.self.gzArray.push(gz);

                break;
            }
            // Encoders Sensor Data - 2 32-bit Signed Int
            case Data.Encoders: {
                let buf = new ArrayBuffer(4);
                let view = new DataView(buf);
                let dataL = data.slice(3, 7);
                let dataR = data.slice(7, 11);
                dataL.forEach(function (b, i) {
                    view.setUint8(i, b);
                });
                let left = view.getInt32(0);
                dataR.forEach(function (b, i) {
                    view.setUint8(i, b);
                });
                let right = view.getInt32(0);
                this.sharedData.robots[ref_name].Data[Data.Encoders].left = left;
                this.sharedData.robots[ref_name].Data[Data.Encoders].right = right;

                if (this.self.encLArray.length > this.bufferSize) {    
                    this.self.encLArray.splice(0, 1);
                    this.self.encRArray.splice(0, 1);
                }                
                this.self.encLArray.push(left);
                this.self.encRArray.push(right);

                break;
            }
            // Temperature Sensor Data - 1 32-bit Floats
            case Data.Temperature: {
                let dataTemp = data.slice(3, 7);        
                let buf = new ArrayBuffer(4);
                let view = new DataView(buf);
                let me = this;
                dataTemp.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                this.sharedData.robots[ref_name].Data[Data.Temperature] = view.getFloat32(0);
                
                if (this.self.tempArray.length > this.bufferSize) {    
                    this.self.tempArray.splice(0, 1);
                }                
                this.self.tempArray.push(this.sharedData.robots[ref_name].Data[Data.Temperature]);

                break;
            }
            // Battery Sensor Data - 1 32-bit Floats
            case Data.Battery: {
                let dataBatt = data.slice(3, 7);
                let buf = new ArrayBuffer(4);
                let view = new DataView(buf);
                let me = this;
                dataBatt.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                this.sharedData.robots[ref_name].Data[Data.Battery] = view.getFloat32(0);
                if (this.self.batteryArray.length > this.bufferSize) {    
                    this.self.batteryArray.splice(0, 1);
                }
                this.self.batteryArray.push(this.sharedData.robots[ref_name].Data[Data.Battery]);

                break;
            }
            // Magnetometer Sensor Data - 3 32-bit Floats
            case Data.Magnetometer: {
                let buf = new ArrayBuffer(4);
                let view = new DataView(buf);
                let dataX = data.slice(3, 7);
                let dataY = data.slice(7, 11);
                let dataZ = data.slice(11, 15);
                let me = this;
                dataX.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let mx = view.getFloat32(0);
                dataY.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let my = view.getFloat32(0);
                dataZ.forEach(function (b, i) {
                    view.setUint8(me.endianFactor - i, b);
                });
                let mz = view.getFloat32(0);
                this.sharedData.robots[ref_name].Data[Data.Magnetometer].x = mx;
                this.sharedData.robots[ref_name].Data[Data.Magnetometer].y = my;
                this.sharedData.robots[ref_name].Data[Data.Magnetometer].z = mz;

                if (this.self.mxArray.length > this.bufferSize) {    
                    this.self.mxArray.splice(0, 1);
                    this.self.myArray.splice(0, 1);
                    this.self.mzArray.splice(0, 1);
                }                
                this.self.mxArray.push(mx);
                this.self.myArray.push(my);
                this.self.mzArray.push(mz);

                break;
            }
        }
    }



}



// Message Types for USB Packets Sent Over WebUSB
var OutgoingUSBMessageType = {
    Echo: 0,
    BeginScanning: 1,
    EndScanning: 2,
    Connect: 3,
    PassThrough: 4,
    ResetBLE: 5,
    RequestHardwareVersion: 6,
    RequestFirmwareVersion: 7,
    EchoASCII: 48,
};


// Message Types for Incoming USB Packets Sent Through WebUSB
var IncomingUSBMessageType = {
    NewRobot: 0,
    RobotConnected: 1,
    RobotDisconnected: 2,
    Debug: 3,
    HardwareVersion: 4,
    FirmwareVersion: 5,
    RobotPacket: 160
};

// Message Types for a "RobotPacket"
var MessageType = {
    APIMessage: 0,
    Control: 1,
    Data: 2,
    Preference: 3,
    Program: 4,
    DongleCommand: 5,
    DongleEvent: 6,
    USBCommand: 7,
    USBEvent: 8,
    PreferenceRequest: 9,
    State: 10,
    StateRequest: 11,
    DataRequest: 12,
    Calibration: 13,
};

var Data = {
    Ultrasonic: 0,
    UltrasonicFiltered: 1,
    Accelerometer: 2,
    Gyroscope: 3,
    Temperature: 4,
    Battery: 5,
    Encoders: 6,
    Magnetometer: 7,
};

// Preference Types for a "Preference" "Robot Packet"
var Preference = {
    MotorLeftFlip: 0,
    MotorRightFlip: 1,
    Name: 2,
    UltrasonicFiltering: 3,
    Reset: 4,
    RTicks: 5,
    MMPerRev: 6,
    PIDDist: 7,
    PIDVel: 8,
};


// Control Types for a "Control" "Robot Packet"
var Control = {
    ActivateMotors: 0,
    DeactivateMotors: 1,
    Motors: 2,
    LightsRGB: 3,
    SyncLightsRGB: 4,
    PowerOff: 5,
    Disconnect: 7,
    SetupWait: 12,
    MotorsWithWait: 13,
    PlayNote: 16,
    PlaySong: 17,
    Lights: 18,
};

// For Access to WaitType Constants Throughout This File
export var WaitTypeC = {
    Time: 0,
    UltrasonicGreaterThan: 1,
    UltrasonicLessThan: 2,
    Song: 3,
    Distance: 4,
    Rotation: 5,
};

export var MotorDirectionC = {
    Forward: 0,
    Backward: 1,
};

// Preference Types for a "Preference" "Robot Packet"
export var Preference = {
    MotorLeftFlip: 0,
    MotorRightFlip: 1,
    Name: 2,
    UltrasonicFiltering: 3,
    Reset: 4,
    RTicks: 5,
    MMPerRev: 6,
    PIDDist: 7,
    PIDVel: 8,
    VelTargets: 9,
};
// 
export var PreferenceSettingC = {
    PreferenceSet: 0, 
    PreferenceGet: 1,
} 

var USBIndexType2 = [
    IncomingUSBMessageType.NewRobot,
    IncomingUSBMessageType.RobotConnected,
    IncomingUSBMessageType.RobotDisconnected,
    IncomingUSBMessageType.Debug,
];
