// Child Component of: 
// --- BaseBlock

// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    paper: {
      width: 515,//600
      height: 200,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
    },
    grid: {
        justify: 'flex-start',
    },
    titleText: {
        color: "white",
        margin: theme.spacing(1),
    },
    inputField: {
        width: "100%",
        color: "white",
        marginTop: theme.spacing(0.6),
        marginLeft: theme.spacing(1),
    },
    regularText: {
        color: "white",
        margin: theme.spacing(1),
    },
    textButton: {
        color: "white",
        margin: theme.spacing(0),
        border: '1px solid #000',
    },
});


// Object That Stores Characteristics For Each Block Type
/*var LDBlocksOptions = {
    itakeoff: 
    {
        id: 0,
        title: 'Takeoff',
        height: 200, 
        color: '#ec4078',
        control: {},
        code: ['# Command Drone to Takeoff', 'loco_drone.drone_takeoff()'],
    },
    iland: 
    {
        id: 0,
        title: 'Land',
        height: 200, 
        color: '#ec4078',
        control: {},
        code: ['# Command Drone to Land', 'loco_drone.drone_land()'],
    },
    iloop:
    {
        id: 0,
        title: 'Loop',
        height: 200, 
        color: '#8057c1',
        control: {in1: 0, },
        codeRef(ctrl) {
            return [`# Loop for ${ctrl.in1} times`, `for i in range(${ctrl.in1}):`]
        },
        code: [],
        inWidth: '70px',
        inType: 'int',
        text1: 'Repeat',
        text2: 'times',
    },
    iloopend:
    {        
        id: 0,
        title: '',
        height: 200, 
        color: '#8057c1',
        control: {},
        code: [],
    },
    ijoystick:
    {
        id: 0,
        title: 'Joystick Mode',
        height: 200, 
        color: '#f16749',
        control: {in1: 0, },
        codeRef(ctrl) {
            return [`# Fly in Joystick Mode for ${ctrl.in1} seconds`, `loco_drone.set_mode(loco_drone.MODE_JOYSTICK)`, `time.sleep(${ctrl.in1})`, `loco_drone.set_mode(loco_drone.MODE_CONTROL)`]
        },
        code: [],
        inWidth: '50px',
        inType: 'float',
        text1: 'Fly in Joystick Mode for',
        text2: 'seconds',
    },
    itilt:
    {
        id: 0,
        title: 'Tilt Mode',
        height: 200, 
        color: '#f16749',
        control: {in1: 0, },
        codeRef(ctrl) {
            return [`# Fly in Tilt Mode for ${ctrl.in1} seconds`, `loco_drone.set_mode(loco_drone.MODE_ACCELEROMETER)`, `time.sleep(${ctrl.in1})`, `loco_drone.set_mode(loco_drone.MODE_CONTROL)`]
        },
        code: [],
        inWidth: '50px',
        inType: 'float',
        text1: 'Fly in Tilt Mode for',
        text2: 'seconds',
    },
    ihover:
    {
        id: 0,
        title: 'Hover',
        height: 200, 
        color: '#fbc22d',
        control: {in1: 0, },
        codeRef(ctrl) {
            return [`# Hover for ${ctrl.in1} seconds`, `loco_drone.set_data(0, 0, 0)`, `time.sleep(${ctrl.in1})`]
        },
        code: [],
        inWidth: '70px',
        inType: 'float',
        text1: 'Hover for',
        text2: 'seconds',
    },
    iflip:
    {
        id: 0,
        title: 'Flip',
        height: 200, 
        color: '#2196f3',
        control: {btn1: 'left', },
        codeRef(ctrl) {
            return [`# Flip ${ctrl.btn1Txt}`, `loco_drone.${ctrl.btn1Txt}_flip_drone()`]
        },
        code: [],
        text1: 'Flip Drone ',
        btn1Option1: 'left',
        btn1Option2: 'right',
        btn1map: {'left': 1, 'right':-1},
    },
    iroll:
    {
        id: 0,
        title: 'Roll',
        height: 200, 
        color: '#4caf50',
        control: {btn1: '1', btn1Txt: 'left', in1: 0, in2: 0},
        codeRef(ctrl) {
            return [`# Fly ${ctrl.btn1Txt} at ${ctrl.in1} degrees for ${ctrl.in2} seconds`, `loco_drone.set_data(${ctrl.btn1 * ctrl.in1}, 0, 0)`, `time.sleep(${ctrl.in2})`]
        },
        code: [],
        text1: 'Fly ',
        text2: 'at',
        text3: 'degrees',
        text4: 'for',
        text5: 'seconds',
        btn1Option1: 'left',
        btn1Option2: 'right',
        btn1map: {'left': 1, 'right':-1},
        inWidth: '70px',
        in1Type: 'float',
        in2Width: '70px',
        in2Type: 'float',
        in1Limits: 45,
    },
    ipitch:
    {
        id: 0,
        title: 'Pitch',
        height: 200, 
        color: '#4caf50',
        control: {btn1: '1', btn1Txt: 'forward', in1: 0, in2: 0},
        codeRef(ctrl) {
            return [`# Fly ${ctrl.btn1Txt} at ${ctrl.in1} degrees for ${ctrl.in2} seconds`, `loco_drone.set_data(0, ${ctrl.btn1 * ctrl.in1}, 0)`, `time.sleep(${ctrl.in2})`]
        },
        code: [],
        text1: 'Fly ',
        text2: 'at',
        text3: 'degrees',
        text4: 'for',
        text5: 'seconds',
        btn1Option1: 'forward',
        btn1Option2: 'backward',
        btn1map: {'forward': 1, 'backward':-1},
        inWidth: '70px',
        in1Type: 'float',
        in2Width: '70px',
        in2Type: 'float',
        in1Limits: 30,
    },
    ivertical: {
        id: 0,
        title: 'Vertical',
        height: 200, 
        color: '#009788',
        control: {btn1: '1', btn1Txt: 'up', in1: 0, },
        codeRef(ctrl) {
            return [`# Fly ${ctrl.btn1Txt} for ${ctrl.in1} seconds`, `loco_drone.set_data(0, 0, ${ctrl.btn1})`, `time.sleep(${ctrl.in1})`]
        },
        code: [],
        text1: 'Fly ',
        text2: 'for',
        text3: 'seconds',
        btn1Option1: 'up',
        btn1Option2: 'down',
        btn1map: {'up': 1, 'down':-1},
        inWidth: '70px',
        in1Type: 'float',
    },
    irp:
    {
        id: 0,
        title: 'Roll and Pitch',
        height: 200, 
        color: '#4caf50',
        control: {btn1: '1', btn1Txt: 'left', btn2: '1', btn2Txt: 'forward', in1: 0, in2: 0, in3: 0},
        codeRef(ctrl) {
            return [`# Fly ${ctrl.btn1Txt} at ${ctrl.in1} degrees and ${ctrl.btn2Txt} at ${ctrl.in2} degrees for ${ctrl.in3} seconds`, `loco_drone.set_data(${ctrl.btn1 * ctrl.in1}, ${ctrl.btn2 * ctrl.in2}, 0)`, `time.sleep(${ctrl.in3})`]
        },
        code: [],
        text1: 'Fly ',
        text2: 'at',
        text3: 'degrees and',
        text4: 'Fly',
        text5: 'at',
        text6: 'degrees',
        text7: 'for',
        text8: 'seconds',
        btn1Option1: 'left',
        btn1Option2: 'right',
        btn2Option1: 'forward',
        btn2Option2: 'backward',
        btn1map: {'left': 1, 'right':-1},
        btn2map: {'forward': 1, 'backward':-1},
        inWidth: '70px',
        in1Type: 'float',
        in2Width: '70px',
        in2Type: 'float',
        in3Width: '70px',
        in3Type: 'float',
        in1Limits: 45,
        in2Limits: 30,
    },    
    itrim:
    {
        id: 0,
        title: 'Trim',
        height: 200, 
        color: '#f16749',
        control: {},
        code: [`# Trim Drone's Roll and Pitch`, `loco_drone.trim_orientation()`],
        text1: 'Tilt the controller in different directions and return to level to adjust the offset values for roll and pitch. Press the Right Joystick button to continue.',
        text2: '',
        text3: ''
    }
};
*/



// Component Class - ProgramDisplay
class LocoDroneBlocks extends React.Component {

    state = {
        LDBlocksOptions: {
            itakeoff: 
            {
                id: 0,
                title: 'Takeoff',
                height: 200, 
                color: '#ec4078',
                control: {},
                code: ['# Command Drone to Takeoff', 'loco_drone.drone_takeoff()'],
            },
            iland: 
            {
                id: 0,
                title: 'Land',
                height: 200, 
                color: '#ec4078',
                control: {},
                code: ['# Command Drone to Land', 'loco_drone.drone_land()'],
            },
            iloop:
            {
                id: 0,
                title: 'Loop',
                height: 200, 
                color: '#8057c1',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Loop for ${ctrl.in1} times`, `for i in range(${ctrl.in1}):`]
                },
                code: [],
                inWidth: '70px',
                inType: 'int',
                text1: 'Repeat',
                text2: 'times',
            },
            iloopend:
            {        
                id: 0,
                title: 'LoopEnd',
                height: 200, 
                color: '#8057c1',
                control: {},
                code: [],
            },
            ijoystick:
            {
                id: 0,
                title: 'Joystick Mode',
                height: 200, 
                color: '#f16749',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Fly in Joystick Mode for ${ctrl.in1} seconds`, `loco_drone.set_mode(loco_drone.MODE_JOYSTICK)`, `time.sleep(${ctrl.in1})`, `loco_drone.set_mode(loco_drone.MODE_CONTROL)`]
                },
                code: [],
                inWidth: '50px',
                inType: 'float',
                text1: 'Fly in Joystick Mode for',
                text2: 'seconds',
            },
            itilt:
            {
                id: 0,
                title: 'Tilt Mode',
                height: 200, 
                color: '#f16749',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Fly in Tilt Mode for ${ctrl.in1} seconds`, `loco_drone.set_mode(loco_drone.MODE_ACCELEROMETER)`, `time.sleep(${ctrl.in1})`, `loco_drone.set_mode(loco_drone.MODE_CONTROL)`]
                },
                code: [],
                inWidth: '50px',
                inType: 'float',
                text1: 'Fly in Tilt Mode for',
                text2: 'seconds',
            },
            ihover:
            {
                id: 0,
                title: 'Hover',
                height: 200, 
                color: '#fbc22d',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Hover for ${ctrl.in1} seconds`, `loco_drone.set_data(0, 0, 0)`, `time.sleep(${ctrl.in1})`]
                },
                code: [],
                inWidth: '70px',
                inType: 'float',
                text1: 'Hover for',
                text2: 'seconds',
            },
            iflip:
            {
                id: 0,
                title: 'Flip',
                height: 200, 
                color: '#2196f3',
                control: {btn1: 0, btn1Txt: 'left', },
                codeRef(ctrl) {
                    return [`# Flip ${ctrl.btn1Txt}`, `loco_drone.${ctrl.btn1Txt}_flip_drone()`]
                },
                code: [],
                text1: 'Flip Drone ',
                btn1Option1: 'left',
                btn1Option2: 'right',
                btn1map: {'left': 1, 'right':-1},
            },
            iroll:
            {
                id: 0,
                title: 'Roll',
                height: 200, 
                color: '#4caf50',
                control: {btn1: '1', btn1Txt: 'left', in1: 0, in2: 0},
                codeRef(ctrl) {
                    return [`# Fly ${ctrl.btn1Txt} at ${ctrl.in1} degrees for ${ctrl.in2} seconds`, `loco_drone.set_data(${ctrl.btn1 * ctrl.in1}, 0, 0)`, `time.sleep(${ctrl.in2})`, `loco_drone.set_data(0, 0, 0)`]
                },
                code: [],
                text1: 'Fly ',
                text2: 'at',
                text3: 'degrees',
                text4: 'for',
                text5: 'seconds',
                btn1Option1: 'left',
                btn1Option2: 'right',
                btn1map: {'left': 1, 'right':-1},
                inWidth: '70px',
                in1Type: 'float',
                in2Width: '70px',
                in2Type: 'float',
                in1Limits: 45,
            },
            ipitch:
            {
                id: 0,
                title: 'Pitch',
                height: 200, 
                color: '#4caf50',
                control: {btn1: '1', btn1Txt: 'forward', in1: 0, in2: 0},
                codeRef(ctrl) {
                    return [`# Fly ${ctrl.btn1Txt} at ${ctrl.in1} degrees for ${ctrl.in2} seconds`, `loco_drone.set_data(0, ${ctrl.btn1 * ctrl.in1}, 0)`, `time.sleep(${ctrl.in2})`, `loco_drone.set_data(0, 0, 0)`]
                },
                code: [],
                text1: 'Fly ',
                text2: 'at',
                text3: 'degrees',
                text4: 'for',
                text5: 'seconds',
                btn1Option1: 'forward',
                btn1Option2: 'backward',
                btn1map: {'forward': 1, 'backward':-1},
                inWidth: '70px',
                in1Type: 'float',
                in2Width: '60px',
                in2Type: 'float',
                in1Limits: 30,
            },
            ivertical: {
                id: 0,
                title: 'Vertical',
                height: 200, 
                color: '#009788',
                control: {btn1: '1', btn1Txt: 'up', in1: 0, },
                codeRef(ctrl) {
                    return [`# Fly ${ctrl.btn1Txt} for ${ctrl.in1} seconds`, `loco_drone.set_data(0, 0, ${ctrl.btn1})`, `time.sleep(${ctrl.in1})`, `loco_drone.set_data(0, 0, 0)`]
                },
                code: [],
                text1: 'Fly ',
                text2: 'for',
                text3: 'seconds',
                btn1Option1: 'up',
                btn1Option2: 'down',
                btn1map: {'up': 1, 'down':-1},
                inWidth: '70px',
                in1Type: 'float',
            },
            irp:
            {
                id: 0,
                title: 'Roll and Pitch',
                height: 200, 
                color: '#4caf50',
                control: {btn1: '1', btn1Txt: 'left', btn2: '1', btn2Txt: 'forward', in1: 0, in2: 0, in3: 0},
                codeRef(ctrl) {
                    return [`# Fly ${ctrl.btn1Txt} at ${ctrl.in1} degrees and ${ctrl.btn2Txt} at ${ctrl.in2} degrees for ${ctrl.in3} seconds`, `loco_drone.set_data(${ctrl.btn1 * ctrl.in1}, ${ctrl.btn2 * ctrl.in2}, 0)`, `time.sleep(${ctrl.in3})`, `loco_drone.set_data(0, 0, 0)`]
                },
                code: [],
                text1: 'Fly ',
                text2: 'at',
                text3: 'degrees and',
                text4: 'Fly',
                text5: 'at',
                text6: 'degrees',
                text7: 'for',
                text8: 'seconds',
                btn1Option1: 'left',
                btn1Option2: 'right',
                btn2Option1: 'forward',
                btn2Option2: 'backward',
                btn1map: {'left': 1, 'right':-1},
                btn2map: {'forward': 1, 'backward':-1},
                inWidth: '70px',
                in1Type: 'float',
                in2Width: '60px',
                in2Type: 'float',
                in3Width: '70px',
                in3Type: 'float',
                in1Limits: 45,
                in2Limits: 30,
            },    
            itrim:
            {
                id: 0,
                title: 'Trim',
                height: 200, 
                color: '#f16749',
                control: {},
                code: [`# Trim Drone's Roll and Pitch`, `loco_drone.trim_orientation()`],
                text1: 'Tilt the controller in different directions and return to level to adjust the offset values for roll and pitch. Press the Right Joystick button to continue.',
                text2: '',
                text3: ''
            }
        },
        renderType: this.props.blockType,
        btn1: 0,
        btn2: 0,
        btn1Txt: '',
        btn2Txt: '',
        in1: 0,
        in2: 0,
        in3: 0,
    };

    componentDidMount() {
        let updates = {control: this.props.control};
        this.componentRefresh(updates);
    }

    componentRefresh = (updates) => {
        
        if ('name' in updates) {
            this.setState( {renderType: updates.name} );
        }

        let tempLDBlocks = this.state.LDBlocksOptions;
        
        tempLDBlocks[this.state.renderType].id = this.props.id
        if ((typeof updates.control !== "undefined") && (Object.keys(updates.control).length !== 0)) {
            let keyNames = Object.keys(updates.control);
            
            keyNames.forEach((val, i) => {
                tempLDBlocks[this.state.renderType].control[val] = updates.control[val];
                this.setState( {[val]: updates.control[val]} );
                if (tempLDBlocks[this.state.renderType].hasOwnProperty('codeRef')) {
                    tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
                }
            });
        } else {
            if (tempLDBlocks[this.state.renderType].hasOwnProperty('codeRef')) {
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            }

            if ('btn1' in tempLDBlocks[this.state.renderType].control) {
                tempLDBlocks[this.state.renderType].control.btn1 = tempLDBlocks[this.state.renderType].btn1map[tempLDBlocks[this.state.renderType].btn1Option1];
                tempLDBlocks[this.state.renderType].control.btn1Txt = tempLDBlocks[this.state.renderType].btn1Option1;
                this.setState( {btn1Txt: tempLDBlocks[this.state.renderType].control.btn1Txt} );
            }
            if ('btn2' in tempLDBlocks[this.state.renderType].control) {
                tempLDBlocks[this.state.renderType].control.btn2 = tempLDBlocks[this.state.renderType].btn2map[tempLDBlocks[this.state.renderType].btn2Option1];
                tempLDBlocks[this.state.renderType].control.btn2Txt = tempLDBlocks[this.state.renderType].btn2Option1;
                this.setState( {btn2Txt: tempLDBlocks[this.state.renderType].control.btn2Txt} );
            }
            if ('in1' in tempLDBlocks[this.state.renderType].control) {
                tempLDBlocks[this.state.renderType].control.in1 = 0;
                this.setState( {in1: tempLDBlocks[this.state.renderType].control.in1} );
            }
            if ('in2' in tempLDBlocks[this.state.renderType].control) {
                tempLDBlocks[this.state.renderType].control.in2 = 0;
                this.setState( {in1: tempLDBlocks[this.state.renderType].control.in2} );
            }
            if ('in3' in tempLDBlocks[this.state.renderType].control) {
                tempLDBlocks[this.state.renderType].control.in3 = 0;
                this.setState( {in3: tempLDBlocks[this.state.renderType].control.in3} );
            }
        }
        
        this.setState( {LDBlocksOptions: tempLDBlocks} );
        this.props.handleSubState(tempLDBlocks[this.state.renderType]);
    }

    handleIn1 = (event) => {
        let m = event.target.value;
        if (typeof m[1] !== 'undefined') {
            if (m[1] !== ".") {
                m = m.replace(/^0+/, '');
                if (m === "") {
                    m = "0"
                }
            }
        } 
        let tempLDBlocks = this.state.LDBlocksOptions;
        if (!isNaN(parseFloat(m)) && isFinite(m)) {
            let n = Number(m);
            
            if ('in1Limits' in tempLDBlocks[this.state.renderType]) {
                if (n > tempLDBlocks[this.state.renderType].in1Limits) {
                    n = tempLDBlocks[this.state.renderType].in1Limits;
                    m = tempLDBlocks[this.state.renderType].in1Limits;
                }
            }

            if (tempLDBlocks[this.state.renderType].inType === 'int') {
                tempLDBlocks[this.state.renderType].control.in1 = Math.floor(n);
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            } else {
                tempLDBlocks[this.state.renderType].control.in1 = n;
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            }
            this.setState( {[event.target.name]: m} );
        } else {
            tempLDBlocks[this.state.renderType].control.in1 = 0;
            tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            this.setState( {[event.target.name]: 0} );
        }
        this.setState( {LDBlocksOptions: tempLDBlocks} );
        this.props.handleSubState(tempLDBlocks[this.state.renderType]);
    }

    handleIn2 = (event) => {
        let m = event.target.value;
        if (typeof m[1] !== 'undefined') {
            if (m[1] !== ".") {
                m = m.replace(/^0+/, '');
                if (m === "") {
                    m = "0"
                }
            }
        } 
        let tempLDBlocks = this.state.LDBlocksOptions;
        if (!isNaN(parseFloat(m)) && isFinite(m)) {
            let n = Number(m);

            if ('in2Limits' in tempLDBlocks[this.state.renderType]) {
                if (n > tempLDBlocks[this.state.renderType].in2Limits) {
                    n = tempLDBlocks[this.state.renderType].in2Limits;
                    m = tempLDBlocks[this.state.renderType].in2Limits;
                }
            }

            if (tempLDBlocks[this.state.renderType].inType === 'int') {
                tempLDBlocks[this.state.renderType].control.in2 = Math.floor(n);
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            } else {
                tempLDBlocks[this.state.renderType].control.in2 = n;
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            }
            this.setState( {[event.target.name]: m} );
        } else {
            tempLDBlocks[this.state.renderType].control.in2 = 0;
            tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            this.setState( {[event.target.name]: 0} );
        }
        this.setState( {LDBlocksOptions: tempLDBlocks} );
        this.props.handleSubState(tempLDBlocks[this.state.renderType]);
    }

    
    handleIn3 = (event) => {
        let m = event.target.value;
        if (typeof m[1] !== 'undefined') {
            if (m[1] !== ".") {
                m = m.replace(/^0+/, '');
                if (m === "") {
                    m = "0"
                }
            }
        }  
        let tempLDBlocks = this.state.LDBlocksOptions;
        if (!isNaN(parseFloat(m)) && isFinite(m)) {
            let n = Number(m);
            if (tempLDBlocks[this.state.renderType].inType === 'int') {
                tempLDBlocks[this.state.renderType].control.in3 = Math.floor(n);
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            } else {
                tempLDBlocks[this.state.renderType].control.in3 = n;
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            }
            this.setState( {[event.target.name]: m} );
        } else {
            tempLDBlocks[this.state.renderType].control.in3 = 0;
            tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            this.setState( {[event.target.name]: 0} );
        }
        this.setState( {LDBlocksOptions: tempLDBlocks} );
        this.props.handleSubState(tempLDBlocks[this.state.renderType]);
    }

    handleBtn1 = (event) => {

        let tempLDBlocks = this.state.LDBlocksOptions;
        if ('btn1map' in tempLDBlocks[this.state.renderType]) {
            if (tempLDBlocks[this.state.renderType].control.btn1Txt === tempLDBlocks[this.state.renderType].btn1Option1) {
                tempLDBlocks[this.state.renderType].control.btn1 = 
                    tempLDBlocks[this.state.renderType].btn1map[tempLDBlocks[this.state.renderType].btn1Option2];                
                tempLDBlocks[this.state.renderType].control.btn1Txt = tempLDBlocks[this.state.renderType].btn1Option2;
                    
            } else {
                tempLDBlocks[this.state.renderType].control.btn1 = 
                    tempLDBlocks[this.state.renderType].btn1map[tempLDBlocks[this.state.renderType].btn1Option1];
                tempLDBlocks[this.state.renderType].control.btn1Txt = tempLDBlocks[this.state.renderType].btn1Option1;                    
            }
            this.setState( {btn1Txt: tempLDBlocks[this.state.renderType].control.btn1Txt} );
        } else {
            if (tempLDBlocks[this.state.renderType].control.btn1 === tempLDBlocks[this.state.renderType].btn1Option1) {
                tempLDBlocks[this.state.renderType].control.btn1 = tempLDBlocks[this.state.renderType].btn1Option2;
            } else {
                tempLDBlocks[this.state.renderType].control.btn1 = tempLDBlocks[this.state.renderType].btn1Option1;
            }
            this.setState( {btn1Txt: tempLDBlocks[this.state.renderType].control.btn1Txt} );
        }
        tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
        
        this.setState( {LDBlocksOptions: tempLDBlocks} );
        this.props.handleSubState(tempLDBlocks[this.state.renderType]);
    }

    handleBtn2 = (event) => {

        let tempLDBlocks = this.state.LDBlocksOptions;
        if ('btn2map' in tempLDBlocks[this.state.renderType]) {
            if (tempLDBlocks[this.state.renderType].control.btn2Txt === tempLDBlocks[this.state.renderType].btn2Option1) {
                tempLDBlocks[this.state.renderType].control.btn2 = 
                    tempLDBlocks[this.state.renderType].btn2map[tempLDBlocks[this.state.renderType].btn2Option2];                
                tempLDBlocks[this.state.renderType].control.btn2Txt = tempLDBlocks[this.state.renderType].btn2Option2;
                    
            } else {
                tempLDBlocks[this.state.renderType].control.btn2 = 
                    tempLDBlocks[this.state.renderType].btn2map[tempLDBlocks[this.state.renderType].btn2Option1];
                tempLDBlocks[this.state.renderType].control.btn2Txt = tempLDBlocks[this.state.renderType].btn2Option1;                    
            }
            this.setState( {btn2Txt: tempLDBlocks[this.state.renderType].control.btn2Txt} );
        } else {
            if (tempLDBlocks[this.state.renderType].control.btn2 === tempLDBlocks[this.state.renderType].btn2Option1) {
                tempLDBlocks[this.state.renderType].control.btn2 = tempLDBlocks[this.state.renderType].btn2Option2;
            } else {
                tempLDBlocks[this.state.renderType].control.btn2 = tempLDBlocks[this.state.renderType].btn2Option1;
            }
            this.setState( {btn2Txt: tempLDBlocks[this.state.renderType].control.btn2} );
        }
        tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
        
        this.setState( {LDBlocksOptions: tempLDBlocks} );
        this.props.handleSubState(tempLDBlocks[this.state.renderType]);
    }


    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Box 
                    height={this.state.LDBlocksOptions[this.state.renderType].height}
                    style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                >
                    <Typography 
                        className={classes.titleText}
                        variant='h4'
                        style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                    >
                        {this.state.LDBlocksOptions[this.state.renderType].title}
                    </Typography>
                    {
                    ((this.state.renderType === 'iloop') 
                    || (this.state.renderType === 'ijoystick')
                    || (this.state.renderType === 'itilt')
                    || (this.state.renderType === 'ihover')) && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{width: this.state.LDBlocksOptions[this.state.renderType].inWidth}}
                                onChange = {this.handleIn1}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'iflip' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={this.handleBtn1}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        </Grid>
                    )}
                    {((this.state.renderType === 'iroll') || (this.state.renderType === 'ipitch')) && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={this.handleBtn1}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{width: this.state.LDBlocksOptions[this.state.renderType].inWidth}}
                                onChange = {this.handleIn1}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text4}
                            </Typography>
                        </Grid>                        
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in2'
                                style={{width: this.state.LDBlocksOptions[this.state.renderType].inWidth}}
                                onChange = {this.handleIn2}
                                value={this.state.in2}
                                disabled={this.props.isDisabled}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text5}
                            </Typography>
                        </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'ivertical' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={this.handleBtn1}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{width: this.state.LDBlocksOptions[this.state.renderType].inWidth}}
                                onChange = {this.handleIn1}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>

                        </Grid>
                    )}
                    {this.state.renderType === 'irp' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={this.handleBtn1}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{width: this.state.LDBlocksOptions[this.state.renderType].inWidth}}
                                onChange = {this.handleIn1}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text4}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={this.handleBtn2}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn2Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text5}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in2'
                                style={{width: this.state.LDBlocksOptions[this.state.renderType].in2Width}}
                                onChange = {this.handleIn2}
                                value={this.state.in2}
                                disabled={this.props.isDisabled}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text6}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text7}
                            </Typography>
                        </Grid>                        
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in3'
                                style={{width: this.state.LDBlocksOptions[this.state.renderType].in3Width}}
                                onChange = {this.handleIn3}
                                value={this.state.in3}
                                disabled={this.props.isDisabled}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text8}
                            </Typography>
                        </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'itrim' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>                        
                        <Grid item xs={12}></Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        
                        <Grid item xs={12}></Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>
                        </Grid>
                    )}


                </Box>
            </React.Fragment>
        );

    }

}


export default withStyles(styles)(LocoDroneBlocks);
