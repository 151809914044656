// Parent Component of:
// --- ProgramList
// --- ProgramCreate
// --- ProgramCoding

// Import React and Material-UI Modules
import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import Topbar from "./Topbar";


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


// Import Custom Components
import ProgramCreate from "./ProgramCreate";
import ProgramCoding from "./ProgramCoding"
import ProgramList from "./ProgramList";
import ProgramGUI from "./ProgramGUI";

// Import Context to Access Auth/User Information
import { AuthContext } from "../context/auth";
import { Button } from "@material-ui/core";

// Material-UI CSS-type Style Specifications - not all are used
const styles = theme => ({
  root: {
    backgroundColor: "white",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),    
    flexGrow: 1,
    //height: '750px',
    //paddingBottom: 200,
  },
  grid: {
  },
  gridSplit: {
  },
  loadingState: {
    opacity: 0.05
  },
  actionButton: {
    justify: "right",
    margin: theme.spacing(2),
    maxHeight: 16,
    width: 580
  },
  paper: {
    textAlign: "left",
    backgroundColor: theme.palette.primary["light"],
    color: theme.palette.primary["contrastText"],
    height: window.programBarOffset,
  },
  paperList: {
    display: 'flex',
    textAlign: "left",
    backgroundColor: theme.palette.primary["light"],
    color: theme.palette.primary["contrastText"],
    height: window.programBarOffset,
  },
  form: {
    padding: theme.spacing(0),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    textAlign: "left",
    backgroundColor: theme.palette.primary["constrastText"],
  },
  inputLabel: {
    color: "white",
    "&.Mui-focused": {
      color: "white"
    }
  },
  inputFocused: {
  },
  inputHelper: {
    color: "white",
  },
  rangeLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2)
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  outlinedButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1)
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1),
    width: 152,
    height: 36
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  block: {
    padding: theme.spacing(2)
  },
  loanAvatar: {
    display: "inline-block",
    verticalAlign: "center",
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: -2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  interestAvatar: {
    display: "inline-block",
    verticalAlign: "center",
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: -2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light
  },
  inlining: {
    display: "inline-block",
    marginRight: 10
  },
  buttonBar: {
    display: "flex"
  },
  noBorder: {
    borderBottomStyle: "hidden"
  },
  userOptions: {
    marginTop: 10,
    justify: 'flex-end',
  },
  mainBadge: {
    textAlign: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  headerText: {
    padding: theme.spacing(2),
  },
  aboutFilter: {
    color: "white",
    backgroundColor: theme.palette.primary["light"],
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(3.5),
  },
  actionButton: {
    justify: "right",
    color: "white",
    backgroundColor: theme.palette.primary["light"],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //marginTop: theme.spacing(2),
    //margin: theme.spacing(2),
  },
});



// Component Class - Program
class Program extends Component {


  static contextType = AuthContext;

  constructor(props) {
    super(props);


    this.linkedGUI = React.createRef();
    this.linkedCoding = React.createRef();

    this.linkedGUIS = React.createRef();
    this.linkedCodingS = React.createRef();

    this.programList = React.createRef();

    
    this.filterword = '';

  }

  // Class state
  state = {
    activeStep: 0,
    data: [],
    progDetails: {},
    canPassCode: false,
    userMenu: false,
    userAnchor: null,
    programListType: 'My Programs',
    filtername: '',
  };

  // Run When This Component Mounted
  componentDidMount() {
  }

  // When Create-Program Button Is Pressed - Called by Child ProgramCreate
  handleCreateProgram = (progDetails) => {
    // Get fileName Details and Update State
    this.setState({ progDetails: progDetails} );
    
    if (progDetails.selection.language.toLowerCase() === "gui") {
      // Update State for View Selection
      this.setState(state => ({
        activeStep: 2
      }));
    } else if (progDetails.selection.language.toLowerCase() === "python") {
      // Update State for View Selection
      this.setState(state => ({
        activeStep: 1
      }));
    }
    this.setState( {canPassCode: false} );
  }

  handleCreateLink = (savedData) => {
    
    this.setState( {progDetails: savedData} );

    // Update State for View Selection
    this.setState(state => ({
      activeStep: 3
    }));
  }

  handleSplitClose = (sourceStr) => {
    
    if (sourceStr === 'gui') {

      let tempDetails = this.linkedGUIS.current.getProgDetails();
      tempDetails.selection.language = 'gui';
      this.setState( {progDetails: tempDetails} );

      // Update State for View Selection
      this.setState(state => ({
        activeStep: 2
      }));
    } else {

      let tempDetails = this.linkedCodingS.current.getProgDetails();
      tempDetails.selection.language = 'python';
      this.setState( {progDetails: tempDetails} );
            
      // Update State for View Selection
      this.setState(state => ({
        activeStep: 1
      }));
      this.setState( {programListType: 'My Programs'}) ;
    }
  }

  handleUnlink = () => {
    this.linkedGUIS.current.setState( {isLinked: false} );
  }

  enableCodeLink = () => {
    this.setState( {canPassCode: true} );
  }


  handleUpdateLinked = (buffer) => {
    if (this.state.canPassCode === true) {
      this.linkedCodingS.current.linkedBufferUpdate(buffer);
    }    
  }

  // When Close Or Delete Buttons Pressed in Child ProgramCoding - Called by ProgramCoding
  handleCloseDelete = (codingEvent) => {

    this.context.AuthInstance.getProgramList();

    // Update State for View Selection
    this.setState(state => ({
      activeStep: 0
    }));
    if ('backToShare' in codingEvent) {
      this.setState( {programListType: 'Programs shared with me'}) ;
    } else {
      this.setState( {programListType: 'My Programs'}) ;
    }
    
    this.setState( {canPassCode: false} );
  }


  handleUserMenuBtn = (event) => {
    
    this.setState( {userMenu: true, userAnchor: event.currentTarget} );
  };
  
  handleUserMenuClose = () => {

    this.setState( {userMenu: false} );
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  refreshFilter = () => {
    this.programList.current.refreshFilter()
  }

  handleListType = (type) => {
    this.refreshFilter()
    if (type === 0) {
      try {
        this.context.AuthInstance.getProgramList().then(res => {
          this.setState( {programListType: 'My Programs'}) ;
        });
      } catch {

      }

    } else if (type === 1) {
      try {
        this.context.AuthInstance.getSharingProgramList().then(res => {
          this.setState( {programListType: 'Programs I am sharing'}) ;
        });
      } catch {

      }

    } else if (type === 2) {
      try {
        this.context.AuthInstance.getSharedProgramList().then(res => {
          this.setState( {programListType: 'Programs shared with me'});
        });
      } catch {

      }

    }
    this.handleUserMenuClose();
  };


  // Takes input data, returns what to display
  render() {
    // Referenced below for setting styles
    const { classes } = this.props;
    // Referenced below for layout selection
    const { activeStep } = this.state;
    // For TopBar Route Highlight
    const currentPath = this.props.location.pathname;

    // What to Display - Selectable Views Based on activeStep
    return (
      <React.Fragment>
      <CssBaseline />
      <Topbar currentPath={currentPath} />
        <div className={classes.root}
          
          style={{ maxHeight: '100vh', backgroundColor: 'white', overflow: 'visible'}}>
          {activeStep === 0 && (
            <Grid container justify="center">
              <Grid
                spacing={1}
                container
                className={classes.grid}
              >
                <Grid item xs={6}>
                    <Paper className={classes.paperList}>
                      <IconButton
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="secondary"                
                        className={classes.userOptions}
                        onClick={this.handleUserMenuBtn}
                        style={{textTransform: "none", borderRadius: '0%'}}
                      >
                        {this.state.programListType} <ExpandMoreIcon fontSize="large" /> 
                      </IconButton>
                      <Menu
                        id="customized-menu"
                        elevation={0}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        anchorEl={this.state.userAnchor}
                        open={this.state.userMenu}
                        onClose={this.handleUserMenuClose}
                      >
                        <MenuItem onClick={() => this.handleListType(0)}>My Programs</MenuItem>
                        <MenuItem onClick={() => this.handleListType(1)}>Programs I am sharing</MenuItem>
                        <MenuItem onClick={() => this.handleListType(2)}>Programs shared with me</MenuItem>
                      </Menu>
                    </Paper>
                    <ProgramList 
                      ref = {this.programList}
                      onCreateProgram = {this.handleCreateProgram} 
                      programListType={this.state.programListType}
                      listRefresh={this.handleListType}
                    />
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                    <Typography variant='h4' className={classes.headerText}>
                      Program Creation
                    </Typography>
                  </Paper>
                  
                  <ProgramCreate onCreateProgram = {this.handleCreateProgram}/>

                </Grid>
              
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <ProgramCoding 
              ref={this.linkedCoding}
              onCloseDeleteProgram={this.handleCloseDelete}
              progDetails={this.state.progDetails}
              linkedState={false}
              activeStep={this.state.activeStep}
            />
          )}
          {activeStep === 2 && (
            <ProgramGUI
              ref={this.linkedGUI}
              onCloseDeleteProgram={this.handleCloseDelete}
              progDetails={this.state.progDetails}
              linkedState={false}
              handleCreateLink={this.handleCreateLink}
              activeStep={this.state.activeStep}
            />
          )}
          {activeStep === 3 && (
            <Grid container justify="center">
            <Grid
              spacing={0}
              container
              className={classes.gridSplit}
            >
              <Grid item xs={6}>
                  <ProgramGUI
                    ref={this.linkedGUIS}
                    onCloseDeleteProgram={this.handleCloseDelete}
                    progDetails={this.state.progDetails}
                    linkedState={true}
                    handleCreateLink={this.handleCreateLink}
                    handleUpdateLinked={this.handleUpdateLinked}
                    activeStep={this.state.activeStep}
                    handleSplitClose={this.handleSplitClose}
                  />
              </Grid>
              <Grid item xs={6}>
                  <ProgramCoding 
                    ref={this.linkedCodingS}
                    onCloseDeleteProgram={this.handleCloseDelete}
                    progDetails={this.state.progDetails}
                    linkedState={true}
                    setCodeStream={this.enableCodeLink}
                    handleUnlink={this.handleUnlink}
                    activeStep={this.state.activeStep}
                    handleSplitClose={this.handleSplitClose}
                  />
              </Grid>
            </Grid>
            </Grid>
          )}
        </div>
      </React.Fragment>
    );
  }
}
// withRouter provides access to history for routes
export default withRouter(withStyles(styles)(Program));
