import React from 'react'
import './SnakeGame.css'
import GameOver from '../shared/GameOver.js'

class SnakeGame extends React.Component {
  constructor(props) {
    super(props)

    this.handleKeyDown = this.handleKeyDown.bind(this)

    this.state = {
      width: 0,
      height: 0,
      blockWidth: 0,
      blockHeight: 0,
      gameLoopTimeout: 75,
      timeoutId: 0,
      resetTimeout: 20,
      resetTimeoutId: 0,
      startSnakeSize: 0,
      snake: [],
      sBorderRadius: '',
      sBorder: '',
      aBorderRadius: '',
      aBorder: '',
      message: 'Press Enter to restart',  
      mode: '',
      apple: {},
      direction: 'right',
      directionChanged: false,
      isGameOver: false,
      //snakeColor: this.props.snakeColor || this.getRandomColor(),
      //appleColor: this.props.appleColor || this.getRandomColor(),
      snakeColor: this.getRandomColor(),
      appleColor: this.getRandomColor(),
      score: 0,
      settings: {},
      highScore: Number(localStorage.getItem('snakeHighScore')) || 0,
      newHighScore: false,
      btnLevel: 0,
    }
  }

  componentDidMount() {
    //this.initGame()
    //window.addEventListener('keydown', this.handleKeyDown)
    //this.gameLoop()
  }


  updateSettings = (settings) => {

    this.setState( {settings: settings} )
    if (typeof settings['settings'] !== "undefined") {

        for (let i = 0; i < settings['settings'].length; i++) {
            if ('snake color' in settings['settings'][i]) {
                this.setState( {snakeColor: settings['settings'][i]['snake color']} )
            }        
            if ('snake delay' in settings['settings'][i]) {
                this.setState( {gameLoopTimeout: settings['settings'][i]['snake delay']} )
            }           
            if ('snake border' in settings['settings'][i]) {
                this.setState( {sBorder: settings['settings'][i]['snake border']} )
            }           
            if ('snake shape' in settings['settings'][i]) {
                this.setState( {sBorderRadius: settings['settings'][i]['snake shape']} )
            }
            if ('apple color' in settings['settings'][i]) {
                this.setState( {appleColor: settings['settings'][i]['apple color']} )
            }            
            if ('apple shape' in settings['settings'][i]) {
                this.setState( {aBorderRadius: settings['settings'][i]['apple shape']} )
            }             
            if ('apple border' in settings['settings'][i]) {
                this.setState( {aBorder: settings['settings'][i]['apple border']} )
            }     
        }


    }

  }

  initGame(mode) {
    this.setState( {mode: mode} ) 
    if (mode === 'LocoWear') {
      this.props.LRObj.send_command(this.props.LRObj.self.GET_DATA.value);
      this.setState( {message: 'Press Top Button to restart'} )
      this.trackLW();
    }

    // Game size initialization
    let percentageWidth = this.props.percentageWidth || 40
    let width =
      document.getElementById('GameBoard').parentElement.offsetWidth *
      (percentageWidth / 100)
    width -= width % 30
    if (width < 30) width = 30
    let height = (width / 3) * 2

    width = 600
    height = 400


    let blockWidth = width / 30
    let blockHeight = height / 20
    
    // snake initialization
    let startSnakeSize = this.props.startSnakeSize || 6
    let snake = []
    let Xpos = width / 2
    let Ypos = height / 2
    let snakeHead = { Xpos: width / 2, Ypos: height / 2 }
    snake.push(snakeHead)
    for (let i = 1; i < startSnakeSize; i++) {
      Xpos -= blockWidth
      let snakePart = { Xpos: Xpos, Ypos: Ypos }
      snake.push(snakePart)
    }

    // apple position initialization
    let appleXpos =
      Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1)) *
      blockWidth
    let appleYpos =
      Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1)) *
      blockHeight
    while (appleYpos === snake[0].Ypos) {
      appleYpos =
        Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1)) *
        blockHeight
    }

    this.setState({
      width,
      height,
      blockWidth,
      blockHeight,
      startSnakeSize,
      snake,
      apple: { Xpos: appleXpos, Ypos: appleYpos },
    })

    
    window.addEventListener('keydown', this.handleKeyDown)
    this.gameLoop()
  }

  get_rp = (xyz) => {

    let rp = [0,0];
    
    if (Math.sqrt(xyz[0] * xyz[0] + xyz[1] * xyz[1] + xyz[2] * xyz[2]) < 1.5) {
      rp[0] = Math.atan2(xyz[0], Math.sqrt(xyz[1] * xyz[1] + xyz[2] * xyz[2])) * 180.0 / Math.PI
      rp[1] = Math.atan2(xyz[1], Math.sqrt(xyz[0] * xyz[0] + xyz[2] * xyz[2])) * 180.0 / Math.PI
      if ((xyz[1] === 0) && (xyz[2] === 0)) {
          rp[0] = 0
      }
      if ((xyz[0] === 0) && (xyz[2] === 0)) {
          rp[1] = 0
      }
  
      if (rp[0] > 30) {
          rp[0] = 30
      } else if (rp[0] < -30) {
          rp[0] = -30
      }
      if (rp[1] > 45) {
          rp[1] = 45
      } else if (rp[1] < -45) {
          rp[1] = -45
      }

    }

    return rp;
  }


  
  trackLW = () => {
    let timeoutId = setTimeout(() => {

      let rp = this.get_rp(this.props.LRObj.self.accData);

        let btnT = this.props.LRObj.self.topBtnState;
        if ((btnT === 1) && (this.state.btnLevel === 0)) {
          if (this.state.isGameOver === true) {
            setTimeout(this.resetGame, 200)
          }
        }

        if (rp[0] > 25) {
            this.goLeft()
        } else if (rp[0] < -25) {
            this.goRight()
        } else if (rp[1] < -25) {
            this.goUp()
        } else if (rp[1] > 25) {
            this.goDown()            
        }
        this.props.LRObj.send_command(this.props.LRObj.self.GET_DATA.value);

        this.setState( {btnLevel: btnT} )
        if (this.in_reset === true) {
            this.props.LRObj.send_command(this.props.LRObj.self.GET_DATA.value);
        }

        this.trackLW();
    }, this.state.resetTimeout);
    this.setState( {resetTimeoutId: timeoutId} )
}

  gameLoop() {
    let timeoutId = setTimeout(() => {
    


      if (!this.state.isGameOver) {
        this.moveSnake()
        this.tryToEatSnake()
        this.tryToEatApple()
        this.setState({ directionChanged: false })
      }

      window.game_state = {
          'score': this.state.score,
          'snake position': this.state.snake[0],
          'snake length': this.state.snake.length,
          
      }

      this.gameLoop()
    }, this.state.gameLoopTimeout)

    this.setState({ timeoutId })
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId)
    clearTimeout(this.state.resetTimeoutId)
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  resetGame = () => {
    let width = this.state.width
    let height = this.state.height
    let blockWidth = this.state.blockWidth
    let blockHeight = this.state.blockHeight
    let apple = this.state.apple

    // snake reset
    let snake = []
    let Xpos = width / 2
    let Ypos = height / 2
    let snakeHead = { Xpos: width / 2, Ypos: height / 2 }
    snake.push(snakeHead)
    for (let i = 1; i < this.state.startSnakeSize; i++) {
      Xpos -= blockWidth
      let snakePart = { Xpos: Xpos, Ypos: Ypos }
      snake.push(snakePart)
    }

    // apple position reset
    apple.Xpos =
      Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1)) *
      blockWidth
    apple.Ypos =
      Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1)) *
      blockHeight
    while (this.isAppleOnSnake(apple.Xpos, apple.Ypos)) {
      apple.Xpos =
        Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1)) *
        blockWidth
      apple.Ypos =
        Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1)) *
        blockHeight
    }

    this.setState({
      snake,
      apple,
      direction: 'right',
      directionChanged: false,
      isGameOver: false,
      gameLoopTimeout: 75,
      snakeColor: this.getRandomColor(),
      appleColor: this.getRandomColor(),
      score: 0,
      newHighScore: false,
    })
    this.updateSettings(this.state.settings);
  }

  getRandomColor() {
    let hexa = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) color += hexa[Math.floor(Math.random() * 16)]
    return color
  }

  moveSnake() {
    let snake = this.state.snake
    let previousPartX = this.state.snake[0].Xpos
    let previousPartY = this.state.snake[0].Ypos
    let tmpPartX = previousPartX
    let tmpPartY = previousPartY
    this.moveHead()
    for (let i = 1; i < snake.length; i++) {
      tmpPartX = snake[i].Xpos
      tmpPartY = snake[i].Ypos
      snake[i].Xpos = previousPartX
      snake[i].Ypos = previousPartY
      previousPartX = tmpPartX
      previousPartY = tmpPartY
    }
    this.setState({ snake })
  }

  tryToEatApple() {
    let snake = this.state.snake
    let apple = this.state.apple

    // if the snake's head is on an apple
    if (snake[0].Xpos === apple.Xpos && snake[0].Ypos === apple.Ypos) {
      let width = this.state.width
      let height = this.state.height
      let blockWidth = this.state.blockWidth
      let blockHeight = this.state.blockHeight
      let newTail = { Xpos: apple.Xpos, Ypos: apple.Ypos }
      let highScore = this.state.highScore
      let newHighScore = this.state.newHighScore
      let gameLoopTimeout = this.state.gameLoopTimeout

      // increase snake size
      snake.push(newTail)

      // create another apple
      apple.Xpos =
        Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1)) *
        blockWidth
      apple.Ypos =
        Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1)) *
        blockHeight
      while (this.isAppleOnSnake(apple.Xpos, apple.Ypos)) {
        apple.Xpos =
          Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1)) *
          blockWidth
        apple.Ypos =
          Math.floor(
            Math.random() * ((height - blockHeight) / blockHeight + 1)
          ) * blockHeight
      }

      // increment high score if needed
      if (this.state.score === highScore) {
        highScore++
        localStorage.setItem('snakeHighScore', highScore)
        newHighScore = true
      }

      // decrease the game loop timeout
      if (gameLoopTimeout > 25) gameLoopTimeout -= 0.5

      this.setState({
        snake,
        apple,
        score: this.state.score + 1,
        highScore,
        newHighScore,
        gameLoopTimeout,
      })
    }
  }

  tryToEatSnake() {
    let snake = this.state.snake

    for (let i = 1; i < snake.length; i++) {
      if (snake[0].Xpos === snake[i].Xpos && snake[0].Ypos === snake[i].Ypos)
        this.setState({ isGameOver: true })
    }
  }

  isAppleOnSnake(appleXpos, appleYpos) {
    let snake = this.state.snake
    for (let i = 0; i < snake.length; i++) {
      if (appleXpos === snake[i].Xpos && appleYpos === snake[i].Ypos)
        return true
    }
    return false
  }

  moveHead() {
    switch (this.state.direction) {
      case 'left':
        this.moveHeadLeft()
        break
      case 'up':
        this.moveHeadUp()
        break
      case 'right':
        this.moveHeadRight()
        break
      default:
        this.moveHeadDown()
    }
  }

  moveHeadLeft() {
    let width = this.state.width
    let blockWidth = this.state.blockWidth
    let snake = this.state.snake
    snake[0].Xpos =
      snake[0].Xpos <= 0 ? width - blockWidth : snake[0].Xpos - blockWidth
    this.setState({ snake })
  }

  moveHeadUp() {
    let height = this.state.height
    let blockHeight = this.state.blockHeight
    let snake = this.state.snake
    snake[0].Ypos =
      snake[0].Ypos <= 0 ? height - blockHeight : snake[0].Ypos - blockHeight
    this.setState({ snake })
  }

  moveHeadRight() {
    let width = this.state.width
    let blockWidth = this.state.blockWidth
    let snake = this.state.snake
    snake[0].Xpos =
      snake[0].Xpos >= width - blockWidth ? 0 : snake[0].Xpos + blockWidth
    this.setState({ snake })
  }

  moveHeadDown() {
    let height = this.state.height
    let blockHeight = this.state.blockHeight
    let snake = this.state.snake
    snake[0].Ypos =
      snake[0].Ypos >= height - blockHeight ? 0 : snake[0].Ypos + blockHeight
    this.setState({ snake })
  }

  handleKeyDown(event) {
    if (this.state.mode === '') {
        // if spacebar is pressed to run a new game
        if (this.state.isGameOver && event.keyCode === 13) {
          this.resetGame()
          return
        }
        if (this.state.directionChanged) return

        switch (event.keyCode) {
        case 37:
        case 65:
            this.goLeft()
            break
        case 38:
        case 87:
            this.goUp()
            break
        case 39:
        case 68:
            this.goRight()
            break
        case 40:
        case 83:
            this.goDown()
            break
        default:
        }
        this.setState({ directionChanged: true })
    }
  }

  goLeft() {
    let newDirection = this.state.direction === 'right' ? 'right' : 'left'
    this.setState({ direction: newDirection })
  }

  goUp() {
    let newDirection = this.state.direction === 'down' ? 'down' : 'up'
    this.setState({ direction: newDirection })
  }

  goRight() {
    let newDirection = this.state.direction === 'left' ? 'left' : 'right'
    this.setState({ direction: newDirection })
  }

  goDown() {
    let newDirection = this.state.direction === 'up' ? 'up' : 'down'
    this.setState({ direction: newDirection })
  }

  render() {
    // Game over
    if (this.state.isGameOver) {
      return (
        <GameOver
          width={this.state.width}
          height={this.state.height}
          highScore={this.state.highScore}
          newHighScore={this.state.newHighScore}
          score={this.state.score}
          message={this.state.message}
        />
      )
    }

    return (
      <div
        id='GameBoard'
        style={{
          width: this.state.width + this.state.blockWidth * 1.25,
          height: this.state.height + this.state.blockHeight * 1.25,
          borderWidth: this.state.width / 50,
        }}>
        {this.state.snake.map((snakePart, index) => {
          return (
            <div
              key={index}
              className='Block'
              style={{
                width: this.state.blockWidth,
                height: this.state.blockHeight,
                left: snakePart.Xpos,
                top: snakePart.Ypos,
                background: this.state.snakeColor,
                borderRadius: this.state.sBorderRadius,
                border: this.state.sBorder,
              }}
            />
          )
        })}
        <div
          className='Block'
          style={{
            width: this.state.blockWidth,
            height: this.state.blockHeight,
            left: this.state.apple.Xpos,
            top: this.state.apple.Ypos,
            background: this.state.appleColor,
            borderRadius: this.state.aBorderRadius,
            border: this.state.aBorder,
          }}
        />
        <div id='Score' style={{ fontSize: this.state.width / 20 }}>
          HIGH-SCORE: {this.state.highScore}&ensp;&ensp;&ensp;&ensp;SCORE:{' '}
          {this.state.score}
        </div>
      </div>
    )
  }
}

export default SnakeGame