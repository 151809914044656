// Parent Component of:
// --- ProgramDisplay

// Child Component of: 
// --- Program

// Import React and Material-UI Modules
import React from 'react';
import AceEditor from 'react-ace';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import withStyles from "@material-ui/styles/withStyles";

// Import Custom Components
import ProgramDisplay from "./modals/ProgramDisplay";
import ShareProgram from './modals/ShareProgram';
import NameChange from "./drawers/NameChange";

// Import Context to Access Auth/User Information
import { AuthContext } from "../context/auth";


// Import ACE Theme and Mode
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";

import Typography from '@material-ui/core/Typography';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SaveIcon from '@material-ui/icons/Save';
import ShareIcon from '@material-ui/icons/Share';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

import DeleteProgram from './drawers/DeleteProgram';
import SaveProgram from './drawers/SaveProgram';
import UnlinkSave from './drawers/UnlinkSave';
import MarkGraded from './drawers/MarkGraded';

import SavedProgram from './drawers/SavedProgram';

//import LXSearchModal from './modals/LXSearchModal';
import LXSettingsModal from './modals/LXSettingsModal';
import LDTSettingsModal from './modals/LDTSettingsModal';

import { webserial_disconnect } from '../webserial/webserial'

// Base Code for Loading in Ace-Editor, Options Based on Hardware Selection
const templateCode = {
    PythonOnly: [
        '# Enter your code here'
    ],
    LocoDrone : [
        '# Module Import',
        'import LocoDrone',
        'import time',
        '',
        '# Create LocoDrone Class Instance',
        'loco_drone = LocoDrone.LocoDrone()',
        '# Connect to the Controller',
        'loco_drone.connect()',
        '',
        '# Calibrate the Controller',
        'loco_drone.controller_calibrate()',
        '',
        '# Calibrate the Drone',
        'loco_drone.drone_calibrate()',
        '',
        '# Set Mode as Joystick Mode',
        'loco_drone.set_mode(loco_drone.MODE_JOYSTICK)',
        '',
        '# Command the Drone to Takeoff',
        'loco_drone.drone_takeoff()',
        '',
        '',
        '',
        '# Command the Drone to Land',
        'loco_drone.drone_land()',
        '',
        '# Disconnect from the Controller',
        'loco_drone.disconnect()',
    ],
    LocoDroneT: [
        '# Module Import',
        'import LocoDroneT',
        'import time',
        '',
        '# Create LD Class Instance',
        'loco_drone = LocoDroneT.LD()',
        '',
        '# Connect to Serial Device',
        'loco_drone.setup()',
        '',
        '# Scan for Drones',
        'drones = loco_drone.drone_scan()',
        '',
        '# Connect to Drone',
        'loco_drone.drone_connect(drones, "TELLO-000000")',
        '',
        '',
        '',
        '',
        '',
        '',
        '# Disconnect From Drone',        
        'loco_drone.drone_disconnect()',
        '',
        '',
        '# Disconnect from the USB Device',
        'loco_drone.close()',
    ],
    LocoDroneTT: [
        '# Module Import',
        'import LocoDroneTT',
        'import time',
        '',
        '# Create LD Class Instance',
        'loco_drone = LocoDroneTT.LD()',
        '',
        '# Connect to Serial Device',
        'loco_drone.setup()',
        '',
        '# Scan for Drones',
        'drones = loco_drone.drone_scan()',
        '',
        '# Connect to Drone',
        '# Use The Following Line If The RMTT Module Is Not Attached',
        '#loco_drone.drone_connect(drones, "TELLO-000000")',
        '# Use The Following Line If The RMTT Module Is Attached',
        'loco_drone.drone_connect(drones, "RMTT-000000")',
        '',
        '',
        '',
        '',
        '',
        '',
        '# Disconnect From Drone',        
        'loco_drone.drone_disconnect()',
        '',
        '',
        '# Disconnect from the USB Device',
        'loco_drone.close()',
    ],
    LocoIoT : [
        '# Module Import',
        'import LocoIOT',
        'import time',
        '',
        '# Class Instances Creation',
        'loco_iot = LocoIOT.LocoIOT()',
        'msg = LocoIOT.IOT_Codes()',
        '',
        '# Initiate Connection to the Microcontroller',
        'loco_iot.connect()',
        '',
        '',
        '### Add Hardware Enable(s) Here',
        '',
        '',
        '# Instruct the Microcontroller to Start Listening for Control/Request Messages',
        'loco_iot.start()',
        '',
        '',
        '',
        '',
        '# Close Connection to the Microcontroller',
        'loco_iot.close()',
    ],
    LocoArm : [
        '# Module Import',
        'import LocoArm',
        '',
        '# Class Instance Creation',
        'loco_arm = LocoArm.LocoArm()',
        '', 
        '# Initiate Connection to the Microcontroller', 
        'loco_arm.connect()',
        '', 
        '',         
        '', 
        '', 
        '# Close Connection to the Microcontroller', 
        'loco_arm.disconnect()', 
    ],
    LocoArmS : [
        '# Module Import',
        'import LocoArmS',
        '',
        '# Class Instance Creation',
        'loco_arm_s = LocoArmS.LocoArmS()',
        '', 
        '# Initiate Connection to the Microcontroller', 
        'loco_arm_s.connect()',
        '', 
        '',         
        '', 
        '', 
        '# Close Connection to the Microcontroller', 
        'loco_arm_s.disconnect()', 
    ],
    LocoArmM : [
        '# Module Import',
        'import LocoArmM',
        'import time',
        '',
        '# Class Instance Creation',
        'loco_arm_m = LocoArmM.LocoArmM()',
        '', 
        '# Initiate Connection to the Microcontroller', 
        'loco_arm_m.connect()',
        '', 
        '',         
        '', 
        '', 
        '# Close Connection to the Microcontroller', 
        'loco_arm_m.disconnect()', 
    ],
    LocoArmMS : [
        '# Module Import',
        'import LocoArmMS',
        '',
        '# Class Instance Creation',
        'loco_arm_m_s = LocoArmMS.LocoArmMS()',
        '', 
        '# Initiate Connection to the Microcontroller', 
        'loco_arm_m_s.connect()',
        '', 
        '',         
        '', 
        '', 
        '# Close Connection to the Microcontroller', 
        'loco_arm_m_s.disconnect()', 
    ],
    LocoWear : [
        '# Module Import',
        'import LocoWear',
        'import time',
        '',
        '# Class Instance Creation',
        'loco_wear = LocoWear.LocoWear()',
        '', 
        '# Initiate Connection to the Microcontroller', 
        'loco_wear.connect()',
        '', 
        '# Wait for Binding Signal', 
        'loco_wear.start_listening()', 
        '',         
        '', 
        '', 
        '# Close Connection to the Microcontroller', 
        'loco_wear.disconnect()', 
    ],
    LocoXtreme : [
        '# Module Imports',
        'from LocoXtreme import Connection',
        'from LocoXtreme import LocoXtreme',
        'from LocoXtreme import MotorDirection as MD',
        'from LocoXtreme import Data',
        'from LocoXtreme import WaitType as WT',
        'from LocoXtreme import Song',
        'from LocoXtreme import Note',
        'import time',
        '',
        '# Create Connection Instance',
        'connection = Connection()',
        '',
        '# USB Connection Setup',
        'connection.setup()',
        '',
        '# Scan for Robots',
        'robots = connection.scan(4000)',
        '',
        '# Get Named Robot',
        'robot = connection.get_robot(robots, "lr dc:00")',
        '',
        '# Create LocoXtreme Object',
        'locoxtreme = LocoXtreme(robot)',
        '',
        '# Connect to LocoXtreme',
        'locoxtreme.connect()',
        '',
        '# Activate Motors',
        'locoxtreme.activate_motors()',
        '',
        '# Enable Sensors',
        'locoxtreme.enable_sensor(Data.ULTRASONIC, 1)',
        '',
        '# Pause for Initializations',
        'time.sleep(0.4)',
        '',
        '',
        '',
        '',
        '# Deactivate Motors',
        'locoxtreme.deactivate_motors()',
        '',
        '# Disconnect From LocoXtreme',
        'locoxtreme.disconnect()',
    ],
}

const IMU_ERROR_STR = "Vision Positioning System Not Active!\nYou may need to move to a flying area with more light or a more optimal flying surface.";
const IMU_ERROR_CASE = "error No valid imu";
const WAIT_FAIL_CASE = "Communication Failure";
const WAIT_FAIL_STR = "Communication Issue Between The Drone and Controller.";

// Material-UI CSS-type Style Specifications
const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: "white",
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "0 400px",
      //paddingBottom: 200,
    },
    paper: {
        //padding: theme.spacing(3),
        margin: theme.spacing(2),
        textAlign: "left",
        backgroundColor: theme.palette.primary["light"],
        color: theme.palette.primary["contrastText"],
        width: 580
    },  
    grid: {
        width: 1200,
        margin: `0 ${theme.spacing(2)}px`,
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 20px)"
        }
    },
    appBar: {
        position: "relative",
        boxShadow: "none",
        backgroundColor: theme.palette.primary["light"],//"white"
        color: "white",
        height: window.programBarOffset,
        borderLeft: `1px solid ${theme.palette.grey["100"]}`,
        borderRight: `1px solid ${theme.palette.grey["100"]}`,
        minWidth: 636,
    },
    inline: {
        display: "inline"
    },
    flex: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center"
        }
    },
    grow: {
        flexGrow: 1,
    },
    actionButton: {
        justify: "right",
        color: "white",
        backgroundColor: theme.palette.primary["light"],
        margin: theme.spacing(0),
        marginTop: theme.spacing(2),
        //margin: theme.spacing(2),
    },
    nameButton: {
        justify: "left",
        color: "white",
        backgroundColor: theme.palette.primary["light"],
        margin: theme.spacing(0),
    },
    lastSavedText: {
        alignItems: 'center',
        justifyContent: 'center',
        color: "white",
        backgroundColor: theme.palette.primary["light"],
        marginLeft: theme.spacing(4),
    }
});

// Variables for Wrappers and IDs to Allow Manual Complete Shutdown of Any Running Skulpt
var oldSetInterval;
var oldSetTimeout;
var saveUncaughtException = window.Sk.uncaughtException;
var intervalFuncVars = [];
var timeoutFuncVars = [];

// Component Class - ProgramCoding
class ProgramCoding extends React.Component {
  
    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);
        
        this.autoSaveTimerID = null;
        this.canAutoSave = false;
        this.autoSavePeriod = 120000;//300000; // ms, 5 mins

        this.lastUpdateTimerID = null;
        this.lastUpdateTimerPeriod = 60000;

        // Passed Below as 'ref' to ProgramDisplay Child Component, Giving Parent Access
        this.progDisplayElement = React.createRef();

        //
        this.nameChangeElement = React.createRef();

        //
        this.deleteProgramElement = React.createRef();

        //
        this.saveProgramElement = React.createRef();

        //
        this.unlinkProgramElement = React.createRef();

        //
        this.shareProgramElement = React.createRef();

        //
        this.savedProgramElement = React.createRef();

        //
        this.markGradedElement = React.createRef();

        // 
        this.lxSearchElement = React.createRef();

        //
        this.ldtSearchElement = React.createRef();

        //
        this.lxSettingsElement = React.createRef();


        // Wrappers for Skulpt Quitting
        oldSetInterval = window.setInterval;
        oldSetTimeout = window.setTimeout;
        window.Sk.uncaughtException = function (e) {
            //var msg = e.toString();
            this.stopit();
            this.restoreAsync();
            saveUncaughtException(e);
        }
        
    }

    // For Use of AuthContext
    static contextType = AuthContext;

    // Stops any asynchronous functions still running
    stopit = () => {
        for (var i = 0; i < intervalFuncVars.length; i++) {
            window.clearInterval (intervalFuncVars[i]);
        }
        intervalFuncVars = [];
        for (i = 0; i < timeoutFuncVars.length; i++) {
            window.clearTimeout (timeoutFuncVars[i]);
        }
        timeoutFuncVars = [];
    }    

    // Restore setInterval/setTimeout to Original
    restoreAsync = () => {
        window.setInterval = setInterval = oldSetInterval;
        window.setTimeout = setTimeout = oldSetTimeout;
    }

    // Class State
    state = {
        buffer: "",
        fileName: '',//this.props.progDetails.name,
        isRunning: false,
        progDispOpen: false,
        progOutputMsg: "",
        suspArray: [],
        selection: {},
        isSaved: true,
        isLinked: this.props.linkedState,
        activeStep: this.props.activeStep,
        nameStyle: "normal",
        progDetailsNotSaved: false,
        shared: false,
        shareState: 'accepted',
        plotEnabled: false,
        isAutoSaving: false,
        showLastSaved: false,
        startDate: 0,
        lastSavedMsg: "",
        lastSavedBaseStr1: "Last Saved ",
        lastSavedBaseStr2: " Minutes Ago",
        lastSavedBaseStr2b: " Minute Ago",
    }

 
    // Run When This Component Mounted
    componentDidMount() {
        
        let nameExtension = ""
        if (this.state.isLinked === true) {
            this.setState({
                isSaved: false,
                nameStyle: "italic"
            });
        }
        if (this.props.progDetails.hasOwnProperty('notSaved')) {
            if (this.props.progDetails.notSaved === true) {
                this.setState({
                    isSaved: false,
                    nameStyle: "italic"
                });
            }
            this.setState( {progDetailsNotSaved: this.props.progDetails.notSaved} );
        }

        //let selection = this.props.progDetails.selection;
        let selection = Object.assign({}, this.props.progDetails.selection);
        if (this.props.progDetails.type === "new") {
            // Populate buffer for Ace-Editor text with correct template code
            this.setState( {buffer: templateCode[this.props.progDetails.hardware].join('\n')} );
            this.props.progDetails.selection.instructions = templateCode[this.props.progDetails.hardware].join('\n');

            this.context.AuthInstance.createProgram(this.props.progDetails.selection.name, 
                                                    'editor', 
                                                    this.props.progDetails.selection.language, 
                                                    this.props.progDetails.selection.instructions,
                                                    this.props.progDetails.hardware,
                                                    )
            .then(res => {
                // Get resource_uri for saving
                selection.resource_uri = res.resource_uri;
            });                
                        
        } else { // Loaded            
            if ('shared' in this.props.progDetails) {
                this.setState( {shared: true, shareState: this.context.AuthInstance.sharedProgramList[this.props.progDetails.sharedIndex].state} );
                if (this.props.progDetails.selection.fname !== '') {
                    if (this.props.progDetails.selection.fname === '_') {
                        nameExtension = ': ' + this.context.AuthInstance.sharedProgramList[this.props.progDetails.sharedIndex].shared_from
                    } else {
                        nameExtension = ': ' + this.props.progDetails.selection.fname + ' ' + this.props.progDetails.selection.lname
                    }
                }
            }
            if (this.state.isLinked === false) {
                this.setState( {buffer: this.props.progDetails.selection.instructions} );
            } else {
                this.props.setCodeStream();
            }
        }
        this.setState( {selection: selection} );
        this.setState( {fileName: this.props.progDetails.selection.name + nameExtension} );
        if (this.state.isLinked === false) {
            this.startAutoSave();
        }
    }

    writerReset = () => {
        if (window.serialwriter !== null) {
            try {
                window.serialwriter.releaseLock();
            } catch {}
            window.serialwriter = null;
        }
    }

    componentWillUnmount() {
        this.writerReset();
        this.stopAutoSave();
        this.stopSavedMessage();
    }

    startSavedMessage = () => {
        
        let startTime = new Date()
        this.setState( {startDate: startTime, showLastSaved: true} )
        let msgString = this.state.lastSavedBaseStr1 + String(0) + this.state.lastSavedBaseStr2
        this.setState( {lastSavedMsg: msgString} );

        this.lastUpdateTimerID = setTimeout(this.updateSavedMessage, this.lastUpdateTimerPeriod);
    }

    updateSavedStart = () => {
        let startTime = new Date()
        this.setState( {startDate: startTime} )
    }

    stopSavedMessage = () => {
        clearTimeout(this.lastUpdateTimerID)
    }

    updateSavedMessage = () => {

        let diff = Math.abs(new Date() - this.state.startDate)
        let minutes = Math.floor((diff/1000)/60);

        let msgString = this.state.lastSavedBaseStr1 + String(minutes)
        if (minutes === 1.0) {
            msgString += this.state.lastSavedBaseStr2b
        } else {
            msgString += this.state.lastSavedBaseStr2
        }
        this.setState( {lastSavedMsg: msgString} );

        this.lastUpdateTimerID = setTimeout(this.updateSavedMessage, this.lastUpdateTimerPeriod);
    }

    startAutoSave = () => {
        if (this.state.isLinked === false) {            
            if (this.state.shared === false) {
                if (!this.props.progDetails.hasOwnProperty('notSaved')) {
                    this.canAutoSave = true;
                    this.autoSaveTimerID = setTimeout(this.autoSave, this.autoSavePeriod);
                }
            }
        }
    }

    autoSave = () => {    
        if (this.state.isSaved === false) {

            let instructions = this.state.buffer;
            if (instructions === "") {
                instructions = "\n";
            }
            
            const data = {
                name: this.state.selection.name,
                instructions: instructions,
                revision: this.state.selection.revision,
                deleted: this.state.selection.deleted,
            }

            this.setState({isAutoSaving: true});
            
            this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(() => {

                this.setState({
                    isAutoSaving: false,
                    isSaved: true,            
                    nameStyle: "normal",
                });
    
                let tempSelection = this.state.selection;
                tempSelection.instructions = instructions;
                tempSelection.revision = this.state.selection.revision + 1;
                this.setState( {selection: tempSelection} );

                
                if (this.state.showLastSaved === false) {
                    this.startSavedMessage();
                } else {
                    this.updateSavedStart();
                    this.updateSavedMessage();
                }
    
            });            
        }
        this.autoSaveTimerID = setTimeout(this.autoSave, this.autoSavePeriod);
    }

    stopAutoSave = () => {
        try {
            clearTimeout(this.autoSaveTimerID);
        } catch {};
        this.canAutoSave = false;
    }


    // Function called when Ace Editor Updated
    handleChange = (buffer) => {
        // Update state of buffer from Ace Editor text
        this.setState({
            buffer: buffer,
            isSaved: false,
            nameStyle: "italic"
        });
    }

    handleSearchBtn = () => {
        
        this.lxSearchElement.current.handleOpen("SEARCH");

    }

    handleSearchBtnLDT = () => {
        this.writerReset();
        this.ldtSearchElement.current.handleOpen("SEARCH");

    }



    handlePlayBtn = () => {
        this.writerReset();
        let hasSaveCase = false;
        if (this.state.isLinked === false) {            
            if (this.state.shared === false) {
                if (!this.props.progDetails.hasOwnProperty('notSaved')) {                    
                    if (this.state.isSaved === false) {
                        let instructions = this.state.buffer;
                        if (instructions === "") {
                            instructions = "\n";
                        }
                        
                        const data = {
                            name: this.state.selection.name,
                            instructions: instructions,
                            revision: this.state.selection.revision,
                            deleted: this.state.selection.deleted,
                        }

                        this.setState({isAutoSaving: true});
                        hasSaveCase = true
                        this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(() => {
                            
                            this.setState({
                                isAutoSaving: false,
                                isSaved: true,            
                                nameStyle: "normal",
                            });
                
                            let tempSelection = this.state.selection;
                            tempSelection.instructions = instructions;
                            tempSelection.revision = this.state.selection.revision + 1;
                            this.setState( {selection: tempSelection} );

                            
                            if (this.state.showLastSaved === false) {
                                this.startSavedMessage();
                            } else {
                                this.updateSavedStart();
                                this.updateSavedMessage();
                            }

                            this.runPlayBtn()
                
                        });   
                    }
                }
            }
        }
        if (hasSaveCase === false) {
            this.runPlayBtn()
        }

    }


    // Function called when Play button pressed
    runPlayBtn = async () => {
        this.stopAutoSave();
        this.stopSavedMessage();
        /*
        try {
            window.port.disconnect();
        } catch (err) {
        }
        */
        // Default to no-plot
        window.plotAdded = [0, 0];
        this.setState({plotEnabled: false, })

        // Probably not used, run if external library has dependencies
        function loadDependency(filename) {
            return new Promise(function(resolve, reject) {
              var scriptElement = document.createElement("script");
              scriptElement.type = "text/javascript";
              scriptElement.src = filename;
              scriptElement.onload = function() {
                resolve(true);
              }
              scriptElement.onerror = function() {
                resolve(false);
              }
              document.body.appendChild(scriptElement);
            });
        }
        
        // Add Libraries Based on User Access
        let externalLibs = {};

        externalLibs['src/builtin/LocoVis.js'] = '/libraries/LocoVis/__init__.js';
        externalLibs['src/builtin/LocoGame.js'] = '/libraries/LocoGame/__init__.js';
        
        if (this.context.AuthInstance.userAccess.includes("LocoDrone")) {
            externalLibs['src/builtin/LocoDrone.js'] = '/libraries/LocoDrone/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoDroneT")) {
            externalLibs['src/builtin/LocoDroneT.js'] = '/libraries/LocoDroneT/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoDroneTT")) {
            externalLibs['src/builtin/LocoDroneTT.js'] = '/libraries/LocoDroneTT/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoIoT")) {
            externalLibs['src/builtin/LocoIOT.js'] = '/libraries/LocoIOT/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoArm")) {
            externalLibs['src/builtin/LocoArm.js'] = '/libraries/LocoArm/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoArmS")) {
            externalLibs['src/builtin/LocoArmS.js'] = '/libraries/LocoArmS/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoArmM")) {
            externalLibs['src/builtin/LocoArmM.js'] = '/libraries/LocoArmM/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoArmMS")) {
            externalLibs['src/builtin/LocoArmMS.js'] = '/libraries/LocoArmMS/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoWear")) {
            externalLibs['src/builtin/LocoWear.js'] = '/libraries/LocoWear/__init__.js';
        }
        if (this.context.AuthInstance.userAccess.includes("LocoXtreme")) {
            externalLibs['src/builtin/LocoXtreme.js'] = '/libraries/LocoXtreme/__init__.js';
        }
        
        // Configure Sk (Skulpt)
        window.Sk.configure({
            inputfun: function (prompt) {
                return window.prompt(prompt);
            },
            inputfunTakesPrompt: true,
            output:this.outf, 
            read: function(x) {
                if (window.Sk.builtinFiles["files"][x] !== undefined)
                    return window.Sk.builtinFiles["files"][x];
                        
                    if(x in externalLibs){
                        let extLib = externalLibs[x];                    
                    
                    if (typeof extLib === "string") {
                        var fileToLoad = extLib;
                        var dependencies = [];
                    } else {
                        var fileToLoad = extLib.path;
                        var dependencies = extLib.dependencies;
                    }
                    if (dependencies.length > 0) {
                        return window.Sk.misceval.promiseToSuspension(
                            // load the dependencies in order
                            dependencies
                                .reduce(function (acc, filename) {
                                return acc.then(function() {
                                    return loadDependency(filename);
                                });
                                }, Promise.resolve())
                                .then(res => fetch(fileToLoad))
                                .then(res => res.text())
                        );
                    } else {
                        return window.Sk.misceval.promiseToSuspension(fetch(fileToLoad).then(res => res.text()));
                    }                
                }
            },
            __future__: window.Sk.python3
        });


        // Update isRunning in 'state' and hardInterrupt for Sk
        this.setState( {isRunning: true, suspArray: []} );
        window.Sk.hardInterrupt = false;
        
        // Return once Promise has resolved
        return Promise.resolve()
            .then(() => {
                // Clear Output Text Storage
                this.setState( {progOutputMsg: ""} );

                // Clear Displayed Text
                this.progDisplayElement.current.clearText();

                // Call Child Component ProgramDisplay's handleOpen() to Display Modal
                this.progDisplayElement.current.handleOpen();
            })
            .then(() => {

                var self = this;
                
                // Wrapper for Complete Skulpt-Quitting Behavior
                window.setInterval = setInterval = function (f,t) {
                    var handle = 
                    oldSetInterval (function () {
                        try {
                            f()
                        } catch (err) {
                            // Report error and abort
                            self.restoreAsync();
                            self.outf(err.toString());
                            self.stopit();
                        }
                    },t);
                    intervalFuncVars.push(handle);
                }.bind(this)
        
                // Wrapper for Complete Skulpt-Quitting Behavior
                window.setTimeout = setTimeout = function (f,t) {
                    var handle = 
                    oldSetTimeout (function () {
                        try {
                            f();
                        } catch (err) {
                            // Report error and abort
                            console.log(err.toString())
                            self.restoreAsync();
                            self.outf(err.toString());
                            self.stopit();
                        }
                    },t);
                    timeoutFuncVars.push(handle);
                }.bind(this)

                // Run Skulpt on state.buffer, populated from ACE
                return window.Sk.misceval.asyncToPromise(() => {
                    return window.Sk.importMainWithBody('<stdin>', false, this.state.buffer, true);
                }); // Probably don't need this interruptHanlder anymore

            }).catch((err) => {
                // Try to generate error message
                try {
                    //console.log(err);
                    // Assemble error message to send to Modal
                    let errText = err.args.v[0].v;
                    if (err.traceback.length > 0) {
                        errText = errText + ' on line ' + err.traceback[0].lineno;
                        if (err.traceback[0].colno) {
                            errText = errText + ' column ' + err.traceback[0].colno;
                        }
                    }
                    // Use referneced to ProgramDisplay to send error message and modal behavior information
                    if (err.args.v[0].v === IMU_ERROR_CASE) {
                        errText = IMU_ERROR_STR
                    } else if (err.args.v[0].v === WAIT_FAIL_CASE) {
                        errText = WAIT_FAIL_STR
                    }
                    let textObj = {text: errText, bodyColor: "red", stopClose: "Close"};
                    
                    this.progDisplayElement.current.updateText(textObj);
                    // Need to try to disconnect in case program connected and didn't disconnect, so next run can have clean connection
                    try {
                        if (this.props.progDetails.hardware === 'LocoXtreme') {
                            try {
                                let packet = Uint8Array.from([5, 1, 0]);
                                const writer = window.serialport.writable.getWriter();
                                writer.write(packet).then(() => {
                                    writer.releaseLock();
                                    return
                                });
                            } catch (err) {
                            }
                        } else if (this.props.progDetails.hardware === 'LocoDroneT') {
                            try {
                                let packet = Uint8Array.from([70, 1, 0]);
                                const writer = window.serialport.writable.getWriter();
                                writer.write(packet).then(() => {
                                    writer.releaseLock();
                                    return
                                });
                            } catch (err) {
                            }
                        } else if (this.props.progDetails.hardware === 'LocoDroneTT') {
                            try {
                                let packet = Uint8Array.from([70, 1, 0]);
                                const writer = window.serialport.writable.getWriter();
                                writer.write(packet).then(() => {
                                    writer.releaseLock();
                                    return
                                });
                            } catch (err) {
                            }
                        } else {
                            window.port.disconnect();
                        }
                    } catch (err) {
                    }
                } catch(err) {
                    // TODO: make separate popup - might not be needed anymore
                    console.log(err);
                }
            }).then(() => {
                // Handle program run wrap-up
                window.Sk.hardInterrupt = false;
                this.setState( {isRunning: false} );
                // Change Stop/Close Button of Modal                
                try {
                    this.progDisplayElement.current.setClose("Close");
                } catch (err) {}
                // Restore setTimeout and setInterval behaviors
                this.restoreAsync();

                // reboot drone
                if (this.props.progDetails.hardware === 'LocoDroneTT') {
                    try {
                        let packet = Uint8Array.from([97, 1, 0]);
                        const writer = window.serialport.writable.getWriter();
                        writer.write(packet).then(() => {
                            writer.releaseLock();
                            return
                        });
                    } catch (err) {
                    }
                }
                
                if (this.state.isLinked === false) {
                    this.startAutoSave();
                }

                if (this.state.showLastSaved === false) {
                    this.startSavedMessage();
                } else {
                    this.updateSavedStart();
                    this.updateSavedMessage();
                }
            });
    };

    // Function passed below to ProgramDisplay to let button click trigger program wrap-up
    stopRunning = async () =>
    {
        /*
        if (this.props.progDetails.hardware === 'LocoXtreme') {
            try {
                webserial_disconnect()
            } catch (err) {}
        } 
        */
        
        try {
            if (this.props.progDetails.hardware === 'LocoXtreme') {
                let packet = Uint8Array.from([5, 1, 0]);
                const writer = window.serialport.writable.getWriter();
                writer.write(packet).then(() => {
                    writer.releaseLock();
                    return
                });
            } else if (this.props.progDetails.hardware === 'LocoDroneT') {

                let packet = Uint8Array.from([76, 4, 0, 0, 0, 0]);
                //const writer = window.serialport.writable.getWriter();
                window.serialwriter = window.serialport.writable.getWriter();
                window.serialwriter.write(packet).then(() => {
                    try {
                        //window.serialwriter.releaseLock();
                        
                        let packetLand = Uint8Array.from([70, 1, 0]);
                        /*const writerLand = window.serialport.writable.getWriter();
                        writerLand.write(packetLand).then(() => {
                            writerLand.releaseLock();
                            return
                        });*/
                        window.serialwriter.write(packetLand).then(() => {
                            //window.serialwriter.releaseLock();
                            return
                        });
                    } catch {
                        return
                    }
                })
            } else if (this.props.progDetails.hardware === 'LocoDroneTT') {

                let packet = Uint8Array.from([76, 4, 0, 0, 0, 0]);
                const writer = window.serialport.writable.getWriter();
                writer.write(packet).then(() => {
                    try {
                        writer.releaseLock();
                        
                        let packetLand = Uint8Array.from([70, 1, 0]);
                        const writerLand = window.serialport.writable.getWriter();
                        writerLand.write(packetLand).then(() => {
                            writerLand.releaseLock();
                            return
                        });
                    } catch {
                        return
                    }
                });
            }
        } catch (err) {
        }

        // Quit All timeouts and intervals in "window" using accumulated ID's
        this.stopit();
        this.restoreAsync();

        if (this.state.isLinked === false) {
            this.startAutoSave();
        }
        
        // Need to try to disconnect in case program connected and didn't disconnect, so next run can have clean connection
        //try {
        //    window.port.disconnect();
        //} catch (err) {
        //}
    }

    stopEmergency = async () => {
            
        // Quit All timeouts and intervals in "window" using accumulated ID's
        this.stopit();
        this.restoreAsync();

        let packet = Uint8Array.from([101, 1, 1]);
        const writer = window.serialport.writable.getWriter();
        writer.write(packet).then(() => {
            try {
                writer.releaseLock();
            } catch {
            }

            if (this.state.isLinked === false) {
                this.startAutoSave();
            }
            return
        })


    }

    // Function called by Sk (skulpt) when program is running
    outf = (text) => { 
        // Only update if program is running
        if (this.state.isRunning === true) {
            if (text.slice(0, 10) === window.game_msg_code) {
                //this.progDisplayElement.current.
                let msg = text.slice(10, text.length);
                
                if (msg[0] === 'i') {
                    this.progDisplayElement.current.handleInitGame(msg.slice(1, msg.length));
                } else if (msg[0] === 's') {
                    this.progDisplayElement.current.handleOpenGame();
                } else if (msg[0] === 'p') {
                    this.progDisplayElement.current.handlePassSettings(msg.slice(1, msg.length));
                } else if (msg[0] === 'c') {
                    this.progDisplayElement.current.handleConnect();
                }

            } else if (text.slice(0, 10) === window.ldt_esp_ver_code) { 
                // Firmware Version has been read, update state if applicable
                if (window.ldt_esp_ver >= 4) {
                    this.progDisplayElement.current.handleLDTExtra()
                }
            } else {
                // Append output to progOutputMsg state variable, send assmebled information to ProgramDisplay
                this.setState( {progOutputMsg: this.state.progOutputMsg + text} );
                let textObj = {text: this.state.progOutputMsg, bodyColor: "black", stopClose: "Stop"};
                this.progDisplayElement.current.updateText(textObj);
            }

        }

        if ((window.plotAdded[0] !== 0) && (this.state.plotEnabled === false)) {
            this.progDisplayElement.current.handleOpenPlot();
            this.setState({plotEnabled: true, })
        }
    }

    getProgDetails = () => {

        let instructions = this.state.buffer;
        if (instructions === "") {
            instructions = "\n";
        }
        let tempSelection = this.state.selection;
        tempSelection.instructions = instructions;
        
        this.setState( {selection: tempSelection} );
        let progDetails = {type: "load", selection: tempSelection, hardware: this.props.progDetails.hardware};
        progDetails.notSaved = !this.state.isSaved;
        return progDetails;
    }


    // Function to call when save button is pressed
    handleSaveBtn = () => {

        if (this.state.shared === false) {
            if (this.state.isLinked === false) {

                let instructions = this.state.buffer;
                if (instructions === "") {
                    instructions = "\n";
                }

                if (this.props.progDetails.hasOwnProperty('notSaved')) {
                    if (this.state.progDetailsNotSaved === true) {

                        //let selection = this.props.progDetails.selection;
                        let selection = Object.assign({}, this.props.progDetails.selection);
                        this.context.AuthInstance.createProgram(this.props.progDetails.selection.name, 
                                                                'editor', 
                                                                this.props.progDetails.selection.language, 
                                                                this.props.progDetails.selection.instructions,
                                                                this.props.progDetails.hardware,
                                                                )
                        .then(res => {
                            this.setState({
                                isSaved: true,            
                                nameStyle: "normal",
                            });
                            // Get resource_uri for saving
                            selection.resource_uri = res.resource_uri;
                            selection.revision = 1;
                            this.setState( {selection: selection} );
                            this.setState( {progDetailsNotSaved: false} );
                            // 
                            this.savedProgramElement.current.handleOpen();
                        });
                        return;
                    }
                }
                
                const data = {
                    name: this.state.selection.name,
                    instructions: instructions,
                    revision: this.state.selection.revision,
                    deleted: this.state.selection.deleted,
                }
                
                this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(() => {
                    // 
                    this.savedProgramElement.current.handleOpen();

                    if (this.state.showLastSaved === false) {
                        this.startSavedMessage();
                    } else {
                        this.updateSavedStart();
                        this.updateSavedMessage();
                    }

                })
                
                this.setState({
                    isSaved: true,            
                    nameStyle: "normal",
                });

                let tempSelection = this.state.selection;
                tempSelection.instructions = instructions;
                tempSelection.revision = this.state.selection.revision + 1;
                this.setState( {selection: tempSelection} );
                
            } else {
                this.unlinkProgramElement.current.handleOpen();
            }
        } else {
            this.nameChangeElement.current.handleOpen();
        }
    }

    // Function called when X close button is pressed
    handleCloseBtn = event => {
        this.stopAutoSave();
        if ((this.props.progDetails.hardware === 'LocoXtreme') ||
            (this.props.progDetails.hardware === 'LocoDroneT') || 
            (this.props.progDetails.hardware === 'LocoDroneTT')) {
            try {
                if (this.state.isLinked === false) {
                    webserial_disconnect()
                }
            } catch (err) {}
        } else {
            try {
                window.port.disconnect();
            } catch {}
        }
        if (this.state.isSaved === false) {

            this.saveProgramElement.current.handleOpen();

        } else {
            // Refresh For ProgramList
            this.context.AuthInstance.getProgramList().then(res => {
                if (this.state.activeStep !== 3) {
                    // Send Information to Parent Program component, through passed down "onCloseDeleteProgram" function
                    var closeDetails = {progAction: "KeepProg"};
                    if (this.state.shared === true) {
                        closeDetails.backToShare = true
                    }
                    this.props.onCloseDeleteProgram(closeDetails)
                } else {
                    this.props.handleSplitClose('gui');
                }
            });
        }

    }


    // 
    handleMarkGradedBtn = event => {          
        //
        this.markGradedElement.current.handleOpen();
    }

    // Function called when trash-can delete button is pressed
    handleDeleteBtn = event => {   
           
        if (this.props.progDetails.hasOwnProperty('notSaved')) {
            if (this.state.progDetailsNotSaved === true) {
                return;        
            }
        }
        this.stopAutoSave();
        //
        this.deleteProgramElement.current.handleOpen();
    }


    // Function called when Name button pressed
    handleNameBtn = () => {
        if (this.state.shared === false) {
            if (this.state.isLinked === false) {
                this.stopAutoSave();
                this.nameChangeElement.current.handleOpen();
            }
        }
    }

    //
    nameChangeEvent = (event) => {
        
        if (event.state === "OK") {
            
            this.nameChangeElement.current.handleClose();
            if (/^[a-zA-Z0-9_]+$/.test(event.name)) {                
                if (event.name !== this.state.selection.name) {
                    try {
                        let tempSelection = this.state.selection;
                        tempSelection.name = event.name;

                        this.context.AuthInstance.createProgram(event.name, 
                                                                'editor', 
                                                                this.state.selection.language, 
                                                                this.state.selection.instructions,
                                                                this.props.progDetails.hardware,
                                                                )
                        .then((createRes) => {
                            tempSelection.resource_uri = createRes.resource_uri;
                            tempSelection.revision = 1;
                            this.setState( {selection: tempSelection} );
                            this.setState( {fileName: event.name});
    
                            if (this.state.shared === true) {
                                this.setState( {shared: false} );
                            }
                        });
                    } catch {
                        alert("Could not create program");
                    }
                }                
            } else {
                setTimeout(this.reOpenNameChange, 250);
            }
        } else {
            this.nameChangeElement.current.handleClose();
        }
        this.startAutoSave();
    }

    reOpenNameChange = () => {
        this.nameChangeElement.current.handleOpen();
    }

    checkGradeProgram = (event) => {

        if (event['resp'] === "YES") {
            try {
                //
                const uri = this.context.AuthInstance.sharedProgramList[this.props.progDetails.sharedIndex].resource_uri;
                const stateData = 'graded';
                const gradeData = event['grade'];
                return this.context.AuthInstance.saveSharedProgram(uri, stateData, gradeData).then(() => {
                    return this.context.AuthInstance.getSharedProgramList().then(() => {
                        this.setState( {shareState: stateData} );
                        this.markGradedElement.current.handleClose();
                    });
                });
                        
            } catch {
                alert("Could not grade program");
            }

        }
        this.markGradedElement.current.handleClose();
    }


    checkDeleteProgram = (event) => {

        if (event === "OK") {

            try {
                //
                const data = {
                    deleted: true,
                    revision: this.state.selection.revision,
                };
                this.context.AuthInstance.deleteProgram(this.state.selection.resource_uri, data).then(res => {
                    // Refresh For ProgramList
                    this.context.AuthInstance.getProgramList().then(res => {
                        if (this.state.activeStep !== 3) {
                            // Send Information to Parent Program component, through passed down "onCloseDeleteProgram" function
                            var closeDetails = {progAction: "KeepProg"};
                            this.props.onCloseDeleteProgram(closeDetails)
                        } else {
                            this.props.handleSplitClose('gui');
                        }
                    });
                }); 
                        
            } catch {
                alert("Could not delete program");
            }

        } 
        this.deleteProgramElement.current.handleClose();
    }

    unlinkSaveProgram = (event) => {
        if (event === "OK") {

            let selection = Object.assign({}, this.props.progDetails.selection);
            selection.language = 'python';
            selection.instructions = this.state.buffer;

            this.context.AuthInstance.createProgram(this.props.progDetails.selection.name, 
                                                    'editor', 
                                                    selection.language,
                                                    selection.instructions,
                                                    this.props.progDetails.hardware,
                                                    )
            .then(res => {
                // Get resource_uri for saving
                selection.resource_uri = res.resource_uri;
                selection.revision = 1;
                this.setState( {selection: selection} );
                this.setState( {fileName: this.props.progDetails.selection.name} );
                
                this.setState({
                    isSaved: true,            
                    nameStyle: "normal",
                });
    
                this.setState( {progDetailsNotSaved: false} );
                this.setState( {isLinked: false} );
                this.props.handleUnlink();
                this.startAutoSave();
            });
        } else {

            // Refresh For ProgramList
            this.context.AuthInstance.getProgramList().then(res => {
                if (this.state.activeStep !== 3) {
                    // Send Information to Parent Program component, through passed down "onCloseDeleteProgram" function
                    var closeDetails = {progAction: "KeepProg"};
                    this.props.onCloseDeleteProgram(closeDetails)
                } else {
                    this.props.handleSplitClose('gui');
                }
            });
        }
        this.unlinkProgramElement.current.handleClose();
    }


    checkSaveProgram = (event) => {
        
        if (event === "OK") {

            try {
                let instructions = this.state.buffer;
                if (instructions === "") {
                    instructions = " ";
                }

                if (this.props.progDetails.hasOwnProperty('notSaved')) {
                    if (this.state.progDetailsNotSaved === true) {

                        //let selection = this.props.progDetails.selection;
                        let selection = Object.assign({}, this.props.progDetails.selection);
                        
                        // 12-20-22 new
                        selection.language = 'python';
                        selection.instructions = this.state.buffer;
                        
                        this.context.AuthInstance.createProgram(this.props.progDetails.selection.name, 
                                                                'editor', 
                                                                'python', 
                                                                selection.instructions,//this.props.progDetails.selection.instructions,
                                                                this.props.progDetails.hardware,
                                                                )
                        .then(res => {
                            // Get resource_uri for saving
                            selection.resource_uri = res.resource_uri;
                            selection.revision = 1;
                            this.setState( {selection: selection} );
                            this.saveProgramElement.current.handleClose();

                            // Refresh For ProgramList
                            this.context.AuthInstance.getProgramList().then(res => {
                                this.setState({
                                    isSaved: true,            
                                    nameStyle: "normal",
                                });
                                // Send Information to Parent Program component, through passed down "onCloseDeleteProgram" function
                                var closeDetails = {progAction: "KeepProg"};
                                this.props.onCloseDeleteProgram(closeDetails)
                            });
                        });  
                        this.setState( {progDetailsNotSaved: false} );
                        return;
                    }
                }
        
                const data = {
                    name: this.state.selection.name,
                    instructions: instructions,
                    revision: this.state.selection.revision,
                    deleted: this.state.selection.deleted,
                }
                
                this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(res => {
            
                    let tempSelection = this.state.selection;
                    tempSelection.revision = this.state.selection.revision + 1;
                    this.setState( {selection: tempSelection} );

                    // Refresh For ProgramList
                    this.context.AuthInstance.getProgramList().then(res => {
                        if (this.state.activeStep !== 3) {
                            // Send Information to Parent Program component, through passed down "onCloseDeleteProgram" function
                            var closeDetails = {progAction: "KeepProg"};
                            this.props.onCloseDeleteProgram(closeDetails)
                        } else {
                            this.props.handleSplitClose('gui');
                        }
                    });
                });
            } catch {
                alert("Could not save program");
            }
        } else {

            // Refresh For ProgramList
            this.context.AuthInstance.getProgramList().then(res => {
                if (this.state.activeStep !== 3) {
                    // Send Information to Parent Program component, through passed down "onCloseDeleteProgram" function
                    var closeDetails = {progAction: "KeepProg"};
                    this.props.onCloseDeleteProgram(closeDetails)
                } else {
                    this.props.handleSplitClose('gui');
                }
            });
        }
        this.saveProgramElement.current.handleClose();
    }

    linkedBufferUpdate = (buffer) => {
        this.setState( {buffer: buffer} );
    }

    handleShareBtn = () => {
        //if (this.state.isSaved === true) {
        //    this.shareProgramElement.current.handleOpen(this.state.selection.resource_uri, this.state.selection.name);
        //} else {
        this.stopAutoSave();
        let instructions = this.state.buffer;
        if (instructions === "") {
            instructions = "\n";
        }

        const data = {
            name: this.state.selection.name,
            fname: this.context.AuthInstance.fn,
            lname: this.context.AuthInstance.ln,
            instructions: instructions,
            revision: this.state.selection.revision,
            deleted: this.state.selection.deleted,
        }
        
        this.context.AuthInstance.saveProgram(this.state.selection.resource_uri, data).then(() => {

            this.setState({
                isSaved: true,            
                nameStyle: "normal",
            });

            let tempSelection = this.state.selection;
            tempSelection.instructions = instructions;
            tempSelection.revision = this.state.selection.revision + 1;
            this.setState( {selection: tempSelection} );

            this.shareProgramElement.current.handleOpen(this.state.selection.resource_uri, this.state.selection.name);
            this.startAutoSave();
        });
        //}
    }

    handleLXEdit = () => {

        this.lxSettingsElement.current.handleOpen("SETTINGS");


    }



    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        const disablePlay = (this.state.isLinked && (this.props.progDetails.hardware === 'LocoXtreme'));

        // What to Display - A Toolbar, AceEditor, and initially-hidden ProgramDisplay Child component
        return (
            <div id="ace-div" className={classes.root}  style={{maxHeight: '100vh', overflow: 'visible'}}>
                <AppBar position="static" color="default" className={classes.appBar}>
                    <Toolbar>
                        <div className={classes.inline}>
                            <Button
                                name="nameBtn"
                                color="primary"
                                variant="contained"
                                className={classes.nameButton}
                                onClick={this.handleNameBtn}
                                disabled={this.state.isAutoSaving}
                                style={{fontStyle: this.state.nameStyle, fontSize: '25px', textTransform: "none"}}
                            >
                                {this.state.fileName}
                            </Button>
                            {(this.state.shared === true 
                                && this.state.shareState === 'accepted' &&
                                this.context.AuthInstance.account_type !== 'student') && (
                                <Button
                                    name="gradeBtn"
                                    color="primary"
                                    variant="contained"
                                    className={classes.nameButton}
                                    onClick={this.handleMarkGradedBtn}
                                    style={{fontStyle: this.state.nameStyle, fontSize: '25px', textTransform: "none"}}
                                >
                                    Mark Graded
                                </Button>
                            )}
                            {(this.state.showLastSaved === true && (
                                <Typography
                                    color="primary"
                                    variant="button"
                                    className={classes.lastSavedText}
                                    style={{fontSize: '18px', textTransform: "none"}}
                                >
                                    {this.state.lastSavedMsg}
                                </Typography>
                            ))}
                        </div>
                        <div className={classes.grow} />
                        <div>                            
                            {((this.state.isLinked === false) && (this.props.progDetails.hardware === 'LocoXtreme')) && (
                                <Button
                                    name="lxEditBtn"
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleLXEdit}
                                    style={{textTransform: "none"}}
                                >
                                    Robot Settings
                                </Button>
                            )}
                            {(this.props.progDetails.hardware === 'LocoXtreme') && (
                                <IconButton
                                    name="searchBtn"
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleSearchBtn}
                                >
                                    <SearchIcon fontSize="inherit" />
                                </IconButton>
                            )}
                            {((this.props.progDetails.hardware === 'LocoDroneT') || 
                                ((this.props.progDetails.hardware === 'LocoDroneTT'))) && (
                                <IconButton
                                    name="searchBtn"
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleSearchBtnLDT}
                                >
                                    <SearchIcon fontSize="inherit" />
                                </IconButton>
                            )}
                            <IconButton
                                name="playBtn"
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.handlePlayBtn}
                                disabled={this.state.isAutoSaving}
                            >
                                <PlayArrowIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                                name="saveBtn"                
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.handleSaveBtn}
                                disabled={this.state.isAutoSaving}
                            >
                                <SaveIcon fontSize="inherit" />
                            </IconButton>
                            {((this.state.shared !== true) && (this.state.isLinked !== true)) && (
                                <IconButton
                                    name="shareBtn"                
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleShareBtn}
                                    disabled={this.state.isAutoSaving}
                                >
                                    <ShareIcon fontSize="inherit" />
                                </IconButton>
                            )}
                            {((this.state.shared !== true) && (this.state.isLinked !== true)) && (
                                <IconButton
                                    name="deleteBtn"                
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleDeleteBtn}
                                    disabled={this.state.isAutoSaving}
                                >
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            )}
                            <IconButton
                                name="closeBtn"                
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.handleCloseBtn}
                                disabled={this.state.isAutoSaving}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                        
                    </Toolbar>
                </AppBar>

                <AceEditor
                    name="editor"
                    width="100%"
                    height='800px'
                    mode="python"
                    theme="monokai"
                    className={"code-screen__editor-wrapper"}
                    value={this.state.buffer}
                    onChange={this.handleChange}
                    style={{fontSize: '25px',
                            maxHeight: `calc(100vh - ${window.navBarOffset} - ${window.programBarOffset} - ${window.extraOffset})`, 
                            overflow: 'auto'}}
                />
                <ProgramDisplay 
                    ref={this.progDisplayElement}
                    onStop={this.stopRunning}
                    onEmergency={this.stopEmergency}
                />
                <NameChange
                    ref={this.nameChangeElement}
                    onClose={this.nameChangeEvent}
                />
                <DeleteProgram
                    ref={this.deleteProgramElement}
                    onClose={this.checkDeleteProgram}
                />
                <SaveProgram
                    ref={this.saveProgramElement}
                    onClose={this.checkSaveProgram}
                />
                <UnlinkSave
                    ref={this.unlinkProgramElement}
                    onClose={this.unlinkSaveProgram}
                />
                <ShareProgram
                    ref={this.shareProgramElement}
                />
                <SavedProgram
                    ref={this.savedProgramElement}
                />
                <MarkGraded
                    ref={this.markGradedElement}
                    onClose={this.checkGradeProgram}
                />
                <LXSettingsModal
                    ref = {this.lxSearchElement}
                />
                <LXSettingsModal
                    ref = {this.lxSettingsElement}
                    onClose = {() => void 0}
                />
                <LDTSettingsModal
                    ref = {this.ldtSearchElement}
                />
            </div>
        );
    }

}

export default withStyles(styles)(ProgramCoding);